import axios from 'axios';
import CryptoJS from 'crypto-js';

export default class AuthenticationService {
    #AUTH_TOKEN_URI;
    #AUTH_CLIENT_ID;
    #AUTH_REDIRECT_URI;
    #AUTH_AUTHORIZATION_URI;

    constructor(tokenUri, clientId, redirectUri, authorizationUri) {
        this.#AUTH_TOKEN_URI = tokenUri;
        this.#AUTH_CLIENT_ID = clientId;
        this.#AUTH_REDIRECT_URI = redirectUri;
        this.#AUTH_AUTHORIZATION_URI = authorizationUri;
    }

    // Create singleton instance: begin
    static #singleInstance = null;

    static getSingleInstance() {
        if (!AuthenticationService.#singleInstance) {
            AuthenticationService.#singleInstance = new AuthenticationService(
                process.env.VUE_APP_FED_URL + '/as/token.oauth2',
                process.env.VUE_APP_FED_CLIENT_ID,
                process.env.VUE_APP_AUTH_REDIRECT_URI || AuthenticationService.#getDefaultAuthRedirectUri(),
                process.env.VUE_APP_FED_URL + '/as/authorization.oauth2'
            );
        }
        return AuthenticationService.#singleInstance;
    }

    static #getDefaultAuthRedirectUri() {
        const env = process.env.VUE_APP_ENV; // dev, preprod, prod
        const isPreProdForPlaywright = Boolean(process.env.VUE_APP_ENV_FOR_PLAYWRIGHT);
        let redirectUri = "";
        switch (env) {
            case "dev":
            case "preprod":
                redirectUri = isPreProdForPlaywright ? "https://localhost:8080" :  `https://mdm.${env.toLowerCase()}.decathlon.net`;
                break;
            case "prod":
                redirectUri = "https://mdm.decathlon.net";
                break;
            default:
                redirectUri = "https://localhost:8080";
                break;
        }
        return redirectUri;
    }
    // Create singleton instance: end

	static generateCodeVerifier() {
		return AuthenticationService.generateRandomString(128);
	}

	static generateRandomString(length) {
		let text = '';
		const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz9876543210-._~';
		const array = new Uint32Array(length);
		self.crypto.getRandomValues(array);

		for (const num of array) {
			text += possibleText.charAt(
				Math.floor((num / Math.pow(10, num.toString().length)) * possibleText.length)
			);
		}
		return text;
	}

	static generateCodeChallenge(code_verifier) {
		sessionStorage.setItem('code_verifier', code_verifier);
		const hashedCodeVerifier = CryptoJS.SHA256(code_verifier).toString(CryptoJS.enc.Base64);
		return AuthenticationService.base64URL(hashedCodeVerifier);
	}

	static base64URL(code) {
		return code.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
	}

	getAccessToken() {
		return sessionStorage.getItem('access_token');
	}

	getRefreshToken() {
		return sessionStorage.getItem('refresh_token');
	}

	async updateTokens() {
		try {
			if (this.getRefreshToken()) {
				const response = await axios.post(
                    this.#AUTH_TOKEN_URI,
					new URLSearchParams({
						grant_type: 'refresh_token',
						refresh_token: this.getRefreshToken()
					}),
					{
						headers: {
							Accept: 'application/json, text/plain, */*',
							'Content-Type': 'application/x-www-form-urlencoded'
						}
					}
				);

				const sessionInfos = response.data;

				if (!sessionInfos.access_token) {
					sessionStorage.clear();
				}

				Object.keys(sessionInfos).forEach(function (key) {
					sessionStorage.setItem(key, sessionInfos[key]);
				});

				return sessionInfos;
			}

			await this.connectIfNoAccessToken();
		} catch (error) {
			sessionStorage.clear();

			await this.connectIfNoAccessToken();
		}
	}

	async connectIfNoAccessToken() {
        // Store the current URL(except: ?code=) before redirecting for authentication
        if (!window.location.href.includes("?code=")) sessionStorage.setItem('previous_url', window.location.href);

		if (!sessionStorage.getItem('code_verifier')) {
			const code_verifier = AuthenticationService.generateCodeVerifier();
			const code_challenger = AuthenticationService.generateCodeChallenge(code_verifier);
			const authCodeFlowConfig = new URLSearchParams({
				client_id: this.#AUTH_CLIENT_ID,
				redirect_uri: this.#AUTH_REDIRECT_URI,
				response_type: 'code',
				scope: 'openid profile',
				code_challenge_method: 'S256',
				code_challenge: code_challenger
			});

			window.location.replace(this.#AUTH_AUTHORIZATION_URI + '?' + authCodeFlowConfig);
		}

		if (sessionStorage.getItem('code_verifier') && !sessionStorage.getItem('access_token')) {
			const urlParam = new URLSearchParams(window.location.search);
			const authorization_code = urlParam.get('code');
			if (!authorization_code) return;

			const result = await axios.post(
				this.#AUTH_TOKEN_URI,
				new URLSearchParams({
					grant_type: 'authorization_code',
					client_id: this.#AUTH_CLIENT_ID,
					code: authorization_code,
					redirect_uri: this.#AUTH_REDIRECT_URI,
					code_verifier: sessionStorage.getItem('code_verifier')
				}),
				{
					headers: {
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				}
			);
			const sessionInfos = result.data;

			if (!sessionInfos?.access_token) return;
			Object.keys(sessionInfos).forEach(function (key) {
				sessionStorage.setItem(key, sessionInfos[key]);
			});
        }

        // Redirect back to the previous URL after successful authentication
        const previousUrl = sessionStorage.getItem('previous_url');
        if (previousUrl) {
            sessionStorage.removeItem('previous_url');
            window.location.replace(previousUrl);
        } else {
            window.location.replace(this.#AUTH_REDIRECT_URI);
        }
	}
}
