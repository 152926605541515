import applicationServices from '../service/application/application-service';

export default {
    async getApplicationLogo(application) {
        return {icon:await applicationServices.getApplicationIcon(application.id)};
    },
    async getApplicationLogosByPackageNames(packageNamesPayload) {
        return await applicationServices.getApplicationIconsByPackageNames(packageNamesPayload);
    }

};
