<template>
    <div class="flip-card">
        <div :class="{'flip-card-inner': true, 'flip-card-rotate': rotate}">
            <div ref="cardFront" class="flip-card-front">
                <slot name="content-front" />
            </div>
            <div ref="cardBack" class="flip-card-back" :style="{width: maxWidth + 'px'}">
                <slot name="content-back" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mdm-flip',
    data: function() {
        return {
            maxWidth: 200
        }
    },
    props: {
        rotate: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        rotate: function() {
            this.$refs.cardBack.style.width = 'fit-content';
            this.maxWidth = this.$refs.cardBack.offsetWidth;
        }
    },
}
</script>

<style lang="scss" scoped>

.flip-card {
    background-color: transparent;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    perspective: 1000px; 

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .flip-card-rotate {
        transform: rotateY(180deg);
    }

    .flip-card-front, .flip-card-back {
        position: relative;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .flip-card-back {
        position: absolute;
        top: 0;
        transform: rotateY(180deg);
    }
} 
</style>
