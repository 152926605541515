<template>

    <div class="page-content">
        <div class="mainSearch" :class="{detailedSearchDisplayed : isDisplay}">
            <device-general-search id="devices-full-search"
                                   placeholder="Search for a device, a user, ..."
                                   :has-to-showlabel="false"
                                   :hasToCheckNumericType="false"
                                   @startSearch="loadFilteredDataAndReinitOrderBy(activeFilters, false, true)"
                                   @criteriaSelected="getSelectedCriteria"
                                   :value="activeFilters"
                                   :already-selected-error="alreadySelected"
                                   @updateAlreadySelected="updateAlreadySelected"
                                   @displayDetailedSearch="display"
                                   @reloadSecondFilterList="reloadSecondFilterList"
            />
        </div>

        <div class="detailedSearch" v-if="isDisplay">
            <v-container class="mt-0 pt-0">
                <div class="orSearchBy">Or search by...</div>
                <device-filter :active-focus="isFocus"
                               :refresh="refresh"
                               :arbo="arbo"
                               :arboChange="arboChange"
                               :detailed-search-criteria="activeFilters"
                               @newDetailedSearchCriteria="addCriteriaInDetailedSearchCriteria"
                               @newSearch="newSearch"
                               @newGlobalSearch="newSearch(false, true)"
                               @hide-detailed-search="isDisplay = false" />
            </v-container>
        </div>

        <div class="content content-all-devices"
            ref="contentAllDevices"
            v-if="activeFilters" @click="isDisplay = false">

            <div v-if='loading' class='overlay' />
            <md-progress-spinner v-if='loading' class='loading'  md-mode="indeterminate"
                                 :md-diameter="100" :md-stroke="10" />

            <div :style="cssVars()" :class="{transparentFilter:isDisplay}"  />

            <device-search-header :devices-count-stats="devicesCountStats"
                                  :devices-total="devicesTotal"
                                  :active-filter="activeFilterForHeader"
                                  @addFilter="addHeadersFilters"
            />

            <div v-if='filteredData'>
                <div v-if='this.nbOfDevice <= 0' class='noResult'>{{Constants.DEVICE.SEARCH.NO_SEARCH_RESULTS}}</div>
            </div>

            <v-row >
                <v-col cols="4">
                    <pagination :page-size="PAGE_SIZE" :nb-of-elems="nbOfDevice" :elems-total="devicesTotal"
                                :current-page="currentPage" @changePage="changeCurrentPage"/>
                </v-col>

                <v-col cols="3">
                    <device-filter-columns :active-application-filter="applicationActiveFilter" @getDisplayedHeaders="getDisplayedHeaders" />
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="2" align="center">
                    <div v-if="csvExport.loading" class="progressSpinner">
                        <v-progress-circular
                            :rotate="-90"
                            :size="50"
                            :width="5"
                            :value="spinnerProgressStatus"
                            color="primary"
                        >
                            {{ spinnerProgressStatus }}
                        </v-progress-circular>
                    </div>
                    <div v-else-if="csvExport.error">
                        <mdm-toaster :modelValue="csvExport.error" @close="csvExport.error = false">
                            <p>An error occurred when trying to build the export, contact your administrator or try to change your filters.</p>
                        </mdm-toaster>
                    </div>
                    <div v-else>
                        <button class="vtmn-btn mb-8" id="devices-icon-export"
                                @click="exportToCsv"><i class="ri-database-line"></i> Export</button>
                    </div>

                </v-col>
            </v-row>

            <device-search-table :devices="filteredData" :order-by="orderBy" :headers="headers"
                                 @startSearch="loadFilteredDataAndSetOrderBy" />

            <mdm-toaster :modelValue="devicesError" @close="devicesError = false">
                <p>{{Constants.DEVICE.SEARCH.ERROR_SEARCHING_DEVICES}}</p>
            </mdm-toaster>

        </div>

        <md-button v-if='filterFix' class="md-raised topbutton" @click="topFunction()" id="myBtn" title="Go to top">
            <md-icon>keyboard_arrow_up</md-icon>
            Top
        </md-button>
    </div>
</template>

<script>

    import DeviceFilter from "../../components/Filter/device-filter";
    import DeviceService from "../../service/devices/device-service";
    import FieldConstant from "../../utils/field-constant";
    import DeviceGeneralSearch from "./device-general-search";
    import "vue-material/dist/theme/default.css";
    import DeviceSearchTable from "./search/device-search-table";
    import MdmVtmnPopup from "../../../src/components/mdm/vitamine/popup/mdm-vtmn-popup";
    import DeviceSelectDataToDisplay from "../../../src/pages/devices/search/device-select-data-to-display";
    import DeviceSearchConstants from "../../../src/utils/device-search-constant";
    import LocalStorageConstant from "../../constant/local-storage-constant";
    import LocalStorageService from "../../service/local-storage-service";
    import DeviceFilterColumns from "../../components/Filter/device-filter-columns";
    import DeviceFilterColumnsAlert from "../../components/Alerts/device-filter-columns-alert";
    import DeviceSearchHeader from "./search/device-search-header";
    import DeviceFilterSite from "./search/device-filter-site";
    import DeviceFilterService from "../../service/devices/device-filter-service";
    import ExportService from "../../service/devices/export-service";
    import Constants from "../../constant/constants";

    export default {
        components: {
            DeviceFilter,
            DeviceGeneralSearch,
            MdmVtmnPopup,
            DeviceSelectDataToDisplay,
            DeviceFilterColumns,
            DeviceFilterColumnsAlert,
            DeviceSearchTable,
            DeviceSearchHeader,
            DeviceFilterSite
        },
        data: function () {
            return {
                alreadySelected: false,
                NO_VALUE: FieldConstant.TEXT.NO_VALUE,
                PAGE_SIZE: FieldConstant.DEVICE.SEARCH.PAGE_SIZE,
                arbo: LocalStorageService.getJsonItem(LocalStorageConstant.ACTIVE_ARBO),
                loading: false,
                csvExport: {
                    loading: false,
                    error: false,
                },
                devices: null,
                devicesTotal: 0,
                devicesCountStats: null,
                nbOfDevice: 0,
                totalPages: 0,
                currentPage: 1,
                orderBy: {
                    key: undefined,
                    order: undefined,
                },
                allColumns: [
                    {"displayName": "name", "id": "name", "idt": "name"},
                    {"displayName": "bu", "id": "entity", "idt": "entity"},
                    {"displayName": "site", "id": "siteId", "idt": "site_id"},
                    {"displayName": "country", "id": "country", "idt": "country"},
                    {"displayName": "category", "id": "category", "idt": "device_type"},
                    {"displayName": "manufacturer", "id": "manufacturer", "idt": "manufacturer"},
                    {"displayName": "model", "id": "model", "idt": "model"},
                    {"displayName": "agent", "id": "agent", "idt": "agent"},
                    {"displayName": "os", "id": "os", "idt": "os"},
                    {"displayName": "version", "id": "osVersion", "idt": "os_version"},
                    {"displayName": "serial n°", "id": "serialNumber", "idt": "serial_number"},
                    {"displayName": "imei", "id": "imeiValue", "idt": "imei_value"},
                    {"displayName": "last check", "id": "dateLastCheck", "idt": "date_last_check"},
                    {"displayName": "user", "id": "user", "idt": "connected_user"},
                    {"displayName": "Ownership", "id": "displayPerso", "idt": "enterprise"}
                ],
                activeFilters: [],
                applicationActiveFilter: false,
                selectedCriteria: [],
                filterFix: false,
                filteredData: [],
                devicesApplications: {},
                isDisplay: false,
                refresh: false,
                isFocus: false,
                headers: this.getDisplayedHeaders(),
                devicesError: false,
                Constants
            };
        },
        computed: {
            getColumnsDefinition() {
                if (this.$vuetify.breakpoint.smAndDown) {
                    const displayColumnForMobile = this.displayColumnForMobile();
                    return this.allColumns.filter(({id}) => displayColumnForMobile.includes(id));
                }
                return this.allColumns;
            },
            activeFilterForHeader() {
                if(this.isCriteriaExist(FieldConstant.FILTER.AAM)) return FieldConstant.FILTER.AAM;
                if(this.isCriteriaExist(FieldConstant.FILTER.DS)) return FieldConstant.FILTER.DS;
                if(this.isCriteriaExist(FieldConstant.FILTER.DS2)) return FieldConstant.FILTER.DS2;
                return FieldConstant.FILTER.ALL;
            },
            spinnerProgressStatus() {
                return this.$store.state.devices.devices.exportSpinnerProgressStatus;
            }

        },
        mounted: async function () {
            if (LocalStorageService.getItem(LocalStorageConstant.ACTIVE_FILTERS)) {
                this.activeFilters = DeviceFilterService.parseLocalStorageActiveFilter(LocalStorageService.getJsonItem(LocalStorageConstant.ACTIVE_FILTERS));
            }
            this.applicationActiveFilter = this.isApplicationActiveFilter();
            this.loading = true;
            await this.loadFilteredData(this.activeFilters, this.page, this.orderBy);
            this.loading = false;
            window.addEventListener("scroll", this.handleScroll);
        },
        watch: {
            activeFilters: {
                handler() {
                    LocalStorageService.setItem(LocalStorageConstant.ACTIVE_FILTERS, {activeFilters: this.activeFilters, version: LocalStorageConstant.FILTER_VERSION});
                    this.applicationActiveFilter = this.isApplicationActiveFilter();
                }
            },
        },
        methods: {
            isApplicationActiveFilter(){
                return this.activeFilters.some(filter => filter.type === 'application')
            },
            updateAlreadySelected(alreadySelected){
                this.alreadySelected = alreadySelected;
            },
            arboChange(arbo) {
                this.arbo = arbo;
                this.isDisplay = false;
                this.loadFilteredData(this.activeFilters, this.currentPage, this.orderBy);
            },
            displayColumnForMobile() {
                return ["name", "siteId", "country", "category", "model", "user"];
            },
            setOrderBy(value) {
                this.orderBy = value;
            },
            loadFilteredDataAndReinitOrderBy(activeFilters, needReloadConnectedStat = false, isNewGlobalSearch) {
                this.alreadySelected = false;
                this.isDisplay = false;
                this.currentPage = 1;
                this.setOrderBy(undefined);
                if(!activeFilters.some(filter => filter.type === 'application')){
                    this.headers = this.headers.filter(header => header.category !== 'Application')
                    LocalStorageService.setItem(LocalStorageConstant.FILTERS_COLUMN, this.headers);
                }
                this.loadFilteredData(activeFilters, this.currentPage, this.orderBy, true, isNewGlobalSearch);
            },
            loadFilteredDataAndSetOrderBy(orderBy) {
                this.setOrderBy(orderBy);
                this.loadFilteredData(this.activeFilters, this.currentPage, this.orderBy);
            },
            getSelectedCriteria(criterias) {
                this.selectedCriteria = criterias;
            },
            loadFilteredData: async function (activeFilters, page, orderBy, needReloadConnectedStat = false, isNewGlobalSearch) {
                this.devicesError = false;
                this.devicesCountStats = needReloadConnectedStat ? { totalAam: this.devicesCountStats.totalAam, totalDs: this.devicesCountStats.totalDs } : null;
                try {
                    this.activeFilters = activeFilters;
                    this.$ga.event({
                        eventCategory: "devices",
                        eventAction: "filter",
                        eventLabel: JSON.stringify(activeFilters)
                    });
                    this.loading = true;
                    const devices = await DeviceService.searchDevice(activeFilters, page, orderBy, this.arbo);
                    this.devicesCountStats = await DeviceService.countDevices(activeFilters, this.arbo, needReloadConnectedStat ? this.devicesCountStats : null, isNewGlobalSearch);
                    this.filteredData = devices.devices;
                    this.devicesTotal = Number(devices.devicesTotal || 0);
                    this.nbOfDevice = devices.nbOfDevice;
                    this.loading = false;
                    this.storageStoreFoundDevices();
                    return devices.devices;
                } catch (e) {
                    console.error("We are unable to load devices due to an error", e)
                    this.devicesError = true;
                } finally {
                    this.loading = false;
                }
            },
            topFunction: function () {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            },
            storageStoreFoundDevices: function (id) {
                sessionStorage.setItem(FieldConstant.DEVICE.SEARCH.ARROW_SESSION_STORAGE_KEY, this.filteredData.map(el=>el.id));
            },
            changeCurrentPage: function (page) {
                this.loadFilteredData(this.activeFilters, page, this.orderBy, true);
                this.currentPage = page;
            },
            handleScroll: function () {
                if (!this.$vuetify.breakpoint.mobile && (document.body.scrollTop > 320 || document.documentElement.scrollTop > 320 || window.pageYOffset > 320)) {
                    this.filterFix = true;
                } else {
                    this.filterFix = false;
                }
            },
            display: function () {
                this.isDisplay = true;
            },
            addCriteriaInDetailedSearchCriteria: function (criteria, isClickSearchButton) {
                this.alreadySelected = false;
                let replaced = false;
                for(let i = 0; i < this.activeFilters.length; i++){
                    if(criteria.type === this.activeFilters[i].type){
                        if(isClickSearchButton) {
                            replaced = true;
                            this.activeFilters.splice(i, 1);
                            this.activeFilters.splice(i, 0, criteria);
                        }
                        else this.alreadySelected = true;
                    }
                }
                if(!this.alreadySelected && !replaced) this.activeFilters.push(criteria);
            },
            addHeadersFilters: function (type) {
                const aamCriteria = this.isCriteriaExist(FieldConstant.FILTER.AAM);
                const dsCriteria  = this.isCriteriaExist(FieldConstant.FILTER.DS);
                const ds2Criteria  = this.isCriteriaExist(FieldConstant.FILTER.DS2);

                if([FieldConstant.FILTER.AAM, FieldConstant.FILTER.DS, FieldConstant.FILTER.DS2].includes(type)) {
                    this.activeFilters = this.activeFilters.filter(criteria => !(this.searchTypeCallBack(type === FieldConstant.FILTER.AAM ? FieldConstant.FILTER.DS : FieldConstant.FILTER.AAM, criteria)));
                    if(!(type === FieldConstant.FILTER.AAM ? aamCriteria : FieldConstant.FILTER.DS ? dsCriteria : ds2Criteria)) this.activeFilters.push(FieldConstant.FILTER.SEARCH_HEADER_CRITERIA[type]);
                } else {
                    this.activeFilters = this.activeFilters.filter(criteria => !(this.searchTypeCallBack(FieldConstant.FILTER.AAM, criteria) || this.searchTypeCallBack(FieldConstant.FILTER.DS, criteria) || this.searchTypeCallBack(FieldConstant.FILTER.DS2, criteria)));
                }
                this.newSearch(true);
            },
            isCriteriaExist(type) {
                return this.activeFilters.some(criteria => this.searchTypeCallBack(type, criteria));
            },
            searchTypeCallBack(type, criteria) {
                return criteria.type === FieldConstant.FILTER.SEARCH_HEADER_CRITERIA[type].type && FieldConstant.FILTER.SEARCH_HEADER_CRITERIA[type].includedValues.includes(criteria.value)
            },
            newSearch: function (needReloadConnectedStat = false, isNewGlobalSearch) {
                this.isDisplay = false;
                this.loadFilteredDataAndReinitOrderBy(this.activeFilters, needReloadConnectedStat, isNewGlobalSearch);
            },
            reloadSecondFilterList: function () {
                this.refresh = true;
            },
            getDisplayedHeaders: function (headers) {
                if (!LocalStorageService.getItem(LocalStorageConstant.FILTERS_COLUMN)) {
                    headers = DeviceSearchConstants.HEADERS.filter(header => header.default === true || header.selected === true)
                } else {
                    headers = JSON.parse(LocalStorageService.getItem(LocalStorageConstant.FILTERS_COLUMN));
                }
                this.pushLastCheckToTheEnd(headers)
                this.refreshTable(headers)
                return headers
            },
            cssVars() {
                if (this.$refs.contentAllDevices && this.isDisplay) return {height: 'calc(100% -  ' + this.$refs.contentAllDevices.offsetTop + 'px - 40px)'};
                return {display: "none"};
            },
            pushLastCheckToTheEnd: function (headers) {
                let headersFormatted = headers
                let lastCheckHeader = headers.filter(header => header.value === "dateLastCheck.value");
                headersFormatted.push(lastCheckHeader[0])
                let index = headersFormatted.indexOf(headersFormatted.find(header => header.value === "dateLastCheck.value"));
                headersFormatted.splice(index, 1)
                return headersFormatted
            },
            refreshTable(headers) {
                this.headers = headers;
            },
            async exportToCsv() {
                this.csvExport.loading = true;
                try{
                    await ExportService.buildFrom(this.activeFilters, this.orderBy, this.arbo);
                }catch(e){
                    console.error("[Error] CSV export : " + e.stack);
                    this.csvExport.error = true
                }
                this.csvExport.loading = false;
            }
        }
    }
</script>


<style lang="scss">

    @import './all.scss';

    .max-width-with-overflow {
        max-width: 100%;
        overflow: scroll;
    }

    .orSearchBy {
        margin-bottom: 0
    }

    .detailedSearch {
        background-color: #fafafa;
    }

    .mainSearch {
        background-color: #efefef;
        border-top: solid 1px var(--vtmn-color_grey-light-3);
    }

    .container {
        border: none !important;
        padding-left: 20px !important;
    }

    .detailedSearchDisplayed {
        background-color: #fafafa;
    }

    .content {
        padding-top: 0 !important
    }

    .transparentFilter {
        position: absolute;
        left: 0;
        display: flex;
        width: 100%;
        background-color: rgba(26, 42, 52, .5);
        z-index: 99;
    }

    .v-application .primary {
        background-color: transparent!important;
    }

    .progressSpinner {
        margin-bottom: 20px;
    }

    #devices-icon-export {
        background-color: #3643BA;
        &:hover{
            background-color: #3643BA !important;
            opacity: 0.7;
        }
    }

</style>


