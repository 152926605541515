<template>
    <div class="page-content">
        <h1 class='page-title'>
            <md-icon class="md-size-2x">phone_android</md-icon>
            <span>New device</span>
        </h1>

        <div class="content pa-1 fill-all-height vertical-align" >
            <wso-enrollment />
        </div>
    </div>

</template>

<script>

import wsoEnrollment from '../../pages/devices/new/step/enrollment/wso-enrollment';

export default {
    name: 'wso',
    components: {
        wsoEnrollment
    }
}
</script>

<style lang="scss">
    @import '~@/assets/style/color';

    .fill-all-height{
        height: -webkit-fill-available;
    }
    .vertical-align{
        display: grid;
        place-items: center;
    }
</style>
