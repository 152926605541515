<template>
    <div id="end-of-kdm-life-panel">
        <md-icon class="danger md-size-5x">error</md-icon>
        <p class="mt-5">You have been redirected on this site because, <b class="kdm-close">the kdm site is now closed.</b></p>
        <p>Before closing the KDM website, we have tried to migrate all your kiosks into this new tool.</p> 
        <p class="my-5">If your kiosk was offline, this one is probably not migrated, don't worry, to migrate your kiosk, you can click on the button below</p>
        <mdm-vtmn-button variant="secondary" @click="goToDevicePage">Search my kiosk into this website</mdm-vtmn-button>
        <mdm-vtmn-button @click="goToProcedure">Migrate an old kiosk to MDM</mdm-vtmn-button>
    </div>
</template>

<script>
export default {
    name: 'end-of-kdm-redirect',
    methods: {
        goToProcedure() {
            window.open('https://wiki.decathlon.net/display/SDX/Migrate+manually+KDM+kiosk+to+MDM', '_blank');
        },
        goToDevicePage() {
            this.$router.push("/devices");
        }
    }
}
</script>

<style lang="scss" scoped>
#end-of-kdm-life-panel {
    margin: auto;
    box-shadow: 0px 2px 5px;
    background-color: var(--vtmn-semantic-color_background-primary);
    text-align: center;
    padding: 30px;

    .kdm-close {
        color: var(--vtmn-semantic-color_background-alert);
    }
}
</style>