<template>
    <unknown-step v-if="!id" />

    <md-step v-else :id="id" :md-label="label" :md-description="description" @click="onClick" :md-done="mdDone">
        <component :is="render"
            @selected="selected"
            :data="data" :extra-data="extraData"
        />
    </md-step>
</template>

<script>
    import UnknownStep from './unknown-step';

    export default {
        name: 'GenericStep',
        components: {
            UnknownStep
        },
        props: {
            id: {
                type: String
            },
            label: {
                type: String
            },
            description: {
                type: String
            },
            onClick: {
                type: Function
            },
            selected: {
                type: Function
            },
            isFinishedParcours: {
                type: Boolean
            },
            render: {
                type: Object
            },
            data: {
                type: Object
            },
            extraData: {
                type: Object
            },
            mdDone: {
                type: Boolean
            }
        },
    }
</script>
