import Vue from "vue";
import {store} from "../../store/store";

import { sendSocketMsg } from '../../utils/socketRealtime.js';

export default {
  changeDefaultApp(socket, deviceid, packageName) {
    sendSocketMsg(socket, deviceid, "appDefault", packageName);
  }
}
