<template>
    <div>
        <label v-if="this.name.length !== 0" :for="id" class="vtmn-text-input_label">{{name}}</label>

        <input  :readonly="readOnly"
                @input="$emit('update:val', $event.target.value); $emit('touched', 'touched');"
                :value="val"
                type="text"
                class="vtmn-text-input input-fill-all-width"
                :class="{'vtmn-text-input vtmn-text-input--error': isError}"
                :id="id"
                :placeholder="placeholder"
                ref="inputText"
                @keyup.enter="$emit('keyupEnter')"
                @keyup="$emit('keyup')"
                autocomplete = "off"
                @blur= "(e) => $emit('onBlur', e)"
        />

        <div v-if="isError">
            <p><i class="ri-error-warning-line vtmn-text-danger msg-vertical-align"></i> {{errorMessage}}
            </p>
        </div>
    </div>
</template>


<script type="text/javascript">

    export default {
        name: 'mdm-input',
        props: {
            val: {
                type: String
            },
            id: {
                type: String,
                required: true
            },
            name: {
                type: String
            },
            placeholder: {
                type: String,
            },
            errorMessage: {
                type: String,
            },
            isError: {
                type: Boolean,
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            isFocus : {
                Boolean,
                default: false
            }
        },
        mounted: function() {
            if(this.isFocus) this.focus();
        },
        updated: function () {
            if(this.isFocus) this.focus();
        },
        methods: {
            focus: function () {
                this.$nextTick(() => this.$refs.inputText.focus());
            },
        }
    }

</script>
