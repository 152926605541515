<template>
    <div class="filter-component">
        <v-row align="center">
            <v-col class="d-flex align-center flex-column">
                <mdm-select :filters="filters" @getFilterEventWithType="getFilterEventWithType" @getFilterEvent="getFilterEvent"/>
            </v-col>
            <v-col>
                <second-input :filter-type="filterType" :second-filters="secondFilters" :error-message-empty="errorMessageEmpty"
                              :name="name" :is-error-empty="isEmptySearch"
                              :is-os-selected="isOsSelected" :os-version-filters="osVersionFilters"
                              :os-version-placeholder="osVersionPlaceholder"
                              :is-focus="isFocus"
                              :refreshed="refreshed"
                              :nb-items="nbItems"
                              @doNewSearch="doNewSearch" @getDateEvent="getDateEvent"
                              @getFilterEventWithoutType="getFilterEventWithoutType"
                              @getOsVersionFilterEvent="getOsVersionFilterEvent"
                              @textSearchCriteriaEvent="getTextSearchCriteria"
                              @addOsVersionInSearchCriteria="addOsVersionInSearchCriteria"
                              @checkToRefreshList="checkToRefreshList"
                              ref="secondSearchInput"
                              />

            </v-col>
            <v-col class="d-flex align-center mt-1">
                <button class="d-flex align-center justify-center vtmn-btn search-btn mr-2"
                        @click="doNewSearch">
                    Search
                </button>

                <button class="d-flex align-center justify-center vtmn-btn add-btn"
                        @click="createNewSearchCriteria(false)"><i
                    class="ri-add-line"></i></button>
            </v-col>
            <v-col class="d-flex align-center mt-1">
                <device-filter-site @storeSelected="arboChange" :arbo="arbo"/>
            </v-col>
        </v-row>
    </div>

</template>


<script>
import MdmSelect from "../../components/mdm/vitamine/input/mdm-select";
import MdmInput from "../mdm/vitamine/input/mdm-input";
import MdmDatePicker from "../mdm/vitamine/input/mdm-date-picker";

let utils = require("../../utils/utils.js");
let messageConstant = require("../../../src/utils/message-constant")
import FieldConstant from "../../utils/field-constant";
import DeviceService from "../../service/devices/device-service";
import SecondInput from "./device-filter-second-input";
import DeviceFilterService from '../../service/devices/device-filter-service';
import DeviceFilterSite from "../../pages/devices/search/device-filter-site";

export default {
    components: {
        MdmSelect,
        MdmInput,
        MdmDatePicker,
        SecondInput,
        DeviceFilterSite
    },
    props : {
        detailedSearchCriteria : {
            type: Array
        },
        refresh : {
            type: Boolean
        },
        activeFocus : {
            type: Boolean
        },
        isDisplayDatePickerProps: {
            type: Boolean
        },
        arbo: {
            type: Object,
            default: null
        },
        arboChange: {
            type: Function,
            default(){
                return null
            }
        }
    },
    created: async function () {
        this.getFilterEventWithType(FieldConstant.FILTER.DEVICE_FILTER[0].name, FieldConstant.FILTER.DEVICE_FILTER[0].type);
    },
    updated() {
        this.isFocus = this.activeFocus;
    },

    data: function () {
        return {
            refreshed: false,
            nbItems: 0,
            errorMessageEmpty: messageConstant.messageConstant.ERROR_EMPTY_INPUT,
            name: "",
            filters: FieldConstant.FILTER.DEVICE_FILTER,
            value: [],
            secondFilters: [],
            osVersionFilters: [],
            chosenFilter: "",
            filterType: FieldConstant.FILTER.DEVICE_FILTER[0].type,
            typeSearchCriteria: FieldConstant.FILTER.DEVICE_FILTER[0].name,
            textSearchCriteria: "",
            os: "",
            osVersion: "",
            osVersionPlaceholder: messageConstant.messageConstant.OS_VERSION_PLACEHOLDER,
            isEmptySearch: false,
            isNewSearch: false,
            isDisplay: false,
            isClickSearchButton: false,
            isOsSelected: false,
            isCleanInput: false,
            isCleanInputTextAfterAdd: false,
            isCleanAutocompleteInputAfterAdd: false,
            isFocus : this.activeFocus,
            refreshProps: this.refresh,
            IOS: FieldConstant.OS_NAME.IOS,
            ANDROID: FieldConstant.OS_NAME.ANDROID,
            TEXT: FieldConstant.DEVICE_FILTER_TYPE.TEXT,
            SELECT: FieldConstant.DEVICE_FILTER_TYPE.SELECT,
            AUTOCOMPLETE: FieldConstant.DEVICE_FILTER_TYPE.AUTOCOMPLETE
        };
    },
    methods: {
        getFilterEvent: function(chosenFilter) {
            this.filterType = this.getFilterType(chosenFilter);
            if(this.filterType) {
                this.getFilterEventWithType(chosenFilter, this.filterType);
            } else {
                if([this.ANDROID.toLowerCase(), this.IOS.toLowerCase()].includes(this.chosenFilter.toLowerCase())) {
                    this.getOsVersionFilterEvent(chosenFilter);
                } else {
                    this.getFilterEventWithoutType (chosenFilter);
                }
            }
        },
        getFilterType: function(chosenFilter) {
            const chosenFilterAndType = this.filters.filter(filter => {
                  return chosenFilter === filter.name;
              })
              return chosenFilterAndType[0].type;
        },
        getFilterEventWithType: function (chosenFilter, filterType) {
            this.isOsSelected = false;
            this.isEmptySearch = false;
            this.textSearchCriteria = "";
            this.chosenFilter = chosenFilter;
            this.filterType = filterType;
            if (this.filterType.toLowerCase() === this.SELECT || this.filterType.toLowerCase() === this.AUTOCOMPLETE) this.getSecondFiltersList(this.chosenFilter);
            this.addTypeInSearchCriteria(chosenFilter);
        },
        getFilterEventWithoutType: function (chosenSecondFilter) {
            this.textSearchCriteria = chosenSecondFilter;
        },
        getOsVersionFilterEvent: function (chosenFilter) {
            this.osVersionFilters = [];
            this.textSearchCriteria = chosenFilter;
            if (this.textSearchCriteria?.toLowerCase() === this.ANDROID.toLowerCase() || this.textSearchCriteria?.toLowerCase() === this.IOS.toLowerCase()) {
                this.getOsVersionFilters();
            }
        },
        getSecondFiltersList: async function (chosenFilter) {
            this.secondFilters = [];
            this.textSearchCriteria = "";
            const secondFilterList = await DeviceFilterService.calcSecondFilterList(chosenFilter, this.detailedSearchCriteria, this.getOsVersionFilters);
            this.nbItems = secondFilterList.secondFilters.length;
            this.secondFilters = secondFilterList.secondFilters;
            this.textSearchCriteria = secondFilterList.textSearchCriteria;
        },
        addTypeInSearchCriteria: function (chosenItem) {
            this.typeSearchCriteria = chosenItem.toLowerCase().replaceAll(" ", "_");
            if (this.typeSearchCriteria === FieldConstant.ANDROID_API_MANAGEMENT_UNDERSCORE) {
                this.typeSearchCriteria = "aam";
            }
            (this.filterType.toLowerCase() === this.TEXT || this.filterType.toLowerCase() === this.AUTOCOMPLETE) ? this.isFocus = true : this.isFocus = false;
        },
        createNewSearchCriteria: function (isClickSearchButton) {
            this.refreshed = !this.refreshed;
            this.isClickSearchButton = isClickSearchButton;
            this.createNewOsVersionSearchCriteria();

            if (this.isEmptySearch && !this.isClickSearchButton) this.isNewSearch = false;

            let searchTerms = null;
            if(this.textSearchCriteria) {
                const type = this.typeSearchCriteria?.toLowerCase();
                let value = (this.textSearchCriteria.value || this.textSearchCriteria).toLowerCase();
                if(type !== FieldConstant.APP && type !== FieldConstant.CHOSEN_FILTER.MANUFACTURER) {
                    value = value.replaceAll(" ", "_");
                }

                searchTerms = { type, value, key: `${type}:${this.textSearchCriteria.key || value}`};
            }

            if (!this.textSearchCriteria) {
                this.isEmptySearch = true;
            } else {
                this.isEmptySearch = false;
                this.addNewSearchCriteriaInArray(searchTerms, isClickSearchButton);
            }
        },

        createNewOsVersionSearchCriteria: function () {
            if (this.isEmptySearch) this.isNewSearch = false;

            let searchTerms = null;

            if (this.os !== null && this.osVersion !== null) { 
                searchTerms = { 
                    type: 'os_version', value: this.osVersion,
                    key: `os_version:${this.osVersion}`
                }
            }

            if (!this.osVersion) {
                this.isEmptySearch = true;
            } else {
                this.isEmptySearch = false;
                this.addNewSearchCriteriaInArray(searchTerms);
            }

            this.osVersion = "";
        },
        addNewSearchCriteriaInArray: function (criteria, isClickSearchButton) {
            this.$emit("newDetailedSearchCriteria", criteria, isClickSearchButton);

            if (this.isNewSearch && !this.isEmptySearch) {
                this.$emit("newGlobalSearch");
                this.isNewSearch = false;
                this.isClickSearchButton = false;
            }
            this.isCleanInput = true;
        },
        doNewSearch: function () {
            this.$emit('hideDetailedSearch')
            if (!this.isNewSearch) this.isNewSearch = true;
            if (this.isEmptySearch) this.isNewSearch = false;
            this.createNewSearchCriteria(true);
        },
        getDateEvent: function (pickerValue) {
            this.textSearchCriteria = pickerValue;
        },
        getOsVersions: async function () {
            const os = this.textSearchCriteria ? this.textSearchCriteria : FieldConstant.OS_NAME.ANDROID;
            const osVersions = await DeviceService.getOsVersions(os.toUpperCase());
            return osVersions;
        },
        getOsVersionFilters: async function () {
            this.isOsSelected = true;
            this.osVersionFilters = await this.getOsVersions();
        },
        addOsVersionInSearchCriteria: function (osVersion) {
            this.osVersion = osVersion;
        },
        getTextSearchCriteria: function(criteria) {
                this.textSearchCriteria = criteria;
        },
        checkToRefreshList: function() {
            if(this.refresh) this.getSecondFiltersList(this.chosenFilter);
            this.refresh = false;
        }

    }
};

</script>

<style lang="scss">

.filter-component {

    .row {
        align-items: flex-start
    }

    .vtmn-btn {
        min-height: 43px;
        min-width: 64px;
    }

    .search-btn {
        background-color: transparent;
        box-shadow: inset 0 0 0 0.125rem #007dbc;
        color: #007dbc;
        height: 44px;
        width: 128px;
    }

    .search-btn:hover {
        background-color: #007dbc;
        color: #fff;
    }

    .add-btn {
        background-color: transparent;
        box-shadow: inset 0 0 0 0.125rem #007dbc;
        color: #007dbc;
        height: 44px;
    }

    .add-btn:hover {
        background-color: #007dbc;
        color: #fff;
    }

    .emptyInputMessage {
        color: var(--vtmn-color_red);
    }

    .vtmn-text-input {
        margin-top: 5px
    }

    .mdm-autocomplete {
        height: 43px;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 4px;
    }

    .vtmn-text-input full-size mb-0 multi-select-input-area {
        margin-top: 0;
        padding-top: 0;
    }
}


</style>
