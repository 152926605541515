<template>
    <div v-if="application">

        <v-row >
            <v-col cols="12" sm="12" v-if="appSizeCheckResult.isGreater">
                <v-alert dense type="warning" prominent outlined text>
                    {{ WARNING_MSG.APPLICATION_SIZE_NEAR_TO_LIMIT_WARNING.replace("{allowedAppSize}", appSizeCheckResult.allowedAppSize) }}
                </v-alert>
            </v-col>

            <v-col cols="12" sm="6">
                <label for="application-add-input-name">Name of the app</label>
                <input v-model.trim="application.name" type="text"
                       class="vtmn-text-input input-fill-all-width"
                       id="application-add-input-name" placeholder="Name of the app"/>
            </v-col>
            <v-col cols="12" sm="6">
                <label for="application-add-input-version">Version</label>
                <input readonly v-model.trim="application.version" type="text" class="vtmn-text-input input-fill-all-width" id="application-add-input-version" placeholder="Version"/>
            </v-col>

            <v-col cols="12" sm="6">
                <label for="application-add-input-package-name">Package name</label>
                <input readonly v-model.trim="application.packageName" type="text" class="vtmn-text-input input-fill-all-width" id="application-add-input-package-name" placeholder="Package name"/>
            </v-col>
            <v-col cols="12" sm="6">
                <label for="application-add-input-package-version-code">Version code</label>
                <input readonly v-model.trim="application.versionCode" type="text" class="vtmn-text-input input-fill-all-width" id="application-add-input-package-version-code" placeholder="Version code"/>
            </v-col>

            <v-col cols="12" >
                <label for="application-add-input-package-owner">Owner</label>
                <input v-model.trim="application.applicationResponsible" type="text"
                       v-on:input="$v.application.applicationResponsible.$touch"
                       class="vtmn-text-input input-fill-all-width"
                       :class="{'vtmn-text-input vtmn-text-input--error': $v.application.applicationResponsible.$error}"
                       id="application-add-input-package-owner" placeholder="Owner"/>
                <div v-if="$v.application.applicationResponsible.$error">
                    <p><i class="ri-error-warning-line vtmn-text-danger msg-vertical-align"></i> You forgot the application responsible</p>
                </div>
            </v-col>
            <v-col cols="12" class="text-right">
                <button class="vtmn-btn vtmn-btn_variant--secondary-reversed mdm-vtmn-custom-blue-borders" id="application-add-button-validate"
                        :disabled="$v.$invalid"
                        @click="submit">Validate</button>
            </v-col>
        </v-row>

    </div>
</template>

<script type="text/javascript">

    import Vue from 'vue';
    import Vuelidate from 'vuelidate';
    import {numeric, required} from 'vuelidate/lib/validators'
    import AppUtils from "@/utils/application-utils";
    const {messageConstant} = require('@/utils/message-constant');

    export default {
    data : function(){
        return {
            WARNING_MSG: {
                APPLICATION_SIZE_NEAR_TO_LIMIT_WARNING: messageConstant.APPLICATION_SIZE_NEAR_TO_LIMIT_WARNING
            }
        }
    },

    computed: {
        application() {
            return this.$store.state.application.application;
        },
        appSizeCheckResult() {
            return AppUtils.checkAppSize({typeSource: this.application.filePath, size: this.application.fileSize},
                0.9, data => data);
        }
    },
    validations: {
        application: {
            name: {
                required,
            },
            version: {
                required,
            },
            packageName: {
                required,
            },
            versionCode: {
                required,
                numeric,
            },
            applicationResponsible: {
                required,
            }
        }
    },
    methods: {
        submit(){
            if (!this.$v.$invalid) {
                this.$emit('onSubmit', this.application);
            }
        }
    }
}

</script>

<style lang="scss" >

    .msg-vertical-align{
        vertical-align: bottom;
    }

    .input-fill-all-width{
        width: -webkit-fill-available;
    }

    .mdm-vtmn-custom-blue-borders{
        box-shadow: inset 0px 0px 0px 2px var(--vtmn-color_brand) !important;
        color: var(--vtmn-color_brand) !important;
        margin-top: 1em;
    }

</style>
