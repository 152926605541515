<template>

        <v-row no-gutters class="app-description-form">

            <v-alert
                color="var(--vtmn-color_green-light-1)"
                icon="mdi-emoticon-happy-outline"
                text
                prominent>
                <p>The description may let users to understand the usefulness of your application</p>
            </v-alert>


            <v-col cols="12" class="select-right-aligned">
                <v-select v-if="descriptions" class="select-text-deco py-0"
                    v-model="langSelected"
                    :items="descriptions"
                    item-text="label"
                    item-value="code"
                    @change="changeLangCode"
                    :append-icon="'ri-arrow-up-s-line'"></v-select>
            </v-col>

            <v-col cols="12">
                <v-textarea v-if="findDescriptionModel()" v-model.trim="findDescriptionModel().content"
                            solo
                            :label="'Description (' + findDescriptionModel().label + ')'"
                            id="application-add-input-description"
                            :placeholder="'Description (' + findDescriptionModel().label + ')'"
                            class="width-auto"
                            :maxlength="maxLengthDescription"
                            v-on:input="inputDescription"></v-textarea>

                <div><p class="font-italic">* {{findDescriptionModel() ? findDescriptionModel().content.length : 0}}/{{maxLengthDescription}} car.</p></div>
                <div v-if="$v.$invalid && formChanged">
                    <p><i class="ri-error-warning-line vtmn-text-danger msg-vertical-align" /> You forgot to enter at least one description</p>
                </div>
            </v-col>

            <v-col cols="12" class="text-right">
                <button class="vtmn-btn vtmn-btn_variant--secondary-reversed mdm-vtmn-custom-blue-borders" id="application-add-button-validate-desc"
                        :disabled="$v.descriptions.$invalid"
                        @click="submit">Validate</button>
            </v-col>

        </v-row>

</template>

<script type="text/javascript">

    import Vue from 'vue';
    import Vuelidate from 'vuelidate';
    import {minLength} from 'vuelidate/lib/validators'
    import {hasMinOneFilledContent} from '../../../utils/form-validator-utils';

export default {
    props: {
        values: {
            type: Array
        },
        type: {
            type: String,
            validator: (val) => ['webview', 'application'].includes(val)
        },
    },
    data() {
        return {
            descriptions: this.values && this.values?.length > 0 ? this.values : [
                {code: "GB", label: "ENGLISH", content: "", selected: false, changed: false},
                {code: "FR", label: "FRENCH", content: "", selected: false, changed: false},
                {code: "DE", label: "GERMAN", content: "", selected: false, changed: false},
                {code: "ES", label: "SPANISH", content: "", selected: false, changed: false},
                {code: "PT", label: "PORTUGUESE", content: "", selected: false, changed: false},
                {code: "ZH", label: "CHINESE", content: "", selected: false, changed: false},
            ],
            maxLengthDescription: 250,
            langSelected: null,
            formChanged: false,
        }
    },
    computed: {
        application() {
            return this.$store.state[this.type][this.type];
        }
    },
    mounted: function() {
      const descByUser = this.descriptions.find(d => d.code === this.$store.state.user.country) || this.descriptions[0];
      this.changeLangCode(descByUser.code);
    },
    validations: {
        descriptions: {
            minLength: minLength(1),
            hasMinOneFilledContent,
        }
    },
    methods: {
        changeLangCode(code){
            this.descriptions.forEach(d => {
                if(d.code === code) {
                    d.selected = true;
                    d.changed = true;
                } else {
                    d.selected = false;
                }
            });
            this.langSelected = code;
        },
        findDescriptionModel(){
            return this.descriptions.find(d => d.selected);
        },
        inputDescription(value){
            this.$v.descriptions.$touch;
            this.$store.dispatch(this.type + '/set' + (this.type.charAt(0).toUpperCase() + this.type.slice(1)), {descriptions: this.descriptions});
            this.formChanged = true;
        },

        submit(){
            if (!this.$v.$invalid) {
                this.$emit('onSubmit', this.application);
            }
        },

    }
}

</script>

<style lang="scss">
.app-description-form {
    .select-right-aligned{
        text-align: -webkit-right;
    }

    .v-select__selection{
        color: var(--vtmn-color_info);
        font-style: italic;
        text-align: -webkit-right;
        font-weight: bold;
        width: -webkit-fill-available;
    }

    .v-text-field>.v-input__control>.v-input__slot:before {
        border-style: none;
    }

    .v-alert__content{
        color: var(--vtmn-color_black);
    }

    .v-alert--prominent .v-alert__icon:after {
        opacity: 0;
    }
    .v-alert--prominent .v-alert__icon{
        font-size: 3.5em !important;
        color: var(--vtmn-color_black) !important;
    }
    .v-text-field__details{
        display: none;
    }
}

</style>
