import constant from './field-constant';

const MAX_LENGTH = 30;
const SEPARATOR = ":";
const NO_HTML_TAG = /<(\s)*(script|img|embed)/i;
export default {
    canParseDeviceSearchCriteria(criteria, currentCriterias) {
        if (criteria.key.length > MAX_LENGTH) {
            return false
        }
        const searchTermsToCheck = currentCriterias.map(({type}) => type);
        return (criteria.type.startsWith(constant.DEVICE.SEARCH.CUSTOM) || constant.DEVICE.SEARCH.ALLOWED_CRITERIAS.includes(criteria.type)) &&
            !searchTermsToCheck.some(({type, value}) => criteria.type === type && criteria.value === value);
    },

    trimSearchCriteria(criteria) {
        const criteriaTrim = criteria.trim();
        const targetedCriteriaIndex = criteriaTrim.indexOf(SEPARATOR);
        if (targetedCriteriaIndex > -1) {
            return criteriaTrim.substring(0, targetedCriteriaIndex).trim() + SEPARATOR + criteriaTrim.substring(targetedCriteriaIndex + 1).trim();
        }
        return criteria.trim();
    },

    mapSearchTerm(searchTerm) {
        const splitedSearchTerm = searchTerm.split(SEPARATOR);

        if (splitedSearchTerm.length > 1) {
            let type = splitedSearchTerm[0].toLowerCase();
            const value = splitedSearchTerm[1].toLowerCase().trim().replaceAll(" ", "_");
            if (!constant.DEVICE.SEARCH.ALLOWED_CRITERIAS.includes(type)) {
                type = constant.DEVICE.SEARCH.CUSTOM + type;
            }
            return {key: type + SEPARATOR + value, type, value}
        }

        return {key: searchTerm, value: searchTerm, type: constant.DEVICE.SEARCH.CUSTOM + 'blank'}
    },

    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },

    isNoBadHTMLTag(content) {
        return !NO_HTML_TAG.test(content);
    },
    truncateString(inputString, maxLength = 100) {
        if (inputString.length <= maxLength) {
            return inputString;
        } else {
            return inputString.substring(0, maxLength) + "...";
        }
    }
}
