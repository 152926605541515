import Vue from 'vue';
import {store} from '../../store/store';

export default {
    async getInformationHeader() {
        return (await Vue.http.get(store.state.urlHeaderInformation)).body;
    },
    async setInformationHeader(information) {
        await Vue.http.post(store.state.urlHeaderInformation, convertIntoHeaderInformation(information));
    }
};

const convertIntoHeaderInformation = (information) => {
    return {
        active: information.active,
        title: information.title,
        content: information.content,
        criticity: information.criticity
    }
}
