<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5002 3.08397C6.66437 3.08397 3.5835 6.16533 3.5835 10.0017C3.5835 13.8381 6.66437 16.9194 10.5002 16.9194C14.3359 16.9194 17.4168 13.8381 17.4168 10.0017V9.41926L18.9168 9.41828V10.0017C18.9168 14.6666 15.1644 18.4197 10.5002 18.4197C5.83595 18.4197 2.0835 14.6666 2.0835 10.0017C2.0835 5.33677 5.83595 1.58374 10.5002 1.58374C12.2181 1.58374 13.7923 2.03747 15.1028 2.97365L14.2309 4.19444C13.208 3.4637 11.9489 3.08397 10.5002 3.08397ZM18.8638 5.11462L10.9472 13.0325C10.6913 13.2884 10.3574 13.3772 10.0835 13.3772C9.80963 13.3772 9.47564 13.2884 9.21983 13.0325L6.46983 10.2821L7.53049 9.22126L10.0835 11.7747L17.8032 4.05379L18.8638 5.11462ZM9.8865 11.9717C9.88637 11.9718 9.88662 11.9716 9.8865 11.9717V11.9717Z" fill="#149B65"/>
    </svg>
</template>

<script>
export default {
    name: "check-circle"
};
</script>

<style scoped lang="scss">

</style>
