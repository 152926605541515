<template>

    <div>
        <div v-if="dataLoading" class="text-center">
            <md-progress-spinner :md-diameter="30" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div v-else>

            <div v-if="results && results.length > 0 && results[0].error">

                <md-content class="md-accent">An error occurred while searching the User / Cache information. See the console for details.</md-content>
                <div class="text-center">
                <button class="vtmn-btn vtmn-btn_variant--secondary-reversed mdm-vtmn-custom-no-borders"
                        id="admin-cache-results-button-cancel-error" @click="cancel"><em class="ri-arrow-go-back-line"></em> Return
                </button>
                    </div>

            </div>
            <div v-else-if="results && results.length > 0">
                <cache-search-result-table-component :headers="CACHE_HEADERS" :data="results"/>

                <div class="text-center">

                <button
                    class="vtmn-btn vtmn-btn_variant--secondary-reversed mdm-vtmn-custom-no-borders mr-5"
                        id="admin-cache-results-button-cancel" @click="cancel"><em v-if="!hasCacheResults" class="ri-arrow-go-back-line"></em> {{hasCacheResults ?  'Cancel' : 'Back'}}
                </button>
                <button v-if="hasCacheResults" class="vtmn-btn ml-5" id="admin-cache-results-button-empty" @click="openPopupConfirmationEmptyCache">Empty cache</button>
                </div>
            </div>

         </div>

        <mdm-vtmn-popup ref="adminCachePopupConfirmationEmptyCache" >
            <mdm-vtmn-adjust-card>
                <template v-slot:header>
                    <div class="text-h6">EMPTY CACHE</div>

                    <div class="text-h6 mt-16 mb-16">
                        You are about to empty the cache information of your following user.
                        Are you sure to continue ?
                    </div>
                </template>

                <cache-search-result-table-component :headers="CACHE_HEADERS" :data="results"/>

                <template v-slot:footer>
                    <md-content class="md-accent" v-if="emptyCacheError">An error occurred while clearing the User / Cache information. See the console for details.</md-content>

                    <mdm-vtmn-button variant="secondary" notBlock
                        class="mr-5" id="admin-cache-results-button-cancel-card" @click="cancelFromPopupConfirmationEmptyCache">
                        <em v-if="!hasCacheResults" class="ri-arrow-go-back-line"></em> {{hasCacheResults ?  'Cancel' : 'Back'}}
                    </mdm-vtmn-button>
                    <mdm-vtmn-button v-if="hasCacheResults" notBlock class="ml-5" id="admin-cache-results-button-empty-card" @click="empty">Empty cache</mdm-vtmn-button>

                </template>
            </mdm-vtmn-adjust-card>
        </mdm-vtmn-popup >

    </div>

</template>

<script>

    import CacheSearchResultTableComponent from "./cache-search-result-table";
    import userService from '../../../service/user/user-api-service';
    import Moment from 'moment'

    export default {
        name: "cache-search-result-component",
        components: {CacheSearchResultTableComponent},
        props: {
            userCacheResults: {
                type: Object
            },
            dataLoading: {
                type:Boolean
            }
        },
        data: function () {
            return {
                CACHE_HEADERS: [
                    {text: "USER ID", value: "uid", col: "uid"},
                    {text: "DISPLAY NAME", value: "displayName", col: "displayName"},
                    {text: "EMAIL ADDRESS", value: "mail", col: "mail"},
                    {text: "WILL EXPIRE AT...", value: "willExpireAt", col: "willExpireAt"}
                ],
                results: [],
                emptyCacheLoading: false,
                emptyCacheError: false,
                hasCacheResults: false,

            };
        },
        watch: {
            userCacheResults: function(){
                this.buildTableData();
                this.hasCacheResults = this.getLastCacheExpirationDate() !== 'No cache found';
            }, deep: true
        },
            methods: {
            cancelFromPopupConfirmationEmptyCache(){
                this.$refs.adminCachePopupConfirmationEmptyCache.close();
                this.cancel();
            },
             reload() {
                const user = this.results[0].uid;
                this.results = [];
                this.$emit("reloadCacheInfo", user);
             },
            cancel() {
                this.result = [];
                this.$emit("cancelCacheEmpty");
            },
            openPopupConfirmationEmptyCache() {
                this.$refs.adminCachePopupConfirmationEmptyCache.open();
            },
            async empty() {
                this.emptyCacheError = false;
                this.emptyCacheLoading = true;
                const result = await userService.deleteCacheKeysByUid(this.results[0].uid);
                this.emptyCacheLoading = false;

                if (result.error){
                    this.emptyCacheError = true;
                }else{
                    this.reload();
                    this.$refs.adminCachePopupConfirmationEmptyCache.close();
                }
            }
            ,
            buildTableData() {
                if ( !this.userCacheResults ){
                    this.results = []
                    return ;
                }

                const user = this.userCacheResults.user;
                const cache = this.userCacheResults.cache;

                if ( !user ){
                    this.results = [];
                }
                this.results = [{
                    uid: user.uid,
                    displayName: user.displayName,
                    mail: user.mail,
                    willExpireAt: cache ? this.getLastCacheExpirationDate() : "No cache found",
                    error: user.error || cache.error
                }];
            }
            ,
            getLastCacheExpirationDate() {
                const cache = this.userCacheResults?.cache;
                const defaultResult = "No cache found";
                let expDate = undefined;

                if ( cache && cache.length > 0){
                    expDate = cache.sort((firstD, secondD) => new Date(firstD.expirationDate) > new Date(secondD.expirationDate)
                    )[0].expirationDate;
                }
                return expDate ? Moment(expDate).format('DD/MM/YYYY HH:mm') : defaultResult;
            }
        }

    };
</script>

<style lang="scss">
    .mdm-vtmn-custom-no-borders{
        border: none;
        color: var(--vtmn-color_brand) !important;
        margin-top: 1em;
    }

</style>
