<template>
    <mdm-vitamine-card :id="id"
        title="ADD A PUBLIC APP"
        :minimized="minimized"
        @click="cardClicked">
            <p>
                A public application is an application not designed by Decathlon<br />
                Example: Google tools
            </p>
            <template v-slot:footer>
                <div class="under-description-logo">
                    <img :src="'data:image/png;base64,'+ ICONS_BASE64.FACEBOOK" alt="Facebook logo" />
                    <img :src="'data:image/png;base64,'+ ICONS_BASE64.INSTAGRAM" alt="Instagram logo" />
                    <img :src="'data:image/png;base64,'+ ICONS_BASE64.GMAIL" alt="Gmail logo" />
                </div>
            </template>
    </mdm-vitamine-card>
</template>

<script>
import MdmVitamineCard from '../../../../components/mdm/vitamine/card/mdm-vtmn-card';
import MdmVitamineBadge from '../../../../components/mdm/vitamine/badge/mdm-vtmn-badge';
import IconConstant from '../../../../utils/icon-constant';

export default {
    name: 'public-app-card',
    components: {
        MdmVitamineCard, MdmVitamineBadge
    },
    props: {
        id: {
            type: String,
            required: true
        },
        minimized: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {ICONS_BASE64: IconConstant.BASE64}
    },
    methods: {
        cardClicked() {
            this.$router.push({name: "application-public-information"});
        }
    }
}
</script>
