import Vue from 'vue';
import {store} from "../store/store";

export default {
    async transformRequirementToArbo(requirement) {
        const response = await Vue.http.get(`${store.state.urlArbo}`);
        let arbo = response.data;

        fillCountriesAndStores(requirement, arbo, requirement?.allRetail, "retail");
        fillCountriesAndStores(requirement, arbo, requirement?.allLogistic, "logistic");

        return arbo;
    },
    transformArboToRequirement(arbo) {
        return {
            allRetail: arbo?.retail?.checked,
            allLogistic: arbo?.logistic?.checked,
            countries: {
                retail  : !arbo || arbo.retail?.checked ? [] : getCountries(arbo, "retail"),
                logistic: !arbo || arbo.logistic?.checked ? [] : getCountries(arbo, "logistic")
            },
            stores: {
                retail  : !arbo || arbo.retail?.checked ? [] : getSites(arbo, "retail"),
                logistic: !arbo || arbo.logistic?.checked ? [] : getSites(arbo, "logistic")
            }
        };
    },
    transformArboToRequirementForMigration(arbo) {
        return {
            allRetail: arbo?.retail?.checked ?? false,
            countries: {
                retail  : !arbo || arbo.retail?.checked ? [] : getCountries(arbo, "retail"),
            },
            stores: {
                retail  : !arbo || arbo.retail?.checked ? [] : getSites(arbo, "retail"),
            }
        };
    }
}

const getCountries = (arbo, type) => {
    if(!arbo) return [];
    return arbo[type]?.site
        .filter((site) => site.checked)
        .map(site => site.ref);
}

const getSites = (arbo, type) => {
    if(!arbo) return [];

    let list = [];
    arbo[type].site
        .filter((site) => !site.checked)
        .map(site => site.sites.filter(sites => sites.checked).map(sites => sites.id))
        .forEach(site => list.push(...site));

    return list;
}

const fillCountriesAndStores = (requirement, arbo, allFill, type) => {
    if(!requirement) return;
    if(allFill) {
        arbo[type].site.forEach(site => {
            site.checked = true;
            site.sites.forEach(s => s.checked = true)
        });
    } else {
        requirement.countries[type]?.forEach(country => {
            let site = arbo[type].site.find(({ref}) => ref === country);
            if(site) {
                site.checked = true;
                site.sites.forEach(s => s.checked = true)
            }
        });

        arbo[type].site?.forEach(site => {
            site.sites.forEach(s => {
                if(requirement.stores[type].includes(s.id + "")) {
                    s.checked = true;
                }
            });
        });
    }
}
