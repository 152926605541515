<template>
    <div class="legend">
        <v-chip
            v-for="(item, index) in legends"
            :key="index"
            class="legend-chip"
            color="grey lighten-3"
            dark
        >
            <hourglass v-if="item.text.includes('Waiting to wipe')"/>
            <bin-mdm-status v-else-if="item.text.includes('Wiped')"/>
            <check-circle v-else-if="item.text.includes('Enrolled')"/>
            <close-circle v-else-if="item.text.includes('Not on WSO')"/>
            {{ item.text }}

        </v-chip>
    </div>
</template>

<script>
import Hourglass from "../icons/hourglass-icon.vue";
import CheckCircle from "../icons/check-circle-icon.vue";
import BinMdmStatus from "../icons/bin-mdm-status-icon.vue";
import CloseCircle from "../icons/close-circle-icon.vue";
import migrationConstants from "../../../constant/migration-constants";

export default {
    name: 'Legend',
    components: {CloseCircle, BinMdmStatus, CheckCircle, Hourglass},
    data() {
        return {
            legends: [
                { text: 'Waiting to wipe on MDM' },
                { text: 'Wiped on MDM' },
                { text: 'Enrolled on WSO' },
                { text: 'Not on WSO' },
            ]
        };
    }
};
</script>

<style scoped>
.legend {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    margin-bottom: 15px;
}

.legend-chip {
    margin-bottom: 5px;
    color: black;
}
</style>
