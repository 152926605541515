<template>
    <md-table v-model="servicesGroups.length > 0 ? servicesGroups : defaultServicesGroup" md-card>

        <table-row-services v-if="servicesGroups.length > 0" v-for="group in servicesGroups"
                            slot="md-table-row" slot-scope="{ item:group }"
                            :group="group"
                            :app="app"
                            :grant="grant"
        />

        <table-row-services
                 slot="md-table-row" slot-scope="{ item:defaultServicesGroup }"
                 :group="defaultServicesGroup"
                 :app="app"
                 :grant="grant"
        />

    </md-table>


</template>

<script>
import FieldConstant from "../../../utils/field-constant";
import TableRowServices from "../../../components/applications/deploymentGroupsTable/tableRowServices";
import DeploymentGroupConstant from "../../../constant/deployment-group-constant";

export default {
    name: "servicesTable",
    components: {
        TableRowServices
    },
    props: {
        servicesGroups : {
            type: Array
        },
        grant: {
            type: Function
        },
        app: {
            type: Object
        }
    },
    data() {
        return {
            tooltipModifyNoVersionsAvailable: this.$store.state.labels.GROUP_MODIFY_NO_VERSION_AVAILABLE,
            addGroupMaxLimitServices: 1,
            tooltipAddGroupMaxLimit: this.$store.state.labels.GROUP_ADD_IMPOSSIBLE_MAX_LIMIT,
            tooltipAddGroup: this.$store.state.labels.GROUP_ADD_IMPOSSIBLE,
            CONSTANT: FieldConstant,
            defaultServicesGroup: DeploymentGroupConstant.DEFAULT_SERVICES_GROUP
        }
    }
};
</script>

<style scoped>

</style>
