<template>
    <v-layout row wrap  align-center text-md-center>
        <v-flex xs12 sm12 ext-xs-center text-md-center>
            <md-progress-spinner v-if="!filteredValue" md-mode="indeterminate" class="my-10"></md-progress-spinner>

            <p v-else-if="extraData.stores.length === 0" class="message-error">You don't have access at any store, please contact your manager</p>

            <template  v-else >
                <md-field class="mb-0">
                    <label>Search</label>
                    <md-input ref="filterinput" @keyup="filterData(this)" ></md-input>
                </md-field>

                <md-list v-if="filteredValue" :class="listClass">
                    <div v-for="store in filteredValue">
                        <md-list-item @click="choose(store)">
                            <vue-flag :code="store.country" size='medium'/>&nbsp;&nbsp;
                            <img v-if="store.wso" :src="'data:image/png;base64,'+ iconConstants.BASE64.WSO" width="20" alt="WSO logo" class="ml-n5 mt-5" />
                            <span class="md-list-item-text">{{ store.idTxt ? store.idTxt : store.id  }} - {{ store.name }}</span>
                        </md-list-item>
                    </div>
                </md-list>
            </template>
        </v-flex>
    </v-layout>
</template>


<script>

    import androidApi from "@/service/devices/android-api-service";
    import parameterApiService from "@/service/admin/parameter/parameter-api-service";
    import newDeviceContants from "@/constant/new-device-constant";
    import iconConstants from "../../../../utils/icon-constant";

    export default {
        name: "new-device-store-choice",
        data() {
            return {
                filter: "",
                filteredValue: this.extraData?.stores,
                iconConstants
            };
        },
        props: {
            data: {
                type: Object,
                required: true
            },
            extraData: {
                type: Object
            },
            listClass: {
                type: String,
                default: "store-list"
            }
        },
        watch: {
            extraData() {
                this.filteredValue = this.extraData.stores;
            },
            data() {
                this.skipThisStepCauseOnlyOneStore();
            }
        },
        async mounted() {
            const uid = this.data.dataSelectUser?.userChoose;

            const currentUser = await androidApi.getActiveUser(uid);
            if ( !currentUser ){
                console.warn("currentUser is null !");
            }
            if ( !currentUser?.stores){
                console.warn("currentUser.stores is null !");
            }

            this.extraData.stores = currentUser?.stores || [];
            await this.decorateStoresWithWsoFlag(this.extraData.stores);
            this.filteredValue =  this.extraData.stores;

            this.$emit("storeChoiceLoaded", true);
            this.skipThisStepCauseOnlyOneStore();
        },
        methods: {

            useWsoEnrollment(wsoEnrollmentFeatureFlipping, country, storeId){
                return wsoEnrollmentFeatureFlipping.countries.includes(country)
                    || wsoEnrollmentFeatureFlipping.stores.includes(storeId);
            },

            async decorateStoresWithWsoFlag(stores){
                let wsoEnrollmentFeatureFlipping = await parameterApiService.getJsonValueBy("front.wso.enrolment.perimeter.activated", newDeviceContants.WSO_ENROLMENT_PERIMETER_ACTIVATED);
                wsoEnrollmentFeatureFlipping.countries = wsoEnrollmentFeatureFlipping.countries.split(',');
                wsoEnrollmentFeatureFlipping.stores = wsoEnrollmentFeatureFlipping.stores.split(',').map(String);
                this.extraData.stores =
                    stores.map(store => ({... store, wso: this.useWsoEnrollment(wsoEnrollmentFeatureFlipping, store.country, store.id + "")}));
            },
            isKioskType() {
                return this.data.deviceSelected === "KIOSK";
            },
            skipThisStepCauseOnlyOneStore() {
                let siteSelected, storeSelected, deviceOwner;
                ({storeSelected, siteSelected, deviceOwner} = this.data);

                if(!storeSelected && this.userHasOnlyOneStore()) {
                    if((this.isKioskType() && siteSelected) || (!this.isKioskType() && deviceOwner)){
                        this.choose(this.extraData.stores[0]);
                    }
                }
            },
            userHasOnlyOneStore() {
                return this.extraData && this.extraData.stores && this.extraData.stores.length === 1;
            },
            async choose(store) {
                this.$emit('selected', store);
            },
            filterData() {
                new Promise( () => {
                    let filter = document.getElementById(this.$refs.filterinput.id).value; // With a v-model, input is very slow, so use it to increase performance
                    this.filteredValue = this.extraData.stores.filter(
                        (store) =>
                            store.name.toLowerCase().includes(filter.toLowerCase()) ||
                            store.idTxt.toString().includes(filter)
                        );
                });
            }
        }
    }

</script>

<style>
    .store-list {
        background-color : lightgrey !important;
        max-height: 400px;
        overflow: auto;
    }

    .store-list span.md-list-item-text {
        margin-left: 10px;
    }

    .message-error {
        color: red;
    }
</style>
