<template>

    <div id = "web-app-add-panel-steppers">
        <md-steppers class="no-background-color" :md-active-step.sync="active" md-vertical md-linear >

            <md-step id="web-app-add-step-information" md-label="INFORMATION" :md-editable="true"
                     :md-done.sync="step[0].value"
                     @click="changeActiveStep('information','none')">
                <web-app-information-form @onSubmit="changeActiveStep('type','information')" />
            </md-step>

            <md-step id="web-app-add-step-type" md-label="TYPE & ICON"
                     :md-editable="true"
                     :md-done.sync="step[1].value"
                     @click="changeActiveStep('type','information')">
                <web-app-type-icon-form @onSubmit="changeActiveStep('description','type')"/>

            </md-step>

            <md-step id="web-app-add-step-description" md-label="DESCRIPTION" :md-editable="true"
                     :md-done.sync="step[2].value">
                <web-app-description-form :values="webview.descriptions" :type="'webview'" @onSubmit="changeActiveStep('authorization','description')"/>
           </md-step>

            <md-step id="web-app-add-step-authorization" md-label="DEPLOYMENT AUTHORIZATION" :md-editable="false"
                     :md-done.sync="step[3].value">
                <app-deployment-auth-form :type="'webview'"/>
            </md-step>

        </md-steppers>
    </div>

</template>

<script type="text/javascript">
    import WebAppInformationForm from './web-app-information-form';
    import WebAppTypeIconForm from "./web-app-type-icon-form";
    import WebAppDescriptionForm from '../app-description-form';
    import AppDeploymentAuthForm from '../app-deployment-auth-form';

    const utils = require('../../../../utils/utils.js');
    const vueStepperUtils = require('../../../../utils/vue-stepper-utils');

    export default {
        components: {
            WebAppTypeIconForm,
            WebAppInformationForm,
            WebAppDescriptionForm,
            AppDeploymentAuthForm,
        },
        computed: {
            webview() {
                return this.$store.state.webview.webview;
            }
        },
        data: function () {
            return {
                active: 'web-app-add-step-information',
                step: [
                    {type: 'information', value: false},
                    {type: 'type', value: false},
                    {type: 'description', value: false},
                    {type: 'deployment', value: false},
                ]
            }
        },
        methods: {
            changeActiveStep(stepNameToActivate, stepNameToSetDone) {
                this.active = vueStepperUtils.getActiveStep('web-app-add-step-', stepNameToActivate);
                this.step = vueStepperUtils.getSteps(this.step, stepNameToSetDone);
                this.$store.dispatch('webview/setActiveStep', stepNameToActivate);
            }
        }
    }
</script>

<style lang="scss">
    #web-app-add-panel-steppers{
        button {
            &:not([disabled]) .md-stepper-number{
                background-color: var(--vtmn-color_brand-digital);
            }
            &.md-done .md-stepper-number{
                background-color: var(--vtmn-color_green);
            }
        }
    }
</style>
