<template>
    <v-dialog v-if="data" v-model="data.visible" max-width="50%" max-height="80%">
        <v-card>
            <v-card-title>
                {{ data.type === "update" ? 'Update Firmware' : 'Create New Firmware' }}
            </v-card-title>
            <v-container>
                <v-row justify="center">
                    <v-col sm="12" md="6" align-self="start">
                        <p class="vtmn-typo_title-5">Information</p>
                        <v-text-field v-model="firmware && firmware.priority" label="Firmware priority" type="number" class="firmware-field"/>
                        <v-text-field v-model="firmware && firmware.firmware_build_version" label="Firmware Version" class="firmware-field"/>
                        <v-text-field v-model="firmware && firmware.firmware_build_version_url" label="Firmware Version Url" class="firmware-field"/>
                    </v-col>
                    <v-col sm="12" md="6" align-self="start">
                        <p class="vtmn-typo_title-5">Deployment perimeter</p>
                        <FirmwareArbo :arbo="arbo" @changeArbo='changeArbo' class="firmware-arbo"/>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="justify-space-around">
                <v-btn color="primary" size="large" @click="_ => data.confirm(firmware) && data.closeDialog()">
                    {{ data.type === "update" ? 'Update' : 'Create' }}
                </v-btn>
                <v-btn size="large" @click="data.closeDialog">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FirmwareArbo from "./firmaware-arbo";
import firmwareService from "@/service/admin/firmware/firmware-service";

export default {
    components: {
        FirmwareArbo
    },
    props: {
        data: {
            type: Object
        }
    },
    computed: {
        firmware() {
            return this.data ? this.data.firmware : null;
        },
        arbo() {
            if(!this.data) return null;
            if (this.data.arbo && this.data.firmware.calculatedPerimeter)
                firmwareService.calculateArboByPerimeter(this.data.arbo, this.data.firmware.calculatedPerimeter);
            return this.data.arbo;
        }
    },
    methods: {
        changeArbo(perimeter) {
            this.firmware.perimeter = perimeter;
        }
    }
}
</script>

<style lang="scss" scoped>
.firmware-field {
    margin-left: 0
}
.firmware-arbo {
    max-height: 500px;
    overflow-y: auto;
}
</style>
