import FieldConstant from './field-constant';
import {messageConstant} from "./message-constant";

export default {
    applicationCantBeDefaultApp(packageName) {
        const forbiddenApps = JSON.parse(Buffer.from(process.env.VUE_APP_CANT_BE_DEFAULT_APP, 'base64'));
        return forbiddenApps.app.some(constantPackageName => constantPackageName === packageName)
            || forbiddenApps.regex.map(regexString => new RegExp(regexString)).some(rx => rx.test(packageName));
    },
    checkAppSize(appData, rate = 1, callback = data => {if(data.isGreater) throw new Error(messageConstant[data.allowedAppSizeEnvVar]);}) {
        let allowedAppSizeEnvVar = `VUE_APP_ALLOWED_${appData.typeSource.split(".").slice(-1)[0].toUpperCase()}_SIZE`;
        let allowedAppSize = Number(process.env[allowedAppSizeEnvVar]);
        return callback(
            {
                isGreater: appData.size > allowedAppSize * 1024 * 1024 * rate,
                allowedAppSizeEnvVar,
                allowedAppSize
            }
        );
    }
};

