<template>
    <img :src="'data:image/png;base64,'+ iconConstants.BASE64.WSO" width="120" alt="WSO logo" />
</template>

<script>
import iconConstants from '../../utils/icon-constant';

export default {
    name: "wso-icon",
    data(){
        return {
            iconConstants
        }
    }
}
</script>
