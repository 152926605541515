<template>
    <div v-if="$vuetify.breakpoint.smAndDown">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Action button</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <slot />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
    <div v-else class="mt-4 mb-4">
        <v-row no-gutters justify="center">
            <slot />
        </v-row>
    </div>
</template>


<script>
    export default {
      name: "responsive-action-button"
    }
</script>

