<template>
    <div>
        <md-dialog :md-active="showDialog" class="jsonDialog">
            <div>
                <div class="onLeft">
                    <h1>{{ "Stores of " + uid }}</h1>
                </div>
                <div class="onRight">
                    <mdm-button icon="close" @click="closeAction" />
                </div>
            </div>

            <div class="dialog-content jsonContent" md-label="General">
                <v-autocomplete
                    class="autocomplete"
                    :items="stores"
                    item-text="name"
                    outlined
                    chips
                    small-chips
                    label="Stores"
                    auto-select-first
                >
                    <template v-slot:item="{ parent, item }">
                        <vue-flag :code="item.country" size='medium'/>
                        <span class="subtitle-1 item"> {{ item.name }} </span>
                    </template>
                </v-autocomplete>
            </div>

        </md-dialog>
    </div>
</template>

<script>

export default {
    name: "storeDialog",
    props: {
        showDialog: {
            type : Boolean
        },
        stores: {
            type: Array
        },
        uid: {
            type: String
        }
    },
    methods: {
        closeAction : function () {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
    .jsonDialog {
        width: 500px;
        height: 200px;
    }
    .jsonContent {
        overflow : auto;
    }
    .onRight {
        right: 0px;
        position: absolute;
    }
    .onLeft {
        left: 10px;
        top : 10px;
        position: absolute;
    }
    .onLeft h1 {
        font-size: 23px;
    }
    .dialog-content {
        margin-top: 50px;
        padding: 20px;
    }
    .autocomplete {
        width: 100%
    }
    .item {
        padding-left: 10px;
    }
</style>
