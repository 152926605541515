import GroupService from '../../../service/group/group-service';
import router from "../../../router/router";
import DeploymentGroupConstant from '../../../../src/constant/deployment-group-constant'
import Moment from 'moment';
import FieldConstant from '../../../utils/field-constant';

const initialState = {
    application: null,
    name: "",
    version: "",
    activated: true,
    availabledVersions: {
        loading: true,
        versions: [],
        error: false
    },
    status: true,
    forceUpdate: false,
    availability: "UPDATABLE",
    includeModelCheck: false,
    excludeModelCheck: false,
    includeDeviceCheck: false,
    arbo: null,
    requirement: {
        devices: [],
        deviceType: [],
        stores: {
            retail: [],
            logistic: []
        },
        countries: {
            retail: [],
            logistic: []
        },
        models: {
            included: [],
            excluded: []
        },
        allRetail: false,
        allLogistic: false
    },
    notFoundDevice: {
        list: []
    },
    schedule: Moment().format(FieldConstant.DATE.FORMAT.DEFAULT),
    saveGroupLoader: false,
    saveGroupError: null,
    emptyFieldShow: false,
    countUser: 0,
    countGroup: 0,
    countGroupServices: 0,
    bu: ""
}

const state = () => ({
    group: initialState
});


const getters = {
    getGroup(state) {
        return state.group;
    },
    getCountUser(state) {
        return state.countUser;
    }
};


const actions = {
    setGroup({commit}, group) {
        commit('setGroup', group);
    },
    setGroupBu({commit}, bu) {
        commit('setGroupBu', bu);
    },

    setCountUser({commit}, countUser) {
        commit('setCountUser', countUser);
    },

    resetGroup({commit}) {
        commit('resetGroup');
    },

    async saveGroup({commit, getters}) {
        try {
            const group = getters.getGroup;
            commit("setGroup", {saveGroupLoader: true, saveGroupError: null});

            await GroupService.saveGroup(group);
            const idGroup  = group.id || "new";

            router.push({
                path : `/applications/${group.application.id}/group/${idGroup}/deployed`
            });
        } catch(error) {
            console.error(error);
            let errorMessage = "An error occurred when you try to deploy this group, please, contact your administrator";
            if(error.body?.errors?.length > 0) {
                errorMessage = error.body.errors[0].errorMessage;
            }
            commit("setGroup", {saveGroupError: errorMessage});
        } finally {
            commit("setGroup", {saveGroupLoader: false});
        }
    }
};

const mutations = {
    setGroup(state, group) {
        state.group = {...state.group, ...group};
    },
    setGroupBu(state, bu) {
        state.group.bu = bu;
    },
    setGroupIsActive(state, isActive) {
        state.group.activated = isActive;
    },
    setCountUser(state, countUser) {
        state.group.countUser = countUser;
    },
    resetGroup(state) {
        state.group = initialState;
    },
    incrementCountGroups(state, groupBu) {
        groupBu === DeploymentGroupConstant.BU.RETAIL ? state.group.countGroup ++ : state.group.countGroupServices ++
    },
    resetCountGroups(state) {
        state.group.countGroup = 0;
        state.group.countGroupServices = 0;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
