<template>
    <div class="page-content gdpr">
        <h1 class='page-title'>
            <md-icon class="md-size-2x">phone_android</md-icon>
            <span>New device</span>
        </h1>
        <v-row class="page-rgpd-subTitle">How your personal data are managed on MDM?</v-row>
        <div class="content pa-1" >
            <v-skeleton-loader  v-show="!docGdprReady" :type="skeletonType"  />
            <iframe v-if="currentParameter" :src='currentParameter.url' @load="loaded" frameborder="0" :style="iframe_class">
            </iframe>

            <v-row>
                <v-col v-show="currentParameter" align="left">
                    <ul v-for="p in parameterUrls">
                        <li v-if="p && p.country !== currentParameter.country"><vue-flag :code="(p.country.toUpperCase() === 'EN' ? 'GB' : p.country)" size='small'/> Load GDPR in <a id="rgpd-link" :href="p.url" target="_blank" @click="linkToRgpdClicked">{{p.country}}</a> language </li>
                    </ul>
                </v-col>

                <v-col align="right"  :disabled="!docGdprReady"><md-button class='md-raised md-primary' @click="goToNewDevice" :disabled="!docGdprReady">I understand the use of my privacy data on MDM</md-button></v-col>
            </v-row>
        </div>

    </div>

</template>

<script>

import parameterApiService from '../../service/admin/parameter/parameter-api-service';
import newDeviceContants from '../../constant/new-device-constant';

export default {
    name: 'rgpd',
    data() {
        return {
            currentParameter : null,
            parameterUrls: null,
            docGdprReady : false,
            iframe_class : `{ width: 100%; height: 0%; }`,
            skeletonType: this.$vuetify.breakpoint.smAndDown ? 'image' : 'image, image, image',
        }
    },
    async created() {
        if (this.$store.state.gdpr) {
            this.goToNewDevice();
        } else {
            await this.loadGdpr.call(this);
        }
    },
    methods: {
        goToNewDevice() {
            this.$store.commit('setGdprAlreadySeen', true);
            this.$router.push({
                path: `/newDevice`
            });
        },
        loaded: function (){
            this.docGdprReady = this.currentParameter !== null;

            let height = '0%';
            if (this.docGdprReady) {
                height = '90%';    //88%
                if (this.$vuetify.breakpoint.smAndDown) {
                    height = '80%';    //55%
                }
            }
            let computedClass = `{ height: ${height}; align-self: center; width: 100%; min-height:890px; }`;
            this.iframe_class = computedClass;
        },
        linkToRgpdClicked () {
            this.docGdprReady = true;
        },
        async loadGdpr() {
                const parameterUrls = await parameterApiService.getJsonValueBy("aam.enrolment.rgpd.url", newDeviceContants.EN_DESCR_RGPD_URL);
                this.parameterUrls = parameterUrls.sort((a,b) => a.country.localeCompare(b.country));
                const parameter = this.getParameter(this.$store.state.user.country) || this.getParameter(this.getUserPreferredLanguage());
                this.currentParameter = parameter ? parameter : this.getParameter("EN");
        },
        getUserPreferredLanguage() {
            const preferredLocale = window.navigator.userLanguage || window.navigator.language;
            return preferredLocale.split('-')[0].toUpperCase();
        },
        getParameter(countryCode) {
            const parameterByCountryCode = this.parameterUrls.filter(p => p.country === countryCode);
            return parameterByCountryCode ? parameterByCountryCode[0] : null;
        }
    }
}
</script>
<style lang="scss">
@import '~@/assets/style/color';

.gdpr {
    flex: 1;
}

.page-rgpd-subTitle{
    font-family : 'Roboto Condensed';
    font-size : 16px;
    color : $dark2;
    font-style : italic;
    text-transform : uppercase;
    padding-left: 10px;
    margin-bottom: 10px;
    width: 100%;
}

</style>
