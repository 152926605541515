<template>
    <redirection-enrollment :message="message" :link="link" :logo="logo"/>
</template>

<script>

    import RedirectionEnrollment from "@/pages/devices/new/step/enrollment/redirection-enrollment";
    export default {
        name: "new-device-google-endpoint-enrollment",
        components: {RedirectionEnrollment},
        data : function() {
            return {
                message: "Because your user is not RETAIL, you need to register your new device through the Google process.",
                link: "https://sites.google.com/decathlon.com/decathlon-mobiles-en-u/fc-android-united",
                logo: "google-endpoint"
            }
        }
    }
</script>

<style scoped>

</style>
