class NotFoundException extends Error {
    constructor() {
        super("This user is not found");
        this.name = "NotFoundException";
        this.bodyText = "This user is not found";
        this.code = 404;
    }
}

export default NotFoundException;
