<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2.3702L13.8006 6.17075L12.9167 7.05464L10.625 4.76297V13.2541H9.375V4.76297L7.08333 7.05464L6.19945 6.17075L10 2.3702ZM3.75 11.7958V16.1708C3.75 16.2839 3.84518 16.3791 3.95833 16.3791H16.0417C16.1548 16.3791 16.25 16.2839 16.25 16.1708V11.7958H17.5V16.1708C17.5 16.9743 16.8452 17.6291 16.0417 17.6291H3.95833C3.15482 17.6291 2.5 16.9743 2.5 16.1708V11.7958H3.75Z" fill="#101010"/>
    </svg>
</template>

<script>
export default {
    name: "export"
};
</script>

<style scoped lang="scss">

</style>
