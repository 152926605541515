const state = () => ({
    iconsList: [],
    isLoadingIconsList: false
});

const getters = {
    getIconByPackageName(state) {
        return (packageName) => state.iconsList.find(appWithIcon => appWithIcon.packageName === packageName)?.icon?.icon_base64;
    }
}

const mutations = {
    setIcon(state, applicationWithIcon) {

        const foundIcon = state.iconsList.find(item => item.appId === applicationWithIcon.appId);
        const index = state.iconsList.indexOf(foundIcon);
        let newIcon = {};

        if(foundIcon) {
            newIcon = applicationWithIcon;
            state.iconsList.splice(index, 1, newIcon);
        }
        else {
            state.iconsList = [...state.iconsList, applicationWithIcon];
        }
    },
    setIsLoadingIconListToTrue(state) {
        state.isLoadingIconsList = true;
    },
    setIsLoadingIconListToFalse(state) {
        state.isLoadingIconsList = false;
    },
    resetIconsList(state) {
        state.iconsList = [];
    }
};

const actions = {
    setIcon({commit}, applicationWithIcon) {
        commit('setIcon', applicationWithIcon);
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
