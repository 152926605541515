import {
    ANDROID_ENROLLMENT,
    APP_DEFAULT_CHOICE,
    APP_WEBVIEW_INFO,
    DEVICE_CHOICE,
    DEVICE_OWNER_CHOICE,
    SERVICE_SELECT_USER,
    IDLE_CHOICE,
    SITE_CHOICE,
    STORE_CHOICE,
    GENERIC_POLICY_TYPE_CHOICE,
    NORMAL_OR_GENERIC_POLICY_CHOICE,
    NO_BRANCH_USER,
    SERVICE_OR_RETAIL_CHOICE,
    UNKNOWN_END, MODEL_CHOICE
} from "./step-constant";

import FieldConstant from '../../../../utils/field-constant';
import ApplicationService from '../../../application/application-service';
import {findStepFromStepId, getNextStep} from './generic-stepper';
import usersApi from '../../../user/user-api-service';
import NoBranchException from '../../../../error/no-branch-exception';
import StepperParcours from './stepper-parcours-constant';

export default  {
    async changeStep(user, steppers, currentStep, dataFromCurrentSteps) {
        let steppersToBuild = [...steppers];
        let currentStepToBuild = "";
        let extraData = {};

        if(stepProcedureAssociation.hasOwnProperty(currentStep)) {
            steppersToBuild.pop();
            ({currentStepToBuild, extraData} = buildSteps(await stepProcedureAssociation[currentStep](dataFromCurrentSteps, user, steppers), steppersToBuild));
        }

        return {
            steppers: steppersToBuild,
            currentStep: currentStepToBuild || getNextStep(steppers, currentStep).id,
            extraData
        }
    },

    externalDataInStep(steppers, stepToAddData, extraData) {
        switch(stepToAddData.id) {
            case ANDROID_ENROLLMENT.id:
                return {
                    deviceSelected: (findStepFromStepId(steppers, DEVICE_CHOICE.id).data || {}).deviceType,
                    modelSelected: (findStepFromStepId(steppers, MODEL_CHOICE.id).data || {}).kioskModel,
                    siteSelected: (findStepFromStepId(steppers, SITE_CHOICE.id).data || {}).siteType,
                    storeSelected: findStepFromStepId(steppers, STORE_CHOICE.id).data,
                    idleTimeSelected: findStepFromStepId(steppers, IDLE_CHOICE.id).data || {},
                    defaultAppSelected: (findStepFromStepId(steppers, APP_DEFAULT_CHOICE.id).data || {}).packageName,
                    deviceOwner: (findStepFromStepId(steppers, DEVICE_OWNER_CHOICE.id).data || {}).deviceOwner,
                    webview: (findStepFromStepId(steppers, APP_WEBVIEW_INFO.id).data || {}).webview,
                    isGenericPolicy: (findStepFromStepId(steppers, NORMAL_OR_GENERIC_POLICY_CHOICE.id).data || {}).isGenericPolicy,
                    genericPolicyTypeChoose: (findStepFromStepId(steppers, GENERIC_POLICY_TYPE_CHOICE.id).data || {}).type
                }
            case STORE_CHOICE.id:
                return {
                    deviceSelected: (findStepFromStepId(steppers, DEVICE_CHOICE.id).data || {}).deviceType,
                    siteSelected: (findStepFromStepId(steppers, SITE_CHOICE.id).data || {}).siteType,
                    deviceOwner: (findStepFromStepId(steppers, DEVICE_OWNER_CHOICE.id).data || {}).deviceOwner,
                    storeSelected: findStepFromStepId(steppers, STORE_CHOICE.id).data,
                    dataSelectUser: findStepFromStepId(steppers, SERVICE_SELECT_USER.id).data
                }
            case GENERIC_POLICY_TYPE_CHOICE.id:
                return {
                    types: extraData.deviceCanHaveBeSpecificApplication
                }
            case APP_DEFAULT_CHOICE.id:
                return {
                    storeSelected: findStepFromStepId(steppers, STORE_CHOICE.id).data,
                    idleTimeSelected: findStepFromStepId(steppers, IDLE_CHOICE.id).data,
                    defaultAppSelected: (findStepFromStepId(steppers, APP_DEFAULT_CHOICE.id).data || {}).packageName,
                }
            case NO_BRANCH_USER.id:
                return {
                    userChoose: findStepFromStepId(steppers, SERVICE_SELECT_USER.id).data
                }
            default:
                return {}
        }
    }
}

const deviceChoiceStep = async (dataFromCurrentSteps, user) => {
    const isSmartphoneOrTablet = dataFromCurrentSteps.deviceType === FieldConstant.DEVICE_TYPE.SMARTPHONE || dataFromCurrentSteps.deviceType === FieldConstant.DEVICE_TYPE.TABLET;
    if(isSmartphoneOrTablet)  {
        try {
            if(await usersApi.userIsRetail(user.uid)) {
                return StepperParcours.SMARTPHONE_OR_TABLET_BEGINING;
            } else {
                return StepperParcours.SMARTPHONE_OR_TABLET_SERVICE_BEGINING;
            }
        } catch(e) {
            return handleUserRetailOrServiceException(e);
        }
    } else if(dataFromCurrentSteps.deviceType === FieldConstant.DEVICE_TYPE.KIOSK) {
        return StepperParcours.KIOSK_FIRST_PARCOURS;
    }

    return { nextSteppers: [] };
}

const serviceSelectUserStep = async (dataFromCurrentSteps) => {

    if(dataFromCurrentSteps.organisationUnit === null) {
        return StepperParcours.USER_API_DOWN_PARCOURS;
    } else if(dataFromCurrentSteps.organisationUnit === "") {
        return StepperParcours.NO_BRANCH_USER;
    }

    if ( dataFromCurrentSteps.organisationUnit === FieldConstant.ENTITY.RETAIL ){
        return StepperParcours.SMARTPHONE_OR_TABLET_BEGINING;
    }
    return StepperParcours[dataFromCurrentSteps.tabToDisplay];

}

const storeChoiceStep = async (dataFromCurrentSteps, user, steppers) => {
    let currentStepToBuild, steppersToBuild = [], extraData = {};
    const deviceType = (findStepFromStepId(steppers, DEVICE_CHOICE.id).data || {}).deviceType;

    if (deviceType !== FieldConstant.DEVICE_TYPE.KIOSK) {
        ({
            currentStepToBuild,
            extraData
        } = buildSteps(await storeChoiceForSmartphoneAndTabletStep(dataFromCurrentSteps, steppers), steppersToBuild, extraData));
        return { currentStepToBuild, nextSteppers: steppersToBuild, extraData };

    }
    return dataFromCurrentSteps.wso ? StepperParcours.WSO_ENROLLMENT_PARCOURS : StepperParcours.KIOSK_AAM_AFTER_STORE_CHOICE_PARCOURS;
}

const storeChoiceForSmartphoneAndTabletStep = async (dataFromCurrentSteps, steppers) => {

    console.log("storeChoiceForSmartphoneAndTabletStep()");

    let currentStepToBuild, steppersToBuild = [], extraData = {};

    if ( !dataFromCurrentSteps) return;

    if (dataFromCurrentSteps.wso){
        return StepperParcours.WSO_ENROLLMENT_PARCOURS;
    }

    if ( !dataFromCurrentSteps.active){
        return StepperParcours.GOOGLE_ENDPOINT_MANAGEMENT_PARCOURS;
    }

    const deviceOwnerChoice = (findStepFromStepId(steppers, DEVICE_OWNER_CHOICE.id).data || {}).deviceOwner;
    const deviceCanHaveBeSpecificApplication = deviceOwnerChoice === FieldConstant.DEVICE_OWNER.ENTERPRISE && await ApplicationService.getSpecificApplicationByCountryAndStore(dataFromCurrentSteps.country, dataFromCurrentSteps.id);
    extraData = {deviceCanHaveBeSpecificApplication};
    ({currentStepToBuild} = buildSteps(deviceCanHaveBeSpecificApplication.length > 0 ? StepperParcours.NORMAL_OR_GENERIC_POLICY_CHOICE : StepperParcours.AAM_PARCOURS, steppersToBuild));
    return { currentStepToBuild, nextSteppers: steppersToBuild, extraData };
}

const serviceOrRetailChoiceStep = async (dataFromCurrentSteps) => {

    const orgUnit = dataFromCurrentSteps.organisationUnit;
    if ( !orgUnit ){
        console.warn("No Org Unit found !!! Service will be affected by default.");
    }

    const isRetail = FieldConstant.ENTITY.RETAIL === (dataFromCurrentSteps.organisationUnit || "").toUpperCase();

    return isRetail ? StepperParcours.SERVICE_OR_RETAIL_RETAIL_CHOICE :
        StepperParcours[dataFromCurrentSteps.tabToDisplay];
}

const eloTouchOrOtherChoiceStep = async (dataFromCurrentSteps) => {
    const isEloTouch = dataFromCurrentSteps.kioskModel.toUpperCase() === FieldConstant.KIOSK_MODEL.ELO_TOUCH_MIN_3;
    return isEloTouch ? StepperParcours.KIOSK_NEXT_PARCOURS : StepperParcours.DS2_ENROLLMENT_PARCOURS;
}

const normalOrGenericPolicyChoiceStep = async (dataFromCurrentSteps) => {
    let currentStepToBuild, steppersToBuild = [];

    currentStepToBuild = ANDROID_ENROLLMENT.id
    if(dataFromCurrentSteps.isGenericPolicy) {
        steppersToBuild.push(GENERIC_POLICY_TYPE_CHOICE);
        currentStepToBuild = GENERIC_POLICY_TYPE_CHOICE.id
    }
    steppersToBuild.push(ANDROID_ENROLLMENT);

    return { currentStepToBuild, nextSteppers: steppersToBuild };
}

const appDefaultChoiceStep = async (dataFromCurrentSteps) => {
    let currentStepToBuild, steppersToBuild = [];
    currentStepToBuild = ANDROID_ENROLLMENT.id;
    steppersToBuild.push(ANDROID_ENROLLMENT);
    return { currentStepToBuild, nextSteppers: steppersToBuild };
}

const handleUserRetailOrServiceException = (error) => {
    console.warn("Can't get the organisation unit user", error)
    if(error instanceof NoBranchException) {
        return StepperParcours.NO_BRANCH_USER;
    }
    return StepperParcours.USER_API_DOWN_PARCOURS;
}

const buildSteps = (stepperObject, steppersToBuild) => {
    let extraData = null;
    steppersToBuild.push(...stepperObject.nextSteppers);
    if(Boolean(stepperObject.extraData)) {
        extraData = {...stepperObject.extraData}
    }
    return {currentStepToBuild: stepperObject.currentStepToBuild, extraData};
}

const stepProcedureAssociation = {
    [DEVICE_CHOICE.id]: deviceChoiceStep,
    [SERVICE_SELECT_USER.id]: serviceSelectUserStep,
    [SERVICE_OR_RETAIL_CHOICE.id]: serviceOrRetailChoiceStep,
    [NORMAL_OR_GENERIC_POLICY_CHOICE.id]: normalOrGenericPolicyChoiceStep,
    [APP_DEFAULT_CHOICE.id]: appDefaultChoiceStep,
    [STORE_CHOICE.id]: storeChoiceStep,
    [MODEL_CHOICE.id]: eloTouchOrOtherChoiceStep
}
