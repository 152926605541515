<template>
    <v-row no-gutters align="center" class="overridden-choice-panel">
        <v-col cols="12" sm="6" class="text-md-right" >
            <mdm-button id="user-service-or-retail-choice-service" :text="SERVICE"
                        icon="computer" css="md-raised button-choice md-primary"
                        @click="choose(SERVICE)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" sm="6" class="text-md-left">
            <mdm-button id="user-service-or-retail-choice-retail" :text="RETAIL"
                        icon="store" css="md-raised button-choice md-info md-accent"
                        @click="choose(RETAIL)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" align="center">
            <v-alert
                icon="mdi-emoticon-happy-outline"
                text prominent >
                <p class="mb-0">
                    You must choose the unit organisation of the user who will use the phone.
                </p>
            </v-alert>
        </v-col>
    </v-row>
</template>


<script>
import FieldConstant from "../../../../utils/field-constant";
import deviceService from '../../../../service/devices/device-service';

export default {
    name: "service-or-retail-choice",
    data: function () {
        return {
            SERVICE: FieldConstant.ENTITY.SERVICE,
            RETAIL: FieldConstant.ENTITY.RETAIL,
            hoverType: FieldConstant.ENROLLMENT_TYPE.CUSTOM
        }
    },
    methods: {
        async choose(organisationUnit) {
            this.$emit("selected", {organisationUnit,
                ...(this.RETAIL !== organisationUnit
                    && {tabToDisplay: await deviceService.hasToDisplayWsoEnrollmentTab() ? "WSO_ENROLLMENT_PARCOURS"
                    : "GOOGLE_ENDPOINT_MANAGEMENT_PARCOURS"})});
        }
    }
}

</script>

<style lang="scss">

.overridden-choice-panel {
    #user-service-or-retail-choice-service {
        background-color: var(--vtmn-color_brand-digital-light-1)
    }

    #user-service-or-retail-choice-retail {
        background-color: var(--vtmn-color_brand-digital)
    }
}
</style>
