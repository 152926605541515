<template>
	<div>
		<div v-if='loading' class='overlay'></div>
		<md-progress-spinner v-if='loading' class='loading' :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>

		<div class='page-title' @click='cancel'>
			<span> < </span>
			<span>{{appName}}</span>
		</div>
		<div class="priority">
			<h1 class='page-title2'>
				<span>Set priority</span>
			</h1>
			<div class="page-subtitle">Define priority order for your deployement groups :</div>
			<p class='prio-explain'>If a device is affected on 2 (or more) groups, the group with the highest priority will be choose by the device.</p>
			<md-content v-if="error" class="md-accent">
                <ul>
                    <li>{{ GROUP_PRIORITY_ERROR }}</li>
                </ul>
            </md-content>
			<div>
				<div v-sortable="{onEnd: reorder}">
					<ul class="ul-prio" v-for='group in groups'>
						<li class="li-prio"><md-icon>drag_indicator</md-icon>{{group.name}}</li>
					</ul>
				</div>
				<br>
			</div>
			<div class="formActions">
				<md-button id="applications-details-group-priority-btn-cancel" class="md-raised" :class='enableSave' @click='cancel'>Cancel</md-button>
				<md-button id="applications-details-group-priority-btn-save" class="md-raised md-primary" @click='savePriority'>Save</md-button>
			</div>
		</div>
		<md-dialog-alert id="groupPrioAlert"
						:md-active.sync="groupPrioAlert"
						:md-content="groupPrioMessage"
						md-confirm-text="OK" />
	</div>
</template>

<script type="text/javascript">
import Vue from 'vue';
import Moment from 'moment';
import VueRouter from 'vue-router';
import Sortable from 'sortablejs';
import { messageConstant } from '../../utils/message-constant';
import 'vue-material/dist/theme/default.css';
import DeploymentGroupConstant from "../../constant/deployment-group-constant";


Vue.directive('sortable', {
  inserted: function (el, binding) {
    var sortable = new Sortable(el, binding.value || {});
  }
});

export default {
	data : function(){
		return {
			error: false,
			groupPrioAlert: false,
			groupPrioMessage: "",
			appName : localStorage.getItem('current_app_name'),
			groupsId : [],
			loading : false,
			GROUP_PRIORITY_ERROR: messageConstant.GROUP_PRIORITY_ERROR
		}
	},
	computed : {
		enableSave : function(){
			if(this.$route.query.confirm == 'CreateGroup'  || this.$route.query.confirm =='DeleteGroup'){
			  this.groupPrioAlert = true;
				this.groupPrioMessage = "Please define your groups priorities.";
				return 'button-disable';
			} else {
				return '';
			}
		}
	},
	asyncComputed : {
		groups : function(){
			return Vue.http.get(
        this.$store.state.getUrlWithParams(
          this.$store.state.urlApplicationIdGroups,
          {
            idApplication : this.$route.params.id
          })).then((response) => {
			  	if(response.body.data.length === 1) {
					this.$router.push({
						'path' : `/applications/${this.$route.params.id}`,
					});
					return;
				}
				var groups = response.body.data;
				var groupsToReturn = [];
				var groupsID = [];
				groups.forEach(function(el, i){
                    if(el.bu === DeploymentGroupConstant.BU.RETAIL) {
                        groupsID.push(el.id)
                        groupsToReturn.push({
                            'id': el.id,
                            'name': el.name,
                            'usedVersion': el.version.version,
                        });
                    }
				});
                this.groupList = groupsToReturn;
                this.groupsId = groupsID;
				return groupsToReturn;
			}, (error) => {
				console.log(error);
			});
		},

	},

	methods : {
	cancel : function(){
		document.location.href = document.location.href.substring(0, document.location.href.lastIndexOf('/'));
	},
	reorder ({oldIndex, newIndex}) {
    	const movedItem =  this.groupsId.splice(oldIndex, 1)[0];
       	this.groupsId.splice(newIndex, 0, movedItem);
    },

	savePriority : function(){
		this.loading = true;
		this.error = false;
		var groupsId = this.groupsId;

		return Vue.http.post(
			this.$store.state.getUrlWithParams(this.$store.state.urlGroupPrio, { applicationId : this.$route.params.id}),
			{ priorities : this.groupsId.map((groupId, index) => ({id: groupId, priority: index + 1})) })
			.then((response) => {
				this.loading = false;
				if(!response.ok){
					this.groupPrioAlert = true;
					this.groupPrioMessage = "Can't update the group priorities. Contact your administrator.";
					this.loading = false;
				} else {
					this.$router.push({
						'path' : `/applications/${this.$route.params.id}`,
					});
				}
			}, (error) => {
				console.log(error);
				this.loading = false;
				this.error = true;
			});
		},
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';
@import '~@/assets/style/formBySteps';
@import './groupPriority';

.filter-toggle, .filter-toggle-container{
	display : inline-block;
}
.filter-toggle {
	vertical-align : top;
}
</style>
