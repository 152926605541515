<template>
    <div>
        <div v-if="group.saveGroupError" class="danger">
            <p><b>There is an error when deploying your group:</b></p>
            <ul>
                <li>{{group.saveGroupError}}</li>
            </ul>
        </div>
        <div v-if="group.emptyFieldShow">
            <p><b>There is missing information to deploy your application:</b></p>
            <ul>
                <li v-if="group.name === ''">Choose a name</li>
                <li v-if="!group.version">Select a version</li>
                <li v-if="countUser === 0 && !isBuServices()">Select a perimeter or add device IDs (You need to select a larger perimeter because the deployment group contains no users)</li>
                <li v-if="group.requirement.deviceType.length === 0">Enter the type of compatible device</li>
                <li v-if="group.notFoundDevice.list.length  > 0">You must remove the non-existent devices from the list</li>
            </ul>
        </div>
    </div>
</template>

<script>
import GroupServices from "../../../../../service/group/group-service"
    export default {
        name: "group-error-content",
        computed: {
            group() {
                return this.$store.state.group.group;
            },
            countUser() {
                return this.$store.state.group.group.countUser;
            }
        },
        methods: {
            isBuServices() {
                return GroupServices.isBuServices(this.$route.params.idGroup, this.$route.fullPath);
            }
        }
    }
</script>
