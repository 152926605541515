function findDescriptionByCountryCode(descriptions, countryCode){
    return descriptions
        .find( d=> (!countryCode || d.code === countryCode) && d.content != null && d.content.length > 0)?.content
}

function findBestDescriptionToShow(descriptions, userCountry){
    if (!descriptions) return;
    const descriptionByCountry = findDescriptionByCountryCode(descriptions, userCountry);
    if (descriptionByCountry){
        return descriptionByCountry;
    } else{
        const descriptionEN = findDescriptionByCountryCode(descriptions, "EN");
        if (descriptionEN){
            return descriptionEN;
        }
    }
    return findDescriptionByCountryCode(descriptions);
}

export {
    findBestDescriptionToShow
}
