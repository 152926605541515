const languages = [
    {
        language: "fr-FR",
        code: "FR",
        label: "FRENCH"
    },
    {
        language: "en-GB",
        code: "GB",
        label: "ENGLISH"
    },
    {
        language: "de-DE",
        code: "DE",
        label: "GERMAN"
    },
    {
        language: "es-ES",
        code: "ES",
        label: "SPANISH"
    },
    {
        language: "pt-PT",
        code: "PT",
        label: "PORTUGUESE"
    },
    {
        language: "zh-CN",
        code: "ZH",
        label: "CHINESE"
    }
]

export default {
    mapLanguage(language){
        return languages.find((lang) => lang.language === language);
    } 
}