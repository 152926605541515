import GrantUtils from '../Grant/grant';
import GrantConstant from './grant-constant';
import messageConstant from '../../utils/message-constant';

export default {

     grantAccess( currentUserAndDevice, neededUserRoles,
                 neededDeviceProps, currentDeviceConnected, neededDeviceConnected, overrideMessages, isKioskInCache) {

         if(isKioskInCache) {
             return {
                 enabled: false,
                 message: messageConstant.FIRMWARE_UPDATING
             }
         }

        let grantEnabled = false;
        let grantMessage = undefined;

        const userAccess = GrantUtils.grantViaRolesOnly(currentUserAndDevice.user, neededUserRoles);

        if ( userAccess !== GrantConstant.ENABLED ){
            grantMessage = overrideMessages.forbidden || messageConstant.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE;
            return {enabled: grantEnabled, message: grantMessage}
        }
        if ( neededDeviceProps.deviceType !== undefined){
            if ( neededDeviceProps.deviceType !== currentUserAndDevice.device.typeDevice) {
                grantMessage = overrideMessages.deviceType || ( neededDeviceProps.deviceType === 'KIOSK' ? messageConstant.DEVICE_IS_KIOSK : messageConstant.DEVICE_IS_NOT_KIOSK );
                return {enabled: grantEnabled, message: grantMessage}
            }
        }

        if ( neededDeviceConnected !== undefined){
            if ( currentDeviceConnected !== neededDeviceConnected){
                grantMessage = overrideMessages.deviceConnected || messageConstant.DEVICE_IS_NOT_CONNECTED;
                return {enabled: grantEnabled, message: grantMessage}
            }
        }

        if ( neededDeviceProps.androidSilentUpdate !== undefined){
            if ( neededDeviceProps.androidSilentUpdate !== currentUserAndDevice.device.androidSilentUpdate) {
                grantMessage = overrideMessages.androidSilentUpdate || ( neededDeviceProps.androidSilentUpdate ? messageConstant.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_NOT_AAM : messageConstant.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_AAM );
                return {enabled: grantEnabled, message: grantMessage}
            }
        }

        return {
            enabled: true,
            message: undefined
        }

    }
};
