<template>
    <div>

        <div class="text-h6 pb-5">CACHE</div>

        <v-row justify="center" align="center" v-if="!userCacheResults && !isLoading">
            <v-col cols="12" md="8">
                    <mdm-input
                        :val.sync="userSearchCriteria"
                        @touched="$v.userSearchCriteria.$touch"
                        :isError="$v.userSearchCriteria.$error"
                        :id="'admin-cache-input-search'"
                        name='User ID *'
                        placeholder="Ex : Z00XXYYZ"
                        error-message="You put a wrong User ID !"/>

                    <p class="mt-3">If this user ID is unknown, you can contact the support.</p>
            </v-col>
            <v-col cols="12" md="4" class="mb-3">
                <div class="text-center">
                    <button :disabled="$v.$invalid" class="vtmn-btn" id="admin-cache-button-search" @click="search" >Search</button>
                </div>
            </v-col>
        </v-row>

        <cache-search-result-component :user-cache-results="userCacheResults" :data-loading="isLoading"
                                       @cancelCacheEmpty="cancel"
                                       @reloadCacheInfo="searchAgain"/>

    </div>

</template>

<script>

import {required} from 'vuelidate/lib/validators'
import usersApi from '../../../service/user/user-api-service';
import CacheSearchResultComponent from './cache-search-result';
import { matchDecathlonUid } from '../../../utils/form-validator-utils';

export default {
    name: 'cache-search-component',
    components: {CacheSearchResultComponent},
    validations: {
        userSearchCriteria: {
            required,
            matchDecathlonUid
        }
    },
    data : () => {
        return {
          userSearchCriteria: "",
          userCacheResults: undefined,
          isLoading: false,
      }
    },

    methods: {

        async searchAgain(uid){
            this.userSearchCriteria = uid;
            await this.search();
            this.$emit("reloadCacheInfo")
        },

        async search(){
            if ( !this.$v.$invalid) {
                this.isLoading = true;
                this.userCacheResults = {
                    user: await usersApi.getByUid(this.userSearchCriteria),
                    cache: await usersApi.getCacheKeysByUid(this.userSearchCriteria)
                }
                this.isLoading = false;
            }
        },
        cancel(){
            this.userCacheResults = undefined;
            this.isLoading = false;
        }

    }

}
</script>
