<template>
    <v-layout row wrap align-center>
        <v-flex v-if="this.errorMessage" xs12  sm12 text-center>
            <p class="message-error">{{this.errorMessage}}</p>
        </v-flex>
        <template v-else>
            <v-flex xs12  sm12 text-center>
                <md-progress-spinner v-if="!applicationsToChoose" md-mode="indeterminate"></md-progress-spinner>

                <p v-else-if="applicationsToChoose.length === 0" class="message-error">No application uploaded on playstore for this store. Please, contact your administrator.</p>

                <el-carousel v-else trigger="click" :initial-index="initialChoosedAppIndex" :autoplay=false :interval="4000" type="card" height="200px" @change="defaultAppChanged">
                    <el-carousel-item v-for="application in applicationsToChoose" :key="application.id">
                        <img :src="application.icon.icon.imageBase64 || application.icon.icon " class="img-auto-size"/>
                        <p>{{application.name}}</p>
                    </el-carousel-item>
                </el-carousel>
            </v-flex>
            <v-flex xs12 text-center v-if="chosenApp">
                <md-button id="devices-new-device-btn-choose-this-app" class='md-raised md-primary' @click="choose()">
                    Choose this app <md-icon>keyboard_arrow_right</md-icon>
                </md-button>
            </v-flex>
        </template>
    </v-layout>
</template>


<script>
    import ApplicationService from '../../../../service/application/application-service';
    import FieldConstant from '../../../../utils/field-constant';
    import ApplicationUtils from '../../../../utils/application-utils';

    export default {
        name: "new-default-app-choice",
        data() {
            return {
                applicationsToChoose: null,
                chosenApp: null,
                initialChoosedAppIndex: null,
                errorMessage: null
            };
        },
        mounted() {
            this.getAllDefaultAppForStore()
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        watch: {
            data() {
                this.getAllDefaultAppForStore()
            }
        },
        methods: {
            getAllDefaultAppForStore() {
                let storeSelected = this.data?.storeSelected;
                if(!storeSelected || !this.data?.idleTimeSelected || this.data?.defaultAppSelected) return;

                this.errorMessage = null;
                this.initialChoosedAppIndex = null;
                this.chosenApp = null;
                this.applicationsToChoose = null;

                ApplicationService.getApplicationForCountryAndStoreUsingPlaystore(
                    storeSelected.country, storeSelected.id)
                    .then((defaultApplications) => {
                        defaultApplications = this.removeSystemApp(defaultApplications);
                        let chosenAppIndex = defaultApplications.findIndex((application) => application?.uid === "com.oxylane.plus");
                        chosenAppIndex = chosenAppIndex === -1 ? 0 : chosenAppIndex;
                        this.chosenApp = defaultApplications[chosenAppIndex];
                        this.initialChoosedAppIndex = chosenAppIndex;
                        this.applicationsToChoose = defaultApplications.filter(application => application !== undefined);
                }).catch(e => {
                    console.error(e);
                    this.errorMessage = "An error occurred when we tried to retrieve application from your store. Please, contact your administrator."
                });
            },
            choose() {
                this.$emit('selected', {packageName: this.chosenApp.uid});
            },
            defaultAppChanged(idx) {
                this.chosenApp = this.applicationsToChoose[idx];
            },
            removeSystemApp(applications) {

                return applications.filter(application => !ApplicationUtils.applicationCantBeDefaultApp(application?.uid));
            }
        }
    }
</script>

<style lang="scss">
    .img-auto-size {
        height: 80% !important;
        width: 100%;
        object-fit: contain;
    }
</style>
