<template>
    <div>
        <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons" />

        <NoAuthentication v-if="noSecuredRoutes"/>

        <Authentication v-else>
            <v-app>
                <link href="https://cdn.jsdelivr.net/npm/remixicon@2.2.0/fonts/remixicon.css" rel="stylesheet" />
                <dHeader v-if="!isNavigationHide"/>
                <div :class="getMainPageClass()">
                    <router-view></router-view>

                    <md-dialog-alert id="alertPopup"
                                     :md-active.sync="alertPopupDisplay"
                                     :md-content="alertPopuplMessage"
                                     md-confirm-text="OK" />

                    <md-dialog-confirm
                        id="alertPopupYesNo"
                        :md-active.sync="alertPopupDisplayYesNo"
                        :md-content="alertPopupMessageYesNo"
                        md-confirm-text="Yes"
                        md-cancel-text="No"
                        @md-cancel="alertPopupDisplayYesNoClick(false)"
                        @md-confirm="alertPopupDisplayYesNoClick(true)" />

                </div>
                <dFooter v-if="!isNavigationHide && isGroupRoute"/>
            </v-app>
        </Authentication>
    </div>
</template>

<script>
    import Authentication from "@/components/Auth/authentication";
    import dHeader from "./components/Header/Header";
    import dFooter from "./components/Footer/Footer";
    import "vue-material/dist/vue-material.min.css";
    import {EventBus} from "./utils/event-bus.js";
    import NoAuthentication from "@/components/Auth/noAuthentication";

    export default {
        name: "app",
        data: function () {
            return {
                browser: "",
                alertPopupDisplay: false,
                alertPopuplMessage: "",
                alertPopupDisplayYesNo: false,
                alertPopupMessageYesNo: ""
            };
        },
        mounted: function () {
            let _this = this;
            if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
                this.browser = "firefox";
            } else if (navigator.userAgent.toLowerCase().indexOf("safari/") > -1) {
                if (navigator.userAgent.toLowerCase().indexOf("chrome/") > -1) {
                    this.browser = "chrome";
                } else {
                    this.browser = "safari";
                }
            } else if (navigator.userAgent.toLowerCase().indexOf("gecko") > -1) {
                this.browser = "ie";
            }
            // Listen for the display-alert-dialog event and its payload.
            EventBus.$on("display-alert-dialog", message => {
                _this.alertPopupDisplay = true;
                _this.alertPopuplMessage = message;
            });
            // Listen for the display-alert-dialog event and its payload.
            EventBus.$on("display-alert-dialog-yes-no", message => {
                _this.alertPopupDisplayYesNo = true;
                _this.alertPopupMessageYesNo = message;
            });
        },
        computed: {
            isNavigationHide() {
                return this.$route.path?.startsWith("/error");
            },
            noSecuredRoutes() {
                return this.$store.state.noSecuredVueRoutes.some(route => this.$route.path?.startsWith(route));
            },
            isDevicePath() {
                return this.$route.path === "/devices";
            },
            isGroupRoute() {
                let groupRegex = /(?!(.*group\/(\d)+\/))(?=.*group)^(.+)$/g;
                return !groupRegex.test(this.$route.path);
            }
        },
        methods: {
            alertPopupDisplayYesNoClick(yesOrNo) {
                EventBus.$emit("display-alert-dialog-yes-no-response", yesOrNo);
            },
            getMainPageClass() {
                let classes = "layer-content";
                if (!this.isNavigationHide) {
                    classes += " page";
                }
                return classes;
            }
        },
        components: {
            NoAuthentication,
            Authentication,
            dHeader,
            dFooter
        }
    }
</script>

<style lang="scss">
@import '~@/assets/style/reset';
@import '~@/assets/style/font';
@import '~@/assets/style/color';
@import '~@/assets/style/button';
@import '~@/assets/style/filter';
@import '~@/assets/style/flags';

.text-error {
  color: var(--vtmn-color_danger);
}

.text-align-center {
  text-align: center;
}

.v-layout-text-center{
    text-align: -webkit-center;
}

.hideBecauseNotReady{
   opacity : 0.4;
   pointer-events : none;
   cursor: not-allowed;
}

html {
  overflow: auto !important;
}

strong{
  font-weight : bolder;
}

body{
  background-color : $grey;
  font-family : Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif;
  letter-spacing: normal;
  min-width: 100%;
}

.full-size {
  width: 100%;
}

.margin-btm-10 {
  margin-bottom: 10px !important;
}

.text-align-end {
  text-align: end;
}

.page-content {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    @media only screen and (max-width: 600px) and (min-width: 0px) {
        min-width: fit-content;
    }
}

.layer-content {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  margin : auto;
  justify-content: center;
}

@media (min-width: 0px) {
  .layer-content {
    width: 100%;
  }
}

.layer-content {
  @media (min-width: 1350px) {
      width: 80%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1349px) {
      width: 90%;
  }
}

.page{
  padding-top : 20px;
  padding-bottom : 63px;
}

.page-title{
  font-family: 'Roboto Condensed';
  font-style : italic;
  font-weight:  bold;
  font-size: 33px;
  color : #3643BA;
  text-transform: uppercase;
  margin-bottom : 30px;
}

.page-title2{
  font-family : 'Roboto Condensed';
  font-size : 27px;
  font-weight : bolder;
  color : $dark;
  text-align : center;
  margin-bottom : 30px;
  font-style : italic;
  text-transform : uppercase;
}

a{
  text-decoration : none;
  color : inherit;
}
.page-icon{
  margin-right : 10px;
}
.page-subtitle {
  font-family: 'Roboto Condensed';
  font-size : 27px;
  font-style: italic;
  font-weight: lighter;
  color : #3643BA;
  text-transform: uppercase;
  display : inline-block;
  margin-bottom: 25px;
  span{
    line-height: 44px;
    vertical-align: top;
  }
}

@media (max-width: 1110px) {
  .page-subtitle{
    font-size : 1.5em;
  }
}

@media (max-width: 950px) {
  .page-subtitle{
    margin-bottom: 0px;
    font-size : initial;
  }
}

.page-subTitle2{
  font-family : 'Roboto Condensed';
  font-size : 25px;
  color : $dark2;
  font-style : italic;
  text-transform : uppercase;
  margin-bottom : 20px;
}

.content{
  padding-top : 30px;
  padding-bottom : 30px;
  background-color : #fff;
}

@media (max-width: 700px) {
  .content {
    padding: 0;
  }
}

.logoMDM {
    margin: auto;
    width: 143px;
    height: 43px;
}


.ma {
    margin: auto;
}


.align-center {
  align-items: center;
}

#app {
  background-color: #efefef;
}

.z-index-max {
  z-index: 999;
}

.no-background-color{
    background-color: transparent !important;
}

.danger {
  color: var(--vtmn-color_danger) !important;
}

.width-90 {
  width: 90%;
}

.mdm-visibility-hidden {
    visibility:hidden;
}

.form-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.mdm-vtmn-default-backgroud {
  background-color: #fff;
}

.overlay {
	position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    z-index: 10;
}

.loading {
	position : fixed;
	top : 50%;
	left : 50%;
	margin-left : -50px;
	margin-top : -50px;
	z-index: 11;
}

</style>
