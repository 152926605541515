<template>
  <v-layout v-if="qrCodeValue">
    <v-layout v-if="this.expirationSeconde >= 0" row wrap align-center>
        <v-flex xs12 sm6 ext-xs-center text-md-right>
          <new-device-content-qr-code :url=qrCodeValue />
        </v-flex>
        <v-flex xs12 sm6 text-xs-center text-md-left>
          <v-flex xs12>
            <p>This QRcode will expire in {{ expirationMinutes }} min and {{ expirationSeconde }} s</p>
          </v-flex>
          <v-flex xs12>
            <p id="devices-new-device-label-enrollment-code" >You can also use an enrollment code : <b>{{ enrollmentCode }}</b></p>
          </v-flex>
          <v-flex xs12 v-if="data.deviceSelected === SMARTPHONE || data.deviceSelected === TABLET">
            <h3><span class="text-capitalize">{{formatedDeviceEnterprise}}</span> procedure</h3>
            <p>Follow <a id="devices-new-device-url-procedure" :href="linkToProcedure" target="_blank">this link</a> to know how use this QrCode</p>
          </v-flex>
        </v-flex>
    </v-layout>
    <v-layout v-else row wrap align-center>
      <v-flex xs12 sm6 text-xs-center text-md-right>
        This QRcode is expired
      </v-flex>
      <v-flex xs12 sm6 text-xs-center text-md-left>
        <mdm-button text="Regenerate a QRCode" icon="autorenew" @click="regenerateQrCode()"></mdm-button>
      </v-flex>
    </v-layout>
  </v-layout>
  <v-flex v-else xs12 sm6 text-xs-center >
  <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
  </v-flex>
</template>

<script>
    import qrCode from '../../qrCode';
    import AndroidApi from '../../../../../service/devices/android-api-service'
    import FieldConstant from '../../../../../utils/field-constant';

    export default {
      name: "new-device-android-enrollment",
      components: {
        'new-device-content-qr-code': qrCode,
      },
      props: {
        data: {
          type: Object,
          required: true
        },
        extraData: {
          type: Object,
          required: true
        }
      },
      data() {
        return {
          qrCodeValue:null,
          enrollmentCode:"",
          expirationMinutes:59,
          expirationSeconde:59,
          SMARTPHONE: FieldConstant.DEVICE_TYPE.SMARTPHONE,
          TABLET: FieldConstant.DEVICE_TYPE.TABLET,
          KIOSK: FieldConstant.DEVICE_TYPE.KIOSK
        };
      },
      mounted: function () {
        this.regenerateQrCode();
      },
      computed: {
        formatedDeviceEnterprise() {
          return this.data?.deviceOwner?.toLowerCase();
        },
        getProcedureCountry() {
          return this.$store.state.user?.country === 'FR' ? 'FR' : 'EN';
        },
        linkToProcedure() {
          return FieldConstant.LINK.ENROLLMENT_PROCEDURE[this.getProcedureCountry][this.data?.deviceOwner];
        }
      },
      methods: {
        canGenerateQrCode() {
          let deviceSelected, storeSelected, idleTimeSelected, siteSelected, defaultAppSelected, webview, deviceOwner, isGenericPolicy, genericPolicyTypeChoose;
          ({deviceSelected, storeSelected, idleTimeSelected, siteSelected, defaultAppSelected, webview, deviceOwner, isGenericPolicy, genericPolicyTypeChoose} = this.data);

          if(deviceSelected === 'KIOSK') {
            const webviewSelected = defaultAppSelected != undefined &&
                ((FieldConstant.APPLICATION.WEBAPP.DEFAULT_PACKAGE_NAME === defaultAppSelected && webview != undefined)
                        || defaultAppSelected !== FieldConstant.APPLICATION.WEBAPP.DEFAULT_PACKAGE_NAME);

            return storeSelected && idleTimeSelected && idleTimeSelected.rebootTime && siteSelected && defaultAppSelected && webviewSelected;
          } else {
            if(isGenericPolicy) {
              return storeSelected && genericPolicyTypeChoose != null;
            }
            return storeSelected;
          }
        },

        regenerateQrCode() {
          if(!this.canGenerateQrCode()) return;

          AndroidApi.getEnrollmentValue(AndroidApi.getPolicy(this.data.deviceSelected), this.extraData.uid, this.data).then (data => {
            let expDate = new Date(data.expirationTimestamp);

            let intervalId = setInterval(() => {
              let now = new Date();

              this.expirationMinutes = parseInt((expDate.getTime() - now.getTime()) / 60 / 1000);
              this.expirationSeconde = parseInt(((expDate.getTime() - now.getTime()) / 1000) % 60);

              if(this.expirationSeconde < 0) {
                clearInterval(intervalId);
              }
            }, 1000)
            this.qrCodeValue = data.qrCode;
            this.enrollmentCode = data.value;
          });
        }
      },
      watch: {
        data() {
          this.regenerateQrCode();
        }
      }
    }


</script>

<style scoped>
  .text-capitalize {
    text-transform: capitalize;
  }
</style>
