<template>
    <mdm-vitamine-card id="application-add-information-card"
                       :title="application.name"
                       :iconSrc="getIconLink()"
                       :url="type === 'webview' ? application.url : undefined"
                       inactive>
        <v-skeleton-loader class="full-size" v-if="!descriptionToShow" type="text@3" :boilerplate="true" />
        <p v-else>{{descriptionToShow}}</p>
    </mdm-vitamine-card>
</template>

<script type="text/javascript">
    import MdmVitamineCard from '../../../components/mdm/vitamine/card/mdm-vtmn-card';
    import FieldConstant from '../../../utils/field-constant';
    const appDescUtils = require('../../../utils/app-description-utils');
    const ICON_BASE64_PREFIX = "data:image/png;base64,";

    export default {
        components: {
            MdmVitamineCard
        },
        props: {
            isBase64: {
                type: Boolean,
                default: true,
            },
            type: {
                type: String,
                validator: (val) => ['webview', 'application'].includes(val)
            },
        },
        mounted() {
            if(this.type === 'application' && this.application.name == undefined) {
                this.$router.push("/applications/new");
            }
        },
        computed: {
            application() {
                return this.$store.state[this.type][this.type];
            },
            descriptionToShow(){
                const description = appDescUtils.findBestDescriptionToShow(this.application.descriptions, this.$store.state.user.country);
                return _.truncate(description, {'length': 255});
            },
        },
        methods: {
            getIconLink() {
                if(!this.application.logo || !this.isBase64) return this.application.logo || this.appDefaultIcon;
                return this.application.logo.startsWith(ICON_BASE64_PREFIX) ? this.application.logo : ICON_BASE64_PREFIX + this.application.logo;
            }
        },
        data: function () {
            return {
                appDefaultIcon: `${ICON_BASE64_PREFIX}${FieldConstant.APPLICATION.WEBAPP.DEFAULT_ICON}`
            }
        }
    }

</script>

<style lang="scss" >

.buble {
    background-color: var(--vtmn-color_grey-light-1);
    color: white;
    border-radius: 5px;

    p {
        padding: 0;
        margin: 0;
    }
}

</style>
