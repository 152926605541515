<template>
    <div v-if="isAuthenticated">
        <slot></slot>
    </div>
</template>

<script>
import AuthenticationService from '../../service/auth/authentication-service';
export default {
    data() {
        return {
            isAuthenticated: false
        };
    },
    async mounted() {
        const authService = AuthenticationService.getSingleInstance();
        this.isAuthenticated = !!(authService.getAccessToken());
        if(!this.isAuthenticated) await authService.connectIfNoAccessToken();
    }
};
</script>
