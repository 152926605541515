<template>
    <div>
            <v-menu
                v-model="isDisplayed"
                :close-on-content-click="false"
                :close-on-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="dateRangeText"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        v-on="on"
                    >
                        <template v-slot:append-outer>
                            <v-icon
                                v-if="dates.length"
                                class="mr-2"
                                @click.stop="clearDates"
                            >
                                mdi-close-circle
                            </v-icon>
                        </template>
                    </v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    range
                    @input="datePicked"
                    :color="constants.COLORS.BLUE_BRAND"
                >
                    <v-spacer></v-spacer>
                    <v-btn text @click="clearDates">Clear</v-btn>
                    <v-btn text @click="isDisplayed = false">Cancel</v-btn>
                    <v-btn :disabled="dates.length < 1" text @click="confirmDates">OK</v-btn>
                </v-date-picker>
            </v-menu>
    </div>
</template>

<script>
import ConstantsMigration from "../constants-migration";
import localStorageService from "../../../service/local-storage-service";
import localStorageConstant from "../../../constant/local-storage-constant";

export default {
    name: "wipe-date-picker",
    data() {
        return {
            dates: this.alreadySelectedDates ? this.alreadySelectedDates : [],
            isDisplayed: false,
            constants: ConstantsMigration
        }
    },
    props: {
        alreadySelectedDates: {
            type: Array
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.length ? this.dates.join(' ~ ') : 'Select wipe date';
        }
    },
    methods: {
        datePicked() {
            this.dates.sort((a, b) => new Date(a) - new Date(b));
            localStorageService.setItem(localStorageConstant.MIGRATION.ACTIVE_DATE_FILTERS, this.dates);
            this.$emit("isSelectedDate", true);
        },
        confirmDates() {
            this.isDisplayed = false;
            this.$emit('validSelectedDates', this.dates);
        },
        clearDates() {
            this.dates = [];
            this.isDisplayed = false;
            localStorageService.remove(localStorageConstant.MIGRATION.ACTIVE_DATE_FILTERS);
            this.$emit("isSelectedDate", false);
        }
    },
    watch: {
        alreadySelectedDates: {
            handler: async function (value) {
                if(value?.length === 0) {
                    this.dates = [];
                    this.$emit("isSelectedDate", false);
                }
            }
        }
    }
};
</script>

<style lang="scss">
.v-input {
    width: 280px;
}

.v-text-field--outlined fieldset {
    height: 50px;
}

.v-text-field--outlined .v-text-field__slot, .v-text-field--single-line .v-text-field__slot {
    height: 45px;
}

.v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__prepend-outer {
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .v-input {
        width: auto;
    }
}

</style>
