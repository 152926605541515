import appTypeEnum from '../service/application/app-type-enum';
import GroupServices from "../service/group/group-service";

export default {
    canDeploy(group, countUser, idGroup, path) {

        const isGoodVersion = group && group.version && group.application && ((group.application.appType === appTypeEnum.PUBLIC_APP || group.application.appType === appTypeEnum.WEBVIEW )|| group.availabledVersions.versions.some(({id}) => id == group.version.id));

        return group.name != "" && (GroupServices.isBuServices(idGroup, path) || (countUser === null || countUser > 0)) &&
               isGoodVersion && group.requirement.deviceType.length > 0 && group.notFoundDevice.list.length === 0;
    }
}
