// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import VueMaterial from "vue-material";
import VueResource from "vue-resource";
import AsyncComputed from "vue-async-computed";
import VueCharts from "vue-charts";
import Vuex from "vuex";
import VueSession from "vue-session";
import VueSocketIO from "vue-socket.io";
import Tabs from "vue-tabs-component";
import VueFlag from "vue-flag";
import "vue-flag/dist/vue-flag.css";
import Vuetify from "vuetify";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import Vuelidate from "vuelidate";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import VueAnalytics from "vue-analytics";

import LocalStorageService from "./service/local-storage-service";
import LocalStorageConstant from "./constant/local-storage-constant";

import mdmTextArea from './components/mdm/vitamine/text-area/mdm-text-area';
import mdmSelect from './components/mdm/vitamine/input/mdm-select';
import pagination from "./components/Filter/pagination";
import filterSelect from "./components/Filter/FilterSelect/filter-select";
import inputText from "./components/Filter/input-text";
import arbo from "./components/Filter/arbo";
import chipLegend from "./components/Legend/chip-legend";
import chipLegendInactif from "./components/Legend/chip-legend-inactif";
import navBullet from "./components/Filter/nav-bullet";
import mdmButton from "./components/MdmButton/mdmButton";
import jsonDialog from "./components/dialog/jsonDialog";
import lastActivities from "./components/LastActivities/lastActivities";
import filterToggle from "./components/Filter/filter-toggle";
import mdmVtmnButton from "./components/mdm/vitamine/button/mdm-vtmn-button";
import mdmVtmnPopup from "./components/mdm/vitamine/popup/mdm-vtmn-popup";
import mdmVtmnPopover from "./components/mdm/vitamine/popover/mdm-vtmn-popover";
import mdmVtmnAdjustCard from "./components/mdm/vitamine/card/mdm-vtmn-adjust-card";
import mdmFlip from "./components/mdm/vitamine/animation/flip";
import mdmAutocomplete from "./components/mdm/vitamine/input/mdm-autocomplete";
import mdmAutocompleteMulti from "./components/mdm/vitamine/input/mdm-autocomplete-multi";
import mdmMultiChoice from "./components/mdm/vitamine/input/mdm-multi-choice";
import mdmToaster from "./components/mdm/vitamine/toaster/toaster";
import mdmInput from "./components/mdm/vitamine/input/mdm-input";

// Import SVG
import DeviceSVG from "./components/app-icon/device-svg";
import KioskSVG from "./components/app-icon/kiosk-svg";
import TabletSVG from "./components/app-icon/tablet-svg";
import SpinnerSVG from "./components/app-icon/spinner-svg";
import DktLogoSVG from "./components/app-icon/dkt-logo-svg";

import JsonViewer from "vue-json-viewer";
import StarRating from "vue-star-rating";
import {setStore, store} from "./store/store";
import router from "./router/router";
import ElementUI from "element-ui";
import Gapi from "vue-googleapis";
import "element-ui/lib/theme-chalk/index.css";

// Vitamin design starter
import "@vtmn/css/dist/index.css";
import "@vtmn/css-progressbar/dist/index.css";
import "@vtmn/css-button/dist/index.css";
import "@vtmn/css-text-input/dist/index.css";
import "@vtmn/css-toggle/dist/index.css";
import "@vtmn/css-checkbox/dist/index.css";
import "@vtmn/css-design-tokens/dist/index.css";
import "@vtmn/css-popover/dist/index-with-vars.css";
import "@vtmn/css-popover/dist/index.css";
import "@vtmn/css-tooltip/dist/index.css";
import "@vtmn/icons/dist/vitamix/font/vitamix.css";
import mdmVitamineSwitch from "./components/mdm/vitamine/switch/switch";
import "typeface-roboto";
import "typeface-roboto-condensed";
import HttpRequestsUtils from './utils/http-requests-utils';
import AuthenticationService from "./service/auth/authentication-service";

Vue.use(ElementUI);
Vue.use(VueMaterial);
Vue.use(AsyncComputed);
Vue.use(VueCharts);
Vue.use(VueResource);
Vue.use(VueSession);
Vue.use(Tabs);
Vue.use(JsonViewer);
Vue.use(Vuetify);
Vue.use(Vuelidate);
Vue.use(Gapi, {});
Vue.use(Donut);

Vue.directive('focus', {
  // Quand l'élément lié est inséré dans le DOM...
  inserted: function (el) {
    // L'élément prend le focus
    el.focus()
  }
});


Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.use(VueResource);
Vue.use(Vuex);

Vue.http.interceptors.push((request, next) => {
    HttpRequestsUtils.updateUrlAndApiKeyIfNeeded(request);
    next(response => reRequestIfUnauthorized(request, response));
});

const reRequestIfUnauthorized = async (request, response) => {
    if(response.status === 401) {
        LocalStorageService.remove(LocalStorageConstant.USER.COUNTRY);

        const authService = AuthenticationService.getSingleInstance();
        await authService.updateTokens();
        request.headers.set("Authorization", "Bearer " + authService.getAccessToken());
        return Vue.http(request);
    }
    return response;
};

let url = process.env.VUE_APP_URL_BACK;
const APP_ENV = process.env.VUE_APP_ENV;

let urlUtils = require('./url');
setStore(urlUtils.getStore(url));

const timestamp = new Date().getTime();
localStorage.setItem("timestamp", timestamp);

console.log("process.env.VUE_APP_ENV: " + APP_ENV);

const URL_SUFFIX = APP_ENV === 'dev' ? "-dev" : "";
const SOCKET_IO_STAGING = (APP_ENV || "").startsWith('prod') ? 'prod' : APP_ENV;

Vue.use(new VueSocketIO({
    debug: true,
    connection: store.state.urlKioskPulling,
    options: {
        path: `/emmunsecured${URL_SUFFIX}/${SOCKET_IO_STAGING}/realtime/socket.io`,
        query: `deviceType=BROWSER&deviceId=BROWSER${timestamp}&uid=mdm-retail`,
        transports: ['polling', 'websocket'],
        transportOptions: {
            polling: {
                extraHeaders: {
                    "x-api-key": process.env.VUE_APP_API_PORTAL_KEY_EMM_UNSECURED
                }
            }
        }
    }
}));

Vue.use(VueAnalytics, {
    id: 'UA-93693449-1',
    debug: {
        enabled: false,
        trace: false,
        sendHitTask: store.state.staging === 'prod'
    },
    router,
    autoTracking: {
        pageviewTemplate(route) {
            return {
                page: route.path,
                title: document.title,
                location: window.location.href
            }
        }
    }
});

/* COMPONENTS */

Vue.component('pagination', pagination);
Vue.component('filter-select', filterSelect);
Vue.component('input-text', inputText);
Vue.component('arbo', arbo);
Vue.component('chip-legend', chipLegend);
Vue.component('chip-legend-inactif', chipLegendInactif);
Vue.component('nav-bullet', navBullet);
Vue.component('mdm-button', mdmButton);
Vue.component('last-activities', lastActivities);
Vue.component('filter-toggle', filterToggle);
Vue.component('star-rating', StarRating);
Vue.component('VueFlag', VueFlag);
Vue.component('mdm-json-dialog', jsonDialog);
Vue.component('mdm-vtmn-popup', mdmVtmnPopup);
Vue.component('mdm-vtmn-button', mdmVtmnButton);
Vue.component('mdm-vtmn-popover', mdmVtmnPopover);
Vue.component('mdm-vtmn-adjust-card', mdmVtmnAdjustCard);
Vue.component('mdm-vtmn-switch', mdmVitamineSwitch);
Vue.component('mdm-autocomplete', mdmAutocomplete);
Vue.component('mdm-autocomplete-multi', mdmAutocompleteMulti);
Vue.component('mdm-multi-choice', mdmMultiChoice);
Vue.component('mdm-toaster', mdmToaster);
Vue.component('mdm-input', mdmInput);
Vue.component('mdm-text-area', mdmTextArea);
Vue.component('mdm-select', mdmSelect);
Vue.component('mdm-flip', mdmFlip);

Vue.component('device-svg', DeviceSVG);
Vue.component('kiosk-svg', KioskSVG);
Vue.component('tablet-svg', TabletSVG);
Vue.component('spinner-svg', SpinnerSVG);
Vue.component('dkt-logo-svg', DktLogoSVG);

/* FILTER */
Vue.filter('byteToString', function (value) {
    var bytes = value;
    var si = true;
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];

})

  /* INIT VUE */
new Vue({
    vuetify : new Vuetify(),
    router,
    store,
    el: '#app',
    template: '<App/>',
    components: {App},
    render: h => h(App)
  });
