<template>
    <nav>
        <generic-nav />
    </nav>
</template>

<script>
    import Vue from 'vue';
    import 'vue-material/dist/theme/default.css';
    import GenericNav from './generic-nav';

    export default {
        components: {
            'generic-nav': GenericNav,
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@/assets/style/color';
    @import '~@/assets/style/font';

    nav {
        line-height: 50px;
        background-color: $greyLight;
    }
</style>
