<template>
    <div class="page-content full-size">

        <div v-if="error">
            <md-content class="md-accent">{{error}}</md-content>
        </div>
        <div v-else-if="!group.application" class="text-center">
            <md-progress-spinner :md-diameter=50 :md-stroke=5 md-mode="indeterminate"/>
        </div>
        <div v-else>
            <mdm-vitamine-back-link :link="'/applications/' + $route.params.id" id="application-add-button-back">
                Back to the application
            </mdm-vitamine-back-link>

            <p class="mb-5 vtmn-typo_title-5 app-title">Create a deployment group for {{ group.application.name }} </p>

            <v-row no-gutters class="mdm-vtmn-default-backgroud pb-5 pt-5" justify="space-around">
                <v-col cols=11 sm=8 md=5 :class="this.$vuetify.breakpoint.smAndDown && 'mb-5'">
                    <h2 class="mb-5 vtmn-typo_title-5">Information</h2>
                    <group-information />
                </v-col>
                <v-col cols=11 sm=8 md=5>
                    <h2 class="mb-5 vtmn-typo_title-5">Deployment perimeter</h2>
                    <group-perimeter />
                </v-col>
            </v-row>

            <group-recap />
        </div>

        <div>
            <div v-if='group.saveGroupLoader' class='overlay' />
            <md-progress-spinner class='loading' v-if="group.saveGroupLoader" :md-diameter=50 :md-stroke=5 md-mode="indeterminate" />
        </div>

        <mdm-toaster :modelValue="!$vuetify.breakpoint.mdAndDown && (group.saveGroupError || group.emptyFieldShow)" @close="popupEmptyFieldClose">
            <group-error-content />
        </mdm-toaster>

        <md-content v-if="$vuetify.breakpoint.mdAndDown && (group.saveGroupError || isAnError)" class="md-accent mb-5">
            <group-error-content />
        </md-content>
    </div>
</template>

<script>
    import Vue from 'vue';
    import MdmVitamineBackLink from '../../../../components/mdm/vitamine/link/mdm-vtmn-back-link';
    import GroupInformation from './group-information';
    import GroupRecap from './recap/group-recap';
    import GroupErrorContent from './error/group-error-content';
    import GroupPerimeter from './perimeter/group-perimeter';
    import ApplicationService from '../../../../service/application/application-service';
    import appTypeEnum from '../../../../service/application/app-type-enum';
    import groupService from '../../../../service/group/group-service';
    import UrlLogoUtils from '../../../../utils/url-logo-utils';
    import GroupUtils from '../../../../utils/group-utils';
    import Moment from 'moment';
    import FieldConstant from '../../../../utils/field-constant';
    import ArboUtils from '../../../../utils/arbo-utils';
    import DeploymentGroupConstant from "../../../../constant/deployment-group-constant";

    export default {
        name: "group-gesture",
        components: {
            MdmVitamineBackLink, GroupInformation, GroupPerimeter, GroupRecap, GroupErrorContent
        },
        data: () => {
            return {
                error: null,
                PRODUCTION_VERSION_NAME: FieldConstant.APPLICATION.PLAYSTORE.PRODUCTION_VERSION_NAME
            };
        },

        mounted: function() {
            this.$store.dispatch('group/resetGroup');
            if(this.$route.path.endsWith('/group')) {
                this.$store.commit("group/setGroupIsActive", true);
            }
            if(this.$route.params.idGroup) {
                this.getGroup();
            } else {
                this.$store.dispatch("group/setGroupBu", DeploymentGroupConstant.BU.RETAIL);
                this.getApplication();
            }
        },
        computed: {
            group() {
                return this.$store.state.group.group;
            },
            countUser() {
                return this.$store.state.group.group.countUser;
            },
            isAnError() {
                return !GroupUtils.canDeploy(this.group, this.$store.state.group.group.countUser, this.$route.params.idGroup, this.$route.fullPath);
            }
        },
        methods: {
            async getApplication() {
                try {
                    const application = await ApplicationService.getApplication(this.$route.params.id);
                    if(application.appType === appTypeEnum.PUBLIC_APP) this.putPSVersionFirst(application);

                    const applicationWithIcon = {
                        ...application,
                        icon: (await UrlLogoUtils.getApplicationLogo(application)).icon
                    }
                    this.$store.dispatch('group/setGroup', {
                        application: applicationWithIcon,
                        arbo: await ArboUtils.transformRequirementToArbo()
                    });
                    await groupService.getAvailableVersion(applicationWithIcon);
                    return applicationWithIcon;
                } catch(e) {
                    console.error("Error when loading the application:", e);
                    this.error = "Unable to load the associated application to create the group. Please try again. If the problem persists, please contact your administrator. "
                }
            },
            async getGroup() {
                try {
                    const application = await this.getApplication();
                    const group = await groupService.getGroup(this.$route.params.idGroup);
                    const requirement = group.requirementJson || {};

                    if (application.appType === appTypeEnum.PUBLIC_APP) delete group.version;

                    this.$store.dispatch('group/setGroup', {
                        application,
                        ...group,
                        forceUpdate: group.availability === 'UPDATE_REQUIRED',
                        includeModelCheck: Boolean(requirement.models?.included?.length > 0),
                        excludeModelCheck: Boolean(requirement.models?.excluded?.length > 0),
                        includeDeviceCheck: Boolean(requirement.devices?.length > 0),
                        requirement: {...this.group.requirement, ...requirement},
                        schedule: Moment(group.activationDate).format(FieldConstant.DATE.FORMAT.DEFAULT),
                        status: group.activated
                    });
                } catch(e) {
                    console.error("Unable to load the associated application and group to edit it.", e)
                    this.error = "Unable to load the associated application and group to edit it. Please try again. If the problem persists, please contact your administrator. "
                };
            },
            popupEmptyFieldClose() {
                this.$store.dispatch('group/setGroup', {
                    emptyFieldShow: false
                });
            },
            putPSVersionFirst(playstoreApp) {
                const indexOfPlaystoreApp = playstoreApp.versions.findIndex(version => version.versionName === this.PRODUCTION_VERSION_NAME);

                if(indexOfPlaystoreApp > 0) {
                    playstoreApp.versions.unshift(playstoreApp.versions.splice(indexOfPlaystoreApp, 1)[0]);
                }
            }
        },
    }
</script>

<style lang="scss">
.mdm-toaster {
    ul, li {
        list-style: disc;
    }
}
</style>
