var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"wrap":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticClass:"title-step"},[_vm._v("Starting and stopping the kiosk")])]),_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","lg6":"","text-center":"","text-sm-right":""}},[_c('el-time-select',{attrs:{"id":'mdm-starting-time-choice',"picker-options":{
                    start: '07:00',
                    step: '00:5',
                    end: '22:00'
                },"placeholder":'Start time (default ' + _vm.DEFAULT_REBOOT_TIME + ')'},model:{value:(_vm.rebootTime),callback:function ($$v) {_vm.rebootTime=$$v},expression:"rebootTime"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","lg6":"","text-center":"","text-sm-left":""}},[_c('el-time-select',{attrs:{"id":'mdm-end-time-choice',"picker-options":{ 
                    start: '07:00',
                    step: '00:5',
                    end: '22:00'
                },"placeholder":'End time (default ' + _vm.DEFAULT_LOCK_TIME + ')'},model:{value:(_vm.lockTime),callback:function ($$v) {_vm.lockTime=$$v},expression:"lockTime"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"id":"devices-new-device-btn-idle-time-save"},on:{"click":function($event){return _vm.choose()}}},[_vm._v(" Save "),_c('md-icon',[_vm._v("keyboard_arrow_right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }