<template>
    <div>
        <group-choose-store class="mb-5"/>

        <group-choose-type class="mb-5 mt-5" />

        <group-choose-model class="mb-5 mt-5" id="include" placeholder="Models"
                            :allModels="allModels"
                            :isCheck="group.includeModelCheck"
                            :handle="handleIncludeModel"
                            :handleSelect="handleSelectIncludeModel"
                            :value="group.requirement.models ? group.requirement.models.included : []"
                            :disabled="group.excludeModelCheck"
                            tooltips="You cannot select include at the same time as exclude. You have to select either one or the other."
        >
            Include models
        </group-choose-model>

        <group-choose-model class="mb-5 mt-5" id="exclude" placeholder="Models"
                            :allModels="allModels"
                            :isCheck="group.excludeModelCheck"
                            :handle="handleExcludeModel"
                            :handleSelect="handleSelectExcludeModel"
                            :value="group.requirement.models ? group.requirement.models.excluded : []"
                            :disabled="group.includeModelCheck"
                            tooltips="You cannot select exclude at the same time as include. You have to select either one or the other."
        >
            Exclude models
        </group-choose-model>
        <group-choose-ids   class="mb-5 mt-5" id="device-id" placeholder="Device id"
                            :isCheck="group.includeDeviceCheck"
                            :handle="handleIncludeDevice"
                            :value="group.requirement.devices"
        >
            Add device IDs (If you choose an ID, it will be included directly in the deployment. The option "Include / Exclude a model" will be ignored for this ID!)
        </group-choose-ids>
    </div>
</template>

<script>
    import GroupChooseStore from './group-choose-store';
    import GroupChooseType from './group-choose-type';
    import GroupChooseModel from './group-choose-model';
    import GroupChooseIds from './group-choose-ids';
    import ModelService from '../../../../../service/models/model-service';

    export default {
        name: "group-perimeter",
        components: {
            GroupChooseStore, GroupChooseType, GroupChooseModel, GroupChooseIds
        },
        data: function() {
            return {
                allModels: []
            }
        },
        mounted: function() {
            this.getAllModels();
        },
        computed: {
            group() {
                return this.$store.state.group.group;
            }
        },
        methods: {
            getAllModels() {
                ModelService.getModels().then(models => {
                    this.allModels = models;
                });
            },
            handleIncludeModel() {
                this.$store.dispatch('group/setGroup', {includeModelCheck: !this.group.includeModelCheck});
            },
            handleExcludeModel() {
                this.$store.dispatch('group/setGroup', {excludeModelCheck: !this.group.excludeModelCheck});
            },
            handleSelectIncludeModel(models) {
                this.$store.dispatch('group/setGroup', {
                    requirement:  {
                        ...this.group.requirement,
                        models: {
                            ...this.group.requirement.models,
                            included: models
                        }
                    }
                });
            },
            handleSelectExcludeModel(models) {
                this.$store.dispatch('group/setGroup', {
                    requirement:  {
                        ...this.group.requirement,
                        models: {
                            ...this.group.requirement.models,
                            excluded: models
                        }
                    }
                });
            },
            handleIncludeDevice() {
                this.$store.dispatch('group/setGroup', {includeDeviceCheck: !this.group.includeDeviceCheck});
            }
        }
    }
</script>
