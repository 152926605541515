<template>
    <v-row no-gutters align="center" class="device-choice-panel">
        <v-col cols="12" sm="4" class="text-md-left" >
            <mdm-button id="devices-new-device-btn-device-choice-smartphone" :text="SMARTPHONE"
                        :icon="'stay_primary_portrait'" css="md-raised button-choice md-primary"
                        @click="choose(SMARTPHONE)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" sm="4" class="text-md-left">
            <mdm-button id="devices-new-device-btn-device-choice-tablet" :text="TABLET"
                        :icon="'stay_primary_landscape'" css="md-raised button-choice md-info md-accent"
                        @click="choose(TABLET)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" sm="4" class="text-md-left">
            <mdm-button id="devices-new-device-btn-device-choice-kiosk" :text="KIOSK" :icon="'dvr'"
                        css="md-raised button-choice md-info md-primary" @click="choose(KIOSK)"
                        is-no-need-inline-block/>
        </v-col>
    </v-row>
</template>


<script>
import FieldConstant from "../../../../utils/field-constant";


export default {
    name: "new-device-device-choice",
    data: function(){
        return {
            SMARTPHONE: FieldConstant.DEVICE_TYPE.SMARTPHONE,
            TABLET: FieldConstant.DEVICE_TYPE.TABLET,
            KIOSK: FieldConstant.DEVICE_TYPE.KIOSK
        }
    },
    methods: {
        choose(deviceType) {
            this.$emit("selected", {deviceType});
        }
    }
};

</script>

<style lang="scss">

.device-choice-panel {
    #devices-new-device-btn-device-choice-smartphone {
        background-color: var(--vtmn-color_brand-digital-light-1)
    }

    #devices-new-device-btn-device-choice-tablet {
        background-color: var(--vtmn-color_brand-digital)
    }

    #devices-new-device-btn-device-choice-kiosk {
        background-color: var(--vtmn-color_brand-digital-dark-1)
    }
}

</style>
