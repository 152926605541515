<template>
    <div
        class="vtmn-popover"
        :data-position="position"
        :aria-describedby="id"
        tabindex="0"
    >
        <slot />
        <div :id="id" role="tooltip" :style="{maxWidth: maxWidth + 'px'}">
            <p class="vtmn-popover_title">
                <slot name="title" />
            </p>
            <p class="vtmn-popover_text">
                <slot name="content" />
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'mdm-vtmn-popover',
        props: {
            id: {
                type: String,
                required: true
            },
            position: {
                type: String,
                default: "bottom",
                validator: function (value) {
                    return [
                        'top-right',
                        'top',
                        'top-left',
                        'right',
                        'left',
                        'bottom-right',
                        'bottom',
                        'bottom-left'
                    ].indexOf(value) !== -1
                }
            },
            maxWidth: {
                type: Number,
                default: 300
            }
        }
    }
</script>
<style lang="scss" >
    .vtmn-popover {
        > [role='tooltip'] {
            width: max-content !important;
            padding-bottom: 5px !important;
            padding-top: 5px !important;
        }
    }
</style>
