<template>
    <v-row no-gutters align="center" justify="center">
        <v-col cols="12" sm="6" md="2" v-if="!isUserWithoutAnyRight">
            <router-link id="navigation-home" to="/" class="router-link" exact>
                <md-icon class="md-size-1x">home</md-icon>
                <span class="title-text" style="color:#000;">Home</span>
            </router-link>
        </v-col>
        <v-col cols="12" sm="6" md="3" v-if="!isUserWithoutAnyRight">
            <router-link id="navigation-devices" to="/devices" class="router-link">
                <md-icon class="md-size-1x">phone_android</md-icon>
                <span class="title-text" style="color:#000;">Devices</span>
            </router-link>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mobile && !isUserWithoutAnyRight" cols="12" sm="6" md="3">
            <router-link id="navigation-new-device" to="/rgpd" class="router-link">
                <md-icon class="md-size-1x">add_box</md-icon>
                <span class="title-text" style="color:#000;">New device</span>
            </router-link>
        </v-col>

        <v-col cols="12" sm="6" md="3" v-if="!isUserWithoutAnyRight">
            <router-link id="navigation-applications" to="/applications" class="router-link">
                <md-icon class="md-size-1x">apps</md-icon>
                <span class="title-text" style="color:#000;">Applications</span>
            </router-link>
        </v-col>
        <v-col v-if="IS_ADMIN && IS_MDM_TEAM" cols="12" sm="6" md="2">
            <router-link id="navigation-admin" to="/admin" class="router-link">
                <md-icon class="md-size-1x">build</md-icon>
                <span class="title-text" style="color:#000;">Admin</span>
            </router-link>
        </v-col>
        <v-col v-if="isActivatedMigrationTab" cols="12" sm="6" md="2">
            <router-link id="navigation-migration" to="/migration" class="router-link">
                <md-icon class="md-size-1x">visibility</md-icon>
                <span class="title-text" style="color:#000;">Migration</span>
            </router-link>
        </v-col>
    </v-row>
</template>

<script>
    import UserApiService from "@/service/user/user-api-service";
    import AdminParameterApiService from "@/service/admin/parameter/parameter-api-service";

    export default {
        data(){
            return {
                IS_MDM_TEAM: false,
                IS_ACTIVATED_PARAMETER_MIGRATION_TAB: false,
            }
        },
        computed: {
            IS_ADMIN: function(){
                return this.$store.state.user?.admin;
            },
            isUserWithoutAnyRight() {
                const user = this.$store.state.user;
                return !user.admin && !user.appManager && !user.cp && !user.cc;
            },
            isActivatedMigrationTab() {
                const user = this.$store.state.user;
                const isUserCPorAdmin = (user.admin || user.cp);
                return isUserCPorAdmin && this.IS_ACTIVATED_PARAMETER_MIGRATION_TAB;
            },

        },
        mounted(){
            this.isPartOfMdmTeam();
            this.isActivatedParameterMigrationTab();
        },
        methods:{
            async isPartOfMdmTeam(){
                this.IS_MDM_TEAM = await UserApiService.isPartOfMdmTeam();
            },
            async isActivatedParameterMigrationTab(){
                const featureFlippingMigrationTab = await AdminParameterApiService
                    .getBooleanBy("front.migration.tab.activated", false);
                this.IS_ACTIVATED_PARAMETER_MIGRATION_TAB = featureFlippingMigrationTab;
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import '~@/assets/style/color';
    @import '~@/assets/style/font';

    .router-link {
        min-height: 50px;
        border-bottom: 4px solid transparent;
        display: inherit;
        text-transform: uppercase;
        font-family: 'Roboto Condensed';
        font-size: 19px;
        color: $dark;
        text-decoration: none;
    }
    .router-link>* {
        vertical-align: middle;
    }

    .router-link:hover {
        background-color: $grey2;
        text-decoration: none;
    }


    .router-link-active {
        border-color: #3643BA;
        background-color: $grey2;
        color: $dark;
    }

    .router-link-icon {
        vertical-align: middle;
        margin-bottom: 5px;
        margin-right: 10px;
    }

</style>
