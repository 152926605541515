<template>

    <div
        ref="mdmCard"
        class="mdm-card"
        :class="additionalCardClass"
        @click="cardClicked" :id="id">
            <div v-if="this.$vuetify.breakpoint.mdAndUp && isActivated === true" class="mdm-card-close">
                <span @click="closeCardClicked">
                    <md-icon :id="idCloseBtn" @click="closeCardClicked" >close</md-icon>
                </span>
            </div>
            <mdm-vtmn-adjust-card class="mt-5" content="center" content-align="center">
                <template v-slot:header>
                    <img v-if="iconSrc" :src="`${iconSrc}`" class="app-icon" />
                    <h2 class="vtmn-typo_title-5" >{{title}}</h2>
                    <h3 v-if="url" class="vtmn-typo_text-3-font-size">{{url}}</h3>
                </template>

                <slot />

                <template v-slot:footer>
                    <slot name="footer" />
                </template>
            </mdm-vtmn-adjust-card>
    </div>
</template>

<script>
export default {
    name: 'mdm-vitamine-card',
    data: function () {
        return {
            isActivated : false,
            idCloseBtn : this.id + "-close",
        }
    },
    props: {
        title: {
            type: String,
            default: "No Title"
        },
        disabled: {
            type: Boolean,
            default: false,
            description: "Disabled the click on the card"
        },
        click: {
            type: Function,
            default: () => {},
            description: "What to do when the component is clicked"
        },
        minimized: {
            type: Boolean,
            default: false
        },
        inactive: {
            type: Boolean,
            default: false,
            description: "The will never be clicked"
        },
        id: {
            type: String,
            required: true
        },
        iconSrc: {
            type: String,
        },
        url: {
            type: String,
        }
    },
    computed: {
        canClickOnCard() {
            return !this.disabled && !this.minimized && !this.isActivated && !this.inactive
        },
        additionalCardClass: function () {
            let additionalClass = this.disabled ? 'mdm-card-disabled' : '';
            additionalClass += this.minimized ? ' mdm-card-minimized' : '';
            additionalClass += this.isActivated ? ' mdm-card-activated' : '';
            additionalClass += this.inactive ? ' mdm-card-inactive' : '';
            return additionalClass;
        }
    },
    methods: {
        cardClicked: function (event) {
            if(event.target.id !== this.idCloseBtn && this.canClickOnCard) {
                this.isActivated = true;
                !this.disabled && this.$emit('click', {event, id: this.id || this._uid});
            }
        },
        closeCardClicked: function(event) {
            if(this.isActivated) {
                this.isActivated = false;
                this.$emit('close', {event, id: this.id || this._uid});
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.mdm-card-activated {
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 2px var(--vtmn-color_brand);
}

.mdm-card {
    background-color: #ffffff;
    border-radius: 8px;
    transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 10px 20px 10px 20px;
    min-height: 300px;
    height: 100%;

    display: flex;
    flex-direction: column;

    &:not(.mdm-card-inactive):hover {
        @extend .mdm-card-activated;
    }

    .mdm-card-close {
        text-align: right;
    }
}

.mdm-card-disabled:not(.mdm-card-inactive) {
    &:hover {
        cursor: pointer;
        box-shadow: inset 0px 0px 0px 2px var(--vtmn-color_grey-light-1);
        background-color: var(--vtmn-color_grey-light-2);
    }
    &:active {
        cursor: not-allowed;
    }
}

.mdm-card-minimized {
    width: 75px;
    min-height: 75px;
    height: 75px;

    div {
        display: none;
    }
}

</style>
