<template>
    <div>
        <v-row >

            <v-col cols="12" align="center" justify="center">
                <v-alert
                    color="var(--vtmn-color_green-light-1)"
                    icon="mdi-emoticon-happy-outline"
                    text prominent >
                    <p>Fill in the countries whose users will have access to the deployment of your application</p>
                </v-alert>

                <mdm-vtmn-popup ref="popupauth"
                                id-button-open="application-add-button-open-popup-auth"
                                class-button-open="vtmn-btn vtmn-btn_variant--secondary-reversed mdm-vtmn-custom-blue-borders" >
                    <template v-slot:button-open-content>Select the countries authorized to deploy</template>

                    <mdm-vtmn-adjust-card>
                        <template v-slot:header>
                            <p class="vtmn-typo_title-5" align="center">Deployment authorization</p>
                        </template>

                        <arbo type='lite' export='array' @changeArbo='changeArbo' :countryAlreadyChecked="null" />

                        <template v-slot:footer>
                            <button class="vtmn-btn btn-popup-close" id="application-add-button-close-popup-auth" @click="$refs.popupauth.close()">Validate</button>
                        </template>
                    </mdm-vtmn-adjust-card>
                </mdm-vtmn-popup>

                <p class="vtmn-typo_text-1 mt-5">{{selectedCountriesToDeploy}} {{selectedCountriesToDeploy <= 1 ? 'country ': 'countries'}} have access to the deployment of your application.</p>
            </v-col>
        </v-row>
    </div>
</template>

<script type="text/javascript">

export default {
    props: {
        type: {
            type: String,
            validator: (val) => ['webview', 'application'].includes(val)
        },
    },
    computed: {
        application() {
            return this.$store.state[this.type][this.type];
        },

        selectedCountriesToDeploy() {
            if(!this.application.arbo) return 0;
            return this.application.arbo.country?.length || 0;
        }
    },
    mounted: function() {
        this.$store.dispatch(this.getDispatchAction(), {arbo: {country: this.$store.state.user.countries}});
    },
    methods: {
        changeArbo(arbo) {
            this.$store.dispatch(this.getDispatchAction(), {arbo: {...arbo}});
        },
        getDispatchAction(){
            return this.type + '/set' + (this.type.charAt(0).toUpperCase() + this.type.slice(1));
        }
    }
}

</script>

<style lang="scss" >
    .popin {
        background: var(--vtmn-color_white);
    }
</style>
