<template>
	<div class="page-content">
		<h1 class='page-title'>
			<md-icon class="md-size-2x">home</md-icon>
			<span>Home</span>
		</h1>
		<div class="content" style="padding-bottom:0px !important;">
			<div class="page-subtitles" v-if='state'>
				<v-row no-gutters>
					<v-col cols="12" sm="6" pa-2>
						<h2 class='page-subtitle'>
							<md-icon class="md-size-2x" style="color: #3643BA;">apps</md-icon>
							<span id="home-label-number-applications-deployed" data-cerberus='mdm-home-appdeployed'> {{state.deployedApps}} </span>
							<span> Applications deployed</span>
						</h2>
					</v-col>
					<v-col cols="12" sm="6">
						<h2 class='page-subtitle'>
							<md-icon class="md-size-2x" style="color: #3643BA;">android</md-icon>
							<span id="home-label-number-os-deployed" data-cerberus='mdm-home-osversion'> {{state.osVersions}} </span>
							<span> OS version </span>
						</h2>
					</v-col>
					<v-col cols="12" sm="6" >
						<h2 class='page-subtitle'>
							<md-icon class="md-size-2x" style="color: #3643BA;">smartphone</md-icon>
							<span id="home-label-number-models-deployed" data-cerberus='mdm-home-models'> {{state.models}} </span>
							<span> Models </span>
						</h2>
					</v-col>
					<v-col cols="12" sm="6">
						<h2 class='page-subtitle'>
							<md-icon class="md-size-2x" style="color: #3643BA;">supervised_user_circle</md-icon>
							<span id="home-label-number-users-deployed" data-cerberus='mdm-home-users'> {{state.users}} </span>
							<span> Users </span>
						</h2>
					</v-col>
				</v-row>
            </div>
			<div class="home-stats">
            	<last-activities/>
				<div class="chart-half-home">
					<h1 id="home-label-chart-enrollment" class="md-title" style="background-color: white; border-radius: 10px; padding-top: 30px; padding-left: 35px;">Enrollments</h1>
					<vue-chart
						v-if='enrolment'
						chart-type="LineChart"
						:columns="enrolment.columns"
						:rows="enrolment.rows"
						:options="enrolment.options"
					></vue-chart>
                    <h1 id="home-label-chart-enrollment-2" class="md-title" style="background-color: white; border-radius: 10px; color:white;">.</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import Vue from "vue";
    import Moment from "moment";
    import "vue-material/dist/theme/default.css";
	import {getHostnameSocket } from "../../utils/socketRealtime.js";

    export default {
		data : function(){
			return {
				globalRate : 0,
				comment : ''
			}
		},
		asyncComputed : {
			state : function(){
				return Vue.http.get(`${this.$store.state.urlHomepageState}`).then((response) => {
					return response.body.data;
				}, (error) => {
					console.log(error);
				});
			},
            enrolment: async function() {
                try {
                    const response = await Vue.http.get(`${this.$store.state.urlHomepageEnrollment}`);
                    const data = [];

                    response.body.forEach((el) => {
                        data.push([
                            Moment(el[0]).format('DD MMM'),
                            el[1]
                        ]);
                    });

                    return {
                        columns: [
                            {
                                'type': 'string',
                                'label': 'Date'
                            },
                            {
                                'type': 'number',
                                'label': 'Number off devices'
                            }
                        ],
                        rows: data,
                        options: {
                            titlePosition: 'top',
                            hAxis: {
                                titlePosition: 'none',
                                baselineColor: '#e8e9e9',
                                viewWindowMode: 'maximized',
                                textStyle: {
                                    color: '#898989'
                                },
                                gridlines: {
                                    color: '#e8e9e9',
                                    count: 5
                                }
                            },
                            vAxis: {
                                titlePosition: 'none',
                                baselineColor: '#e8e9e9',
                                textStyle: {
                                    color: '#898989'
                                },
                                gridlines: {
                                    color: '#e8e9e9',
                                    count: -1
                                }
                            },
                            height: 430,
                            curveType: 'none',
                            colors: ['#189bdc'],
                            legend: { position: 'none' },
                            chartArea: {
                                left: 50,
                                width: "100%",
                                height: 330
                            },
                            backgroundColor: {
                                fill: '#FFFFFF'
                            }
                        }
                    };
                } catch (error) {
                    console.log(error);
                }
            },
		},
		methods : {
			opened : function() {
				this.countryFilterValue += " ";
				this.countryFilterValue = this.countryFilterValue.substring(0, this.countryFilterValue.length - 1)
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';
</style>
<style lang="css" scoped>
	.md-toolbar.md-theme-default.md-transparent {
    	background-color: #f5f5f5 !important;
	}
</style>

<style lang="scss">
	@import '~@/assets/style/color';
	@import './homepage.scss';

    .home-stats{
        display:flex;
        justify-content: space-evenly;

        @media only screen and (max-width: 600px) and (min-width: 0px) {
            flex-direction: column;
        }
    }

</style>
