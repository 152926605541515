<template>
	<div class='filter-toggle' :class="{'filter-toggle-active' : status}" @click='changeStatus'>
		<div class="filter-toggle-container" :class="{'filter-toggle-cursor' : isService}">
			<div class="filter-toggle-trigger"></div>
		</div>
	</div>
</template>

<script>
const utils = require("../../../src/utils/utils");
import MessagesConstants from "../../../src/utils/message-constant";

export default{
	props : ['clickable', 'status', 'isService'],
	methods : {
		changeStatus : function(){
			if(this.clickable){
                if(this.isService) {
                    this.handleIsActive()
                } else {
                    this.$emit('changeStatus', !this.status);
                }
			}
		},
        async handleIsActive() {
            const isConfirmed = await utils.displayAlertDialogYesNo(MessagesConstants[`${this.status ? 'UNDEPLOY' : 'DEPLOY'}_ON_SERVICES`]);
            if(isConfirmed) {
                this.$store.commit("group/setGroup", {saveGroupLoader: true, saveGroupError: null});
                this.$emit('changeStatus', !this.status);
            }
        }
	}
}
</script>

<style lang="scss">
@import 'filter-toggle';
.filter-toggle-cursor {
    cursor: pointer;
}
</style>
