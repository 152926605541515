import {store} from "../store/store";
import browserConstant from "../constant/browser-constant";
import LocalStorageService from "../service/local-storage-service";
import LocalStorageConstant from "../constant/local-storage-constant";

export default {
    isChinese() {
        return getCountry(this.getCurrentTimezone()) === browserConstant.COUNTRIES.CN;
    },
    getCurrentTimezone(){
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    canCreateApp() {
        return store.state.user.admin == true || store.state.user.cp == true || store.state.user.appManager == true;
    },
    canUpdateKioskInfo() {
        const {admin, cp, cc} = store.state.user;
        return admin || cp || cc;
    }
}

const getCountry = (timezone) => {

    const storedCountry = LocalStorageService.getItem(LocalStorageConstant.USER.COUNTRY);
    if (storedCountry) {
        return storedCountry;
    }

    let foundCountry = browserConstant.COUNTRIES[browserConstant.COUNTRIES.DEFAULT];

    if (!timezone || timezone === "") {
        return foundCountry;
    }
    const _country = browserConstant.TIMEZONES[timezone]?.c[0];
    foundCountry = browserConstant.COUNTRIES[_country] || browserConstant.COUNTRIES[browserConstant.COUNTRIES.DEFAULT];

    LocalStorageService.setItem(LocalStorageConstant.USER.COUNTRY, foundCountry, true);
    return foundCountry;

}
