<template>
    <v-row no-gutters class="mt-5 mb-7" id="device-search-header" >
        <v-col cols="12" sm="5" md="5">
            <v-row no-gutters align="center" class="devices-header-active-number" >
                <v-col cols="1" class="d-flex justify-center">
                    <span aria-hidden="smartphone" class="vtmx-smartphone-line icon-medium" />
                </v-col>
                <v-col >
                    <div class="divider-left pl-3">
                        <div id="devices-header-search-title">
                            {{ computedDevicesTotal }} DEVICES MATCH YOUR SEARCH
                        </div>
                        <div v-if="!isStatsError" class="devices-header-search-button">
                            <mdm-vtmn-button variant="secondary" notBlock id="devices-header-search-button-all" :data-state="activeFilter === ALL"
                                @click="searchButtonClick(ALL)"
                                v-if="!devicesCountStats || (devicesCountStats.totalDs > 0 && devicesCountStats.totalAam > 0)">
                                <div class="devices-header-active-panel">
                                    <span>All</span>
                                    <span v-if="computedDevicesTotal && !isNaN(computedDevicesTotal)" id="devices-header-search-all-number-label">{{ computedDevicesTotal }}</span>
                                    <v-skeleton-loader type="text" v-else />
                                </div>
                            </mdm-vtmn-button>
                            <mdm-vtmn-button variant="secondary" notBlock id="devices-header-search-button-aam" :data-state="activeFilter === AAM"
                                @click="searchButtonClick(AAM)" :disabled="devicesCountStats && devicesCountStats.totalAam == 0">
                                <div class="devices-header-active-panel">
                                    <span>AAM</span>
                                    <span v-if="devicesCountStats" id="devices-header-search-aam-number-label">{{ devicesCountStats.totalAam }}</span>
                                    <v-skeleton-loader type="text" v-else />
                                </div>
                            </mdm-vtmn-button>
                            <mdm-vtmn-button variant="secondary" notBlock id="devices-header-search-button-ds" :data-state="activeFilter === DS"
                                @click="searchButtonClick(DS)" :disabled="devicesCountStats && devicesCountStats.totalDs == 0">
                                <div class="devices-header-active-panel">
                                    <span>DS</span>
                                    <span v-if="devicesCountStats" id="devices-header-search-ds-number-label">{{ devicesCountStats.totalDs }}</span>
                                    <v-skeleton-loader type="text" v-else />
                                </div>
                            </mdm-vtmn-button>
                            <mdm-vtmn-button variant="secondary" notBlock id="devices-header-search-button-ds" :data-state="activeFilter === DS2"
                                             @click="searchButtonClick(DS2)" :disabled="devicesCountStats && devicesCountStats.totalDs2 == 0">
                                <div class="devices-header-active-panel">
                                    <span>DS2</span>
                                    <span v-if="devicesCountStats && devicesCountStats.totalDs2" id="devices-header-search-ds2-number-label">{{ devicesCountStats.totalDs2 }}</span>
                                    <v-skeleton-loader type="text" v-else />
                                </div>
                            </mdm-vtmn-button>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="7" md="7">
            <div v-if="isStatsError" class="devices-header-error">
                <p>We are not abled to load connected devices stats. Retry later. </p>
            </div>
            <v-row v-else no-gutters align="center" class="devices-header-inactive-number">
                <v-col cols="4">
                    <div class="devices-header-inactive" data-green>
                        <span>CONNECTED</span>
                        <span v-if="devicesCountStats">{{ devicesCountStats.sinceOneDay }}</span>
                        <v-skeleton-loader type="text" v-else />
                    </div>
                </v-col>
                <v-col cols="4">
                    <div class="devices-header-inactive">
                        <span>CONNECTED SINCE 60 DAYS</span>
                        <span v-if="devicesCountStats">{{ devicesCountStats.sinceTwoMonths }}</span>
                        <v-skeleton-loader type="text" v-else />
                    </div>
                </v-col>
                <v-col cols="4">
                    <div class="devices-header-inactive">
                        <span>CONNECTED SINCE 90 DAYS</span>
                        <span v-if="devicesCountStats">{{ devicesCountStats.sinceThreeMonths }}</span>
                        <v-skeleton-loader type="text" v-else />
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import FieldConstant from "../../../utils/field-constant";
    export default {
        name: 'device-search-header',
        props: {
            devicesCountStats: {
                type: Object,
            },
            devicesTotal: {
                type: Number,
                required: true
            },
            activeFilter: {
                type: String,
                default: FieldConstant.FILTER.ALL,
                validator: (value) => [FieldConstant.FILTER.ALL, FieldConstant.FILTER.AAM, FieldConstant.FILTER.DS, FieldConstant.FILTER.DS2].includes(value.toLowerCase())
            }
        },
        computed: {
            computedDevicesTotal() {



                return this.devicesCountStats ?
                    (Number(this.devicesCountStats.totalDs) +
                        Number(this.devicesCountStats.totalAam) +
                            Number(this.devicesCountStats.totalDs2)) :
                        this.devicesTotal
            },

            isStatsError() {
                return this.devicesCountStats && this.devicesCountStats.error;
            }
        },
        methods: {
            searchButtonClick(newActiveFilter) {
                if(this.activeFilter === newActiveFilter) return;
                this.$emit('addFilter', newActiveFilter)
            }
        },
        data() {
            return {
                ALL : FieldConstant.FILTER.ALL,
                AAM : FieldConstant.FILTER.AAM,
                DS : FieldConstant.FILTER.DS,
                DS2 : FieldConstant.FILTER.DS2
            }
        },
    }
</script>

<style lang="scss" >
    #device-search-header {
        .v-skeleton-loader {
            height: auto !important;
            width: 40%;
        }
        .devices-header-search-button {
            .v-skeleton-loader__text {
                height: 100%;
            }
            button {
                padding: 10px !important;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
</style>
<style lang="scss" scoped>
    .devices-header-error {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: var(--vtmn-base-color_red400);
    }
    #device-search-header {
        .devices-header-active-number {
            color: var(--vtmn-base-color_blue300);
            .icon-medium {
                font-size: 2em;
            }
            .divider-left {
                border-left: 2px solid var(--vtmn-base-color_blue300);
            }
            .devices-header-search-button > button:not(:last-child) {
                margin-right: 10px;
            }
            #devices-header-search-title {
                font-size: 1.1em;
            }
            .devices-header-active-panel {
                display: flex;
                justify-content: space-around;
                width: 100%;
            }
            button[data-state=true] {
                background-color: var(--vtmn-semantic-color_active-primary);
            }
        }
        .devices-header-inactive-number {
            height: 100%;
            div {
                height: 100%;
            }
            div .devices-header-inactive {
                height: 100%;
                background-color: var(--vtmn-base-color_grey50);
                width: 95%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                color: var(--vtmn-base-color_blue300);
                text-align: center;

                &[data-green] {
                    color: var(--vtmn-base-color_conifer400);
                }

                span:first-child {
                    max-width: 70%;
                }
            }
        }
    }
</style>
