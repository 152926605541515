<template>
    <v-data-table id="devices-search-table" v-if="devices"
                  :headers="headersToShow"
                  :items="devices"
                  :items-per-page="pageSize"
                  hide-default-footer
                  hide-default-header
                  @click:row="showDevice">

        <template v-slot:header="{ props }">
            <th class="text-start" v-for="head in props.headers">
                <div class="header-wrapper">
                    <span :class="getHeaderClass(head)"> {{ head.text }} </span>
                    <div class="sort-icons-wrapper ml-2 mt-n1">
                        <i class="ri-arrow-up-s-line cursor-hover" :class="getSortClass(head, 'asc')" @click="sortBy(head.col, 'asc')"></i>
                        <i class="ri-arrow-down-s-line cursor-hover" :class="getSortClass(head, 'desc')" @click="sortBy(head.col, 'desc')"></i>
                    </div>
                </div>
            </th>
        </template>

        <template v-slot:item.name="{ item }">
            <div class="font-weight-bold h3"><router-link class='a-decoration-none default-row-color'  :to="{ name: 'device-show-one', params: {id: item.id}}">{{ item.name }}</router-link></div>
        </template>

        <template v-slot:item.serialNumber="{ item }">
            <div class="truncate-serial-number">{{ item.serialNumber }}</div>
        </template>

        <template v-slot:item.entity="{ item }">
            <i :class="getBuIcon(item.entity)"></i>
        </template>

        <template v-slot:item.dateLastCheck.value="{ item }">
            <div class="date-container">{{ item.dateLastCheck.value }}
                <span class="dot" :class="'dot-' + item.dateLastCheck.status"></span>
            </div>
        </template>

    </v-data-table>
</template>

<script>
    import DeviceSearchConstants from "../../../utils/device-search-constant";
    import FieldConstants from "../../../utils/field-constant";

    export default {
        name: "device-search-table",
        props: {
            headers: {type: Array, required: false}, // cannot use this.$vuetify.breakpoint to use the default option here
            devices: {type: Array, required: true},
            pageSize: {type: Number, required: false, default: () => FieldConstants.DEVICE.SEARCH.PAGE_SIZE},
            orderBy: {type: Object, required: false}
        },
        data: function () {
            return {
                headersToShow: this.getHeaders(),
                latestSortValue: null,
            };
        },
        watch: {
            orderBy: function (newVal, oldVal) {
                if (!(!this.latestSortValue || (newVal?.key === this.latestSortValue?.key && newVal?.order === this.latestSortValue?.order))) {
                    this.sortBy(newVal?.key, newVal?.order);
                }
            },
            headers: function () {
                this.headersToShow = this.headers
            }

        },
        methods: {
            getHeaders(){
                return this.$vuetify.breakpoint.mdAndUp ? this.headers : DeviceSearchConstants.DEFAULT_MOBILE_HEADERS
            },
            getBuIcon(bu) {
                return bu === FieldConstants.DEVICE.ENTITY.RETAIL ? "ri-shopping-cart-line" : "ri-truck-line";
            },
            getSortClass(header, sort) {
                return header?.sort && header.sort?.order === sort ? "full-sort-icon" : "";
            },
            getHeaderClass(header) {
                return header?.sort ? "full-sort-icon" : null;
            },
            sortBy(col, sortDirection) {
                const sort = {key: col, order: sortDirection};
                let cancelled = false;
                this.headersToShow = this.headersToShow.map(h => {
                    if (h.sort?.key === col && h.sort?.order === sortDirection) {
                        cancelled = true;
                    }
                    return {...h, sort: col === h.col ? cancelled ? null : sort : null};
                });
                this.latestSortValue = sort;
                this.$emit("startSearch", cancelled ? null : sort);
            },
            showDevice(row) {
                this.$router.push({"path": `/devices/${row.id}`});
            }
        }
    };
</script>

<style lang="scss">

    .header-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        @media only screen and (max-width: 600px) and (min-width: 0px) {
            flex-direction: column;

        }
    }

    .sort-icons-wrapper {
        display: flex;
        flex-direction: column;
        text-align-last: right;
        @media only screen and (max-width: 600px) and (min-width: 0px) {
            align-items: center;
            padding-top: 5px;
        }
    }

    .date-container {
        display: flex;
    }

    #devices-search-table {
        div {
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                text-align: center;
            }
        }

        td {
            vertical-align: middle;
            height: 48px;
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                display: table-cell;
                font-size: 0.6em;
                padding-left: 0;
                padding-right: 0;
            }
        }

        th {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            text-align-last: left;
            padding-left: 16px;
            padding-bottom: 10px;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-bottom-color: var(--vtmn-color_grey-light-2);
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                text-align-last: center;
            }
        }

        tr {
            &:nth-of-type(even) {
                background-color: var(--vtmn-color_grey-light-4) !important;
                border: hidden !important;
            }

            &:hover {
                cursor: pointer;
            }

            @media only screen and (max-width: 600px) and (min-width: 0px) {
                display: table-row;
            }
        }

        table {
            min-width: 80%;
            @media only screen and (max-width: 600px) and (min-width: 0px) {
                min-width: unset;
                width: 100%;
                table-layout: fixed;
                overflow-wrap: break-word;
            }
        }
    }

    .dot {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: bottom;
        margin-left: 3px;
    }

    .dot-SYNC_OK {
        background-color: var(--vtmn-color_success);
        font-size: 40px;
    }

    .dot-SYNC_KO {
        background-color: var(--vtmn-color_danger);
    }

    .dot-SYNC_WARN {
        background-color: var(--vtmn-color_orange-light-1);
    }

    .truncate-serial-number {
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cursor-hover:hover {
        cursor: pointer;
    }

    .full-sort-icon {
        font-size: 13px;
        font-weight: bolder;
    }

    .v-data-table--mobile>.v-data-table__wrapper tbody {
        display: contents;
    }

    .default-row-color{
        color: var(--vtmn-color_black) !important;
    }
    .grey-row-color{
        color: var(--vtmn-color_grey) !important;
    }
    .opacity-05{
        opacity:0.5;
    }

</style>
