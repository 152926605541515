<template>
    <v-dialog v-if="data" v-model="data.visible" max-width="400">
        <v-card>
            <v-card-title style="padding-top: 5px">{{ data.title }}</v-card-title>
            <v-card-text>{{ data.text }}</v-card-text>
            <v-card-actions class="justify-space-around">
                <v-btn id="admin-firmware-dialog-confirm" @click="_ => { data.confirm && data.confirm(); closeDialog(); }" color="primary">Confirm</v-btn>
                <v-btn id="admin-firmware-dialog-cancel" @click="_ => { data.cancel && data.cancel(); closeDialog(); }">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        data: {
            type : Object,
        }
    },
    methods: {
        closeDialog() {
            this.data.visible = false;
        }
    }
}
</script>
