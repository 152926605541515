<template>
    <mdm-vtmn-popup ref="popupFilterSite"
                    id-button-open="devices-all-button-open-site-filter">
        <template v-slot:button-open-content>
                <md-icon>store</md-icon>
                <span class="text-button-store">Filter by sites (retail or logistic)</span>
        </template>

        <mdm-vtmn-adjust-card>
            <template v-slot:header>
                <p class="vtmn-typo_title-5" align="center">Select a permiter to filter</p>
            </template>

            <arbo type="light" :source="mutableArbo || arbo" @changeArbo="changeArbo"/>

            <template v-slot:footer>
                <mdm-vtmn-button notBlock id="devices-all-button-close-site-filter" @click="valid">
                    Validate
                </mdm-vtmn-button>
            </template>
        </mdm-vtmn-adjust-card>
    </mdm-vtmn-popup>

</template>

<script>
    import localStorageService from '../../../service/local-storage-service';
    import localStorageConstant from '../../../constant/local-storage-constant';

    export default {
        name: 'device-filter-site',
        props: {
            arbo: {
                type: Object,
                default: null
            }
        },
        data: function() {
            return {
                mutableArbo: null
            }
        },
        methods: {
            changeArbo(arbo) {
                this.mutableArbo = arbo;
            },
            valid() {
                localStorageService.setItem(localStorageConstant.ACTIVE_ARBO, this.mutableArbo);
                this.$emit("storeSelected", this.mutableArbo);
                this.$refs.popupFilterSite.close();
            }
        }
    }
</script>

<style scoped>
#devices-all-button-well-done-site-filter {
    background-color: var(--vtmn-color_green);
}

.text-button-store {
    color: #0082c3;
    font-weight: 700;
}
</style>
