<template>
	<div class="filter-item filter-text" id="name" :id="idFilter + 'Filter'">
		<label :for="idFilter + 'ForLabel'" class='filter-label filter-aFilter' :class="{'filter-actif':valueSent == valueInput}"> {{placeholder}} : </label>
		<input :id="idFilter + 'ForLabel'" type="text" class="filter-aFilter" @keyup.enter="submit" v-model='valueInput'>
		<div v-if="canBeDelete" class="filter-delete" @click="deleteFilter(placeholder)"></div>
	</div>
</template>

<script>
export default {
	data : function(){
		if(sessionStorage.getItem('userfilter') == null ){
			var userPreferencesInJson = localStorage.getItem('userPreferences');
			if(userPreferencesInJson == 'undefined'){
				userPreferencesInJson = '';
				var ph = '';
			}else{
				userPreferencesInJson = JSON.parse(userPreferencesInJson);
				var ph = userPreferencesInJson['deviceFilter'][this.idFilter];
			}
		}
		else{
			var userPreferencesInJson = sessionStorage.getItem('userfilter');
			if(userPreferencesInJson == 'undefined'){
				userPreferencesInJson = '';
				var ph = '';
			}else{
				userPreferencesInJson = JSON.parse(userPreferencesInJson);
				var ph = userPreferencesInJson[this.idFilter];
			}
		}
			
			var placeholderValue = '';
			if(Array.isArray(ph)){
				if(typeof ph[0] !== 'undefined'){
					placeholderValue = ph[0];
				}	
			}else{
				placeholderValue = ph;
			}
		return{
			valueSent : placeholderValue,
			valueInput : placeholderValue
		}


	},
	props : ['placeholder', 'idFilter', 'canBeDelete'],
	methods : {
		deleteFilter : function(value){
			this.valueInput = '';
			this.$emit('deleteFilter', this.idFilter);
		},
		submit : function(){
			this.valueSent = this.valueInput
			this.$emit('dataSelected', {
				'id' : this.idFilter + 'Filter',
				'value' : this.valueInput
			});
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';

label{
	border-right: none ;
	padding-right : 5px;
	color : $grey3;
	padding-bottom: 13px;
	padding-top: 14px;
	height: 13px;
	box-sizing: content-box;
	&.filter-actif{
		color : $dark;
	}
}
input{
	padding-left : 5px;
	border-left : none;
	margin-left: -4px;
}
.filter-aFilter{
	display: inline-block;
    vertical-align: top;
}
</style>

<style>
	textarea:focus, input:focus{
	    outline: none;
	}
</style>
