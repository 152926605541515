<template>
    <router-link :id="id" class="mdm-router-link" :to="link" disabled>
        <div class="mdm-router-link-content"> 
            <p>            
                <md-icon>chevron_left</md-icon>
                <slot />
            </p>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'mdm-vitamine-back-link',
    props: {
        link: {
            type: String,
            default: "",
            required: true
        },
        id: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.mdm-router-link {
    text-decoration: none;
    color: var(--vtmn-color_brand-pro) !important;
    
    .mdm-router-link-content {
        vertical-align: middle;
    }

    &:hover {
        text-decoration: none;
    }
}
</style>
