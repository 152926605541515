<template>
    <div>

        <v-row >
            <v-col cols="12" class="pt-3 pb-0" style="margin-top: -10px;" align="center" justify="center">

                <mdm-vtmn-popup ref="popupWebviewChoose"
                                id-button-open="application-add-button-open-popup-auth"
                                class-button-open="border-none editButton"
                                :disabled-button="!canChangeApp">
                    <template v-slot:button-open-content>

                        <md-button id="devices-details-btn-new-webview"
                                   class="md-raised md-primary button-addApplication caption mx-0 my-0"
                                   :class="{'button-disable' : !canChangeApp, 'd-none' : !displayButton}">
                            <md-icon>link</md-icon>
                            Pick a webview
                        </md-button>

                    </template>

                    <mdm-vtmn-adjust-card>
                        <template v-slot:header>
                            <p class="vtmn-typo_title-5" align="center">Pick a Webview in the list</p>
                        </template>

                        <webview-choice :list-class="'flex-store-list'" class="px-5"
                                                :data="this.webviews"
                                                 @selected="selected"
                                                 @webviewChoiceLoaded="getAllWebviews()" />


                        <template v-slot:footer>

                            <div class="px-10 py-2 font-italic text-left" v-if="webviewSelected != null">
                                Webview selected : {{ StringUtils.truncateString(webviewSelected.name + " - " + webviewSelected.url) }}
                            </div>

                            <mdm-vtmn-button class="ma" id="device-details-button-close-popup-auth"
                                    @click="validChoice"
                                    :disabled="webviewSelected == null || !canChangeApp"
                            >Pick this one
                            </mdm-vtmn-button>

                            <mdm-vtmn-button v-if="canCreateApp" class="ma" id="device-details-button-create-new-webview" @click="createNewWebview">
                            Create a new one
                            </mdm-vtmn-button>

                        </template>
                    </mdm-vtmn-adjust-card>
                </mdm-vtmn-popup>

            </v-col>
        </v-row>
    </div>
</template>

<script type="text/javascript">
    import WebviewChoice from '../../../pages/devices/new/step/webview-choice.vue';
    import FieldConstant from './../../../utils/field-constant';
    import applicationService from "@/service/application/application-service";
    import StringUtils from '../../../utils/string-utils';

    export default {
    components: {WebviewChoice},
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        canCreateApp: {
            type: Boolean,
            default: false
        },
        canChangeApp: {
            type: Boolean,
            default: false
        },
        displayButton: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return {
            webviews: {webviews:undefined},
            webviewSelected: null,
            isChangingApp: this.isLoading,
            CANT_EDIT_STORE_KIOSK: FieldConstant.DEVICE.TOOLTIPS.CANT_EDIT_STORE_KIOSK,
            StringUtils
        }
    },
    methods: {
        async getAllWebviews(){
            const webviewsFromApi = (await applicationService.getAllApplications())?.filter(app => app.appType === 'WEBVIEW' && app.playstoreReady === 'DONE');
            const printableApps = await Promise.all( webviewsFromApi.map(webview => applicationService.convertAppToPrintableApp(webview)));
            this.webviews = {webviews: printableApps.filter( printableApp => printableApp.url !== 'ERROR')};
        },
        selected(webview){
            this.webviewSelected = webview;
        },
        validChoice(){
            if ( this.webviewSelected != null ){
                this.$emit("webviewSelected", this.webviewSelected);
                this.$refs.popupWebviewChoose.close();
            }
        },
        createNewWebview(){
            this.$refs.popupWebviewChoose.close();
            this.$router.push({
                'path': `/applications/pwa/information`
            });
        }

    }
}

</script>

<style lang="scss">
    .popin {
        background: var(--vtmn-color_white);
    }
    .border-none{
        border: none;
    }
    .flex-store-list{
        background-color : lightgrey !important;
        height: 100%;
        overflow: auto;
    }
    .padding-neg-1{
        margin-top: -8px;
        margin-bottom: 6px;
    }
    .padding-neg-info{
        margin-top: -16px;
        margin-bottom: 6px;
        margin-right: auto;
    }
</style>
