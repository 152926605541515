import Vue from 'vue'

export function sendSocketMsg (socket, deviceId, command, parameters, callback) {
  let timestamp = localStorage.getItem("timestamp")

  let realTimeRequest = {
    application:"com.decathlon.installer",
    totype: "ONE",
    to: "K" + deviceId,
    deviceType: "KIOSK",
    from: "BROWSER" + timestamp,
    message:  JSON.stringify (
      {
        command: command,
        parameters:parameters,
      }),
    headers: {
        authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        "x-api-key": process.env.VUE_APP_API_PORTAL_KEY_EMM
    }
  }

    socket.emit('MSG', JSON.stringify ( realTimeRequest ), callback);

}

export function verifyDeviceIsConnected(store, deviceId) {
  const token = sessionStorage.getItem('access_token');

  return Vue.http.get(
    store.state.getUrlWithParams(
      store.state.urlKioskConnected, {idDevice : deviceId})).then((response) => {

    let kiosk = response.body;

    return kiosk.rooms != undefined && kiosk.rooms.length > 0
  }, (error) => {
    console.log(error);
  })

}

