<template>
    <v-row no-gutters align="center">
        <v-col cols="2" class="text-align-center">
            <md-icon class="danger" :class="iconClass">error</md-icon>
        </v-col>
        <v-col cols="10">
            <p :class="titleClass">{{title}}</p>
            <p class="margin0" :class="subtitleClass">{{subTitle}}</p>
        </v-col>
    </v-row>
</template>

<script>
import ErrorPageConstant from '../../utils/error-page-constant';

export default {
    name: 'error',
    props: {
        errType: {
            type: String
        }
    },
    computed: {
        type() {
            let type = this.$route.query.type || this.errType || "default";
            if(!ErrorPageConstant.ERROR_ENUM.includes(type.toUpperCase())) type = "default";
            return type.toUpperCase();
        },
        title() {
            return this.$route.query.title || ErrorPageConstant[this.type].title;
        },
        subTitle() {
            return this.$route.query.subTitle || ErrorPageConstant[this.type].subTitle;
        },
        iconClass() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return "md-size-4x";
            }
            return "md-size-5x";
        },
        titleClass() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return "vtmn-typo_title-3";
            }
            return "vtmn-typo_title-1";
        },
        subtitleClass() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return "vtmn-typo_title-5";
            }
            return "vtmn-typo_title-3";
        }
    }
}


</script>

<style lang="scss" scoped>
    .margin0 {
        margin: 0;
    }
</style>
