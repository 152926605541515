<template>
    <div class="root-display">
        <div class="switch-display">
            <v-flex md3 class="info-component">
                <el-switch
                    :disabled="blockClick || (param.error ? false : needDisabled)"
                    :value="param.isOn"
                    @change="onChange"
                    active-color="#13ce66"
                    inactive-color="#ff4949" />
            </v-flex>
        </div>
        <div v-if="param.error || blockClick" class="error-display">
            <v-flex md12>
                <md-content v-if="blockClick" class="md-accent">
                    <ul v-if="blockClick">
                        <li>You can't click anymore !</li>
                    </ul>    
                </md-content>
                <md-content v-if="param.error" class="md-accent">
                    <ul>
                        <li>An error occurred: {{param.error}}</li>
                    </ul>    
                </md-content>
            </v-flex>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'switch-on-off',
        data: function () {
            return {
                needDisabled: false,
                blockClick: false,
                nbClick: 0,
                clickTimeout: null
            }
        },
        props: {
            param: {
                type: Object,
                default: false
            },
        },
        watch: {
            param() {
                this.needDisabled = false
            },
            nbClick() {
                if(this.nbClick > 2) {
                    this.blockUser();
                }
            }
        },
        methods: {
            onChange(value) {
                this.needDisabled = true;
                this.$emit('dispatchValue', value);

                this.generateClickInterval();
            },

            generateClickInterval() {
                this.nbClick++;
                clearTimeout(this.clickTimeout);
                this.clickTimeout = setTimeout(() => {
                   this.nbClick = 0; 
                }, 2000)
            },

            blockUser() {
                this.blockClick = true;
                setTimeout(() => {
                   this.blockClick = false; 
                }, 5000)
            }
        }
    }

</script>

<style>
    .el-switch.is-disabled > .el-switch__core {
        cursor: wait !important;
    }

    .root-display {
        display: contents;
    }

    .switch-display {
        display: inline-flex;
    }

    .error-display .md-accent {
        margin-top: 10px;
    }
</style>