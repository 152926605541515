<template>
    <div class="pa-5">
        <v-row no-gutters >
            <v-col cols="12" :sm="showCacheList ? 6 : 12" class="pa-5">
                <cache-search-component @reloadCacheInfo="askReloadCacheList(true)" ref="cacheSearchComponent"/>
            </v-col>

            <v-divider v-if="showCacheList" vertical></v-divider>

            <v-col cols="12" sm="6" class="pa-5" v-if="showCacheList">
                <cache-list-component  @reloadCacheInfoOK="askReloadCacheList(false)" :reload="reloadCacheInfo"/>
            </v-col>
        </v-row>
    </div>

</template>

<script>

import cacheListComponent from './cache-list';
import cacheSearchComponent from './cache-search-form';

export default {
    name: 'cache-main-component',
    components: {cacheSearchComponent, cacheListComponent},
    data: function(){
      return {reloadCacheInfo: false,
      showCacheList: true}
    },
    mounted(){
        this.$watch(
            () => {
                return this.$refs.cacheSearchComponent?.userCacheResults;
            },
            (val) => {
                this.showCacheList = val === undefined
            }
        )

    },
    methods: {
        askReloadCacheList(value){
            this.reloadCacheInfo = value;
        }
    }
}
</script>
