import Vue from "vue";
import {store} from "../../store/store";
import Constant from "../../utils/field-constant";
import constants from "../../constant/constants";
const utils = require('../../utils/utils.js');

export default {

  getActiveUser(uid) {
    return Vue.http.get(store.state.urlAndroidApiActiveUser + "/" + (uid || ""), {}).then((response) => {
      return response.body.user;
    }, this.errorManagement);
  },
  getEnrollmentValue(policy, userUid, dataToSend) {
    let storeSelected, idleTimeSelected, siteSelected, defaultAppSelected, webview, deviceSelected, genericPolicyTypeChoose;
    ({storeSelected, idleTimeSelected, siteSelected, defaultAppSelected, webview, deviceSelected, genericPolicyTypeChoose} = dataToSend);


    return Vue.http.put(
      store.state.urlEnrollment,
      {
        policy: policy,
        additonnalData: {
          user: {
            site: storeSelected.idTxt ? storeSelected.idTxt : 'S' + storeSelected.id,
            siteTimeZone: deviceSelected === Constant.DEVICE_TYPE.KIOSK ? storeSelected?.timeZone : null,
            country: storeSelected.country,
            user: userUid + '',
            entity: storeSelected.type === 'WAREHOUSE' ? 'LOGISTIC' : 'RETAIL',
          },
          kiosk: {
            idleTime: idleTimeSelected,
            siteType: siteSelected,
            defaultApp: defaultAppSelected,
            webview
          },
          smartphone: {
            isTablet : deviceSelected === Constant.DEVICE_TYPE.TABLET,
            overridenPolicy: genericPolicyTypeChoose === Constant.ENROLLMENT_TYPE.NORMAL ? null : genericPolicyTypeChoose
          },

        }
      })
      .then((response) => {
        return response.body
      }, this.errorManagement);
  },
  errorManagement(error, errForUser) {
    console.error("Error api", error);
    utils.displayAlertDialog(errForUser || "An error occurred, see console log please");
  },
  getPolicy(deviceType) {
    if (store.state.staging === 'prod') {
      if (deviceType === Constant.DEVICE_TYPE.SMARTPHONE || deviceType === Constant.DEVICE_TYPE.TABLET) {
        return "production_beta_smartphone";
      } else if (deviceType === Constant.DEVICE_TYPE.KIOSK) {
        return "kiosk_smartphone_production_beta_test";
      }
    } else {
      if (deviceType === Constant.DEVICE_TYPE.SMARTPHONE || deviceType === Constant.DEVICE_TYPE.TABLET) {
        return "preproduction_beta_smartphone";
      } else if (deviceType === Constant.DEVICE_TYPE.KIOSK) {
        return "kiosk_smartphone_preproduction_beta_test";
      }
    }
  },
  getApplicationTracks(packageName) {

    return Vue.http.get(
        store.state.getUrlWithParams(
            store.state.urlAndroidApiApplicationsTracks, {packageName: packageName}
        ))
        .then(response => response.body)
        .catch(e => {
            console.error(e);
            return Promise.resolve([]);
        })
  },
  getDevicePolicy(androidDeviceName, needApplicationReports) {
    let url = store.state.getUrlWithParams(store.state.urlAndroidApiDevice, {deviceName: androidDeviceName});

    if(needApplicationReports) url += "?needApplicationReports="+needApplicationReports;

    return Vue.http.get(url).then((response) => {
        return response.body;
      }).catch(e => {
        if(e.status === 404) return { detail : 'Device is not found on Android Api Management'}
        this.errorManagement(e, e.status === 403 && constants.DEVICE.POLICY.FORBIDDEN_FETCH);
      });
  },
    async blockAamDevice(deviceInfos){
        return this.doAamAction(deviceInfos, Constant.AAM_ACTIONS.RESET_PASSWORD_BLOCK);
    },
    async unblockAamDevice(deviceInfos){
        return this.doAamAction(deviceInfos, Constant.AAM_ACTIONS.RESET_PASSWORD_UNBLOCK);
    },
    async rebootAamDevice(deviceInfos){
        return this.doAamAction(deviceInfos, Constant.AAM_ACTIONS.REBOOT);
    },
    async changeLockCode(deviceInfos, newPassword) {
        return this.doAamAction(deviceInfos, Constant.AAM_ACTIONS.CHANGE_LOCK_CODE, newPassword)
    },
    async doAamAction(deviceInfos, action, newPassword){
        let url = store.state.getUrlWithParams(store.state.urlAndroidApiDevice, {deviceName: deviceInfos.deviceName})+'/action/'+action;
        return Vue.http.post(url, {newPassword, deviceInfos});
    }
}
