<template>
    <div class="mdm-toaster" v-show="modelValue">
        <slot />
        <span @click="$emit('close');" class="btn-close">
            <md-icon>close</md-icon>
        </span>
    </div>
</template>

<script>
    import Vue from 'vue'; 

    export default {
        name: 'mdm-toaster',
        props: {
            modelValue: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .mdm-toaster {
        position: absolute;
        right: 50px;
        background-color: var(--vtmn-color_yellow-light-2);
        border: 2px solid var(--vtmn-color_orange-light-1);
        padding: 10px 45px 10px 20px;
        border-radius: 3px;
        max-width: 40%;

        .btn-close {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 5px;

            i {
                color: var(--vtmn-color_orange-light-1);
                border: 2px solid;
                border-radius: 25px;
                width: 16px;
                min-width: 16px;
                height: 16px;
                font-size: 12px!important;
            }
        }
    }
</style>