<template>
    <div class='column-device-detail'>
        <v-row wrap>
            <enrollment-info
                :device="device"
                :kiosk="kiosk"
                :is-kiosk="isKiosk"
            />

            <card-info icon="perm_device_info" title="Android Api Management"
                :contents="[
                    { libelle  : 'Activated : ', value: device.androidSilentUpdate ? 'YES' : 'NO'},
                    { libelle  : 'Management mode :', value: device.androidManagementMode, display:  device.androidSilentUpdate},
                    { libelle  : 'Custom mode :', value: device.overriddenPolicyType, display:  Boolean(device.overriddenPolicyType)},
                    { libelle  : 'Last Google report time :', value: device.errorAamDeviceNotFound ? device.errorAamDeviceNotFound : device.lastStatusReportTime, display:  device.androidSilentUpdate},
                    { libelle  : 'Show the policy', icon: 'loupe', id:'policy', linkStyle:true, display: (isAdmin() || isCP) && device.androidSilentUpdate},
                    { libelle  : 'Dev mode: ', id:'dev-mode', renderer : {component: renderOnOff, param: {isOn: devMode || kiosk.dev_mode, error: devModeError}}, display: (isAdmin() || isCP) && device.androidSilentUpdate && isKiosk},
                    //{ libelle  : 'Screen Orientation: ', id:'screen-orientation', flexStyle:true, renderer : {component: renderSetDeviceOrientation, param: {screenOrientation, error: screenOrientationError}}, display: (isAdmin() || isCP) && device.androidSilentUpdate && isKiosk}
                ]"
                :id-device="device.id"
                @clickOnInfo="clickOnInfo"
                @dispatchValue="specialComponentChange"

            />

            <card-info icon="perm_device_info" title="Device"
                :contents="[
                        { libelle  : 'Type :', value: device.type },
                        { libelle  : 'Constructor :', value: device.manufacturer },
                        { libelle  : 'Model :', value:  device.model },
                        { libelle  : 'Serial number :', value:  device.serialNumber },
                        { libelle  : 'IMEI :', value:  device.imei },
                ]"
            />

            <card-info icon="settings" title="OS"
                :contents="[
                    { libelle  : 'OS :', value:  device.os },
                    { libelle  : 'Version :', value:  device.version },
                    { libelle  : 'Build version :', value:  device.buildVersion },
                ]"
            />

            <card-info icon="contact_mail" title="Users"
                :contents="[
                    { libelle  : 'Enrolled by :', value: device.enrolledBy || 'Unknown', display: device.androidSilentUpdate },
                    { libelle  : 'Last user logged :', value: device.lastUsedLogged },
                    { libelle  : 'Email :', value: device.email },
                    { libelle  : 'Show the stores', icon: 'loupe', id:'stores', linkStyle:true, display: isAdmin() || isCP},
                ]"
                @clickOnInfo="clickOnInfo"
            />

            <card-info icon="sim_card" title="SIM"
                :contents="[
                    { libelle  : 'Phone N° :', value:  device.phoneNumber },
                    { libelle  : 'SIM N° :', value:  device.simNumber },
                ]"
            />

            <card-info icon="cell_wifi" title="Network"
                :contents="[
                    { libelle  : 'Wifi :', value: device.wifi },
                    { libelle  : 'Address IP :', value:  device.adressIp },
                    { libelle  : 'Mac address :', value:  device.macAdress },
                ]"
            />


            <error-kiosk-update v-if="displayErrorMessage" @closeErrorMessage="isDisplayError=false" icon="live_help" title="Support informations"/>

            <card-kiosk-info icon="live_help" title="Support informations" v-if="device.type==='KIOSK' && !displayErrorMessage"

            :content=contentComputed

            @updateKiosk="updateKiosk"
            />
        </v-row>

        <div v-if='loading' class='overlay'></div>
        <md-progress-spinner v-if=loading class='loading' :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>

        <mdm-json-dialog :json="jsonPolicy" :showDialog="showPolicy" @close="showPolicy=false" />
        <store-dialog :uid="device.lastUsedLogged" :stores="stores" :showDialog="showStores" @close="showStores=false" />

    </div>
</template>

<script>

    import CardInfo from './cardInfo';
    import EnrollmentInfo from './enrollmentInfo';
    import Moment from 'moment';
    import AndroidApiService from "../../../service/devices/android-api-service";
    import SwitchOnOff from "./switch-on-off";
    import SetDeviceOrientation from "./setDeviceOrientation";
    import KioskInfoService from "../../../service/devices/kiosk-info-service";
    import CardKioskInfo from './cardKioskInfo';
    import ErrorKioskUpdate from "../../Alerts/error-kiosk-update";
    import StoreDialog from "@/components/dialog/storeDialog";
    import kioskInfosConstant from "@/constant/kiosk-infos-constant";
    import MessageConstant from "@/utils/message-constant";
    import constants from "@/constant/kiosk-infos-constant";
    const utils = require('../../../utils/utils');

    const DEFAULT_IDLE_MESSAGE     = "Idle mode has not been disabled yet.";
    const KIOSK_WIPED_IDLE_MESSAGE = "This kiosk has been wiped, waiting for emm kiosk agent to disable idle mode.";

    export default {
        name: 'deviceDetails',
        components: {
            StoreDialog,
            CardInfo,
            EnrollmentInfo,
            CardKioskInfo,
            ErrorKioskUpdate
        },
        data: function () {
            return {
                showPolicy: false,
                jsonPolicy: null,
                stores: null,
                showStores: false,
                loading: false,
                renderOnOff: SwitchOnOff,
                renderSetDeviceOrientation: SetDeviceOrientation,
                devMode: this.kiosk?.devMode || false,
                devModeError: null,
                screenOrientation: this.kiosk?.screenOrientation || constants.SCREEN_ORIENTATION.LANDSCAPE,
                screenOrientationError: null,
                isDisplayError: false
            }
        },
        computed: {
            isCP() {
                return this.$store.state.user.cp;
            },
            idleModeInfo() {
                if(this.device && this.kiosk) {
                    return this.getIdleModeData(this.kiosk.idleModeInfo, this.device.enrollmentTime);
                }
                return {};
            },
            displayErrorMessage() {
              return this.isDisplayError
            },
            contentComputed() {
               return {
                    kioskStartAt: this.kiosk.kioskStartAt,
                    kioskStopAt: this.kiosk.kioskStopAt,
                    aam : this.device.androidSilentUpdate,
                    idleModeInfo : this.idleModeInfo
                }
            }
        },
        methods : {
            clickOnInfo(content) {
                if(content.id === 'policy') {
                    this.loading = true;
                    return AndroidApiService.getDevicePolicy(this.device.androidManagementDeviceName).then((policy) => {
                        this.loading = false;

                        if(policy == null) {
                            utils.displayAlertDialog("Policy not exist for this device");
                            return;
                        }
                        this.jsonPolicy = policy;
                        this.showPolicy = true;
                    }).catch(e => {
                        log.error("An error occurred when loading the policy: ", e);
                        utils.displayAlertDialog("An error occurred when loading the policy");
                    });

                }else if(content.id === 'stores'){
                    this.loading = true;
                    if(!this.stores){
                        AndroidApiService.getActiveUser(this.device.lastUsedLogged).then((user) => {
                            this.loading = false;
                            if(!user){
                                utils.displayAlertDialog("No stores for this device, please, contact the leader of this user");
                                return;
                            }
                            this.stores = user.stores.map(function(store) {
                                store.disabled = true;
                                return store;
                            });
                            this.showStores = true;
                        }).catch(e => {
                            log.error("An error occurred when loading the stores: ", e);
                            utils.displayAlertDialog("An error occurred when loading the stores");
                        });
                    }else{
                        this.loading = false;
                        this.showStores = true;
                    }
                }
            },
            specialComponentChange({content, value}) {
                if(content.id === 'dev-mode') {
                    this.devModeError = null;
                    KioskInfoService.updateKioskInfo({device_id: this.device.id, dev_mode: value})
                        .then(() => {
                            this.devMode = value;
                        })
                        .catch(e => {
                            this.devModeError = "Can't update the kiosk in database, please, contact your administrator";
                            console.error("Error when changing the dev mode: ", e)
                        });
                } else if (content.id === 'screen-orientation') {
                    this.screenOrientationError = null;
                    KioskInfoService.updateKioskInfo({device_id: this.device.id, screen_orientation: value})
                        .then(() => {
                            this.screenOrientation = value;

                            // Reboot device after the screen orientation change
                            this.rebootAfterOrientationChange(
                                this.device.androidManagementDeviceName,
                                this.screenOrientation,
                                new Date().getTime() + process.env.REBOOT_DURATION_AFTER_ORIENTATION_CHANGE ?? 2 * 60 * 1000
                            )
                        })
                        .catch(e => {
                            this.screenOrientationError = "Can't update the kiosk in database, please, contact your administrator";
                            console.error("Error when changing the screen orientation: ", e)
                        });
                }
            },
            rebootAfterOrientationChange(deviceAamName, newScreenOrientation, timeToStop) {
                AndroidApiService.getDevicePolicy(deviceAamName).then(devicePolicy => {
                    let isPolicyUpdated = devicePolicy.policyContent.applications.some(app => app.managedConfiguration.screen_orientation === newScreenOrientation);
                    if (isPolicyUpdated) {
                        AndroidApiService.rebootAamDevice(
                            {
                                deviceName: this.device.androidManagementDeviceName,
                                deviceId: this.device.id,
                                serialNumber: this.device.serialNumber,
                                deviceType: this.device.type
                            }
                        ).then(_ => {
                            utils.displayAlertDialog("Device is now rebooted");
                        }, (e) => {
                            this.screenOrientationError = MessageConstant.ERROR_REBOOT;
                            console.error("Error when changing the screen orientation: ", e)
                        });
                    } else {
                        if (timeToStop - new Date().getTime() <= 0) return;
                        this.rebootAfterOrientationChange(deviceAamName, newScreenOrientation, timeToStop);
                    }
                });

            },
            getIdleModeData(idleModeInfo, enrollmentTime) {
                if(idleModeInfo.mode === "Unknown") {
                    return {mode: idleModeInfo.mode, message: idleModeInfo.message};
                }

                const idleModeDate   = Moment(idleModeInfo.time);
                const deviceIsWiped = enrollmentTime.isAfter(idleModeDate);

                if(deviceIsWiped || !idleModeInfo.mode) return {
                    mode: "Enabled",
                    message: deviceIsWiped ? KIOSK_WIPED_IDLE_MESSAGE : idleModeInfo.message || DEFAULT_IDLE_MESSAGE
                }

                return {
                    mode: "Disabled",
                    message: null
                }
            },
            async updateKiosk(hours) {
              try {
                  await KioskInfoService.updateKioskInfo({device_id: this.device.id, device_reboot_hour: hours.rebootTime.hour, device_reboot_minute: hours.rebootTime.minute, device_standby_hour: hours.lockTime.hour, device_standby_minute: hours.lockTime.minute})
              } catch(e) {
                this.isDisplayError = true
                console.error(e);
              }
            }
        },
        props: {
            device: {
                type: Object
            },
            isKiosk: {
                type: Boolean
            },
            kiosk: {
                type: Object
            },
            isAdmin: {
                type: Function
            }
        },
        watch: {
            kiosk: function() {
                this.devMode = this.kiosk?.devMode || false;
                this.screenOrientation = this.kiosk?.screenOrientation;
            }
        }
    }
</script>

<style scoped>

</style>
