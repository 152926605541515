<template>
    <div class="mdm-flex-parent">
        <div class="mdm-flex-header">
            <slot name="header" />
        </div>
        <div class="mdm-flex-content" :class="contentClass()">
            <slot />
        </div>
        <div class="mdm-flex-footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script type="text/javascript">

export default {
    name: 'mdm-vitamine-adjust-card',
    props: {
        content: {
            type: String,
            default: null
        },
        contentAlign: {
            type: String,
            default: null
        }
    },
    methods: {
        contentClass() {
            let additionalClass = this.content === "center" ? "mdm-flex-center" : "";
            additionalClass += this.contentAlign === "center" ? " text-center" : "";
            return additionalClass;
        }
    }
}

</script>

<style lang="scss" scoped >
    .mdm-flex-parent {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        .mdm-flex-header, .mdm-flex-footer {
            text-align: center;
        } 

        .mdm-flex-content {
            flex-grow: 1;
            overflow: auto;
        }

        .mdm-flex-center {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
