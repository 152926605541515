<template>
    <v-dialog v-model="dialog" >
        <template v-slot:activator="{ on, attrs }">
            <button :class="classButtonOpen" :id="idButtonOpen"
                    v-bind="attrs"
                    v-on="on" :disabled="disabledButton"
                    @click="$emit('cleanFilters')">
                <slot name="button-open-content" />
            </button>
        </template>

        <div class="popin" >
            <span @click="close" class="btn-popup-close">
                <md-icon>close</md-icon>
            </span>
            <slot />
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: 'mdm-vtmn-popup',
        data: function() {
            return {
                dialog: false
            }
        },
        props: {
            idButtonOpen: {
                type: String
            },
            classButtonOpen: {
                type: String
            },
            disabledButton: {
                Boolean,
                default: false
            },
            isClose : {
                Boolean,
                default: false
            }
        },
        methods: {
            close() {
                this.dialog=false;
            },
            open() {
                if (!this.disabledButton){
                    this.dialog = true;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.popin {
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 20px;
    width: 60%;
    height: 80%;
    display: flex;
}
.btn-popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    i {
        color: var(--vtmn-color_info) !important;
    }
}
</style>
