<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="delete-bin">
            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M5.36562 2.03265C5.67103 1.72718 6.07968 1.58374 6.50011 1.58374H13.5001C13.9205 1.58374 14.3292 1.72718 14.6346 2.03265C14.94 2.33811 15.0834 2.74682 15.0834 3.16732V4.00078H13.5834V3.16732C13.5834 3.12577 13.5766 3.10337 13.5735 3.0954C13.5734 3.09501 13.5732 3.09465 13.5731 3.09431C13.5728 3.09417 13.5724 3.09403 13.572 3.09388C13.564 3.09081 13.5416 3.08397 13.5001 3.08397H6.50011C6.45857 3.08397 6.43618 3.09081 6.4282 3.09388C6.42781 3.09403 6.42745 3.09417 6.42711 3.09431C6.42698 3.09465 6.42683 3.09501 6.42668 3.0954C6.42362 3.10337 6.41678 3.12577 6.41678 3.16732V4.00078H4.91678V3.16732C4.91678 2.74682 5.0602 2.33811 5.36562 2.03265ZM2.89673 4.66755H17.1035L15.4906 17.1247L15.4855 17.1499C15.3483 17.8361 14.7677 18.503 13.9168 18.503H6.08345C5.23256 18.503 4.6519 17.8361 4.51468 17.1499L4.50963 17.1247L2.89673 4.66755ZM4.6035 6.16778L5.98957 16.873C6.00331 16.9248 6.02918 16.9631 6.05212 16.9844C6.06389 16.9954 6.07255 16.9998 6.07623 17.0013C6.07914 17.0025 6.08075 17.0028 6.08345 17.0028H13.9168C13.9195 17.0028 13.9211 17.0025 13.924 17.0013C13.9277 16.9998 13.9363 16.9954 13.9481 16.9844C13.971 16.9631 13.9969 16.9248 14.0107 16.873L15.3967 6.16778H4.6035ZM7.41678 15.3359V7.66801H8.91678V15.3359H7.41678ZM11.0834 15.3359V7.66801H12.5834V15.3359H11.0834Z" fill="#D70321"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "bin-mdm-status"
};
</script>

<style scoped lang="scss">

</style>
