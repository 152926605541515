
export default {
    setItem: function (key, value, raw = false) {
        localStorage.setItem(key, raw ? value : JSON.stringify(value));
    },
    getItem: function(localStorageKey) {
        return localStorage.getItem(localStorageKey);
    },
    getJsonItem: function(localStorageKey) {
        const item = localStorage.getItem(localStorageKey);
        return item ? JSON.parse(item) : null;
    },
    remove(key){
        localStorage.removeItem(key);
    },
    setItemWithTtl(key, value, ttl) {
        const item = {
            value,
            ttl: new Date().getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    },
    getItemWithTtl(key) {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        if (new Date().getTime() > item.ttl) {
            this.remove(key);
            return null;
        }
        return item.value;
    }
};
