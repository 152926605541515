<template>
    <v-row no-gutters >
        <v-col cols="6">
            <div id="retail" class="bu-tab" :class="{buTabActive:activeBuComputed === buRetail}" @click="changeActiveTab(buRetail)">
                <md-icon class="md-size-1x">store</md-icon>
                <span class="title-text">RETAIL</span>
            </div>
        </v-col>
        <v-col cols="6" v-if="playstoreReady === 'TODO' || (isNaN(parseInt(productionVersionCodeComputed)) && app.appType === privateApp)">
            <div id="service-disabled" class="bu-tab-disabled" >
                <md-icon class="md-size-1x" style="color: var(--vtmn-color_grey-light-1)" >person</md-icon>
                <span class="title-text-disabled">SERVICES</span>
            </div>
        </v-col>
        <v-col cols="6" v-else>
            <div id="service" class="bu-tab" :class="{buTabActive:activeBuComputed === buServices}" @click="changeActiveTab(buServices)">
                <md-icon class="md-size-1x">person</md-icon>
                <span class="title-text">SERVICES</span>
            </div>
        </v-col>

    </v-row>

</template>

<script>
import DeploymentGroupConstant from '../../../src/constant/deployment-group-constant'
import groupService from "../../service/group/group-service";
import Vue from "vue";
import {store} from "../../store/store";
import LocalStorageService from "../../service/local-storage-service";
import LocalStorageConstant from "../../constant/local-storage-constant";
import appTypeEnum from "../../service/application/app-type-enum";
import PublicApplicationServices from "../../service/application/public-application-services";
import FieldConstant from "../../../src/utils/field-constant";

export default {
    name: "deployment-groups-nav",
    props: {
        app: {
            type: Object
        }
    },
    data() {
        return {
            activeBu: null,
            buRetail: DeploymentGroupConstant.BU.RETAIL,
            buServices: DeploymentGroupConstant.BU.SERVICES,
            productionVersionCode: undefined,
            productionVersionName: undefined,
            privateApp: appTypeEnum.PRIVATE_APP,
            playstoreReady: "",
            publicApp: appTypeEnum.PUBLIC_APP,
        }
    },
    computed: {
        activeBuComputed() {
            return this.activeBu;
        },
        productionVersionCodeComputed() {
            return this.productionVersionCode;
        }

    },
    watch: {
        app: {
            handler: async function (appNewVal) {
                if (appNewVal.uid && (appNewVal.appType === appTypeEnum.PRIVATE_APP)) {
                    try {
                        this.playstoreReady = appNewVal.playstoreReady;
                        this.productionVersionCode = (await Vue.http.get(store.state.getUrlWithParams(store.state.urlApplicationVersionCode, {packageName: appNewVal.uid}))).body;
                        this.productionVersionName = (appNewVal?.versions?.find(version => version.versionCode === this.productionVersionCode))?.version;
                        this.$store.commit("application/setProductionVersionCode", this.productionVersionCode);
                        this.$store.commit("application/setProductionVersionName", this.productionVersionName);
                    } catch (e) {
                        console.error(e);
                        this.productionVersionCode = undefined;
                    }
                } else if (appNewVal.uid && (appNewVal.appType === appTypeEnum.PUBLIC_APP)) {
                    const version = (await PublicApplicationServices.getApplicationInformation(appNewVal.uid)).version;
                    this.$store.commit("application/setProductionVersionName", version);
                    this.$store.commit("application/setProductionVersionCode", version);
                } else {
                    this.$store.commit("application/setProductionVersionName", FieldConstant.APPLICATION.PLAYSTORE.PRODUCTION_VERSION_NAME);
                    this.$store.commit("application/setProductionVersionCode", FieldConstant.APPLICATION.PLAYSTORE.PRODUCTION_VERSION_NAME);
                }
            }, deep:true
        }
    },
    methods: {
        changeActiveTab(bu) {
            this.activeBu = bu
            this.$emit('displayDeploymentGroupsArray', bu);
        }
    },
     mounted() {
        this.activeBu = DeploymentGroupConstant.BU.RETAIL;
        LocalStorageService.setItem(LocalStorageConstant.BU, DeploymentGroupConstant.BU.RETAIL);
    }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/color';
@import '~@/assets/style/font';

.bu-tab, .bu-tab-disabled  {
    min-height: 50px;
    border-bottom: 4px solid transparent;
    display: inherit;
    text-transform: uppercase;
    font-family: 'Roboto Condensed';
    font-size: 19px;
    color: $dark;
    text-decoration: none;
    text-align: center;
    padding-top: 10px;
}


.bu-tab:hover {
    background-color: $grey2;
    text-decoration: none;
}


.buTabActive {
    border-color: #3643BA;
    background-color: $grey2;
    color: $dark;
}

.title-text {
    color: var(--vtmn-color_black);
}

.title-text-disabled {
    color: var(--vtmn-color_grey-light-1);
}
</style>
