<template>
    <div class="group-information">
        <div class="mb-3">
            <label class="vtmn-text-input_label"  for="application-group-deploiement-name">Name of the deployment group *</label>
            <input type="text" v-model.trim="getGroup.name" class="vtmn-text-input input-fill-all-width" id="application-group-deploiement-name" placeholder="Name" @change="handleNameChange" />
        </div>

        <div v-if="group.availabledVersions.error" class="mb-3">
            <label class="vtmn-text-input_label">Version *</label>
            <md-content class="md-accent">An error occurred when checking the available version. Refresh the page or contact your administrator.</md-content>
        </div>

        <div v-else-if="group.availabledVersions.loading" class="mb-3">
            <label class="vtmn-text-input_label">Version *</label><br />
            <md-progress-spinner :md-diameter="20" :md-stroke="2" md-mode="indeterminate" />
        </div>

        <div v-else class="mb-3">
            <mdm-autocomplete :value="actualVersion" :options="higherVersions" @selected="handleVersionSelect"
                              key-to-print="version" placeholder="Version" label="Version *" :disabled="isAppPublicOrWebview" :noNeedRefreshForOptions="true"
                              id="group-input-choose-version" >
                <template slot="mdm-autocomplete-item" slot-scope="{ item }">{{item.versionName}}</template>
                <template slot="error-text">Please select a version from the list</template>
                <template slot="no-item">No versions are available for this application</template>
            </mdm-autocomplete>
            <v-alert v-if="isAppPublicOrWebview"
                color="var(--vtmn-color_green-light-1)"
                icon="mdi-emoticon-happy-outline"
                text prominent >
                <p class="mb-0">As your application is a public application, the latest version of the playstore is selected for you. So you don't have to do anything.</p>
            </v-alert>
        </div>

        <div class="mb-3">
            <mdm-vtmn-switch :value="group.forceUpdate" @update:value="handleForceUdpate($event)"><b>Force update</b> (Silent update only for playstore users)</mdm-vtmn-switch>
            <v-alert v-show="group.forceUpdate" class="mt-1"
                prominent
                icon="warning"
                type="warning" >
                <p>Be careful, by clicking on this button, the application will automatically close on all phones to update itself!</p>
                <p>Use with caution!</p>
            </v-alert>
        </div>

        <div>
            <mdm-vtmn-switch :value="group.activated" @update:value="handleIsActive($event)"><b>Activate group</b> (If your group is inactive, it will be ignored)</mdm-vtmn-switch>
        </div>
    </div>
</template>

<script>
    import appTypeEnum from '../../../../service/application/app-type-enum';
    import LocalStorageService from "../../../../service/local-storage-service";
    import LocalStorageConstant from "../../../../constant/local-storage-constant";
    import DeploymentGroupConstant from "../../../../constant/deployment-group-constant";
    import GroupServices from "../../../../service/group/group-service"
    import router from "../../../../router/router";
    import Vue from "vue";
    import {store} from "../../../../store/store";

    export default {
        name: "group-information",
        data: function() {
            return {
                appTypeEnum,
                previousVersionCode: undefined,
                actualVersionCode: undefined,
                buServices: DeploymentGroupConstant.BU.SERVICES
            }
        },
        computed: {
            isCreated(){
                return this.group.id;
            },
            group() {
                return this.$store.state.group.group;
            },
            higherVersions() {
                const lastVersionCode = this.previousVersionCode ? this.previousVersionCode : this.actualVersionCode;
                let versions = this.group.availabledVersions.versions;
                if(!this.isAppPublicOrWebview && lastVersionCode && this.isCreated)
                    versions = versions.filter(item => +item.versionCode >= +lastVersionCode)
                return versions;
            },
            isAppPublicOrWebview(){
                return this.application.appType === appTypeEnum.PUBLIC_APP || this.application.appType === appTypeEnum.WEBVIEW;
            },
            application() {
                return this.$store.state.group.group.application;
            },
            actualVersion() {
                this.actualVersionCode = this.group.version?.versionCode;
                return this.group.version?.version;
            },
            getGroup() {
                if(LocalStorageService.getJsonItem(LocalStorageConstant.BU) === DeploymentGroupConstant.BU.SERVICES) {
                    this.$store.dispatch('group/setGroup', {name: DeploymentGroupConstant.DEFAULT_GROUP_NAME});
                    return this.group
                } else {
                    return this.group
                }
            }
        },
        methods: {
            handleVersionSelect : function(version) {
                if(!this.previousVersionCode)
                    this.previousVersionCode = this.actualVersionCode
                this.$store.dispatch('group/setGroup', {version});
            },
            handleNameChange : function() {
                this.$store.dispatch('group/setGroup', {name: this.group.name});
            },
            handleForceUdpate : function(needForceUpdate) {
                this.$store.dispatch('group/setGroup', {
                    forceUpdate: needForceUpdate,
                    availability: needForceUpdate ? 'UPDATE_REQUIRED' : 'UPDATABLE'
                });
            },
            handleIsActive: function(activated) {
                this.$store.dispatch('group/setGroup', {activated});
            },
        }
    }
</script>
<style>
    .v-alert--prominent .v-alert__icon:after {
        opacity: 0;
    }
</style>
