const initialState = {
    exportSpinnerProgressStatus: 0
};

const state = () => ({
    devices: initialState
});

const mutations = {
    setExportSpinnerProgressStatus(state, spinnerProgressStatus) {
        state.devices.exportSpinnerProgressStatus = spinnerProgressStatus;
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
