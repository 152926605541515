<template>
    <button class="validate-button" @click="$emit('clear')">
        {{ label }}
    </button>

</template>

<script>
export default {
    name: "clear-all-button",
    props: {
        label: {
            type: String
        }
    }
};
</script>


<style scoped lang="scss">
.validate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 50%;
    padding: 20px 40px 20px 40px;
    background-color: var(--vtmn-color_white);;
    color: var(--vtmn-color_black);
    font-size: 14px;
    font-weight: 500;
    margin: 15px 0 5px 0;
    border: 1px solid #949494
}

</style>
