<template>
    <redirection-enrollment :message="message" :link="link" :logo="logo"/>
</template>

<script>
import RedirectionEnrollment from "@/pages/devices/new/step/enrollment/redirection-enrollment";

export default {
    name: "ds2-enrollment",
    components: {RedirectionEnrollment},
    data : function() {
        return {
            message: "Because your kiosk is not an ELOTOUCH, you need to register your new device through the Decathlon Services V2 App.",
            link: "https://docs.google.com/presentation/d/1yDm5iqasdJO1H99Ws6ivIXEStDb3E7bE",
            logo: "ds2"
        }
    }
};
</script>

<style scoped>

</style>
