<template>
    <v-col cols="12" md="3" sm="6" class="info-component">
        <h2 class="md-title"><md-icon>{{ icon }}</md-icon> {{title}} </h2>
        <div class='information-phone area error-component'>
            <v-row>
                <v-col cols="2" align-self="center">
                    <span aria-label="Emoticon with normal emotion" class="vtmx-emotion-normal-fill"/>
                </v-col>
                <v-col cols="8" align-self="center" >
                    <span style="font-weight: bold">{{errorTitle}}</span>
                    <div>{{errorText}}</div>
                </v-col>
                <v-col cols="2">
                    <span aria-label="Cross to close error message" class="vtmx-close-line" @click="$emit('closeErrorMessage')"/>
                </v-col>
            </v-row>
        </div>
    </v-col>
</template>

<script>
import KioskInfosConstant from '../../constant/kiosk-infos-constant'

export default {
    name: "error-kiosk-update",
    props: {
        title : {
            type: String,
            required: true
        },
        icon : {
            type: String,
            required: true
        }
    },
    data() {
        return {
            errorTitle: KioskInfosConstant.ERROR_UPDATE_TITLE,
            errorText: KioskInfosConstant.ERROR_UPDATE_MESSAGE
        }
    }
};
</script>

<style scoped>
.error-component {
    background-color: var(--vtmn-color_danger);
    color: var(--vtmn-color_white);
}

.vtmx-emotion-normal-fill, .vtmx-close-line {
    color: var(--vtmn-color_white);
    font-size: 25px;
}

</style>
