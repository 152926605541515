<template>
    <v-row no-gutters align="center">
        <v-col cols="12" sm="6" class="text-md-right">
            <mdm-button id="devices-new-device-btn-site-type-decathlon" text="Decathlon" css="md-raised button-choice md-primary" @click="choose('DECATHLON')" is-no-need-inline-block />
        </v-col>
        <v-col cols="12" sm="6" class="text-md-left">
            <mdm-button id="devices-new-device-btn-site-type-partner" text="Partners" css="md-raised button-choice button-partner-choice md-green" @click="choose('PARTNERS')" is-no-need-inline-block />
        </v-col>
    </v-row>
</template>


<script>
    export default {
        name: "new-device-site-choice",
        methods: {
            choose(siteType) {
                this.$emit('selected', {siteType});
            }
        }   
    }
</script>

<style lang="scss"> 
    .button-partners-choice {
        border-radius: 5px;
    }
</style>
