<template>

    <div>
        <div>

            <span :style="'color :' + getMetricColor(getPercentage(groupFirmwareStats.update_since.all_time.perimeter.up_to_date.length, groupFirmwareStats.update_since.all_time.perimeter.global.length))">{{getPercentage(groupFirmwareStats.update_since.all_time.perimeter.up_to_date.length, groupFirmwareStats.update_since.all_time.perimeter.global.length)}} %</span>

            <v-icon @click="showIcon()" v-if="groupFirmwareStats.update_since?.all_time?.perimeter?.global?.length > 0" small :id="'group-eye-icon-' + groupFirmwareId" class="ml-2">
                {{this.show ? 'mdi-eye' : 'mdi-eye-off'}}
            </v-icon>
        </div>

    <div class="group-firmware-stats-content mt-5 pa-3" v-if="groupFirmwareStats.update_since && this.show" :id="'admin-firmware-advanced-stats-'+ groupFirmwareId" >

            <div class="up-to-date-content">

                <div class="pb-2">
                    <div class="popover-content">
                        <span>• LT 1 day: {{groupFirmwareStats.update_since.one_day.perimeter.up_to_date.length }} / {{groupFirmwareStats.update_since.one_day.perimeter.global.length }}</span>
                        <span>({{getPercentage(groupFirmwareStats.update_since.one_day.perimeter.up_to_date.length, groupFirmwareStats.update_since.one_day.perimeter.global.length)}} %)</span>
                    </div>
                </div>
                <div class="pb-2">
                    <div class="popover-content">
                        <span>• LT 1 week: {{groupFirmwareStats.update_since.one_week.perimeter.up_to_date.length }} / {{groupFirmwareStats.update_since.one_week.perimeter.global.length }}</span>
                        <span>({{getPercentage(groupFirmwareStats.update_since.one_week.perimeter.up_to_date.length, groupFirmwareStats.update_since.one_week.perimeter.global.length)}} %)</span>
                    </div>
                </div>
                <div class="pb-2">
                    <div class="popover-content">
                        <span>• LT 1 month: {{groupFirmwareStats.update_since.one_month.perimeter.up_to_date.length }} / {{groupFirmwareStats.update_since.one_month.perimeter.global.length }}</span>
                        <span>({{getPercentage(groupFirmwareStats.update_since.one_month.perimeter.up_to_date.length, groupFirmwareStats.update_since.one_month.perimeter.global.length)}} %)</span>
                    </div>
                </div>

                <div class="pb-2">
                    <div class="popover-content">
                        <span>• All time: {{groupFirmwareStats.update_since.all_time.perimeter.up_to_date.length }} / {{groupFirmwareStats.update_since.all_time.perimeter.global.length }} </span>
                        <span>({{getPercentage(groupFirmwareStats.update_since.all_time.perimeter.up_to_date.length, groupFirmwareStats.update_since.all_time.perimeter.global.length)}} %)</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>

import {getMetricColor} from "../../../utils/utils";

    export default {
        name: "up-to-date-column",
        props: {
            groupFirmwareId: {
                type: Number,
                required: true
            },
            groupFirmwareStats : {
                type: Object
            }
        },
        data: function(){
            return {
                show: false,
                getMetricColor
            }
        },
        methods: {
            showIcon(){
                this.show = !this.show;
            },
            getPercentage(numerator, denominator){
                if ( denominator == 0 ) return 100;
                return Math.ceil((numerator / denominator) * 100);
            }
        }
    }
</script>

<style lang="scss" scoped>

.group-firmware-stats-content {
    background-color: #001018;
    color: var(--vtmn-color_white);
    border-radius: 20px;
    font-weight: 400;
}

.up-to-date-buble {
    border: 1px solid white;
    border-radius: 15px;
    padding: 0px 10px;
}
.max-content {
    width: max-content;
    float:right;
}
.up-to-date-content {
    > div {
        &:not(:last-child) {
            border-bottom: 2px dotted white;

        }

        > div:not(:first-child) {
            font-size: 0.8em;
        }
    }
    .popover-content {
        display: flex;
        justify-content: space-between;

        span:last-child {
            margin-left: 10px;
        }
    }
    span {
        margin-top: 6px;
        margin-bottom: -2px;
    }
}
</style>
