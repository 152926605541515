import { EventBus } from './event-bus.js';

export function getMetricColor(value) {
    let color = '#7acf1f';

    if ( value >= 0 && value < 20 ){
        color = 'red';
    }else if (value >= 20 && value < 40) {
        color = 'orange';
    }else if (value >= 40 && value < 70){
        color = 'darkkhaki';
    }
    return color;
}

export function isKiosk(device) { // syncDeviceDto object
  if(device == undefined) return false;

  return device.typeDevice === "KIOSK";
}


export function displayAlertDialog(message) {
  EventBus.$emit('display-alert-dialog', message);
}


export function displayAlertDialogYesNo(message) {
  EventBus.$emit('display-alert-dialog-yes-no', message);

  return new Promise( (resolve) => {
    EventBus.$on('display-alert-dialog-yes-no-response', yesOrNo => {
      resolve(yesOrNo)
    });
  });

}


/**
 *
 * @param condition  method which return a promise with data is a boolean (false : we wait again, true, stop waiting)
 * @param timeout
 * @param callbackSuccess
 * @param callbackTimeout
 */
export function waitFor(condition, timeout, callbackSuccess, callbackTimeout) {
  let start = Date.now();
  waitForRecursive(start, condition, timeout, callbackSuccess, callbackTimeout)
}

function waitForRecursive(startDate, condition, timeout, callbackSuccess, callbackTimeout) {
  let now = Date.now();

  if( now - startDate > timeout) {
    callbackTimeout();
  } else {
    let promise = condition()
    promise.then(( conditionResult ) => {
      if(! conditionResult) {
        window.setTimeout(waitForRecursive.bind(null, startDate, condition, timeout, callbackSuccess, callbackTimeout), 1000); /* this checks the flag every 1 second*/
      } else {
        callbackSuccess();
      }
    })
  }
}

export function searchByName (items, term) {
  if (term) {
    return items.filter(item => item.name.toLowerCase().includes(term.toLowerCase()))
  }
  return items
}

export function isEmpty(obj) {
  return obj === undefined || Object.keys(obj).length === 0;
}
