<template>
    <div class="tooltip-donut" :style="{ top: top + 'px', left: left + 'px', transform: 'translate(-50%, -100%)' }">
        <span class="tooltip-title">{{ title }}</span>
        <div class="value-elements">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <rect x="2.5" y="2" width="12" height="12" rx="1" :fill="legendRectColor"/>
            </svg>
            <span class="tooltip-value">{{ value }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TooltipDonutChart',
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        top: {
            type: Number,
            required: true
        },
        left: {
            type: Number,
            required: true
        },
        legendRectColor: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/color';

.tooltip-donut {
    width: 170px;
    height: 97px;
    position: absolute;
    background-color: var(--vtmn-color_white);
    display: flex;
    padding: 5px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    align-self: stretch;
    z-index: 100;
    box-shadow: 0 0 13px -6px rgba(0,0,0,0.83);
    pointer-events: none;
}

.tooltip-title {
    font-size: 14px;
    font-weight: 500;
}

.tooltip-value {
    font-size: 12px;
    font-weight: 400;
}

.value-elements {
    display: flex;
}


</style>
