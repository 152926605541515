import {store} from "../store/store";

function put(key, subkey, value) {

  if (!keyExists(key)) {
    store.state[key] = {}
  }

  if (subkey && !keyExists(key, subkey)) {
    store.state[key][subkey] = {}
  }

  if (subkey) {
    store.state[key][subkey] = value;
  } else {
    store.state[key] = value;
  }
}

function get(key, subkey) {
  if (subkey) {
    return store.state[key][subkey];
  }
  return store.state[key];
}

function keyExists(key, subkey) {

  if (!subkey) {
    return store.state[key];
  }
  return store.state[key] && store.state[key][subkey];

}

export {
  put, get, keyExists
}

