<template>
    <div class="md-stepper">
        <div disabled class="md-button md-stepper-header md-theme-default">
            <div class="md-ripple  md-disabled">
                <div class="md-button-content">
                    <md-icon class="md-stepper-number">more_vert</md-icon>
                </div>
            </div>
        </div> 
    </div>
</template>


<script>
    export default {
      name: "unknown-step"
    }
</script>

<style>
</style>
