<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="hourglass">
            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M2.375 1.54175H17.625V3.04198H15.9583V6.12019C15.9583 6.717 15.6543 7.27268 15.1517 7.59439L11.3913 10.0014L15.1517 12.4084C15.6543 12.7301 15.9583 13.2858 15.9583 13.8826V16.9608H17.625V18.461H2.375V16.9608H4.04167V13.8826C4.04167 13.2858 4.34572 12.7301 4.84832 12.4084L8.60867 10.0014L4.84832 7.59439C4.34572 7.27268 4.04167 6.717 4.04167 6.12019V3.04198H2.375V1.54175ZM5.54167 3.04198V6.12019C5.54167 6.20545 5.5851 6.28483 5.6569 6.33079L10 9.1108L14.3431 6.33079C14.4149 6.28483 14.4583 6.20545 14.4583 6.12019V3.04198H5.54167ZM10 10.892L5.6569 13.672C5.5851 13.7179 5.54167 13.7973 5.54167 13.8826V16.9608H14.4583V13.8826C14.4583 13.7973 14.4149 13.7179 14.3431 13.672L10 10.892Z" fill="#616161"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: "hourglass"
};
</script>

<style scoped lang="scss">

</style>
