<template>
    <div class="group-recap mdm-vtmn-default-backgroud">
        <div v-if="!$vuetify.breakpoint.xs">
            <img :src="group.application.icon.imageBase64 || group.application.icon" class='group-recap-app-icon'/>
        </div>
        <div class="group-recap-name" v-if="!$vuetify.breakpoint.xs">
            <p id="group-recap-application-name-p"><b>{{group.application.name}}</b></p>
            <p id="group-recap-group-name-p">{{group.name}}</p>
        </div>
        <div v-if="$vuetify.breakpoint.width > 800">
            <p id="group-recap-version-name-p">{{group.version && group.version.version}}</p>
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp">
            <p v-for="type in group.requirement.deviceType" :key="type" :id="'group-recap-type-p-' + type">{{type}}</p>
        </div>
        <div v-if="$vuetify.breakpoint.width > 800 && countUser !== null" id="group-recap-user-count-p">
            <p>{{countUser || 0}} {{countUser > 1 ? 'users' : 'user'}}</p>
        </div>
        <div class="group-recap-model" v-if="$vuetify.breakpoint.mdAndUp && (group.includeModelCheck || group.excludeModelCheck)">
            <p id="group-recap-model-include-p" v-if="group.includeModelCheck">{{group.requirement.models.included.length}} included {{group.requirement.models.included.length > 1 ? 'models' : 'model'}}</p>
            <p id="group-recap-model-exclude-p" v-if="group.excludeModelCheck">{{group.requirement.models.excluded.length}} excluded {{group.requirement.models.excluded.length > 1 ? 'models' : 'model'}}</p>
        </div>
        <div>
            <div v-if="!canDeployApp" @mouseover="displayEmptyFieldError" class="overlay-disabled-button" />
            <button id="group-recap-btn-deploy" class="vtmn-btn" :disabled="!canDeployApp" @click="deploy">Deploy</button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import GroupUtils from '../../../../../utils/group-utils';
    import GroupService from '../../../../../service/group/group-service';

    export default {
        name: 'group-recap',
        components: {},
        data: function() {
            return {
                canDeployApp: false
            }
        },
        computed: {
            group() {
                return this.$store.state.group.group;
            },
            countUser() {
                return this.$store.state.group.group.countUser;
            }
        },
        watch: {
            async group() {
                await this.countTheUser();
                this.calculCanDeployGroup();

                if(this.$vuetify.breakpoint.mdAndDown && !this.group.emptyFieldShow && !this.canDeployApp) {
                    this.$store.dispatch("group/setGroup", {emptyFieldShow: true});
                } else if(this.group.emptyFieldShow && this.canDeployApp) {
                    this.$store.dispatch("group/setGroup", {emptyFieldShow: false});
                }
            }
        },
        methods: {
            countTheUser : function(){
                return GroupService.countUser(this.group).then((response) => {
                    this.$store.dispatch("group/setCountUser", response.body.count);
                }, (error) => {
                    console.warn("Count user not work.", error);
                    this.$store.dispatch("group/setCountUser", null);
                });
            },
            deploy() {
                GroupUtils.canDeploy(this.group, this.countUser, this.$route.params.idGroup, this.$route.fullPath) && this.$store.dispatch('group/saveGroup');
            },
            displayEmptyFieldError() {
                if(!this.canDeployApp) {
                    this.$store.dispatch("group/setGroup", {emptyFieldShow: true});
                }
            },
            calculCanDeployGroup() {
                this.canDeployApp = GroupUtils.canDeploy(this.group, this.countUser, this.$route.params.idGroup, this.$route.fullPath);
            }
        }
    }
</script>

<style lang="scss">
    .group-recap {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.25);
        max-height: 75px;
        height: 75px;
        z-index: 8;

        > div {
            padding: 0px 30px 0px 10px;
            margin: auto 0px;
            overflow-wrap: break-word;

             > div.row {
                height: 100%;
                align-content: space-evenly;
            }

            &:last-child {
                @media (max-width: 600px) {
                    margin: auto;
                }
                margin-left: auto;
            }

            &.group-recap-model {
                color: var(--vtmn-color_danger);
            }

            &.group-recap-name {
                @media (max-width: 800px) {
                    max-width: none;
                }
                max-width: 20%;
            }

            &#group-recap-user-count-p {
                color: var(--vtmn-color_green);
            }

            > #group-recap-btn-deploy {
                min-width: 200px;
                pointer-events: all;
            }

            > .group-recap-app-icon {
               height: 75px;
                margin: auto;
            }

            > .overlay-disabled-button {
                position: absolute;
                height: -webkit-fill-available;
                width: 200px;
                z-index: 999;
            }
        }

        p {
            margin: 0;
            height: 100%;
        }
    }
</style>
