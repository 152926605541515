<template>
  <div class="page-content full-size">
    <h1 class='page-title'>
      <md-icon class="md-size-2x">phone_android</md-icon>
      <span>New device</span>
    </h1>
    <v-container class="content-new-device lighten-5" id="newDeviceContent" grid-list-md align-center>
      <v-skeleton-loader v-if="!user"
          v-bind="$attrs"
          :types="skeltonStepperType"
          type="stepper@3"
      ></v-skeleton-loader>

      <md-steppers v-else :md-active-step.sync="stepperService.currentStep"  md-vertical md-linear id="new-device-enrollment-stepper">
        <generic-step @keydown.native.space="doKeydownFilter" @keydown.native.enter="doKeydownFilter"  v-for="step in currentParcours" :key="step.id"
          :id="step.id" :label="step.label"
          :description="step.description"
          :selected="setDataToCurrentStep"
          :data="step.data"
          :render="step.render"
          :onClick="() => onClick(step)"
          :extra-data="user"
          :md-done="step.mdDone"
        />
      </md-steppers>
    </v-container>

  </div>

</template>

<script>
    import GenericStepComponent from "./step/generic-step";
    import GenericStepper from "../../../service/devices/newDevice/step/generic-stepper";
    import StepperService from "../../../service/devices/newDevice/step/stepper-service";
    import androidApi from "../../../service/devices/android-api-service";
    import {
        DEVICE_CHOICE,
        UNKNOWN_END
    } from "../../../service/devices/newDevice/step/step-constant";

    export default {
      name: 'NewDevice',
      components: {
        'generic-step': GenericStepComponent
      },
      data() {
        return {
          skeltonStepperType: {stepper: "list-item-avatar, article, divider"},
          stepperService: new GenericStepper(
            [DEVICE_CHOICE, UNKNOWN_END],
            (...args) => StepperService.changeStep(this.user, ...args),
            StepperService.externalDataInStep
          ),
          currentParcours: [],
          currentStep: "",
          user: null
        };
      },
      beforeCreate() {
        if (!this.$store.state.gdpr) {
          this.$router.push({
            path: `/rgpd`
          });
        }
      },
      mounted() {
        this.currentParcours = this.stepperService.getCurrentParcours();
        this.currentStep     = this.stepperService.currentStep;

        androidApi.getActiveUser().then((user) => {
          this.user = user;
        });
      },
      methods: {
        async setDataToCurrentStep(data) {
          if(this.user) {
            this.stepperService.setDataInCurrentStep(data);
            const nextStep = await this.stepperService.nextStep();
            this.currentStep = nextStep.currentStep;
            this.currentParcours = nextStep.currentSteppers;
            this.setMdDoneFalseForFutureSteps();
          }
        },
        setMdDoneFalseForFutureSteps(){
            let isFutureStep = false;
            for(let i = 0; i < this.currentParcours.length; i++){
                if(this.currentParcours[i].id && (this.currentParcours[i].id === this.currentStep)){
                    isFutureStep = true;
                    this.currentParcours[i].onClick = false;
                }
                this.currentParcours[i].mdDone = !isFutureStep;
            }
        },
        async onClick(step) {
          if(step.onClick) {
            const steppers = await this.stepperService.goBackTo(step.id);
            this.currentStep = steppers.currentStep;
            this.currentParcours = steppers.currentSteppers;
          }
        },
        doKeydownFilter(event) {
          if (event.path[0].type !== 'text') {
              event.preventDefault();
          }
        }
      }
    }
</script>

<style>
  .button-choice {
    max-width: 300px;
    width: -webkit-fill-available;
    height: 200px;
    padding-top: 13px;
  }

  .button-choice, .button-choice .md-icon  {
    font-size : 200% !important;
  }

  @media (max-width: 700px) {
    .button-choice, .button-choice .md-icon  {
      font-size : 150% !important;
    }
  }

  .button-choice .md-icon {
    width: inherit !important;
  }

  .md-green, .md-green .md-icon{
    background-color : #4caf50 !important;
    color: #fff  !important;
    color: var(--md-theme-default-text-primary-on-primary, #fff) !important;
  }

</style>
