<template>
    <nav class='nav'>
		<div class="nav-progress" :style="{width : (step-1) * (100 / (listOfSteps.length - 1) - 2)  + '%'}"></div>
		<div class='nav-item' :style="{'margin-right' : 'calc((640px - '+listOfSteps.length * 50+'px) / '+(listOfSteps.length - 1)+' - 4px) '}" @click='changeStep(1)' :class="{'nav-item-active' : step == 1 || step == 2 || step == 3 || step == 4}">
			<div class="nav-number">1</div>
			<div class="nav-text">{{listOfSteps[0]}}</div>
		</div>
		<div class='nav-item' :style="{'margin-right' : 'calc((640px - '+listOfSteps.length * 50+'px) / '+(listOfSteps.length - 1)+' - 4px) '}" @click='changeStep(2)' :class="{'nav-item-active' : step == 2 || step == 3 || step == 4}">
			<div class="nav-number">2</div>
			<div class="nav-text">{{listOfSteps[1]}}</div>
		</div>
		<div class='nav-item' :style="{'margin-right' : 'calc((640px - '+listOfSteps.length * 50+'px) / '+(listOfSteps.length - 1)+' - 4px) '}" @click='changeStep(3)' :class="{'nav-item-active' : step == 3 || step == 4}">
			<div class="nav-number">3</div>
			<div class="nav-text">{{listOfSteps[2]}}</div>
		</div>
		<div v-if='listOfSteps.length >= 4' class='nav-item' :style="{'margin-right' : 'calc((640px - '+listOfSteps.length * 50+'px) / '+(listOfSteps.length - 1)+' - 4px) '}" @click='changeStep(1)' :class="{'nav-item-active' : step == 4}">
			<div class="nav-number">4</div>
			<div class="nav-text">{{listOfSteps[3]}}</div>
		</div>
	</nav>

</template>
<script>
export default{
	data : function(){
		return {
		}
	},
	props : ['listOfSteps', 'step'],
	methods : {
		changeStep : function(value){
			this.$emit('changeStep', value);
		}
	}	
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/color';
.nav{
	width : 640px;
	margin : auto;
	margin-bottom : 30px;
	position : relative;
	&:before{
		content : '';
		position : absolute;
		top: 23px;
    	left: 9px;
		width : 600px;
		border-bottom : 6px solid $grey;
	}
}
.nav-progress{
	border-bottom : 6px solid $blue;
	position : absolute;
	top : 23px;
	left : 9px;
	max-width : 95% !important;
}
.nav-item{
	font-family : 'Roboto Condensed';
	display : inline-block;
	vertical-align : top;
	position : relative;
	cursor : pointer;
	&:last-child{
		margin-right : 0px !important;
	}
	.nav-number{
		width : 36px;
		height : 36px;
		line-height : 36px;
		text-align : center;
		border-radius : 50%;
		background-color : $grey;
		color : $grey3;
		font-size : 20px;
		margin : auto;
		margin-top : 8px;
	}
	.nav-text{
		color : $grey3;
		font-size : 12px;
		font-weight : lighter;
		width: 50px;
    	text-align: center;
    	margin-top : 10px;
    	line-height : 16px;
	}
	&.nav-item-active{
		.nav-number{
			color : #fff;
			background-color : $blue;
			width : 46px;
			height : 46px;
			line-height : 46px;
			margin-top : 0px;
		}
		.nav-text{
			color : $blue;
		}
	}	
}
</style>
