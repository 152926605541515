import ObjectUtils from "../object-utils";

export default {

    updateStoreData(state, pObj) {

        const obj = ObjectUtils.clone(pObj);
        const versionName = obj.versionName;
        if (!versionName) return;

        const versionNameIndex = state.upToDateDevicesByVersionName.findIndex(obj => obj.versionName === versionName);
        if (versionNameIndex <= -1) {
            state.upToDateDevicesByVersionName.push(obj);
            return;
        }

        Object.keys(state.upToDateDevicesByVersionName[versionNameIndex].updateSince)
            // threeMonths / groupUpdate
            .forEach(parentKey =>
                // total / upToDate
                Object.keys(state.upToDateDevicesByVersionName[versionNameIndex].updateSince[parentKey])
                    // "ds": 785,
                    // "aam": 0,
                    // "all": 785
                    // "pourcentDs": "100.0",
                    // "pourcentAam": "0.0",
                    .forEach(key => Object.keys(state.upToDateDevicesByVersionName[versionNameIndex].updateSince[parentKey][key]).forEach(subKey => {
                            if (!subKey.startsWith("pourcent")) {
                                state.upToDateDevicesByVersionName[versionNameIndex].updateSince[parentKey][key][subKey] += obj.updateSince[parentKey][key][subKey];
                            }
                        }
                    )));


    },

    buildChartFromStoreData(state, appUid) {

        if (!appUid) return {};
        const distributionList = state.application.upToDateDevicesByVersionName;

        let distributionChartData = [];
        distributionList.forEach(el => {
            const valueToAdd = Number(el.updateSince.threeMonths.upToDate.all);
            if (valueToAdd && valueToAdd > 0) {
                let distribution = [];
                distribution.push(el.versionName);
                distribution.push(Number(el.updateSince.threeMonths.upToDate.all));
                distributionChartData.push(distribution);
            }
        });

        return {
            columns: [
                {
                    'type': 'string',
                    'label': 'Date'
                },
                {
                    'type': 'number',
                    'label': 'Number of devices'
                }
            ],
            rows: distributionChartData,
            options: {
                titlePosition: 'top',
                fontName: 'Roboto',
                fontSize: 12,
                height: 240,
                pieHole: 0.55,
                curveType: 'none',
                colors: ['#B3E5FC', '#81D4FA', '#4FC3F7', '#29B6F6', '#03A9F4', '#039BE5', '#0288D1', '#0277BD', '#01579B'],
                legend: {position: 'right'},
                pieSliceText: 'none',
                chartArea: {
                    height: 250,
                    left: 20,
                    width: "100%",
                },
                pieSliceBorderColor: 'none',
                backgroundColor: {
                    fill: '#ffffff'
                }
            }

        }

    }
}
