<template>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4998 3.04174C6.65686 3.04174 3.5415 6.15757 3.5415 10.0011C3.5415 13.8447 6.65686 16.9606 10.4998 16.9606C14.3428 16.9606 17.4582 13.8447 17.4582 10.0011C17.4582 6.15757 14.3428 3.04174 10.4998 3.04174ZM2.0415 10.0011C2.0415 5.32901 5.82843 1.5415 10.4998 1.5415C15.1712 1.5415 18.9582 5.32901 18.9582 10.0011C18.9582 14.6733 15.1712 18.4608 10.4998 18.4608C5.82843 18.4608 2.0415 14.6733 2.0415 10.0011ZM7.99984 6.43993L10.4998 8.94032L12.9998 6.43993L14.0605 7.50076L11.5605 10.0011L14.0605 12.5015L12.9998 13.5624L10.4998 11.062L7.99984 13.5624L6.93918 12.5015L9.43917 10.0011L6.93918 7.50076L7.99984 6.43993Z" fill="#D70321"/>
    </svg>
</template>

<script>
export default {
    name: "close-circle"
};
</script>

<style scoped lang="scss">

</style>
