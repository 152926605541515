<template>
    <div class="mdmButton">
        <mdm-vtmn-basic-button
            v-if="!isTooltipShow($props)"
            :class="{forceUpdate : isForceUpdate}"
            :variant="variant"
            :icon-direction="iconDirection"
            :disabled="disabled"
            @click="$emit('click')"
            @mouseover="$emit('mouseover')"
            @mouseout="$emit('mouseout')"
            :size="size" 
            :id=id>
            <span v-if="icon" aria-hidden="true" :class="icon" />
            <slot />
        </mdm-vtmn-basic-button>

        <div class="buttonWithTooltip" v-else>
            <span  tabindex="0" class="vtmn-tooltip" :data-tooltip="textTooltip" data-position="top">
                <mdm-vtmn-basic-button :variant="variant" :icon-direction="iconDirection" :id=id :disabled="disabled" @click="$emit('click')" @mouseover="$emit('mouseover')" @mouseout="$emit('mouseout')" >
                    <span v-if="icon" aria-hidden="true" :class="icon" />
                    <slot />
                </mdm-vtmn-basic-button>
            </span>
        </div>
    </div>
</template>

<script >
import MdmVtmnBasicButton from "./mdm-vtmn-basic-button";
export default {
    name: 'mdm-vitamine-button',
    components: {MdmVtmnBasicButton},
    props: {
        id: {
            type: String,
            required: false
        },
        icon: {
            type: String,
            required: false
        },
        variant: {
            type: String,
            default: "primary",
            validator: (value) => ['primary', 'secondary', 'primary-reversed', 'secondary-reversed'].includes(value.toLowerCase())
        },
        iconDirection: {
            type: String,
            default: "left",
            validator: (value) => ['alone', 'left', 'right'].includes(value.toLowerCase())
        },
        disabled: {
            type: Boolean,
            default : false
        },
        textTooltip: {
            type : String,
            required : false
        },
        isTooltipShow: {
            type: Function,
            default: () => false
        },
        size: {
            type: String,
            default: "medium",
            validator: (value) => ['small', 'medium', 'large'].includes(value.toLowerCase())
        },
        isForceUpdate: {
            type: Boolean,
            default: false
        }
    },
}
</script>
<style scoped>

button {
    margin : 5px
}

.buttonWithTooltip {
    display: flex;
    flex-direction: column;
}

.mdmButton {
    display: inline-block;
}

.forceUpdate {
    background-color: #D9ECF5;
}
</style>
