import Vue from 'vue';
import {store} from "../../store/store";

const RATE_INTO_SESSION = "actualRate";


export default {

    async getRate(data) {
        return Vue.http.post(`${store.state.urlAdminRate}/one`, {app: data.app, appVersion: data.appVersion, user: data.user})
            .then((response) => {
                let rating = response.body.data?.rating;

                if(rating == undefined) {
                    const oldrating = JSON.parse(sessionStorage.getItem(RATE_INTO_SESSION));
                    if(oldrating != undefined && data.appVersion === oldrating.appVersion) {
                        return oldrating;
                    }
                }

                return rating;
            });

    },

    async setRate(bodyToSend) {
        let jsonData = JSON.stringify(bodyToSend);

        return Vue.http.post(`${store.state.urlAdminRate}`, jsonData)
            .then((data) => {
                sessionStorage.setItem(RATE_INTO_SESSION,jsonData);
                return data;
            });
    }


}
