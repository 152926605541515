export default {
    WAITING_TO_WIPE_FROM_MDM: "Waiting to wipe",
    WIPED_FROM_MDM: "Wiped",
    ENROLLED_ON_WSO: "Enrolled",
    NOT_ENROLLED_ON_WSO: "Not enrolled",
    TO_MIGRATE_TO_WSO: "To migrate",
    MIGRATED_TO_WSO: "Migrated",
    STATUS: {
        MDM_STATUS: "mdmStatus",
        WSO_STATUS: "wsoStatus",
        MIGRATION_STATUS: "migrationStatus"
    }
};
