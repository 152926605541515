<template>
	<div class="full-size">

		<router-link to="/applications/new">
			<md-button id="applications-btn-new" class="md-raised md-primary button-addApplication" :class="{'button-disable' : !userUtils.canCreateApp()}">
				<md-icon>add</md-icon> New application
			</md-button>
		</router-link>

		<h1 class='page-title'>
			<md-icon class="md-size-2x">apps</md-icon>
			<span id="applications-label-title" >Applications list</span>
		</h1>
		<div class="filterApp">
		 	<md-field >
				<label id="applications-label-search"><md-icon>search</md-icon>Search :</label>
				 <md-input id="applications-input-search" v-focus v-model='search'></md-input>
			</md-field>
		</div>
		<tbody class="grid-container">

			<ul class ="grid-item" v-for="entry in filterApps"  @click="goToApplication(entry['id'], entry['uid'])" :uid=entry.uid >
				<application-card :application="entry" />
			</ul>
		</tbody>
		<div id="applications-label-number-of-applications" class="appnumber">{{nbOfApps}} Applications</div>
		<md-button v-if='filterFix' class="md-raised topbutton"@click="topFunction()" id="myBtn" title="Go to top"><md-icon>keyboard_arrow_up</md-icon> Top</md-button>
	</div>
</template>

<script>
import Vue from 'vue';
import 'vue-material/dist/theme/default.css'
import applicationService from '../../service/application/application-service';
import ApplicationCard from './details/application-card';
import userUtils from '../../utils/user-utils';
import IconsService from '../../service/application/icons-service';
import LocalStorageService from "../../service/local-storage-service";
import LocalStorageConstant from "../../constant/local-storage-constant";
import DeploymentGroupConstant from '../../../src/constant/deployment-group-constant'

export default {
	components: {
		ApplicationCard
	},
	data : function(){
		return {
            userUtils: userUtils,
            filterFix : '',
			search : sessionStorage.getItem('AppFilter')  || '',
			nbOfApps : 0,
			Apps : [],
		}
	},
	computed : {
		filterApps : function(){
            return this.Apps.filter((entry) =>
                !this.search || ( (entry.name || entry.uid)
                    && this.search
                    && (entry.name.toUpperCase().match(this.search.toUpperCase())
                        || entry.uid.toUpperCase().match(this.search.toUpperCase())
                        )
                    )
            )
		}
	},
    created() {
        this.filteredData();
    },
	mounted : function(){
		window.addEventListener('scroll', this.handleScroll);
	},
	methods : {
		topFunction: function () {
            LocalStorageService.setItem(LocalStorageConstant.BU, DeploymentGroupConstant.BU.RETAIL);
		    document.body.scrollTop = 0;
		    document.documentElement.scrollTop = 0;
		},
		goToApplication : function(id, uid){
			sessionStorage.setItem('AppFilter', this.search);
            // avoid applications/95/95 that occurred sometimes in dev environment
            if(document.location.href.endsWith('applications'))
			    document.location.href += '/'+id;
		},
		handleScroll : function(){
			if(document.body.scrollTop > 320|| document.documentElement.scrollTop > 320 || window.pageYOffset > 320){
				this.filterFix = true;
			} else {
				this.filterFix = false;
			}
		},
        filteredData : async function() {
            const applications = await applicationService.getAllApplications();
            IconsService.getAndStoreIconsList(applications);
            this.nbOfApps = applications.length;
            this.Apps = applications;
            return applications
        },
	}
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/font';
@import '~@/assets/style/color';

#applications-btn-new{
    background-color: #3643ba;
    font-family: 'Roboto';
    border-radius: 4px;
    text-transform: none;
    font-weight: 700;
    font-size: 1rem;
    padding: .875rem 1.5rem;
    height: 3rem;
}

.appnumber{
	float: right;
}
.grid-container {
	justify-content: center;
  	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 160px));
    :hover{
  		cursor: pointer;
  		transform: scale(1.1);
  		border: 5px solid $blue;
  }
}
.grid-item {
	border: 5px solid rgba(0, 0, 0, 0.05);
	border-radius: 10%;
	padding: 20px;
	max-width: 160px;
	margin:4px;
	font-size: 30px;
	text-align: center;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	:hover{
		border: none;
	  	transform: scale(1.1);
	}
}
.filterApp{
	font-size : 20px;
	padding-left: 14px;
	background-color:$greyLight;
}
.button-addApplication{
	float : right;
}

</style>
