<template>
    <div class="mdm-autocomplete">
        <label class="vtmn-text-input_label" :for="id">
            {{ label }}
        </label>
        <div class="vtmn-text-input_container" >
            <input
                :id="id"
                v-model="actualSelectedItem"
                type="text"
                class="vtmn-text-input full-size mb-0"
                :class="{'vtmn-text-input vtmn-text-input--error': isError}"
                :placeholder="placeholder"
                @focus="printOption = true"
                @blur="onBlur"
                @keyup="search"
                :disabled="disabled"
                ref="inputAutocomplete"
                @keyup.enter="$emit('keyupEnter')"
                @input="$emit('checkToRefreshList')"
                @click="$emit('checkToRefreshList'); cleanInput()"
                @refresh="refresh"
                autocomplete="off"
            />

            <div class="option-dropdown" v-if="printOption">
                <md-list>
                    <md-list-item v-for="(item, idx) of actualSearchList" :key="idx" @click="select(item)" :id="(id + '-' || 'autocomplet-drop-down-item-') + idx">
                        <slot name="mdm-autocomplete-item" v-bind:item="item"/>
                    </md-list-item>
                </md-list>
            </div>
        </div>
        <p v-if="actualSelectedItem && !actualSelectedIsInAOptions" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon><slot name="error-text" />
        </p>
        <p v-if="nbItems === 0" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon><slot name="no-item" />
        </p>
        <p v-else-if="options.length === 0" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon><slot name="loading" />
        </p>
        <div v-if="isError">
            <p><i class="ri-error-warning-line vtmn-text-danger msg-vertical-align"></i> {{errorMessage}}
            </p>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: 'mdm-autocomplete',
        props: {
            nbItems: {
                type: Number
            },
            id: {
                type: String
            },
            label: {
                type: String,
                required: false
            },
            placeholder: {
                type: String
            },
            options: {
                type: Array,
                required: true
            },
            value: {
                type: String
            },
            keyToPrint: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isError: {
                type: Boolean
            },
            errorMessage: {
                type: String
            },
            isFocus : {
                type: Boolean,
                default: false
            },
            refreshed: {
                type: Boolean
            },
            noNeedRefreshForOptions: {
                type: Boolean,
                default: false
            },
        },
        data: function() {
            return {
                printOption: false,
                actualSelectedItem: this.value,
                actualSearchList: this.options,
                searchTerms: ''
            }
        },
        mounted: function() {
            if(this.isFocus) this.focus();
        },
        updated: function () {
            if(this.isFocus) this.focus();
        },
        watch: {
            value(value) {
                this.actualSelectedItem = value;
            },
            options(options, oldOption) {
                const optionHasNoChange = options.length === oldOption.length && this.everyArrayContainsOther(options, oldOption) && this.everyArrayContainsOther(oldOption, options);
                if(!optionHasNoChange && !this.noNeedRefreshForOptions){
                    this.refresh();
                }
                this.actualSearchList = options;
            },
            refreshed() {
                this.refresh();
            }
        },
        computed: {
            actualSelectedIsInAOptions() {
                const versions = this.options.filter(item => this.transformItem(item) === this.actualSelectedItem);
                return versions.length > 0 ? versions[0] : null;
            }
        },
        methods: {
            everyArrayContainsOther(array1, array2) {
                const appIds1 = array1.map(({appId}) => appId);
                const appIds2 = array2.map(({appId}) => appId);
                return appIds1.every(appId => appIds2.includes(appId))
            },
            refresh(){
                this.actualSelectedItem = "";
            },
            select(item) {
                this.actualSelectedItem = this.transformItem(item);
                this.$emit("selected", item);
                this.printOption = false;
            },
            transformItem(item) {
                if(!item) return "";
                return this.keyToPrint ? item[this.keyToPrint] : item
            },
            search() {
                this.actualSearchList = this.options.filter(item => {
                    let itemText = item;
                    if(this.keyToPrint) {
                        itemText = item[this.keyToPrint];
                    }
                    return itemText.trim().toLowerCase().includes(this.actualSelectedItem?.trim().toLowerCase());
                });
            },
            onBlur(event) {
                this.$emit("selected", this.actualSelectedIsInAOptions);
                setTimeout(() => this.printOption = false, 200);
            },
            focus: function () {
                this.$nextTick(() => this.$refs.inputAutocomplete.focus());
            },
            cleanInput: function() {
                    this.actualSearchList = this.options;
                    this.actualSelectedItem = ""
                }
            },

    }
</script>

<style lang="scss">
@import './mdm-input.scss';
</style>
