<template>

    <div>

        <mdm-button :id="'applications-details-group-btn-calc-policies-' + group.prio"
                    v-if="canShowComponent()"
                    :css="mdmButtonForceUpdatePoliciesCss"
                    :tooltip-text="tooltipToDisplay"
                    :always-display-tooltip="true"
                    :icon="'android'"
                    :disable="!isAdmin"
                    @click="forceUpdateAndroidPolicies()"/>

        <md-progress-spinner v-else :class="'loading-calc'" :md-diameter="15" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>

    </div>

</template>

<script>

    import GroupService from "../../../service/group/group-service";
    import {messageConstant} from "../../../utils/message-constant";

    export default {
        name: 'GroupActionCalcAndroidPolicyButton',
        data() {
            return {
                tooltipCalcAndroidPolices: messageConstant.APPLICATION_CALC_ANDROID_POLICIES,
                tooltipCalcAndroidPolicesImpossible: messageConstant.APPLICATION_CALC_ANDROID_POLICIES_IMPOSSIBLE,
                loadingUpdate: false,
                lastForceUpdateApiCall: null,
                mdmButtonForceUpdatePoliciesCss: 'md-icon-button md-dense',
                tooltipToDisplay: null,
            }
        },
        props: {
            app: {
                type: Object,
                required: true
            },
            group: {
                type: Object,
                required: true
            },
        },
        computed: {
            isAdmin: function() {
                return this.$store.state.user.admin;
            }
        },
        methods: {
            canShowComponent : function(){
                return !this.loadingUpdate;
            },
            forceUpdateAndroidPolicies: function(){
                const baseButtonCssClass = 'md-icon-button md-dense ';
                this.loadingUpdate = true;
                GroupService.forceUpdateAndroidPoliciesForGroupId(this.group.id)
                    .then( res => {
                        this.lastForceUpdateApiCall = res;
                        this.mdmButtonForceUpdatePoliciesCss = baseButtonCssClass + "md-icon-green";
                        this.tooltipToDisplay = this.lastForceUpdateApiCall.bodyText.length > 0 ? this.lastForceUpdateApiCall.bodyText : "Calculation of the android policies in progress !"
                    }).catch(error => {
                    this.lastForceUpdateApiCall = error;
                    this.mdmButtonForceUpdatePoliciesCss = baseButtonCssClass + "md-accent";
                    this.tooltipToDisplay = this.lastForceUpdateApiCall.bodyText.length > 0 ? this.lastForceUpdateApiCall.bodyText : "An error occurred on calculation of the android policies !"
                }).finally(() => {
                    this.loadingUpdate = false;
                });
            }
        },
        mounted: function () {
            this.tooltipToDisplay = this.isAdmin ? this.tooltipCalcAndroidPolices : this.tooltipCalcAndroidPolicesImpossible;
        }
    }

</script>

<style>

    .loading-calc {
        margin-left: 20px;
        margin-top: 10px;
    }

    .md-icon-green i {
        color: #7acf1f !important;
    }
</style>
