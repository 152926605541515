<template>
    <div class="page-content full-size">
        <mdm-vitamine-back-link :link="isEdit ? '/applications/' + $route.params.idApplication : '/applications/new'" id="application-add-button-back">
            {{isEdit ? 'RETURN TO THE APP' : 'ADD AN APP'}}
        </mdm-vitamine-back-link>

        <p class="vtmn-typo_title-5 app-title">{{isEdit ? 'Edit' : 'Add'}} a web app</p>

        <div v-if="loading">
            <md-progress-spinner :md-diameter=50 :md-stroke=5 md-mode="indeterminate"/>
        </div>
        <v-row v-else no-gutters class="form-flex">
            <v-col cols="12" md="6">
                <web-app-information-form-stepper :key="webviewRefreshComponentKey" />
            </v-col>

            <v-col cols="12" md="6" align-self="center" v-if="!$vuetify.breakpoint.smAndDown" align="center">
                <div id="info-panel">
                    <info-card :type="'webview'" :is-base64="false"/>

                    <mdm-vtmn-popup ref="popupDeployment"
                                    id-button-open="web-app-add-button-valid-and-deploy"
                                    class-button-open="vtmn-btn add-app-valid-button"
                                    :disabled-button="!canDeploy">
                        <template v-slot:button-open-content>Valid and {{isEdit ? 'edit' : 'deploy'}} my application</template>

                        <mdm-vitamine-save-application-card dispatch-route="webview/webviewSave"
                                                            :type="'webview'" :logo-is-base64="!application.logo"
                                                            :is-edit="isEdit" :app-type="appTypeEnum.WEBVIEW"
                                                            :security-validation-status="application.playstoreReady" />

                    </mdm-vtmn-popup>

                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue';
    import MdmVitamineCard from '../../../../components/mdm/vitamine/card/mdm-vtmn-card';
    import MdmVitamineSaveApplicationCard from '../../../../components/mdm/vitamine/card/mdm-vtmn-save-application-card';
    import MdmVitamineBadge from '../../../../components/mdm/vitamine/badge/mdm-vtmn-badge';
    import MdmVitamineBackLink from '../../../../components/mdm/vitamine/link/mdm-vtmn-back-link';
    import InfoCard from '../app-info-card';
    import WebAppInformationFormStepper from './web-app-information-form-stepper';
    import appTypeEnum from '../../../../service/application/app-type-enum';
    import languageUtils from '../../../../utils/i18n/languages';

    export default {
        components: {
            WebAppInformationFormStepper, MdmVitamineSaveApplicationCard,
            MdmVitamineCard, MdmVitamineBadge, MdmVitamineBackLink,
            InfoCard
        },
        data: function() {
            return {
                loading: false,
                webviewRefreshComponentKey: 0,
                appTypeEnum: appTypeEnum
            };
        },
        mounted: function() {
            if(this.$route.params.idApplication) {
                this.getWebview();
            }else {
                this.getNewWebview();
            }
        },
        computed: {
            isEdit() {
                return Boolean(this.$route.params.idApplication);
            },
            application() {
                return this.$store.state.webview.webview;
            },
            canDeploy() {
                return this.application.descriptions?.length > 0
                    && this.$store.state.webview.steppers.activeStep === 'authorization'
                        && this.atLeastOneDescription()
                            && this.$store.state.webview.webview.arbo;
            },

        },
        methods : {
            getNewWebview() {
                this.$store.state.webview.webview = {
                    display: 'FULL_SCREEN'
                }
                this.webviewRefreshComponentKey += 1;
            },
            async getWebview() {
                try {
                    this.loading = true;
                    const applicationResponse = await Vue.http.get(this.$store.state.getUrlWithParams(this.$store.state.urlApplicationIdOrPackageName, {idOrPackageName : this.$route.params.idApplication}));
                    const application = applicationResponse.body;

                    if(application.appType !== appTypeEnum.WEBVIEW) {
                        return this.$router.push({
                            'path': `/applications/${this.$route.params.idApplication}`
                        });
                    }

                    const webViewResponse = await Vue.http.get(this.$store.state.getUrlWithParams(this.$store.state.urlGetWebviewInfo, {packageName : application.uid}));
                    const webview = webViewResponse.data;

                    this.$store.dispatch('webview/setWebview', {
                        id: application.id,
                        name: webview.title,
                        packageName: application.uid,
                        playstoreReady: application.playstoreReady,
                        applicationResponsible: application.applicationResponsible,
                        display: webview.displayMode,
                        url: webview.startUrl,
                        logo: webview.icons[0].imageData,
                        descriptions: application.applicationDescription.map((description) => {
                            const language = languageUtils.mapLanguage(description.language) || {};

                            return {
                                code: language.code,
                                content: description.description[0] || "",
                                label: language.label,
                                selected: false
                            }
                        })
                    });
                    this.loading = false;
                } catch(e) {
                    console.error("Problem when loading the webview for edition: ", e);
                    return this.$router.push({
                        'path': `/applications`
                    });
                }
            },
            atLeastOneDescription() {
                return this.application.descriptions?.some(d => d.content && d.content.length > 0);
            }
        }
    }

</script>

<style lang="scss" >
    @import '../scss/new-app.scss';

    #info-panel {
        width: 60%;
    }

</style>

