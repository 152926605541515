    <template>
        <v-dialog v-model="data && data.visible" max-width="50%" max-height="80%">
            <v-card>
                <v-card-title>List of Firmwares</v-card-title>
                <v-card-text>
                    <ul>
                        <li v-for="(firmware, index) in (data && data.firmwares.filter(firmware => firmware.devicesToApplyFirmware.length))" :key="index" class="firmware-item">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-center">
                                    <h1 class="firmware-version">{{ firmware.firmware_build_version }}</h1>
                                    <v-icon @click="toggleExpand(firmware)">
                                        {{ firmware.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                    </v-icon>
                                </div>
                                <div class="d-flex align-center firmware-perimeter">
                                    <pre v-if="!firmware.isExpanded">{{ JSON.stringify(firmware.devicesToApplyFirmware) }}</pre>
                                    <pre v-else>{{ JSON.stringify(firmware.devicesToApplyFirmware, null, 2) }}</pre>
                                </div>
                            </div>
                        </li>
                    </ul>
                </v-card-text>
                <v-card-actions class="justify-space-around">
                    <v-btn color="primary" size="large" @click="_ => data && data.confirm() && closeDialog()">Apply firmwares</v-btn>
                    <v-btn size="large" @click="closeDialog">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>

    <script>
    import Vue from 'vue';

    export default {
        name: 'FirmwareDialog',
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            toggleExpand(firmware) {
                Vue.set(firmware, "isExpanded", !firmware.isExpanded);
            },
            closeDialog() {
                this.data.visible = false;
            }
        }
    };
    </script>

    <style lang="scss" scoped>
    .firmware-item {
        list-style-type: none;
        display: flex;
    }

    .firmware-item::before {
        content: "\2022";
        color: var(--vtmn-color_grey);
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 1.5rem;
        font-weight: bold;
    }

    .firmware-version {
        font-weight: bold;
        margin-right: 10px;
    }

    .firmware-perimeter {
        max-width: 100%;
        max-height: 100px;
        overflow: auto;
    }
    </style>
