const state = () => [
    "/error"
];

const getters = {
    getNoSecuredRoutes(state) {
        return state;
    }
};

const actions = {
    setNoSecuredRoutes : async (context, data) => {
        context.commit('mutateNoSecuredRoutes', data);
    }
};

const mutations = {
    mutateNoSecuredRoutes(state, newNoSecuredRoutes) {
        state = [...state, ...newNoSecuredRoutes];
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
