<template>
    <div class="page-content full-size">
        <mdm-vitamine-back-link link="/applications" id="navigation-back">
            BACK TO THE APPS
        </mdm-vitamine-back-link>
        <v-row justify="center" align="center">
            <v-col :set="cardId='applications-new-btn-add-public-app'" class="card-transition" :cols="getCardDimension('xs', cardId)" :sm="getCardDimension('sm', cardId)" :md="getCardDimension('md', cardId)">
                <public-app-card :id="cardId" :minimized="minimized(cardId)" />
            </v-col>
            <v-col :set="cardId='applications-new-btn-add-private-app'" class="card-transition" :cols="getCardDimension('xs', cardId)" :sm="getCardDimension('sm', cardId)" :md="getCardDimension('md', cardId)">
                <private-app-card
                    :id="cardId" :minimized="minimized(cardId)"
                    @click="cardClicked" @close="cardClose" />
            </v-col>
            <v-col :set="cardId='applications-new-btn-add-web-app'" class="card-transition" :cols="getCardDimension('xs', cardId)" :sm="getCardDimension('sm', cardId)" :md="getCardDimension('md', cardId)">
                <web-app-card
                    :id="cardId" :minimized="minimized(cardId)"
                    @click="cardClicked" @close="cardClose" />
            </v-col>
        </v-row>
    </div>
</template>

<script type="text/javascript">
import Vue from 'vue';
import VueRouter from 'vue-router';
import MdmVitamineBackLink from '../../../components/mdm/vitamine/link/mdm-vtmn-back-link';
import PublicAppCard from './type-card/public-app-card';
import WebAppCard from './type-card/web-app-card';
import PrivateAppCard from './type-card/private-app-card';

export default {
    components: {
        MdmVitamineBackLink, PublicAppCard, WebAppCard, PrivateAppCard
    },
    data() {
        return {
            selectedCard: null
        }
    },
    methods: {
        cardClicked(info) {
            this.selectedCard = info.id;
        },

        cardClose(info) {
            this.selectedCard = null;
        },

        getCardDimension(type, cardId) {
            switch(type) {
                case 'xs':
                    return '12';
                case 'sm':
                    return '6';
                case 'md':
                    if(this.canBeMinimized()) {
                        return this.selectedCard === cardId ? '10' : '1';
                    }
                    return '4';
            }
        },

        minimized(cardId) {
            return this.canBeMinimized() && this.selectedCard !== cardId;
        },

        canBeMinimized() {
            return this.$vuetify.breakpoint.mdAndUp && this.selectedCard
        }
    }
}
</script>

<style lang="scss" >
.card-transition {
    transition: 0.3s linear;
}

.under-description-logo {
    display: flex;
    margin-bottom: 8px;

    img {
        display: inline-block;
        margin: auto;
        width: 50px;
        height: auto;
    }
}

</style>
