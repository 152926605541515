import FieldConstant from "../../utils/field-constant";
import ApplicationService from '../application/application-service';
import ManufacturerService from '../manufacturers/manufacturer-service';
import ModelService from '../models/model-service';
import StringUtils from '../../utils/string-utils';
import localStorageConstant from "../../constant/local-storage-constant";

const GET_MODELS_METHOD = "getModels";

const secondFilterMap = [
    {name: "category", textToSearch: FieldConstant.FILTER.CATEGORY_FILTERS},
    {name: "os", textToSearch: FieldConstant.FILTER.OS_FILTERS},
    {name: "ownership", textToSearch: FieldConstant.FILTER.OWNERSHIP_FILTERS},
    {name: "android api management", textToSearch: FieldConstant.FILTER.ANDROID_API_MANAGEMENT_FILTERS},
    {name: "android management mode", textToSearch: FieldConstant.FILTER.ANDROID_MANAGEMENT_MODE_FILTERS}
];

export default {
    async calcSecondFilterList(chosenFilter, detailedSearchCriteria, getOsVersionFilters) {
        let secondFilters = [];
        let textSearchCriteria;

        switch(chosenFilter.toLowerCase()) {
            case FieldConstant.CHOSEN_FILTER.MODEL : {
                secondFilters = await calcSecondFilterListModel(detailedSearchCriteria);
                break;
            }
            case FieldConstant.CHOSEN_FILTER.DEFAULT_APPLICATION : {
                secondFilters = await search(() => ApplicationService.getAllApplicationByDeviceType('KIOSK'), "name", "uid");
                break;
            }
            case FieldConstant.CHOSEN_FILTER.APPLICATION : {
                secondFilters = await search(ApplicationService.getAllApplications, "name", "uid");
                break;
            }
            case FieldConstant.CHOSEN_FILTER.MANUFACTURER : {
                secondFilters = await search(ManufacturerService.getManufacturers);
                break;
            }
            case FieldConstant.CHOSEN_FILTER.CATEGORY :
            case FieldConstant.CHOSEN_FILTER.OWNERSHIP :
            case FieldConstant.CHOSEN_FILTER.ANDROID_API_MANAGEMENT :
            case FieldConstant.CHOSEN_FILTER.ANDROID_MANAGEMENT_MODE :
                ({secondFilters, textSearchCriteria} = searchSecondFilterAndText(chosenFilter));
                break;
            case FieldConstant.CHOSEN_FILTER.OS :
                ({secondFilters, textSearchCriteria} = searchSecondFilterAndText(chosenFilter));
                await getOsVersionFilters();
                break;
        }
        return  {secondFilters, textSearchCriteria};

    },

    parseLocalStorageActiveFilter(localStorageActiveFilter) {
        if(!localStorageActiveFilter) return;
        else if(!localStorageActiveFilter.version || localStorageActiveFilter.version < localStorageConstant.FILTER_VERSION) {
            return localStorageActiveFilter.map(StringUtils.mapSearchTerm);
        }
        return localStorageActiveFilter.activeFilters;
    }
};

const extractFilterValue = (filter) => {
    return filter && filter.value;
}

const getModelsFilterToApply = (category, manufacturer) => {
    const and = category && manufacturer ? "And" : "";
    const by = category || manufacturer ? "By" : "";
    return GET_MODELS_METHOD + by + (category ? StringUtils.capitalizeFirstLetter(FieldConstant.CHOSEN_FILTER.CATEGORY) : "") + and + (manufacturer ? StringUtils.capitalizeFirstLetter(FieldConstant.CHOSEN_FILTER.MANUFACTURER) : "")
}

const calcSecondFilterListModel = async (detailedSearchCriteria) => {
    const manufacturerFilter = extractFilterValue(detailedSearchCriteria.find(criteria => criteria.type.startsWith(FieldConstant.CHOSEN_FILTER.MANUFACTURER)));
    const categoryFilter = extractFilterValue(detailedSearchCriteria.find(criteria => criteria.type.startsWith(FieldConstant.CHOSEN_FILTER.CATEGORY)));
    const filterForApi = getModelsFilterToApply(categoryFilter, manufacturerFilter);
    return (await ModelService[filterForApi]({
        [FieldConstant.CHOSEN_FILTER.CATEGORY]: categoryFilter,
        [FieldConstant.CHOSEN_FILTER.MANUFACTURER]: manufacturerFilter})).map(model => ({key: model, value: model}));
};

const searchSecondFilterAndText = (chosenFilter) => {
    const secondFilter = secondFilterMap.find(({name}) => name === chosenFilter.toLowerCase());
    return {
        secondFilters: secondFilter.textToSearch,
        textSearchCriteria: secondFilter.textToSearch[0].name
    };
};

const search = async (callback, key, value) => {
    let secondFiltersObjects = await callback();
    return secondFiltersObjects.map(object => ({
        key: key ? object[key] : object,
        value: key ? object[value || key] : object
    }));
};

