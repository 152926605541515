<template>
    <v-row no-gutters align="center">
        <v-col cols="12" sm="6" class="text-md-right">
            <mdm-button id="mdm-button-enterprise-choice" text="Enterprise" icon="work"
                        css="md-raised button-choice md-primary" @click="choose(ENTERPRISE)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" sm="6" class="text-md-left">
            <mdm-button id="mdm-button-personal-choice" text="Personal" icon="person"
                        css="md-raised button-choice md-info md-primary" @click="choose(PERSONAL)"
                        is-no-need-inline-block/>
        </v-col>
    </v-row>
</template>


<script>
import FieldConstant from "../../../../utils/field-constant";

export default {
    name: "new-device-device-owner-choice",
    data: function() {
        return {
           ENTERPRISE: FieldConstant.DEVICE_OWNER.ENTERPRISE,
           PERSONAL: FieldConstant.DEVICE_OWNER.PERSONAL
        }
    },
    methods: {
        choose(deviceOwner) {
            this.$emit("selected", {deviceOwner});
        }
    }
};

</script>


<style>
#mdm-button-enterprise-choice {
    background-color: var(--vtmn-color_brand-digital-light-1)
}

#mdm-button-personal-choice {
    background-color: var(--vtmn-color_brand-digital-dark-1)
}
</style>
