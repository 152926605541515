<template>
    <md-table v-model="searchedGroups" md-sort="prio" md-sort-order="asc" md-card>
        <md-table-toolbar>
            <h1 id="applications-details-label-deployment-group" class="md-title">Deployment groups : <span id="depGrp">{{countGroup}}</span></h1>

            <md-field md-clearable>
                <md-input :placeholder="TOOLTIPS.SEARCH_BY_NAME" v-model="search" @input="$emit('searchOnTable', search)"/>
            </md-field>


            <mdm-button id="applications-details-btn-priority" :text="'Priority'"
                        :css="'md-raised md-primary'"
                        :tooltip-text="tooltipGroupPriority" :icon="'reorder'"
                        :disable="countGroup === 0 || !setGroupsPriority || grant('application', 'medium', app.userCountries, app.responsible) === 'button-disable'"
                        :to="{path: $route.params.id + '/groupPriority'}"/>

            <mdm-button id="applications-details-btn-add-group" :css="'md-icon-button md-raised md-primary'"
                        :customStyle="customStyle"
                        :tooltip-text="countVersionsAvailable === 0 ? tooltipModifyNoVersionsAvailable : countGroup >= addGroupMaxLimit ? tooltipAddGroupMaxLimit + addGroupMaxLimit : tooltipAddGroup"
                        :icon="'add'"
                        :disable="grant('application', 'low', app.userCountries, app.responsible) === 'button-disable' || countVersionsAvailable === 0 || countGroup >= addGroupMaxLimit"
                        :to="{path: $route.params.id + '/group'}"
            />

            <mdm-button :id="'mdm-button-replay-complete-prio'"
                        :css="'md-icon-button md-raised md-primary'"
                        :tooltip-text="tooltipRetryCompletePrio" :icon="'replay'"
                        :disable="(searchedGroups && searchedGroups.length > 0 && !searchedGroups[0].retry) || grant('application', 'low', app.userCountries, app.responsible) === 'button-disable'"
                        :to="{path: $route.params.id + '/retryCompletePriority'}"/>
        </md-table-toolbar>

        <md-table-empty-state v-if="countGroup !== 0" md-label="No groups found"
                              :md-description="`No groups found for this '${search}' query. Try a different search term .`"></md-table-empty-state>
        <md-table-empty-state v-else md-label="No deployment group"
                              :md-description="CONSTANT.ERROR_MESSAGES.DEPLOYMENT_GROUPS"></md-table-empty-state>

        <table-row v-if="searchedGroups" v-for="group in searchedGroups"
                   slot="md-table-row" slot-scope="{ item:group }"
                   :group="group"
                   :app="app"
                   :android-track="findTrack(group)"
                   :grant="grant"
                   :state="getStates.get(group.id)"
                   :error="statsError"
                   @deleteGroupPopin="(popinView, valueTmp) => $emit('deleteGroupPopin', popinView, valueTmp)"
        />

        <md-table-row v-else>
            <md-table-head>Prio</md-table-head>
            <md-table-head>Name</md-table-head>
            <md-table-head>Version</md-table-head>
            <md-table-head>Method</md-table-head>
            <md-table-head>Deployment</md-table-head>
            <md-table-head>Up To Date</md-table-head>
            <md-table-head>Enabled</md-table-head>
            <md-table-head>Playstore</md-table-head>
            <md-table-head>Status</md-table-head>
            <md-table-head>Action</md-table-head>
        </md-table-row>
    </md-table>
</template>

<script>
import FieldConstant from "../../../utils/field-constant";
import TableRow from "../../../components/applications/deploymentGroupsTable/tableRow";
import DeploymentGroupConstant from "../../../constant/deployment-group-constant";

export default {
    name: "retailTable",
    components: {
        TableRow
    },
    props: {
        searchedGroups : {
            type: Array
        },
        countVersionsAvailable : {
            type: Number
        },
        grant: {
            type: Function
        },
        findTrack: {
            type: Function
        },
        app: {
            type: Object
        },
        statsError: {
            type: Boolean
        },
        getStates: {
            type: Map
        },
        setGroupsPriority : {
            type: Boolean
        }
    },
    data() {
        return {
            TOOLTIPS: FieldConstant.APPLICATION.TOOLTIPS,
            search: null,
            tooltipGroupPriority: this.$store.state.labels.GROUP_PRIO_IMPOSSIBLE,
            addGroupMaxLimit: 10,
            tooltipRetryCompletePrio: this.$store.state.labels.RETRY_COMPLETE_PRIO,
            tooltipModifyNoVersionsAvailable: this.$store.state.labels.GROUP_MODIFY_NO_VERSION_AVAILABLE,
            tooltipAddGroupMaxLimit: this.$store.state.labels.GROUP_ADD_IMPOSSIBLE_MAX_LIMIT,
            tooltipAddGroup: this.$store.state.labels.GROUP_ADD_IMPOSSIBLE,
            CONSTANT: FieldConstant,
            customStyle: 'margin-left:5px; margin-right:5px;'
        }
    },
    computed: {
        countGroup() {
            return this.$store.state.group.group.countGroup;
        }
    }
};
</script>

<style scoped>
.md-field {
    width: 40%;
    margin-right: 100px;
}

</style>
