<template>
    <div class="d-flex flex-column">
        <ImageRadioGroup
            :options="orientationOptions"
            :pickedOption="pickedOption"
            @change="onChange"
        >
            <template #action-confirmation-dialog>
                <ActionConfirmationDialog :data="dialogInfo"/>
            </template>
        </ImageRadioGroup>
        <div v-if="param.error" class="error-display">
            <v-flex md12>
                <md-content v-if="param.error" class="md-accent">
                    <ul>
                        <li>An error occurred: {{param.error}}</li>
                    </ul>
                </md-content>
            </v-flex>
        </div>
    </div>
</template>

<script>
import ImageRadioGroup from '../../Common/imageRadioGroup';
import ActionConfirmationDialog from '../../dialog/actionConfirmationDialog';
import constants from '@/constant/kiosk-infos-constant';

export default {
    components: {
        ImageRadioGroup,
        ActionConfirmationDialog
    },
    props: {
        param: {
            type: Object
        }
    },
    data() {
        return {
            orientationOptions: [
                { value: constants.SCREEN_ORIENTATION.LANDSCAPE, imageSrc: require("../../../assets/icons/screen_orientation_landscape.png") },
                { value: constants.SCREEN_ORIENTATION.PORTRAIT, imageSrc: require("../../../assets/icons/screen_orientation_portrait.png") },
            ],
            pickedOption: this.param.screenOrientation,
            dialogInfo: null
        };
    },
    methods: {
        onChange({newPicked, cancelChange}) {
            this.dialogInfo = {
                visible: true,
                title: 'Confirmation',
                text: `Are you sure to set the ${newPicked} orientation for your device ? If yes, your device will be rebooted.`,
                confirm: _ => this.confirmAction(newPicked),
                cancel: _ => cancelChange()
            }
        },
        confirmAction(newPicked) {
            this.$emit('dispatchValue', newPicked);
        }
    },
    watch: {
        param: {
            handler(newValue, oldValue) {
                this.pickedOption = newValue.screenOrientation;
            },
            deep: true,
        }
    }
}
</script>

<style scoped>
.error-display .md-accent {
    margin-top: 10px;
}
</style>
