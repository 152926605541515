import {store} from "../../store/store";
import Vue from "vue";

export default {

    getLast() {
        return Vue.http.get(`${store.state.urlHomepageTraceS3}`).then((response) => {
            return response;
        }, (error) => {
            console.log(error);
        });
    }

}
