<template>
	<div class="filter-item filter-select" :class="openOption && 'z-index-max'" :id="idFilter + 'Filter'" v-click-outside="close">
		<div
			@click="openCurFilter"
			class="filter-selectOption filter-selectOption-default filter-aFilter"
			:class="{'filter-actif':placeholderValue != null}">
      <span class='filter-selectOption-text' v-show='openOption'>
        <input ref="autocompleteInput" :id="'autocompleteInput' + idFilter" @keyup="doAutoComplete" @change="validateAutoComplete"/>
			</span>
			<span class='filter-selectOption-text' v-show='!openOption && placeholderValue'>
				<span class='placeholderValuePlaceholder'>
					{{placeholder}} :
				</span>
				{{placeholderValue}}
			</span>
			<span class='filter-selectOption-text' v-show='!openOption && !placeholderValue' :value='placeholder'> {{placeholder}}</span>
		</div>
    <filter-option :elems=filteredElems :display=openOption @selectData="selectData"/>
    <div class="filter-delete" @click="deleteFilter(idFilter)" v-show=canBeDelete />
	</div>
</template>

<script>
import filterOption from './filter-option'
import FilterDeviceService from '../../../service/devices/filter-device-service';

export default {
  components: {
    'filter-option': filterOption,
  },
	data : function(){

    const deviceFilter = FilterDeviceService.getDeviceFilter();

    let placeholderValue = ""
    if( deviceFilter ) {
      let ph = deviceFilter[this.idFilter];


      if (this.idFilter == 'displayPerso') {
        if (ph == 'false') ph = 'CORP'
        else ph = 'Perso'
      }

      if (Array.isArray(ph)) {
        if (typeof ph[0] !== undefined) {
          placeholderValue = ph[0];
        }
      } else {
        placeholderValue = ph;
      }

    }
		return{
			placeholderValue : placeholderValue,
      openOption: false,
      filteredElems: false,
		}
	},

	props : {
    elems : Array,
    placeholder : String,
    idFilter : String,
    images : String,
    canBeDelete : {
      type: Boolean,
      default: false
    },
  } ,
  mounted : function() {
    this.filteredElems = this.elems;
  },
  watch : {
    elems: function(oldElems, newElems) {
      this.filteredElems = (newElems == undefined) ? oldElems : newElems;
    },
  },
	methods : {
		openCurFilter: function(){
            this.loadData();
            this.open();
		},
    autocompleteInput() {
      return document.getElementById('autocompleteInput' + this.idFilter)
    },
		selectData : function(value){
			this.placeholderValue = value;
			if(this.idFilter == 'model' || this.idFilter == 'manufacturer'){
				value = new Array(value);
			}
			this.close();
			this.$emit('dataSelected', {
				id : this.idFilter + "Filter",
				value : value
			});

		},
		deleteFilter : function(value){
			this.$emit('deleteFilter', value);
		},
		open : function(){
      this.openOption = true;
      setTimeout( () => {
        this.autocompleteInput().value=""
        this.autocompleteInput().focus()
        } , 200 );
		},
    close : function(){
      this.openOption = false;
    },
    loadData : function(){
      this.$emit('loadData')
    },
    doAutoComplete(event) {
      let filter = this.autocompleteInput().value;
      if(this.elems.length > 0)
        this.filterElemsList(filter)
    },
    filterElemsList(filter) {
      this.filteredElems = this.elems.filter( wordObj =>  {
        let word = wordObj
        if( typeof word == "object") {
          word = wordObj.name
        }

        return word.toLowerCase().includes(filter.toLowerCase())
      } );
    },
    validateAutoComplete() {
      if(this.filteredElems.length === 1) {
        this.selectData(this.filteredElems[0]);
      }
      return false;
    },
	},
}
</script>
