<template>
    <v-layout row wrap  align-center text-md-center>
        <v-flex xs12 sm12 ext-xs-center text-md-center>
            <md-progress-spinner v-if="!filteredValue" md-mode="indeterminate" class="my-10"></md-progress-spinner>

            <p v-else-if="filteredValue && data?.webviews?.length === 0" class="message-error">No webviews available on the MDM.</p>

            <template  v-else >
                <md-field class="mb-0">
                    <label>Search</label>
                    <md-input ref="filterinput" @keyup="filterData(this)" ></md-input>
                </md-field>

                <md-list v-if="filteredValue" :class="listClass">
                    <div v-for="webview in filteredValue">
                        <md-list-item @click="choose(webview)" class="url-webview-parent">
                            <img v-if="webview.icon != null" :src="webview.icon.imageBase64 || webview.icon" class='medium-app-icon mr-2'/>
                            <span class="md-list-item-text">{{ StringUtils.truncateString(webview.name + " - " + webview.url) }}</span>
                            <span><a :href="webview.url" target="_blank" class="a-decoration-none default-row-color url-webview-url"><i class="ri-eye-line"></i></a></span>
                        </md-list-item>
                    </div>
                </md-list>
            </template>
        </v-flex>
    </v-layout>
</template>


<script>

    import androidApi from "@/service/devices/android-api-service";
    import StringUtils from '../../../../utils/string-utils';

    export default {
        name: "webview-choice",
        data() {
            return {
                filter: "",
                filteredValue: undefined,
                StringUtils,
            };
        },
        props: {
            data: {
                type: Object,
                required: true
            },
            listClass: {
                type: String,
                default: "store-list"
            }
        },
        watch: {
            data() {
                this.filteredValue = this.data?.webviews;
            }
        },
        async mounted() {
            this.$emit("webviewChoiceLoaded", true);
        },
        methods: {
            async choose(webview) {
                this.$emit('selected', webview);
            },
            filterData() {
                const filter = document.getElementById(this.$refs.filterinput.id).value;
                this.filteredValue = this.data.webviews?.filter(webview => webview.name?.toUpperCase()?.includes(filter?.toUpperCase()));
            }
        }
    }

</script>

<style>
    .store-list {
        background-color : lightgrey !important;
        max-height: 400px;
        overflow: auto;
    }

    .store-list span.md-list-item-text {
        margin-left: 10px;
    }

    .message-error {
        color: red;
    }
    .medium-app-icon {
        max-width: 32px;
        vertical-align : middle;
    }

    .url-webview-url{
        display: none;
    }


    .url-webview-parent:hover .url-webview-url{
        display: block;
    }

</style>
