export default {
    calculateArboByPerimeter(arbo, perimeter) {
        Object.keys(perimeter).forEach(key => {
            const subPerimeter = perimeter[key];
            switch (key) {
                case "countries":
                    subPerimeter.forEach(elem => {
                        let _country = arbo.retail.site.find(item => item.ref === elem);
                        if (_country) {
                            _country.checked = true;
                            _country.sites.forEach(store => {
                                store.checked = true;
                                store.devices.forEach(device => {
                                    device.checked = true;
                                });
                            });
                        }
                    });
                    break;
                case "stores":
                    subPerimeter.forEach(elem => {
                        arbo.retail.site.forEach(country => {
                            country.sites.forEach(store => {
                                if (store.id === elem) {
                                    store.checked = true;
                                    store.devices.forEach(device => {
                                        device.checked = true;
                                    });
                                }
                            });
                        });
                    });
                    break;
                case "devices":
                    subPerimeter.forEach(elem => {
                        arbo.retail.site.forEach(country => {
                            country.sites.forEach(store => {
                                store.devices.forEach(device => {
                                    if (device.id === elem) device.checked = true;
                                });
                            });
                        });
                    });
                    break;
            }
        });
    },
    calculatePerimeterByArbo(arbo) {
        let perimeter = {countries: [], stores: [], devices: []};
        if(arbo.retail.checked) {
            perimeter.countries = arbo.retail.site.map(country => country.ref);
        } else {
            for (const country of arbo.retail.site) {
                if (country.checked) {
                    perimeter.countries.push(country.ref);
                    continue;
                }
                for (const store of country.sites) {
                    if (store.checked) {
                        perimeter.stores.push(store.id);
                        continue;
                    }
                    for (const device of store.devices) {
                        if (device.checked) {
                            perimeter.devices.push(device.id);
                        }
                    }
                }
            }
        }
        return perimeter;
    },
    calculatePerimeterCount(arbo, calculatedPerimeter) {
        let perimeterToDisplay = {countries: new Set(), stores: new Set(), devices: new Set()};

        let countries = arbo.retail.site.filter(country => calculatedPerimeter.countries.includes(country.ref));
        if(countries.length) {
            countries.forEach(country => {
                perimeterToDisplay.countries.add(country.ref);
                country.sites.forEach(store => {
                    perimeterToDisplay.stores.add(store.id);
                    store.devices.forEach(device => {
                        perimeterToDisplay.devices.add(device.id);
                    });
                });
            });
        }

        arbo.retail.site.forEach(country => {
            let stores = country.sites.filter(store => calculatedPerimeter.stores.includes(store.id));
            if(stores.length) {
                perimeterToDisplay.countries.add(country.ref);
                stores.forEach(store => {
                    perimeterToDisplay.stores.add(store.id);
                    store.devices.forEach(device => {
                        perimeterToDisplay.devices.add(device.id);
                    });
                });
            }
        });

        arbo.retail.site.forEach(country => {
            country.sites.forEach(store => {
                let devices = store.devices.filter(device => calculatedPerimeter.devices.includes(device.id));
                if(devices.length) {
                    perimeterToDisplay.countries.add(country.ref);
                    perimeterToDisplay.stores.add(store.id);
                    devices.forEach(device => {
                        perimeterToDisplay.devices.add(device.id);
                    });
                }
            });
        });

        return Object.keys(perimeterToDisplay).reduce((acc, key) => { acc[key] = perimeterToDisplay[key].size; return acc;}, {});
    }
};
