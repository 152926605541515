<template>
    <vc-donut :sections="sections"
              :size="size"
              :thickness="thickness"
              :has-legend="hasLegend"
              :percentage="percentage"
              :action="action"
              @section-mouseover="handleMouseOver"
              @section-mouseout="handleMouseOut">
        <small>
            <span class="title-into-chart">Total</span>
            <br>
            <div class="text-into-chart">{{total}} {{`${total <= 1 ? 'Device' : 'Devices'}`}} {{ ` ${action} ` }} ({{`${percentage} %`}})</div>
        </small>
    </vc-donut>

</template>


<script>
export default {
    name: "donut-chart",
    props: {
        size: {
            type: Number,
            default: 250
        },
        sections: {
            type: Array
        },
        thickness: {
            type: Number,
            default: 30
        },
        hasLegend: {
            type: Boolean,
            default: false
        },
        total: {
            type: Number
        },
        percentage: {
            type: Number
        },
        action: {
            type: String
        }
    },
    methods: {
        handleMouseOver(section, event) {
            this.$emit('section-mouseover', section, event);
        },
        handleMouseOut(section) {
            this.$emit('section-mouseout', section);
        }
    }

};
</script>


<style scoped lang="scss">

.title-into-chart {
    font-size: 26px;
    font-weight: 600;
}

.text-into-chart {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
}

</style>
