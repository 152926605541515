<template>
    <div class="alert-div">
        <v-alert dense type="warning" prominent outlined text>
            <p> You are about to deploy an application or a version on the Playstore. By clicking on the understand button, you guarantee to respect the
                <terms-of-use-link link="https://play.google.com/about/developer-content-policy/" label="Playstore policies"></terms-of-use-link> and the
                <terms-of-use-link link="https://support.google.com/googleplay/work/answer/9496237?hl=en&ref_topic=9496238" label="best pratices for private apps"></terms-of-use-link>. Finally, you can find here how to correctly
                <terms-of-use-link link="https://developer.android.com/courses/pathways/android-enterprise-build-apps" label="build private apps"></terms-of-use-link>.
            </p>
            <mdm-vtmn-button id="understood-terms-of-use"
                             @click="understand"
            >I understand
            </mdm-vtmn-button>
        </v-alert>
    </div>
</template>

<script>
import TermsOfUseLink from "@/components/Alerts/terms-of-use-link";
export default {
    name: "good-practices",
    components: {TermsOfUseLink},
    props:{
        understood: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        understand(){
            this.$emit("updateUnderstood");
        }
    }
};
</script>

<style scoped>
    .alert-div {
        text-align: left;
        padding-top: 20px
    }
</style>
