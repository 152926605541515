<template>
    <div>
        <v-alert dense type="info">
            Cannot display all selected columns. Please select fewer columns or use a larger screen.
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "device-filter-columns-alert"
};
</script>

<style scoped>

</style>
