export default {
    MIGRATED: "migrated",
    WIPED: "wiped",
    MIGRATED_TO_WSO: "Migrated to WSO",
    NOT_MIGRATED_TO_WSO: "Not migrated to WSO",
    WIPED_FROM_MDM: "Wiped from MDM",
    NOT_WIPED_FROM_MDM: "Not wiped from MDM",
    WORD_TO_FILTER_BY_LABEL: "not",
    COLORS: {
        BLUE_BRAND: "#3643BA",
        RED_STATUS_NEGATIVE: "#D70321",
        RED_STATUS_NEGATIVE_LIGHT: "#EF9AA6",
        BLACK_NEUTRAL: "#101010",
        GREY_INPUT_BUTTON: "#949494"
    },
    ENTIRE_DATABASE_FETCHED: "No filter selected : the entire database is fetched",
    DATE_FILTER_SELECTED: "There is a date filter selected",
    DATE_FILTER_SELECTED_SUFFIX: "and there is a date filter"
}
