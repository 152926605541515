<template>
    <div v-if="configuration && (configuration.actualState.preVisualize || (configuration.active && !userWantClose))" :class="criticity.class" id="informative-header-panel">
        <div v-if="configuration.actualState.preVisualize">
            <p>
                This is a pre visualization of the header you are configuring, users are not able to see this sentence<br />
                {{ configuration.active ? 'active' : 'inactive' }} header
            </p>    
            <v-divider />
        </div>
        
        <v-row no-gutters>
            <v-col cols=1 class="icon-panel">
                <span aria-hidden="true" :class="criticity.icon + ' header-icon-size'"/>
                <h1 id="informative-header-criticity" >{{ criticity.iconTitle }}</h1>
            </v-col>
            <v-col cols=10 class="pt-3">
                <h1 id="informative-header-title"> {{ configuration.title }} </h1>
                <div id="informative-header-content" > 
                    <p v-html="configuration.content" />
                </div>
            </v-col>
        </v-row>
        <span v-if="!configuration.actualState.preVisualize && criticity.canClose" @click="close" class="btn-popup-close" id="informative-header-close-btn">
            <md-icon>close</md-icon>
        </span>
    </div>
</template>

<script>
    import AdminTabsConstant from '../../constant/admin-tabs-constant';
    import InformationHeaderService from '../../service/header/information-header-service'

    export default {
        name: "informative-header",
        data: function () {
            return { 
                userWantClose: false
            }
        },
        computed: {
            configuration() {
                return this.$store.getters['headerConfiguration/getHeaderConfiguration'];
            },
            criticity() {
                const criticity = AdminTabsConstant.HEADER.CRITICITY_LEVEL.find(criticity => criticity.NAME === this.configuration.criticity);
                return {
                    class: criticity.NAME.toLowerCase(),
                    icon: criticity.icon,
                    iconTitle: criticity.NAME + " " + (criticity.NAME === AdminTabsConstant.HEADER.RESOLVED ? '' : ' alert'),
                    canClose: criticity.canClose
                }
            }
        },
        mounted: function() {
            this.$store.dispatch("headerConfiguration/getConfiguration");
        },
        methods: {
            close() {
                this.userWantClose = true;
            }
        }
    }
</script>

<style lang="scss"> 
#informative-header-panel {
    color: white;
    text-align: left;

    .icon-panel {
        align-self: center;
        text-align: center;

        h1 {
            text-transform: uppercase;
            font-weight: bold;
        }
        
        .header-icon-size {
            font-size: 2em;
        }
    }
    
    #informative-header-title {
        font-weight: bold;
        font-size: 2em;
    }

    #informative-header-content {
        font-size: 1.2em;

        a {
            color: white;
            font-weight: bold;
            text-decoration: underline;
        }
    }
    
    .btn-popup-close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        i {
            color: white !important;
        }
    }
    &.minor {
        background-color: var(--vtmn-semantic-color_content-warning);
    }
    &.major {
        background-color: var(--vtmn-semantic-color_background-alert);
    }
    &.resolved {
        background-color: var(--vtmn-semantic-color_content-positive);
    }

    p {
        max-height: 80px;
        overflow: auto;
        overflow-wrap: break-word;
    }
}
</style>
