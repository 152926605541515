<template>
    <v-col cols="12" md="3" sm="6" class="info-component">
        <h2 class="md-title"><md-icon>{{ icon }}</md-icon> {{title}} </h2>
        <div class='information-phone area'>
            <div class='information-item' v-for="content in contents">
                <div v-if="content.display==undefined || content.display" @click="clickOnInfo(content)" :class="content.linkStyle ? 'linkStyle' : (content.flexStyle ? 'flex-item' : '')">
                    <span class='label' style="font-size: 13px">{{ content.libelle }}</span>
                    <component v-if="content.renderer"
                               :is="content.renderer.component" @dispatchValue="(value) => dispatchValue(content, value)"
                               :param="content.renderer.param"
                    />
                    <span v-else class='value'> {{ content.value }}</span>
                </div>
            </div>
        </div>
    </v-col>
</template>


<script>
export default {
    name: "card-info",
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        contents: {
            type: Array,
            required: true
        },
        renderer: {
            type: Object
        }
    },
    methods: {
        clickOnInfo(content) {
            this.$emit('clickOnInfo', content);
        },
        dispatchValue(content, value) {
            this.$emit('dispatchValue', {content, value});
        }
    }
}
</script>

<style>
.info-component {
    padding: 0px 10px;
}
@media (max-width: 960px) {
    .info-component {
        padding: 10px 10px;
    }
}
.information-phone {
    height: 90%;
}
.info-component .md-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info-component .md-title:hover {
    overflow: visible;
}
.linkStyle {
    color: var(--md-theme-default-primary-on-background, #448aff);
    cursor: pointer;
}
.linkStyle:hover {
    text-decoration: underline;
}
.inline-flex {
    display: inline-flex;
}
.flex-item {
    display: flex;
    align-items: center;
}
</style>
