<template>
    <div class="d-flex">
        <label v-for="(option, index) in options" :key="index" class="d-flex align-center mx-1">
            <input
                type="radio"
                :value="option.value"
                :checked="picked === option.value"
                @change="onChange"
            />
            <img :src="option.imageSrc" class="ml-1"/>
        </label>

        <slot name="action-confirmation-dialog"/>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        pickedOption: {
            type: String
        }
    },
    data() {
        return {
            picked: this.pickedOption || ''
        }
    },
    methods: {
        onChange(event) {
            this.picked = event.target.value;
            this.$emit('change', {newPicked: this.picked, cancelChange: _ => {this.picked = this.pickedOption}});
        }
    }
};
</script>
