import { store } from "../store/store";
import { mustNotContainsRegex, matchUrl} from './form-validator-utils';
import { url } from 'vuelidate/lib/validators';
import WebviewConstant from "../constant/webview-constant";


function getWebviewUrlErrorMessage(webviewUrl) {
    if(!matchUrl(webviewUrl)) {
        return WebviewConstant.ERROR.URL.BEGINNING_KO;
    } else if(!url(webviewUrl)) {
        return WebviewConstant.ERROR.URL.NOT_VALID;
    } else if(!mustNotContainsRegex(webviewUrl, store.state.application.blacklistUrl.regex)) {
        return WebviewConstant.ERROR.URL.WEBSITE_NOT_ALLOWED;
    }
    return WebviewConstant.ERROR.URL.MUST_BE_VALID;
}

function getWebviewNameErrorMessage(name) {
    if(name?.length >= WebviewConstant.NAME.MAX_LENGTH) {
        return WebviewConstant.ERROR.NAME.MAX_LENGTH_KO;
    } else if(name?.length < WebviewConstant.NAME.MIN_LENGTH) {
        return WebviewConstant.ERROR.NAME.MIN_LENGTH_KO;
    }
}



export {
    getWebviewUrlErrorMessage, getWebviewNameErrorMessage
}
