<template>
    <div class="main">
        <div class="div-logoDecat" >
            <dkt-logo-svg class="header-logoDecat" />
        </div>
        <div>
            <user-not-retrieved-svg></user-not-retrieved-svg>
        </div>
        <div class="div-text-title">
            <p class="text-title">Access to Mobile Device Management<br/><span class="text-alert">denied</span>.</p>
        </div>
        <div class="div-description">
            <div class="description">
                <span v-if="this.$store.state.user.uid === userConstant.INITIAL_USER_UID_IN_VUEX">{{ userConstant.USER_NOT_RETRIEVED_ERROR_MESSAGE }}</span>
                <span v-else-if="!this.$store.state.user.admin && !this.$store.state.user.appManager && !this.$store.state.user.cp && !this.$store.state.user.cc">
                    {{ userConstant.USER_UNAUTHORIZED_ERROR_MESSAGE}}<a class="enrollmentAndSupportLink" :href="userConstant.OFFERING_LINK">{{userConstant.OFFERING_LINK}}</a>
                    <div class="enrollmentMessageBlock">{{userConstant.ENROLLMENT_LINK_MESSAGE}}
                        <p @click="goToRgpdPage" class="enrollmentAndSupportLink">{{ userConstant.TEXT_LINK_ENROLLMENT_PAGE }}</p>
                    </div>
                </span>
            </div>
        </div>

    </div>
</template>

<script>
import UserNotRetrievedSvg from "@/components/app-icon/user-not-retrieved-svg";
import UserConstant from "../../constant/user-constant";
import UrlConstant from "../../constant/url-constant";
export default {
    name: "user-not-retrieved-or-unauthorized",
    components: {UserNotRetrievedSvg},
    data() {
        return {
            userConstant: UserConstant
        }
    },
    methods: {
        goToRgpdPage() {
            this.$router.push(UrlConstant.rgpdSuffix);
        }
    }
};
</script>

<style scoped>

.main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    width: 100%;
}

.div-description {
    width: 710px;
    margin-bottom: 20px;
}

.description{
    color: #001944;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.enrollmentAndSupportLink {
    color: var(--vtmn-color_brand)!important;
    text-decoration: underline;
}

.enrollmentAndSupportLink:hover {
    cursor: pointer;
}


.div-logoDecat {
    margin-bottom: 58px;
    margin-top: 15px;
}

.header-logoDecat{
    width: 138px;
    height: 34px;
    top: 167px;
    left: 651px;

}

.text-title{
    color: #000;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
}

.text-alert{
    color: #E3262F;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
}

.div-text-title{
    text-align: center;
    margin-top: 32px;
}

.enrollmentMessageBlock {
    margin-top: 15px;
}

</style>
