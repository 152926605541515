<template>
    <div class="admin-main-class mt-15">
        <v-tabs
            v-model="tab"
            background-color="transparent"
            fixed-tabs
        >
            <v-tab
                v-for="tab in allTabs"
                :key="tab.NAME"
                :id="'mdm-admin-tab-' + tab.NAME"
                @click="() => clickOnTab(tab.NAME)"
            >
                {{ tab.NAME }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="tab in allTabs"
                :key="tab.NAME"
            >
                <v-card flat>
                    <component :is="tab.RENDER" />
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import AdminTabsConstant from '../../constant/admin-tabs-constant';
import UserApiService from '../../service/user/user-api-service';

export default {
    name: 'admin-main-component',
    data: function(){
        return {
            TABS_CONSTANT: AdminTabsConstant,
            IS_ADMIN: this.$store.state.user?.admin,
            tab: null,
            allTabs: [ AdminTabsConstant.CACHE, AdminTabsConstant.HEADER, AdminTabsConstant.FIRMWARE ]
        }
    },
    async mounted(){
        if (!(this.IS_ADMIN && await UserApiService.isPartOfMdmTeam())){
            return this.$router.push('/');
        }
    },
    methods: {
        clickOnTab(name) {
            this.$store.dispatch('headerConfiguration/tooglePreVisualize', name === AdminTabsConstant.HEADER.NAME);
        }
    }
}
</script>

<style lang="scss">
    .admin-main-class{
        width: 100%;
        background-color: white;
    }
</style>
