<template>
    <md-table-row id="group-row-services">

        <md-table-cell id="group-row-services-name" class="name-cell" md-label="Deployment perimeter" >
            <md-tooltip md-direction="top" class="tool-tip-multi-row">
                    <span >
                        <p>
                            • Last update : {{group.lastUpdateDate ? TimeUtils.formatTimestampTo(group.lastUpdateDate) : 'none'}}<br />
                            • By : {{group.lastUpdateUser ? group.lastUpdateUser : 'nobody'}}
                        </p>
                    </span>
            </md-tooltip>
            <div>
                {{defaultGroupName}}
            </div>
        </md-table-cell>

        <md-table-cell id="group-row-services-version" md-label="Production version" class="justify-center">
            <div>
                {{getProductionVersionCode}}
            </div>
        </md-table-cell>

        <md-table-cell id="group-row-services-enabled" md-label="Deployment activation" class="justify-center">
            <filter-toggle clickable="true" is-service="true" @changeStatus="changeStatus" :status="isActiveServiceGroupComputed" :is-active-service-group="isActiveServiceGroupComputed"/>
        </md-table-cell>
    </md-table-row>
</template>

<script>
import FieldConstant from "../../../utils/field-constant";
import DeploymentGroupConstant from '../../../../src/constant/deployment-group-constant';
import ApplicationService from '../../../service/application/application-service';
import appTypeEnum from '../../../service/application/app-type-enum';
import GroupServices from "../../../service/group/group-service";
const utils = require("../../../../src/utils/utils");
import UrlLogoUtils from '../../../utils/url-logo-utils';
import ArboUtils from '../../../utils/arbo-utils';
import TimeUtils from '../../../utils/time-utils';


export default {
    name: "tableRow",
    props : {
        group : {
            type: Object,
            required: true
        },
        app : {
            type: Object
        },
        grant : {
            type : Function
        }
    },
    data() {
        return {
            TimeUtils,
            defaultGroupName: DeploymentGroupConstant.DEFAULT_GROUP_NAME,
            FIELD_NO_CANCEL: FieldConstant.NO_CANCEL,
            FIELD_YES_DEPLOY: FieldConstant.YES_DEPLOY,
        }
    },
    computed : {
        getProductionVersionCode() {
            return this.$store.state.application.productionVersionName;
        },
        isActiveServiceGroupComputed() {
            this.$store.commit('group/setGroupIsActive', this.group.activated);
            return this.$store.state.group.group.activated;
        },

    },
    methods: {
        async changeStatus(isActive) {
            try {
                const application = await ApplicationService.getApplication(this.$route.params.id);
                if(application.appType === appTypeEnum.PUBLIC_APP) this.putPSVersionFirst(application);

                const applicationWithIcon = {
                    ...application,
                    icon: (await UrlLogoUtils.getApplicationLogo(application)).icon
                }
                this.$store.dispatch('group/setGroup', {
                    application: applicationWithIcon,
                    arbo: await ArboUtils.transformRequirementToArbo()
                });
                await GroupServices.getAvailableVersion(application, true);
            } catch(e) {
                console.error("Error when loading the application:", e);
                this.error = "Unable to load the associated application to create the group. Please try again. If the problem persists, please contact your administrator. "
            }
            this.$store.commit('group/setGroup', this.group);
            this.$store.commit('group/setGroupIsActive', isActive);
            await this.$store.dispatch('group/saveGroup');
        },
        putPSVersionFirst(playstoreApp) {
            const indexOfPlaystoreApp = playstoreApp.versions.findIndex(version => version.versionName === this.PRODUCTION_VERSION_NAME);

            if(indexOfPlaystoreApp > 0) {
                playstoreApp.versions.unshift(playstoreApp.versions.splice(indexOfPlaystoreApp, 1)[0]);
            }
        }
    }
};
</script>

<style lang="scss" scoped>

th {
    text-align: center;
}

td {
    font-size : 11px;
    vertical-align: middle;
    text-align: center;
    &.name-cell {
        max-width: 120px;
        word-wrap: break-word;
    }
    &.font-family-cell {
        font-family: 'Roboto', sans-serif;
    }
    &.skeleton-cell {
        width:100%;
    }
    &.up-to-date-cell {
        font-size: 13px;
        font-weight: bold;
    }
}

.deployment-error-cell {
    color : var(--vtmn-color_danger);
    font-size: 13px;
    font-weight: bold;
}

.modify-button {
    width: 100%;
}

</style>

<style>
.md-table-head-container, .md-table-head-label {
    text-align: center;
}
</style>
