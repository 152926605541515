<template>
    <mdm-vtmn-popup ref="popupFilterSite"
                    id-button-open="devices-all-button-open-site-filter">
        <template v-slot:button-open-content>
                <div class="icon-and-text">
                    <filter-by-site />
                    <span v-if="!$vuetify.breakpoint.xs" class="text-button-store">FILTER BY SITES</span>
                </div>
        </template>

        <mdm-vtmn-adjust-card>
            <template v-slot:header>
                <p class="vtmn-typo_title-5" align="center">Select a perimeter to filter</p>
            </template>

            <arbo type="light" :source="mutableArbo || arbo" @changeArbo="changeArbo" :is-reset="isReset" @cancelReset="isReset=false" :clear-all-arbo="clearAllArbo"/>

            <template v-slot:footer>
                <div class="footer-buttons">
                    <clear-all-button id="migration-clear-all-btn-arbo" @clear="clearAll" label="CLEAR ALL" />
                    <validate-button id="migration-validate-btn-arbo" @click="valid" label="VALIDATE" />
                </div>

            </template>
        </mdm-vtmn-adjust-card>
    </mdm-vtmn-popup>

</template>

<script>
    import localStorageService from '../../../../service/local-storage-service';
    import localStorageConstant from '../../../../constant/local-storage-constant';
    import Arbo from './arbo';
    import FilterBySite from "../../icons/filter-by-site-icon.vue";
    import ValidateButton from "../../components/arbo-validate-button.vue";
    import ClearAllButton from "../../components/clear-all-button.vue";

    export default {
        name: 'device-filter-site',
        components: {
            ClearAllButton,
            ValidateButton,
            FilterBySite,
            Arbo
        },
        props: {
            arbo: {
                type: Object,
                default: null
            },
            clearAllArbo: {
                type: Object
            }
        },
        data: function() {
            return {
                mutableArbo: null,
                isReset: false
            }
        },
        methods: {
            changeArbo(arbo) {
                this.mutableArbo = arbo;
            },
            valid() {
                localStorageService.setItem(localStorageConstant.MIGRATION.ACTIVE_ARBO, this.mutableArbo);
                this.$emit("storeSelected", this.mutableArbo);
                this.$refs.popupFilterSite.close();
            },
            clearAll() {
                const arboToUse = this.arbo ? this.arbo : this.mutableArbo;
                const resetArbo = {
                    retail: {
                        site: arboToUse.retail.site.map(country => {
                            return {
                                code: country.code,
                                ref: country.ref,
                                checked: false,
                                sites: country.sites.map(store => {
                                    return {
                                        id: store.id,
                                        name: store.name,
                                        checked: false
                                    }
                                }),
                            }
                        })
                    }
                }
                localStorageService.setItem(localStorageConstant.MIGRATION.ACTIVE_ARBO, resetArbo);
                this.isReset = true;
                this.$emit("storeSelected", resetArbo);
            }
        },
    }
</script>

<style scoped>
.text-button-store {
    color: #3643BA;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
}

.icon-and-text {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.footer-buttons {
    display: flex;
}
</style>
