<template>
    <svg width="354" height="397" viewBox="0 0 354 397" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3_594)">
            <path d="M200.685 305.895C285.359 305.895 354 237.418 354 152.948C354 68.477 285.359 0 200.685 0C116.011 0 47.3699 68.477 47.3699 152.948C47.3699 237.418 116.011 305.895 200.685 305.895Z" fill="#EFF1F3"/>
            <path d="M300.874 68.3979H20.0219C19.4196 68.385 18.9418 67.8876 18.9547 67.2868C18.9672 66.7041 19.4378 66.2347 20.0219 66.2222H300.874C301.476 66.235 301.954 66.7325 301.941 67.3333C301.929 67.916 301.458 68.3854 300.874 68.3979Z" fill="#D9DDE1"/>
            <path d="M205.16 105.06H127.522C121.69 105.06 116.963 109.776 116.963 115.593V115.593C116.963 121.411 121.69 126.127 127.522 126.127H205.16C210.992 126.127 215.719 121.411 215.719 115.593C215.719 109.776 210.992 105.06 205.16 105.06Z" fill="white"/>
            <path d="M77.3667 147.194H255.315C261.147 147.194 265.874 151.91 265.874 157.728C265.874 163.545 261.147 168.261 255.315 168.261H77.3667C71.5351 168.261 66.8077 163.545 66.8077 157.728C66.8077 151.91 71.5351 147.194 77.3667 147.194Z" fill="white"/>
            <path d="M77.3667 189.329H255.315C261.147 189.329 265.874 194.045 265.874 199.862C265.874 205.68 261.147 210.396 255.315 210.396H77.3667C71.5351 210.396 66.8077 205.68 66.8077 199.862C66.8077 194.045 71.5351 189.329 77.3667 189.329Z" fill="white"/>
            <path d="M55.3147 396.074L54.8959 396.033C40.6867 394.57 27.8416 386.947 19.7732 375.187C17.5632 371.91 15.7755 368.369 14.4524 364.647L14.3253 364.292L14.7017 364.261C19.1968 363.901 23.8145 363.14 25.8659 362.778L12.6381 358.307L12.5563 357.915C11.7721 354.239 13.3085 350.457 16.4366 348.364C19.5731 346.169 23.7325 346.099 26.9417 348.186C28.3819 349.104 29.8883 350.013 31.3449 350.892C36.3507 353.913 41.527 357.036 45.4078 361.31C51.2927 367.921 53.8721 376.828 52.4277 385.55L55.3147 396.074Z" fill="#EFF1F3"/>
            <path d="M103.397 389.511L109.916 389.51L113.018 364.423L103.395 364.424L103.397 389.511Z" fill="#FFE4D6"/>
            <path d="M103.167 396.296L123.216 396.295V396.042C123.215 391.743 119.722 388.258 115.412 388.258L111.75 385.486L104.917 388.258L103.167 388.258L103.167 396.296Z" fill="#001018"/>
            <path d="M51.15 371.497L56.3645 375.4L73.9372 357.19L66.2401 351.43L51.15 371.497Z" fill="#FFE4D6"/>
            <path d="M46.8845 376.787L62.9216 388.789L63.0738 388.587C65.6598 385.147 64.9619 380.268 61.5148 377.688L60.2524 373.278L53.1194 371.405L51.7195 370.357L46.8845 376.787Z" fill="#001018"/>
            <path d="M72.8215 271.785C72.8215 271.785 73.1814 281.578 73.6353 289.432C73.7098 290.72 70.6848 292.12 70.7631 293.521C70.8151 294.451 71.3837 295.354 71.4365 296.324C71.4922 297.347 70.7072 298.252 70.7631 299.31C70.8178 300.344 71.7133 301.53 71.7673 302.588C72.3487 313.974 74.4562 327.605 72.3458 331.416C71.3058 333.293 58.0493 359.31 58.0493 359.31C58.0493 359.31 65.4354 370.363 67.5457 366.678C70.333 361.812 91.8142 339.047 91.8142 334.7C91.8142 330.358 96.784 297.816 96.784 297.816L100.249 317.074L101.838 320.889L101.339 323.129L102.366 326.152L102.421 329.144L103.421 334.7C103.421 334.7 100.414 377.984 102.107 379.189C103.806 380.399 112.031 382.573 113.002 380.399C113.967 378.226 120.251 335.226 120.251 335.226C120.251 335.226 121.264 315.521 122.362 297.205C122.425 296.142 123.174 294.858 123.232 293.81C123.301 292.595 122.826 291.016 122.889 289.837C122.958 288.484 123.559 287.532 123.617 286.237C124.066 276.232 121.548 264.048 120.747 262.848C118.325 259.227 116.146 256.332 116.146 256.332C116.146 256.332 83.5301 245.641 74.8145 256.504L72.8215 271.785Z" fill="#001018"/>
            <path d="M105.352 171.075L92.4218 168.495L86.3879 176.664C75.1292 187.708 74.9596 197.455 76.9695 213.079V235.007L75.5148 249.706C75.5148 249.706 70.3651 260.452 75.6765 262.954C80.9878 265.456 115.876 265.242 119.206 264.244C122.537 263.245 119.65 262.019 118.776 257.364C117.132 248.622 118.231 252.839 118.345 250.055C119.966 210.124 116.19 193.731 115.806 189.422L109.23 177.524L105.352 171.075Z" fill="#6C4D6F"/>
            <path d="M173.499 203.27C172.151 205.818 168.989 206.793 166.436 205.448C166.164 205.305 165.905 205.138 165.662 204.949L137.739 222.636L137.82 213.006L164.944 197.303C166.877 195.142 170.199 194.953 172.365 196.88C174.178 198.493 174.647 201.135 173.499 203.27Z" fill="#FFE4D6"/>
            <path d="M95.1428 182.227L91.9638 181.891C89.0265 181.587 86.1445 182.842 84.3708 185.197C83.6915 186.091 83.2035 187.114 82.9373 188.203L82.9354 188.212C82.138 191.491 83.3887 194.927 86.1092 196.932L97.0889 205.007C104.794 215.394 119.275 222.285 136.262 227.883L162.665 211.524L153.326 200.241L135.216 209.924L108.296 189.135L108.281 189.122L98.0351 182.537L95.1428 182.227Z" fill="#6C4D6F"/>
            <path d="M100.983 164.741C109.31 164.741 116.061 158.006 116.061 149.699C116.061 141.392 109.31 134.657 100.983 134.657C92.6554 134.657 85.9048 141.392 85.9048 149.699C85.9048 158.006 92.6554 164.741 100.983 164.741Z" fill="#FFE4D6"/>
            <path d="M101.331 164.744C101.209 164.748 101.088 164.751 100.966 164.754C100.914 164.889 100.858 165.023 100.793 165.154L101.331 164.744Z" fill="#001018"/>
            <path d="M105.469 150.116C105.493 150.265 105.529 150.411 105.577 150.553C105.557 150.404 105.521 150.257 105.469 150.116Z" fill="#001018"/>
            <path d="M116.253 135.301C115.632 137.223 115.078 134.785 113.037 135.223C110.581 135.751 107.723 135.57 105.712 134.068C102.715 131.875 98.8313 131.284 95.3156 132.487C91.8741 133.702 86.1085 134.558 85.2118 138.088C84.9001 139.316 84.7763 140.647 84.0208 141.664C83.3602 142.553 82.3176 143.06 81.4458 143.745C78.5018 146.055 80.7519 152.62 82.1452 156.089C83.5386 159.558 86.7347 162.085 90.2487 163.379C93.6487 164.632 97.3389 164.852 100.966 164.754C101.597 163.122 101.324 161.237 100.804 159.545C100.24 157.713 99.4058 155.945 99.1704 154.043C98.935 152.142 99.4519 149.982 101.05 148.919C102.519 147.942 104.91 148.537 105.469 150.116C105.143 148.142 107.147 146.232 109.214 145.896C111.434 145.535 113.653 146.335 115.865 146.732C118.078 147.13 117.293 137.837 116.253 135.301Z" fill="#001018"/>
            <path d="M200.58 174.581C201.985 157.639 189.357 142.767 172.374 141.365C155.39 139.963 140.483 152.561 139.078 169.503C137.672 186.446 150.3 201.317 167.284 202.72C184.267 204.122 199.174 191.524 200.58 174.581Z" fill="#007DBC"/>
            <path d="M177.934 159.216L169.829 167.302L161.723 159.216C160.411 157.907 158.284 157.907 156.972 159.216C155.66 160.525 155.66 162.647 156.972 163.956L165.077 172.042L156.972 180.128C155.661 181.438 155.663 183.561 156.976 184.868C158.288 186.174 160.412 186.174 161.723 184.868L169.829 176.782L177.934 184.868C179.247 186.176 181.375 186.174 182.685 184.864C183.995 183.555 183.995 181.437 182.685 180.128L174.58 172.042L182.685 163.956C183.998 162.647 183.998 160.525 182.685 159.216C181.373 157.907 179.246 157.907 177.934 159.216Z" fill="white"/>
            <path d="M157.669 146.17C158.579 148.903 157.095 151.855 154.355 152.762C154.063 152.859 153.763 152.93 153.458 152.974L146.921 185.32L140.011 178.595L147.431 148.197C147.205 145.31 149.368 142.787 152.262 142.562C154.684 142.374 156.919 143.865 157.669 146.17Z" fill="#FFE4D6"/>
            <path d="M88.2015 188.008L85.7576 190.064C83.5043 191.968 82.4168 194.911 82.8927 197.819C83.0685 198.926 83.4707 199.986 84.0744 200.932L84.0794 200.939C85.8991 203.783 89.2506 205.262 92.584 204.691L106.027 202.378C118.874 204.022 133.884 198.368 149.693 190.015L156.139 159.683L141.512 158.594L135.978 178.335L102.305 183.321L102.285 183.324L90.4282 186.141L88.2015 188.008Z" fill="#6C4D6F"/>
            <path d="M164.745 396.815L0.716378 397C0.319455 396.999 -0.00128182 396.677 3.85175e-06 396.281C0.00127745 395.887 0.321259 395.567 0.716378 395.566L164.745 395.381C165.142 395.382 165.463 395.704 165.462 396.1C165.46 396.494 165.14 396.814 164.745 396.815Z" fill="#D9DDE1"/>
            <path d="M34.2707 54.6176C37.0898 54.6176 39.3751 52.2869 39.3751 49.4119C39.3751 46.5369 37.0898 44.2062 34.2707 44.2062C31.4516 44.2062 29.1663 46.5369 29.1663 49.4119C29.1663 52.2869 31.4516 54.6176 34.2707 54.6176Z" fill="#6C4D6F"/>
            <path d="M51.9042 54.6176C54.7233 54.6176 57.0087 52.2869 57.0087 49.4119C57.0087 46.5369 54.7233 44.2062 51.9042 44.2062C49.0851 44.2062 46.7998 46.5369 46.7998 49.4119C46.7998 52.2869 49.0851 54.6176 51.9042 54.6176Z" fill="#6C4D6F"/>
            <path d="M69.5377 54.6176C72.3568 54.6176 74.6421 52.2869 74.6421 49.4119C74.6421 46.5369 72.3568 44.2062 69.5377 44.2062C66.7186 44.2062 64.4332 46.5369 64.4332 49.4119C64.4332 52.2869 66.7186 54.6176 69.5377 54.6176Z" fill="#6C4D6F"/>
            <path d="M89.6445 53.5263C89.4877 53.5263 89.3312 53.4657 89.2131 53.3452L85.7689 49.8328C85.5393 49.5988 85.5393 49.2248 85.7689 48.9907L89.2131 45.4786C89.4468 45.2411 89.8293 45.2372 90.0666 45.4692C90.305 45.7018 90.3092 46.0832 90.076 46.3207L87.0447 49.4118L90.076 52.5031C90.3091 52.7407 90.305 53.122 90.0666 53.3546C89.9493 53.4693 89.7969 53.5263 89.6445 53.5263Z" fill="#6C4D6F"/>
            <path d="M95.4321 53.5263C95.2798 53.5263 95.1274 53.4693 95.0101 53.3546C94.7716 53.122 94.7675 52.741 95.0007 52.5031L98.0316 49.4118L95.0007 46.3207C94.7675 46.0832 94.7716 45.7018 95.0101 45.4692C95.2479 45.2364 95.6305 45.2408 95.8636 45.4786L99.3075 48.9907C99.5371 49.2248 99.5371 49.5988 99.3075 49.8328L95.8636 53.3452C95.7454 53.4657 95.5889 53.5263 95.4321 53.5263Z" fill="#6C4D6F"/>
            <path d="M275.613 44.8089H269.209C268.479 44.8089 267.887 45.399 267.887 46.1276V52.5225C267.887 53.2511 268.479 53.8413 269.209 53.8413H275.613C276.344 53.8413 276.941 53.2511 276.941 52.5225V46.1276C276.941 45.399 276.344 44.8089 275.613 44.8089Z" fill="#6C4D6F"/>
            <path d="M259.92 44.8089H253.515C252.785 44.8089 252.194 45.399 252.194 46.1276V52.5225C252.194 53.2511 252.785 53.8413 253.515 53.8413H259.92C260.65 53.8413 261.248 53.2511 261.248 52.5225V46.1276C261.248 45.399 260.65 44.8089 259.92 44.8089Z" fill="#6C4D6F"/>
            <path d="M290.402 45.11H283.997C283.267 45.11 282.676 45.7001 282.676 46.4287V52.8236C282.676 53.5522 283.267 54.1423 283.997 54.1423H290.402C291.132 54.1423 291.73 53.5522 291.73 52.8236V46.4287C291.73 45.7001 291.132 45.11 290.402 45.11Z" fill="#6C4D6F"/>
            <path d="M193.873 47.5427H142.681C141.788 47.5427 141.069 48.2653 141.069 49.1505C141.069 50.0357 141.788 50.7582 142.681 50.7582H193.873C194.76 50.7582 195.484 50.0356 195.484 49.1505C195.484 48.2653 194.76 47.5427 193.873 47.5427Z" fill="#6C4D6F"/>
        </g>
        <defs>
            <clipPath id="clip0_3_594">
                <rect width="354" height="397" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "user-not-retrieved-svg"
};
</script>
