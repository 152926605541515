<template>
	<div v-if='arbo' class='filter-arbo' :class="'light'">
        <ul class='filter-buItems' style="padding: 0;">
            <li v-for='entity in entities' class='filter-buItem'>
                <span class="check" :class="{checkActive: arbo[entity].checked, checkPartial: isCheckPartialEntity(arbo, entity)}" @click="check(entity, '*ALL*', '*ALL*', '*ALL*')"></span>
                <span>Decathlon - {{entity}}</span>
                <ul class='filter-countryItems' :class="'filter-countryItems-open'">
                    <li v-for="country in arbo[entity].site" class='filter-countryItem'>
                        <span class="check" :class="{ checkActive: country.checked, checkPartial: isCheckPartialCountry(arbo, entity, country)}" @click="check(entity, country, '*ALL*', '*ALL*')"></span>
                        <span class='label' @click='openArbo("country", entity, country)'
                              :class="{'label-open': arbo[entity].site.find(item => item.code === country.code).open}">{{country.code}}</span>
                        <ul class='filter-siteItems' :class=" {'filter-siteItems-open': arbo[entity].site.find(item => item.code === country.code).open}">
                            <li class='filter-siteItem' v-for="site in arbo[entity].site.find(item => item.code === country.code).sites">
                                <span class="check" :class="{ checkActive: site.checked, checkPartial: isCheckPartialStore(arbo, entity, country, site)}" @click="check(entity, country, site, '*ALL*')"></span>
                                <span class='label' @click='openArbo("store", entity, country, site)'
                                      :class="{'label-open': arbo[entity].site.find(item => item.code === country.code).sites.find(item => item.id === site.id).open}">{{site.name}}</span>
                                <ul class='filter-deviceItems' :class=" {'filter-deviceItems-open': arbo[entity].site.find(item => item.code === country.code).sites.find(item => item.id === site.id).open}">
                                    <li class='filter-deviceItem' v-for="device in arbo[entity].site.find(item => item.code === country.code).sites.find(item => item.id === site.id).devices">
                                        <span class="check" :class="{ checkActive: device.checked}" @click="check(entity, country, site, device)"></span>
                                        <span>{{device.id}}</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
  </div>
</template>

<script>
import Vue from 'vue';
import firmwareService from "@/service/admin/firmware/firmware-service";

export default {
	data : function(){
		return {
            entities : ["retail"]
        }
	},
	props: {
        arbo: {
            type: Object
        }
    },
	methods: {
        openArbo: function(type, entity, country, store) {
            switch (type) {
                case "country":
                    const countryObj = this.arbo[entity].site.find(item => item.code === country.code);
                    Vue.set(countryObj, 'open', !countryObj.open);
                    break;
                case "store":
                    const storeObj = this.arbo[entity].site.find(item => item.code === country.code).sites.find(item => item.id === store.id);
                    Vue.set(storeObj, 'open', !storeObj.open);
                    break;
            }
        },
		check : function(entity, country, store, device) {
            let _entity, _country, _store, _device;
            if (entity !== '*ALL*') _entity = this.arbo[entity];
            if (country !== '*ALL*') _country = this.arbo[entity].site.find(item => item.code === country.code);
            if (store !== '*ALL*') _store = this.arbo[entity].site.find(item => item.code === country.code).sites.find(item => item.id === store.id);
            if (device !== '*ALL*') _device = this.arbo[entity].site.find(item => item.code === country.code).sites.find(item => item.id === store.id).devices.find(item => item.id === device.id);

            if(entity !== '*ALL*' && country === '*ALL*' && store === '*ALL*' && device === '*ALL*'){
				let toCheck = !_entity.checked;
                _entity.checked = toCheck;
                _entity.site.forEach(country => {
                    country.checked = toCheck;
                    country.sites.forEach(store => {
                        store.checked = toCheck;
                        store.devices.forEach(device => {
                            device.checked = toCheck;
                        })
                    })
                });

			} else if(entity !== '*ALL*' && country !== '*ALL*' && store === '*ALL*' && device === '*ALL*') {
				let toCheck = !_country.checked;
				_country.checked = toCheck;
                _country.sites.forEach(store => {
                    store.checked = toCheck;
                    store.devices.forEach(device => {
                        device.checked = toCheck;
                    })
                });
                _entity.checked = _entity.site.every(country => country.checked);

            } else if(entity !== '*ALL*' && country !== '*ALL*' && store !== '*ALL*' && device === '*ALL*') {
                let toCheck = !_store.checked;
                _store.checked = toCheck;
                _store.devices.forEach(device => {
                    device.checked = toCheck;
                })
                _country.checked = _country.sites.every(store => store.checked);
                _entity.checked = _entity.site.every(country => country.checked);

            } else if(entity !== '*ALL*' && country !== '*ALL*' && store !== '*ALL*' && device !== '*ALL*') {
                _device.checked = !_device.checked;
                _store.checked = _store.devices.every(device => device.checked);
                _country.checked = _country.sites.every(store => store.checked);
                _entity.checked = _entity.site.every(country => country.checked);
            }

            this.emitArboChange(this.arbo);
        },
        isCheckPartialStore(arbo, entity, country, store) {
            const storeObj = arbo[entity].site.find(item => item.code === country.code).sites.find(item => item.id === store.id);
            return storeObj.devices.some(device => !device.checked) && storeObj.devices.some(device => device.checked);
        },
        isCheckPartialCountry(arbo, entity, country) {
            const countryToCheck = arbo[entity].site.find(item => item.code === country.code);
            return (countryToCheck.sites.some(store => !store.checked) && countryToCheck.sites.some(store => store.checked)) ||
                countryToCheck.sites.some(store => this.isCheckPartialStore(arbo, entity, country, store));
        },
        isCheckPartialEntity(arbo, entity) {
            return (arbo[entity].site.some(country => !country.checked) && arbo[entity].site.some(country => country.checked)) ||
                arbo[entity].site.some(country => this.isCheckPartialCountry(arbo, entity, country));
        },
        emitArboChange(arbo) {
            this.$emit('changeArbo', firmwareService.calculatePerimeterByArbo(arbo));
        }
	}
}
</script>

<style lang="scss">
@import '@/components/Filter/arbo.scss';
</style>
