<template>
    <v-alert class="mt-1"
            
        icon="error"
        type="error" >
        <p>{{ printError() }}</p>
    </v-alert>
</template>


<script>
export default {
    name: "no-branch-user",
    props: {
        data: {
          type: Object,
          required: true
        }
    },
    methods: {
        printError() {
            if(this.data.userChoose) {
                return "The user " + this.data.userChoose.userChoose + " is not attached to any unit organisation, he needs to contact his manager to set up his profile."
            } 
            return "You are not attached to any unit organisation, you need to contact your manager to set up your profile."
        }
    }
};

</script>
