<template>
  <header class='header'>
    <informative-header />
    <div class="center">
      <div id='feedback' @click="displayFeedback"><img src="./chat-bubble-med.png"/> Feedback</div>
    </div>
    <v-row no-gutters align="center" class="header-logo">
        <v-col>
            <dkt-logo-svg class="header-logoDecat" />
        </v-col>
        <v-col>
            <router-link to="/" exact><img src="./logoMDM.png" class="logoMDM"/></router-link>
        </v-col>
        <v-col v-if='user && !$vuetify.breakpoint.xs' >
            <vue-flag :code=user.country size='small'/>
            <span>{{user.displayName + " [" + user.uid + "]"}}</span>
            <span v-if="user.admin" class="bearer-easter-egg" title="Copy my bearer to my clipboard." @click="copyBearerIntoClipBoard"><i class="ri-lock-password-line ml-2"></i></span>
            <span v-if="user.admin" class="bearer-easter-egg" title="Copy env to my clipboard." @click="copyEnvIntoClipBoard"><i class="ri-tools-line ml-2"></i></span>
        </v-col>
    </v-row>
    <mobile-nav v-if="$vuetify.breakpoint.xs" :user="user" />
    <large-nav v-else />
    <popup-rating v-if="this.displayPopupFeedback" @close="closeFeedback" :popupFeedbackTriggerByHuman="popupFeedbackTriggerByHuman"></popup-rating>
  </header>
</template>

<script>
    import Vue from 'vue';
    import PopupRating from '../rating/popup-rating';
    import 'vue-material/dist/theme/default.css'
    import LargeNav from './navigation/large-nav';
    import MobileNav from './navigation/mobile-nav';
    import InformativeHeader from '../../components/Header/informative-header';
    import UrlConstant from "../../constant/url-constant";
    import UserConstant from "../../constant/user-constant";
    import AuthenticationService from "../../service/auth/authentication-service";
    export default {
        components: {
            "large-nav": LargeNav,
            "mobile-nav": MobileNav,
            "popup-rating": PopupRating,
            InformativeHeader
        },
        data: function () {
            return {
                popin: null,
                displayPopupFeedback:false,
                popupFeedbackTriggerByHuman:false,
                drawer: false,
            }
        },
        asyncComputed: {
            user: function () {
                return Vue.http.get(`${this.$store.state.urlUser}`).then((response) => {
                    this.$store.commit('setUser', response.body.user);
                    var userInfo = response.body.user;
                    localStorage.setItem('userPreferences', JSON.stringify(userInfo.userPreferences));

                    const userObj = {
                        'displayName': userInfo.displayName,
                        'country': userInfo.country.toLowerCase(),
                        'countries': userInfo.countries,
                        'uid': userInfo.uid,
                        'admin': userInfo.admin,
                        'cp': userInfo.cp,
                        'cc': userInfo.cc,
                        'appManager': userInfo.appManager,
                        'userPreferences': userInfo.userPreferences,
                        'hasWholeArbo' : userInfo.hasWholeArbo
                    };

                    this.$ga.set("dimension1", userObj.uid + " - " + userObj.displayName);
                    this.$ga.set("dimension2", userObj.country);

                    return userObj;
                }, (error) => {
                    if(error?.status >= 500){
                        this.$router.push(UrlConstant.userNotRetrievedOrUnauthorizedSuffix);
                    }
                    console.log(error);
                });
            }
        },
        mounted: function () {
            window.addEventListener('scroll', this.handleScroll);
        },
        watch: {
            user() {
                const user = this.$store.state.user;
                if(user.uid !== UserConstant.INITIAL_USER_UID_IN_VUEX && !user.admin && !user.appManager && !user.cp && !user.cc && this.$route.path === UrlConstant.homePageSuffix){
                    this.$router.push(UrlConstant.rgpdSuffix);
                }
                else if(user.uid !== UserConstant.INITIAL_USER_UID_IN_VUEX && !user.admin && !user.appManager && !user.cp && !user.cc && this.$route.path !== UrlConstant.rgpdSuffix) {
                    this.$router.push(UrlConstant.userNotRetrievedOrUnauthorizedSuffix);
                }
            }
        },
        methods: {
            displayFeedback(event) {
                this.popupFeedbackTriggerByHuman=event.isTrusted;
                this.displayPopupFeedback=true;
            },
            copySomethingIntoClipBoard(callback){
                try {
                    navigator.clipboard.writeText(callback());
                }catch(e){
                    console.error("Could not copy the content into clipboard.", e);
                }
            },



            copyEnvIntoClipBoard(){
                this.copySomethingIntoClipBoard(() => {
                    const keyValuePairs = [];
                    const obj = process.env;
                    for (const key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            const value = obj[key];
                            keyValuePairs.push(`${key}=${value}`);
                        }
                    }
                    return JSON.stringify(process.env) + "\n\n" + keyValuePairs.join('\n');
                })
            },
            copyBearerIntoClipBoard(){
                this.copySomethingIntoClipBoard(() => "Bearer " + AuthenticationService.getSingleInstance().getAccessToken());
            },
            closeFeedback() {
                this.displayPopupFeedback=false;
            },
            handleScroll: function () {
                if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0 || window.pageYOffset > 0) {
                    document.getElementById("feedback").style.display = "none";
                } else {
                    document.getElementById("feedback").style.display = "inline";
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import './scss/header';

    .header-logoDecat {
        margin: auto;
        width: 121px;
        height: 30px;
    }

    .header-logo {
        line-height: 50px;
    }

    .header-logo img {
        vertical-align: middle;
    }

    #feedback img {
        display: initial
    }

    .bearer-easter-egg{
        opacity: 0;
        &:hover{
            opacity: 100%;
            cursor: pointer;
        }
        &:active{
            opacity: 100%;
            cursor: copy;
        }
    }

    #feedback {
        background-color: #3643BA;
    }

</style>
