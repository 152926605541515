
const state = () => ({
    models: null
});


const getters = {
    getModels(state) {
        return state.models;
    }
};

const actions = {
    setModels({commit}, models) {
        commit('setGroup', models);
    }
};

const mutations = {
    setGroup(state, models) {
        state.models = {...models};
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
