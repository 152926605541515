<template>
    <div>
        <span class="text-capitalize">{{getKeyModel()}}</span>

        <div class="vtmn-text-input_container">

            <input v-model.trim="webview[getKeyModel()]"
                   v-on:input="$v.webview[getKeyModel()].$touch"
                   type="text"
                   class="input-fill-all-width vtmn-text-input webview-input-style my-0"
                   :class="{'vtmn-text-input vtmn-text-input--error': $v.webview[getKeyModel()].$error}"
                   :id="'device-webview-' + columnId + '-' + appName"
                   :placeholder="getKeyModel()"
                   :disabled="isChangingWebviewUrl"/>

            <p :class="{ 'mdm-visibility-hidden' : !$v.webview[getKeyModel()].$invalid, 'danger' : $v.webview[getKeyModel()].$invalid }">
                <i class="ri-error-warning-line" /> {{ errorMessage }}
                <md-tooltip md-direction="top">{{ errorMessage }}</md-tooltip>
            </p>
        </div>
    </div>
</template>

<script>

    import {matchUrl, mustNotContainsRegex, mustNotContainsBlacklistedUrl} from '../../../utils/form-validator-utils';
    import {required, url} from 'vuelidate/lib/validators';
    import { getWebviewUrlErrorMessage } from '../../../utils/error-message-utils';
    import { getWebviewNameErrorMessage } from '../../../utils/error-message-utils';
    import {minLength, maxLength} from "vuelidate/lib/validators";
    import WebviewConstant from "../../../constant/webview-constant";



    export default {
    name: 'deviceAppTableInputEditWebview',
    validations: {
        webview: {
            name: {
                required,
                minLengthValue: minLength(WebviewConstant.NAME.MIN_LENGTH),
                maxLengthValue: maxLength(WebviewConstant.NAME.MAX_LENGTH)
            },
            url: {
                required,
                url, matchUrl,
                mustNotContainsBlacklistedUrl
            }
        }
    },
    computed: {
        errorMessage() {
            if (this.columnId === 'deploymentGroup'){
                return getWebviewUrlErrorMessage(this.webview.url);
            }
            else if (this.columnId === 'name') {
                return getWebviewNameErrorMessage(this.webview.name);
            }
        }
    },
    methods : {
        getKeyModel(){
            return this.columnId === 'deploymentGroup' ? 'url' : this.columnId;
        }
    },
    props: {
        appName: String,
        columnId: String,
        webview: Object,
        isChangingWebviewUrl: Boolean
    }
  }
</script>

<style lang="scss" scoped>
  .webview-input-style{
      font-size: small;
      padding-top: 5px;
      padding-bottom: 5px;
  }
</style>
