import { Terminal } from 'xterm';
import 'xterm/dist/xterm.css'

/**
 * documentation on https://xtermjs.org/
 */
export class MdmTerminal {



  constructor() {
    this.terminal = new Terminal();
    this.promptIsDisplay = false;
  }

  runTerminal(welcomeMsg="", terminalDoAction) {
    let _this= this;


    let terminalObject = document.getElementById('terminal');

    this.terminal.open(terminalObject);
    this.terminal.focus()

    if (this.terminal._initialized) {
      return;
    }

    this.terminal.prompt = () => {
      _this.promptIsDisplay=true;
      this.terminal.write('$ ');
    };

    this.terminal.writeln(welcomeMsg);
    this.terminal.writeln('');
    this.terminal.prompt();


    this.terminal._initialized = true;


    let command = "";

    this.terminal.on('key', function(key, ev) {
      if (ev.keyCode === 13) { // enter
        _this.promptIsDisplay=false;
        _this.terminal.write("\r\n")
        if(command == "") {
          _this.terminal.prompt()
        } else {
          terminalDoAction(command)

          setTimeout(function() { // timeout 10s, if no response, display on erreur
            if(! _this.promptIsDisplay) {
              _this.printData("Response timeout. Please, verify that the device is connected");
            }
          }, 10000)

        }
        command = ""
      } else if (ev.keyCode === 8) { // delete
        // Do not delete the prompt
        if (_this.terminal._core.buffer.x > 2) {
          _this.terminal.write('\b \b');
          command = command.substr(0,command.length - 1
          )
        }

      }
    });

    this.terminal.on('data', function(data) {
      let asciiCode = _this.stringToBytes(data)[0]
      let isPrintable =  asciiCode >= 31 && asciiCode <= 126

      if( isPrintable ) {
        _this.terminal.write(data)
        command += data
      }
    })
  }


  clear() {
    this.deleteLastLine();
    this.terminal.clear()
    this.terminal.prompt();
  }


  deleteLastLine() {
    this.terminal.write('\x1b[2K\r') // delete last line
  }

  printData(data) {
    if(this.promptIsDisplay) {
      this.deleteLastLine();
      this.promptIsDisplay=false;
    }
    this.terminal.writeln(data) // delete last line
    this.terminal.prompt();
  }


  stringToBytes(str) {
    var ch, st, re = [];
    for (var i = 0; i < str.length; i++ ) {
      ch = str.charCodeAt(i);  // get char
      st = [];                 // set up "stack"
      do {
        st.push( ch & 0xFF );  // push byte to stack
        ch = ch >> 8;          // shift value down by 1 byte
      }
      while ( ch );
      // add stack contents to result
      // done because chars have "wrong" endianness
      re = re.concat( st.reverse() );
    }
    // return an array of bytes
    return re;
  }

}
