<template>

    <div v-if="webview">

        <v-row>

            <v-col cols="12" sm="12">
                <div>{{getDisplayLabel()}}</div>
            </v-col>

            <v-radio-group v-model="webview.display" required style="width: max-content;">
                <v-layout row wrap class="v-layout-text-center">
                    <v-flex xs12 sm4>
                        <v-radio :class="webview.packageName ? 'btn-upload-disabled' : ''" class="mb-3" :label="`Full screen`" :value="'FULL_SCREEN'"></v-radio>
                        <img :src="require('../../../../assets/icons/fullscreen.png')" width="90">
                        <div class="caption">
                            Web app will use the entire screen
                        </div>
                    </v-flex>
                    <v-flex xs12 sm4>
                        <v-radio :class="webview.packageName ? 'btn-upload-disabled' : ''" class="mb-3" :label="`Standalone`" :value="'STANDALONE'"></v-radio>
                        <img :src="require('../../../../assets/icons/standalone.png')" width="90">
                        <div class="caption">
                            Web app shows the phone's navigation and status bars
                        </div>
                    </v-flex>
                    <v-flex xs12 sm4>
                        <v-radio :class="webview.packageName ? 'btn-upload-disabled' : ''" class="mb-3" :label="`Minimal UI`" :value="'MINIMAL_UI'"></v-radio>
                        <img :src="require('../../../../assets/icons/minimalui.png')" width="90">
                        <div class="caption">
                            Web app shows the phone's navigation and status bars, the URL bar, and the Refresh
                            button
                        </div>
                    </v-flex>
                </v-layout>
            </v-radio-group>

            <v-col cols="6" sm="6">
                <div>{{getUploadIconLabel()}}</div>
            </v-col>
            <v-col cols="6" sm="6">
                <v-btn :class="webview.packageName ? 'btn-upload-disabled' : ''" elevation="2" @click="$refs.searchIconInput.click()">Upload Icon</v-btn>
                <input ref="searchIconInput" type="file" accept="image/png, image/jpeg" id="web-app-add-icon-input" @change='checkIcon' />

            </v-col>
            <v-col cols="12" sm="12">
                <div>
                    Icons should be 512px square, png or jpeg.
                    Your app title and image must follow the <span class="font-weight-bold">Google Play Developer Program Policies</span>
                </div>
            </v-col>

            <v-col cols="12" class="text-right">
                <button class="vtmn-btn vtmn-btn_variant--secondary-reversed mdm-vtmn-custom-blue-borders" id="web-app-add-button-validate"
                        :disabled="$v.$invalid"
                        @click="submit">Validate</button>
            </v-col>

        </v-row>

    </div>

</template>

<script type="text/javascript">

    import Vue from 'vue';
    import Vuelidate from 'vuelidate';
    import {required, url} from 'vuelidate/lib/validators'
    import {matchUrl} from '../../../../utils/form-validator-utils';
    import fileUtils from '../../../../utils/file-utils';

    export default {
        computed: {
            webview() {
                return this.$store.state.webview.webview;
            }
        },
        validations: {
            webview: {
                display: {
                    required
                },
                logo : {
                    required: false
                }
            }
        },
        methods: {
            getUploadIconLabel(){
                return 'Icon ' + (this.webview.packageName ? '(not modifiable)':'')
            },
            getDisplayLabel(){
                return 'Display * ' + (this.webview.packageName ? '(not modifiable)':'')
            },
            submit(){
                if (!this.$v.$invalid) {
                    this.$store.dispatch('webview/setWebview', this.webview);
                    this.$emit('onSubmit');
                }
            },

            async checkIcon(value){
                const GENERAL_ERROR_MESSAGE = "Error : the icon could not be read properly.";
                const SIZE_ERROR_MESSAGE = "File size is greater than 10MB. Upload is impossible.";

                const path = value.target.value;

                if(path === '') {
                    return;
                }

                if (value.target.files[0].size > 10000000) {
                    this.displayUploadError(SIZE_ERROR_MESSAGE);
                    return;
                }

                try {
                    const iconBase64 = await fileUtils.getBase64(value.target.files[0]);
                    this.$store.dispatch('webview/setWebview', {...this.webview, logo: iconBase64})
                } catch(e){
                    this.displayUploadError(GENERAL_ERROR_MESSAGE);
                    console.error(GENERAL_ERROR_MESSAGE, e);
                }

            },

        }
    }

</script>

<style lang="scss" >
    .mdm-vtmn-custom-blue-borders{
        box-shadow: inset 0px 0px 0px 2px var(--vtmn-color_brand) !important;
        color: var(--vtmn-color_brand) !important;
        margin-top: 1em;
    }

    #web-app-add-icon-input {
        display: none;
    }

    .btn-upload-disabled{
        cursor: not-allowed;
        pointer-events: none;
        color: var(--vtmn-color_grey-light-1) !important;
    }

</style>
