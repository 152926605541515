<template>
    <div v-if="webview">
        <v-row>
            <v-col cols="12" sm="12">
                <mdm-input  :val.sync="webview.name"
                           @touched="$v.webview.name.$touch"
                           :isError="$v.webview.name.$error"
                            name='Title *'
                            placeholder="Title of the app"
                            :error-message=titleErrorMessage
                            :id="'web-app-add-input-title'"
                />
            </v-col>
            <v-col cols="12" sm="12">
                <mdm-input :val.sync="webview.url"
                           @touched="$v.webview.url.$touch"
                           :isError="$v.webview.url.$error"
                           :name="getUrlLabel()"
                           placeholder="https://"
                           :error-message="urlErrorMessage"
                           :id="'web-app-add-input-url'"
                           :read-only="$route.params.idApplication"
                />
            </v-col>
            <v-col cols="12" class="text-right">
                <button class="vtmn-btn vtmn-btn_variant--secondary-reversed mdm-vtmn-custom-blue-borders"
                        id="web-app-add-button-validate"
                        :disabled="$v.$invalid"
                        @click="submit">Validate
                </button>
            </v-col>
        </v-row>
    </div>
</template>

<script type="text/javascript">
    import {required, url} from 'vuelidate/lib/validators'
    import { mustNotContainsBlacklistedUrl, mustNotContainsRegex, matchUrl } from '../../../../utils/form-validator-utils';
    import {getWebviewUrlErrorMessage, getWebviewNameErrorMessage } from '../../../../utils/error-message-utils';
    import MdmInput from '../../../../components/mdm/vitamine/input/mdm-input';
    import {minLength, maxLength} from "vuelidate/lib/validators";
    import WebviewConstant from "../../../../constant/webview-constant"

    export default {
        components: {
          MdmInput
        },
        computed: {
            webview() {
                return this.$store.state.webview.webview;
            },
            urlErrorMessage() {
                return getWebviewUrlErrorMessage(this.webview.url);
            },
            titleErrorMessage() {
                return getWebviewNameErrorMessage(this.webview.name)
            }
        },
        validations: {
            webview: {
                name: {
                    required,
                    minLengthValue: minLength(WebviewConstant.NAME.MIN_LENGTH),
                    maxLengthValue: maxLength(WebviewConstant.NAME.MAX_LENGTH)
                },
                url: {
                    required,
                    url, matchUrl,
                    mustNotContainsBlacklistedUrl
                }
            }
        },
        methods: {
            submit() {
                if (!this.$v.$invalid) {
                    this.$store.dispatch('webview/setWebview', this.webview);
                    this.$emit('onSubmit');
                }
            },
            getUrlLabel(){
                return 'URL * ' + (this.$route.params.idApplication ? '(not modifiable)' : '');
            }
        }
    }

</script>

<style lang="scss">

    .msg-vertical-align {
        vertical-align: bottom;
    }

    .input-fill-all-width {
        width: -webkit-fill-available;
    }

    #web-app-add-icon-input {
        display: none;
    }

</style>
