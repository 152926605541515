export default {
    COUNTRIES : {
        DEFAULT: "FR",
        CN: "China",
        FR: "France"
    },
    TIMEZONES : {
        "Asia/Shanghai": {
            u: 480,
            c: ["CN"]
        }
        , "Asia/Urumqi": {
            u: 360,
            c: ["CN"]
        }
    }
}
