const GenericStep = class {
    constructor(steppers, nextStepCallBack, externalDataInStepCallBack = () => {}) {
        this.startStepper = [...steppers];
        this.setStartData(steppers);
        this.nextStepCallBack = nextStepCallBack;
        this.externalDataInStepCallBack = externalDataInStepCallBack;
        this.extraData = {};
    }

    setStartData(steppers) {
        this.steppers = steppers;
        this.currentStep = steppers[0].id;
        this.isFinishedParcours = Boolean(steppers[steppers.length - 1].id);
    }

    getCurrentParcours() {
        const steppers = [...this.steppers];

        return this.steppers.map(step => {
            let data = this.externalDataInStepCallBack(steppers, step, this.extraData);

            data = {...step.data, ...data}

            return {
                ...step, data,
                description: !step.description ? "" : step.description(this.steppers),
                selected: (data) => this.setDataInCurrentStep(data)
            }
        });
    }

    setDataInCurrentStep(data) {
        let step = findStepFromStepId(this.steppers, this.currentStep);
        step.data = data;
    }

    /**
     * If the parcours is finish: do nothing
     * If the parcours is known, return the next step
     * If the parcours is not known, call the client callBack: (steppers, currentStep, dataFromCurrentStep) => {steppers, currentStep}
     */
    async nextStep() {
        if(this.isFinishedParcours) {
            return {
                currentSteppers: this.getCurrentParcours(),
                currentStep: this.currentStep,
                isFinishedParcours: this.isFinishedParcours
            };
        }
        const data = findStepFromStepId(this.steppers, this.currentStep).data;
        let currentSteppers = [...this.steppers];

        if(currentSteppers[currentSteppers.length - 1].id) { // The parcours is known

            const nextStep   = getNextStep(currentSteppers, this.currentStep);
            this.currentStep = nextStep.id;
            this.isFinishedParcours  = nextStep.isFinished;
        } else {
            const clientNextStep = await this.nextStepCallBack(currentSteppers, this.currentStep, data);
            this.steppers    = clientNextStep.steppers;
            this.currentStep = clientNextStep.currentStep;
            this.extraData = { ...this.extraData, ...clientNextStep.extraData}
        }
        return {
            currentStep: this.currentStep,
            currentSteppers: this.getCurrentParcours()
        };
    }

    /**
     * Recompute integrality of the parcours since found stepId.
     */
    async goBackTo(stepId) { // Can be improved
        const oldSteppers = [...this.steppers];
        this.removeDataFromSpecificStep(oldSteppers, stepId);
        this.setStartData(this.startStepper);
        let currentStep = findStepFromStepId(oldSteppers, this.currentStep);
        while(currentStep.id !== stepId) {
            this.setDataInCurrentStep(currentStep.data);
            await this.nextStep();
            currentStep = findStepFromStepId(oldSteppers, this.currentStep);
        }
        return {
            currentStep: this.currentStep,
            currentSteppers: this.getCurrentParcours()
        }
    }

    removeDataFromSpecificStep(steppers, currentStep){
        let isFutureStep = false;
        for(let i = 0; i < steppers.length; i++){
            // on repère la step courante
            if(steppers[i].id && (steppers[i].id === currentStep)){
                // on repère la step courante
                isFutureStep = true;
            }
            if(isFutureStep){
                // on retire les datas des steps futures et on retire l'affichage
                steppers[i].data = undefined;
                steppers[i].mdDone = false;
            }else{
                // et on remet l'affichage pour les steps d'avant
                steppers[i].mdDone = true;
            }
        }
    }
}

export const findStepFromStepId = (steppers, stepId) => {
    return steppers.find((step) => step.id === stepId) || {}
}

export const getNextStep = (steppers, currentStep) => {
    let nextStep = steppers[steppers.findIndex((step) => step.id === currentStep) + 1];
    if(!nextStep) throw new Error("No more step");
    return {
        id: nextStep.id,
        isFinished: !Boolean(steppers[steppers.findIndex((step) => step.id === currentStep) + 2])
    }
}

export default GenericStep;
