export default {
    BU: {
        RETAIL: 'RETAIL',
        SERVICES: 'SERVICES',
    },
    DEFAULT_GROUP_NAME: "All devices in BU Services",
    DEFAULT_SERVICES_GROUP : [
        {
            by: "",
            prio: 0,
            name: "BU Services group",
            usedVersion: "",
            activated: false,
            countries: [

            ],
            status: "NO_PRIO",
            userCountry: "",
            androidTrackId: "production",
            appVersionCode: "",
            bu: "SERVICES"
        }

    ]

}
