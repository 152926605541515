import Vue from 'vue';
import {store} from '../../store/store';

export default {
    async getManufacturers() {

        const response = await Vue.http.post(store.state.urlDeviceManufacturers, {model :[], manufacturer : [], arbo : {}});
        const manufacturers = response.body.data.map(manufacturer => manufacturer.manufacturer);

        let manufacturersToLowerCase = [];
        let manufacturersToReturn = [];

        manufacturers.forEach(manufacturer => {
            manufacturersToLowerCase.push(manufacturer.toLowerCase());
        });

        manufacturersToLowerCase.forEach(manufacturer => {
            if(!manufacturersToReturn.includes(manufacturer.charAt(0).toUpperCase() + manufacturer.slice(1))) {
                manufacturersToReturn.push(manufacturer.charAt(0).toUpperCase() + manufacturer.slice(1));
            }
        });
        return manufacturersToReturn;
    }
};
