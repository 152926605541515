<template>
    <div>
        <v-layout id="devices-new-device-label-enrollment" class="content-title">{{message.split(',')[0]}}</v-layout>
        <v-layout id="devices-new-device-label-enrollment" class="content-title">{{message.split(',')[1]}}</v-layout>
        <v-layout id="devices-new-device-label-enrollment" class="content-title">You can follow <a target="_blank" :href="link">this link</a> to register your device</v-layout>
        <div class="logo">

            <component :is="computedComponentLogoName"  />

        </div>
    </div>
</template>

<script>
import GoogleEndpointIcon from "@/components/app-icon/google-endpoint-icon.vue";
import Ds2Icon from "@/components/app-icon/ds2-icon";
import WsoIcon from "@/components/app-icon/wso-png.vue";
export default {
    name: "redirection-enrollment",
    components: {Ds2Icon, GoogleEndpointIcon, WsoIcon},

    computed: {
        computedComponentLogoName() {
            return `${this.logo}-icon`;
        }
    },

    props: {
        message: String,
        link: String,
        logo: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped>
.content-title {
    font-style: italic;
    color: grey;
    font-size: 1.5em;
    text-align: center;
    width: 100%;
    display: inline-block;
}

.logo{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
</style>
