<template>
    <span>
        <svg width="106" height="100" viewBox="0 0 106 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.8631 99.4919C69.4968 99.4919 89.4663 95.7605 89.4663 91.1577C89.4663 86.5548 69.4968 82.8235 44.8631 82.8235C20.2293 82.8235 0.259766 86.5548 0.259766 91.1577C0.259766 95.7605 20.2293 99.4919 44.8631 99.4919Z" fill="#F5F5F7" fill-opacity="0.8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M80.5451 66.6737L64.805 47.3019C64.0497 46.3901 62.9458 45.8381 61.7833 45.8381H27.9412C26.7793 45.8381 25.6754 46.3901 24.9201 47.3019L9.18066 66.6737V76.7941H80.5458V66.6737H80.5451Z" fill="#AEB8C2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5163 20.8354H67.2091C67.907 20.8354 68.5764 21.1127 69.0699 21.6062C69.5634 22.0997 69.8407 22.7691 69.8407 23.467V84.8775C69.8407 85.5755 69.5634 86.2448 69.0699 86.7383C68.5764 87.2319 67.907 87.5091 67.2091 87.5091H22.5163C21.8184 87.5091 21.1491 87.2319 20.6555 86.7383C20.162 86.2448 19.8848 85.5755 19.8848 84.8775V23.467C19.8848 22.7691 20.162 22.0997 20.6555 21.6062C21.1491 21.1127 21.8184 20.8354 22.5163 20.8354V20.8354Z" fill="#F5F5F7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3374 27.3835H61.3881C61.737 27.3835 62.0717 27.5222 62.3185 27.7689C62.5652 28.0157 62.7039 28.3504 62.7039 28.6993V45.1184C62.7039 45.4674 62.5652 45.8021 62.3185 46.0488C62.0717 46.2956 61.737 46.4342 61.3881 46.4342H28.3374C27.9884 46.4342 27.6538 46.2956 27.407 46.0488C27.1603 45.8021 27.0216 45.4674 27.0216 45.1184V28.6993C27.0216 28.3504 27.1603 28.0157 27.407 27.7689C27.6538 27.5222 27.9884 27.3835 28.3374 27.3835ZM28.5098 53.577H61.2157C61.6104 53.577 61.9889 53.7338 62.268 54.0128C62.5471 54.2919 62.7039 54.6704 62.7039 55.0651C62.7039 55.4598 62.5471 55.8383 62.268 56.1174C61.9889 56.3965 61.6104 56.5533 61.2157 56.5533H28.5098C28.1151 56.5533 27.7366 56.3965 27.4575 56.1174C27.1784 55.8383 27.0216 55.4598 27.0216 55.0651C27.0216 54.6704 27.1784 54.2919 27.4575 54.0128C27.7366 53.7338 28.1151 53.577 28.5098 53.577ZM28.5098 61.3158H61.2157C61.6105 61.3158 61.9891 61.4726 62.2682 61.7518C62.5474 62.0309 62.7042 62.4095 62.7042 62.8043C62.7042 63.199 62.5474 63.5776 62.2682 63.8568C61.9891 64.1359 61.6105 64.2928 61.2157 64.2928H28.5098C28.115 64.2928 27.7364 64.1359 27.4573 63.8568C27.1781 63.5776 27.0213 63.199 27.0213 62.8043C27.0213 62.4095 27.1781 62.0309 27.4573 61.7518C27.7364 61.4726 28.115 61.3158 28.5098 61.3158ZM80.3999 89.9355C79.8901 91.9559 78.0993 93.4618 75.969 93.4618H13.7565C11.6262 93.4618 9.83544 91.9553 9.32623 89.9355C9.22911 89.5505 9.18005 89.1549 9.18018 88.7579V66.6743H26.4946C28.4072 66.6743 29.9486 68.2849 29.9486 70.2401V70.2664C29.9486 72.221 31.5078 73.7993 33.4203 73.7993H56.3052C58.2177 73.7993 59.7769 72.2066 59.7769 70.2513V70.2434C59.7769 68.2881 61.3183 66.6737 63.2308 66.6737H80.5453V88.7585C80.5453 89.1645 80.4947 89.5585 80.3999 89.9355Z" fill="#DCE0E6"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M82.5764 21.9026L78.083 23.6461C77.9676 23.6909 77.8418 23.7023 77.7202 23.6789C77.5986 23.6555 77.486 23.5982 77.3955 23.5137C77.305 23.4292 77.2402 23.3208 77.2085 23.2011C77.1769 23.0813 77.1796 22.9551 77.2165 22.8368L78.4909 18.7533C76.7876 16.8164 75.7876 14.4546 75.7876 11.9059C75.7876 5.33026 82.4442 0 90.656 0C98.8659 0 105.523 5.33026 105.523 11.9059C105.523 18.4816 98.8665 23.8118 90.6554 23.8118C87.6764 23.8118 84.9027 23.1105 82.5764 21.9026Z" fill="#DCE0E6"/>
            <path d="M96.5125 14.056C97.5477 14.056 98.3869 13.2268 98.3869 12.204C98.3869 11.1812 97.5477 10.3521 96.5125 10.3521C95.4774 10.3521 94.6382 11.1812 94.6382 12.204C94.6382 13.2268 95.4774 14.056 96.5125 14.056Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M86.673 13.8245H82.9243L84.8309 10.5837L86.673 13.8245ZM89.0158 10.5837H92.2954V13.8245H89.0158V10.5837Z" fill="white"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: "NoData",
    props: {
        fillColor: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">

</style>
