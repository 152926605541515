<template>
    <div class="content">

        <div class="title">Select data to display in the devices table</div>
        <v-container fluid>
            <v-row justify="center">
                    <div v-for="category in listOfCategories">
                        <div class="category" >{{category}}</div>
                        <div v-for="header in getListOfHeadersByCategory(category, listOfHeaders)">
                            <v-checkbox v-model="selected"
                                        :label="header.text"
                                        :value="header.value"
                                        :disabled="!activeApplicationFilter && header.category === 'Application'"
                            ></v-checkbox>
                            <span v-if="!activeApplicationFilter && header.category === 'Application'" class="warning-application-category">
                                A filter on the application must be active
                            </span>
                        </div>
                    </div>
            </v-row>
        </v-container>

        <v-container>
            <v-row class="justify-end">
                <mdm-vtmn-button variant="primary-reversed"  @click="resetFilters">Reset</mdm-vtmn-button>
                <mdm-vtmn-button class="btn-center" id="devices-valid-columns" @click="$emit('getHeaders', selected, listOfHeaders)">Validate filters</mdm-vtmn-button>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import DeviceSearchConstants from "../../../../src/utils/device-search-constant";

export default {
    name: "device-select-data-to-display",
    props: {
        savedHeaders: Array,
        activeApplicationFilter: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            selected: [],
            listOfHeaders: DeviceSearchConstants.HEADERS,
            listOfCategories : this.getListOfCategories()
        };
    },
    mounted () {
        this.selected = this.getHeaders();
    },
    methods: {
        getHeaders() {
            if(this.savedHeaders?.length > 0) {
                return this.savedHeaders
            } else {
                let defaultSelectedHeaders = this.getDefaultSelectedHeaders()
                return defaultSelectedHeaders;
            }
        },
        resetFilters() {
            this.selected = this.getDefaultSelectedHeaders()
            return this.selected
        },
        getListOfCategories() {
            let listOfCategories = [];
            DeviceSearchConstants.HEADERS.forEach(header => {
                if(header.category && !listOfCategories.includes(header.category)) listOfCategories.push(header.category)
            })
            return listOfCategories
        },
        getListOfHeadersByCategory(category, listOfHeaders) {
            return listOfHeaders.filter(header => header.category === category)
        },
        getDefaultSelectedHeaders() {
            return DeviceSearchConstants.HEADERS.filter(header => header.selected === true).map(header => header.value);
        }
    }
};
</script>


<style scoped>

.content {
    width: 100%;
}

.category {
    margin-top: 20px;
    font-weight: bold;
    font-size: large;
}

.title {
    display: flex;
    justify-content: center;
    font-size: large;
    font-weight: bold;
}

.vtmn-btn_variant--primary-reversed {
    color: #007dbc;
    font-size: 1rem;
    letter-spacing: 0.03125rem;
    font-weight: 700;
    margin-right: 30px;
    margin-bottom: 30px;
}

.vtmn-btn {
    margin-bottom: 30px;
}

.container {
    margin-bottom: 0;
}

.warning-application-category {
    color: red
}

</style>
