<template>
    <div class="mdm-autocomplete mb-5">
        <label class="vtmn-text-input_label" :for="id">
            {{ label }}
        </label>

        <div class="vtmn-text-input_container" :class="variant === VARIANT_SEARCH_DEVICE ? 'div-icon-search' : ''">
            <div :id="id" :class="classes.classForBar" class="vtmn-text-input full-size mb-0 multi-select-input-area" >
                <div v-for="item of actualSelectedItems" :key="printedItem(item)" class="actual-selected-item"
                    :class="itemError(item) + ' ' + classes.classForChips"
                    @click="removeItem($event, item)">
                        {{printedItem(item)}}

                        <md-icon class="actual-selected-item-btn-remove" :class="classes.classForChipsIcons"
                                :id="'actual-selected-item-btn-remove-' + printedId(item)" >
                            close
                        </md-icon>
                </div>

                <input
                    v-model="searchTerms"
                    type="text"
                    :placeholder="variant === VARIANT_SEARCH_DEVICE && actualSelectedItems.length > 0 ? '' : placeholder"
                    @keyup.enter="select"
                    @blur="select(true)"
                    :class="classes.classForBar"
                    @click="display"
                    ref="inputMultiChoice" />

                    <i v-if="variant === VARIANT_SEARCH_DEVICE" @click="select" class="ri-search-line icon-search" />

            </div>
        </div>

        <p v-if="criteriaError" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon><slot name="error-text-invalid-criteria" />
        </p>
        <p v-if="alreadySelectedError" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon><slot name="error-text-already-selected" />
        </p>
        <p v-if="hasToCheckNumericType && notANumber" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon> {{Constants.DEVICE.SEARCH.ONLY_ALLOWED_NUMBER}}
        </p>
        <p v-if="notFoundOnApiError.length > 0" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon><slot name="error-text-not-found" v-bind:removeAllNotFoundItem="removeAllNotFoundItem"/>
        </p>
    </div>
</template>

<script>
    import FieldConstant from '../../../../utils/field-constant';
    import StringUtils from '../../../../utils/string-utils';
    import Constants from '../../../../constant/constants';

    export default {
        name: 'mdm-multi-choice',
        props: {
            id: {
                type: String
            },
            label: {
                type: String,
                required: true
            },
            placeholder: {
                type: String
            },
            value: {
                type: Array
            },
            isNumber: {
                type: Boolean,
                default: false
            },
            findItemInApi: {
                type: Function,
                required: false
            },
            validCriteriaCallback: {
                type: Function,
                required: false
            },
            variant : {
                type: String,
                default: FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.CHECK_DEVICE,
                validator: (value) => [
                    FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.CHECK_DEVICE,
                    FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.SEARCH_DEVICE
                ].includes(value.toUpperCase())
            },
            hasToCheckNumericType : {
                type: Boolean,
                default: true
            },
            displayDetailedSearch : {
                type: Boolean,
                default: true
            },
            isFocus : {
                Boolean,
                default: false
            },
            keyToPrint: {
                type: String,
                required: false
            },
            mapSearchTerm: {
                type: Function,
                default: (item) => item
            },
            alreadySelectedError: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                actualSelectedItems: this.value || new Array(),
                searchTerms: '',
                notFoundOnApiError: [],
                criteriaError: false,
                notANumber: false,
                isNumberRegex: /^[0-9]+$/,
                VARIANT_SEARCH_DEVICE : FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.SEARCH_DEVICE,
                VARIANT_CHECK_DEVICE :  FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.CHECK_DEVICE,
                classes: {
                    classForChips : this.variant === FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.SEARCH_DEVICE ? 'input-chips-class': '',
                    classForChipsIcons : this.variant === FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.SEARCH_DEVICE ? 'icon-search-bar-icon': '',
                    classForBar : this.variant === FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.SEARCH_DEVICE ? 'search-bar': '',
                },
                startSearchIfNoInputValue : this.variant === FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.SEARCH_DEVICE,
                Constants
            }
        },
        mounted: function() {
            this.actualSelectedItems?.forEach((item) => {
                this.checkIfNotFound(item);
            })
        },
        watch: {
            value(value) {
                this.actualSelectedItems = value || new Array();
            }
        },
        methods: {
            printedItem(item) {
                return this.keyToPrint ? item[this.keyToPrint] : item;
            },
            printedId(item) {
                return this.printedItem(item).replaceAll(' ', '-');
            },
            checkErrorOnSelect(item) {
                this.$emit('updateAlreadySelected', false)
                this.criteriaError = false;
                this.notANumber = false;

                if(this.printedItem(item) === "") return !this.startSearchIfNoInputValue;
                else if(this.hasToCheckNumericType && this.isNumber && !this.isNumberRegex.exec(item)) {
                    this.notANumber = true;
                    return true;
                }else if (this.validCriteriaCallback && !this.validCriteriaCallback(item) ){
                    this.criteriaError = true;
                    return true;
                } else if(this.isActualSelectedItem(item)) {
                    this.$emit('updateAlreadySelected', true)
                    return true;
                }
                return false;
            },

            select(isBlurEvent) {
                if (this.variant === this.VARIANT_SEARCH_DEVICE && isBlurEvent === true) return ;
                const actualSearchTerms = this.searchTerms;
                if(actualSearchTerms.includes(',')) {
                    const items = actualSearchTerms.split(',');
                    items.forEach(item => item && this.selectOneItem(StringUtils.trimSearchCriteria(item)));
                } else {
                    this.selectOneItem(StringUtils.trimSearchCriteria(actualSearchTerms));
                }
            },
            searchStarted(item){
                return this.startSearchIfNoInputValue && this.printedItem(item) === "" && this.$emit("startSearch", this.actualSelectedItems);
            },
            selectOneItem(item) {
                let mappedItem = this.mapSearchTerm(item);
                if (this.searchStarted(mappedItem)) return;
                if(this.checkErrorOnSelect(mappedItem)) return;
                this.checkIfNotFound(mappedItem);
                this.actualSelectedItems.push(mappedItem);
                this.$emit("selected", this.actualSelectedItems);
                this.searchTerms = "";
            },
            checkIfNotFound(item) {
                this.findItemInApi && this.findItemInApi(item).then(isFind => {
                    if(!isFind) {
                        this.notFoundOnApiError.push(item);
                        this.$emit("notFoundItem", {list: this.notFoundOnApiError, item: item});
                    }
                });
            },
            itemError(item) {
                if(this.alreadySelectedError && this.searchTerms === item || this.notFoundOnApiError.includes(item)) {
                    return  'actual-selected-item-already-select';
                }
                return '';
            },
            isActualSelectedItem(itemToCheck) {
                return this.actualSelectedItems?.some(item => !item.type ? item === itemToCheck : (itemToCheck.type !== 'custom_blank' || itemToCheck.key === item.key) && item.type === itemToCheck.type);
            },
            searchTermsIsAlreadySelected(item) {
                return this.searchTerms === item
            },
            removeItem(event, itemToRemove) {
                if(event.target.id.startsWith('actual-selected-item-btn-remove-')) {
                    this.actualSelectedItems.splice(this.actualSelectedItems.indexOf(itemToRemove), 1);
                    this.$emit("selected", this.actualSelectedItems);
                    this.notFoundOnApiError.splice(this.notFoundOnApiError.indexOf(itemToRemove), 1);
                    this.$emit("notFoundItem", {list: this.notFoundOnApiError, removedItem: itemToRemove});
                    this.$emit("reloadSecondFilterList")
                }
            },
            removeAllNotFoundItem() {
                this.notFoundOnApiError.forEach((itemNotFound) => {
                    this.actualSelectedItems.splice(this.actualSelectedItems.indexOf(itemNotFound), 1);
                });
                this.$emit("selected", this.actualSelectedItems);
                this.notFoundOnApiError = new Array();
                this.$emit("notFoundItem", {list: this.notFoundOnApiError});
            },
            async search(){
                this.$emit("startSearch", this.searchTerms);
            },
            display: function() {
                this.$emit('displayDetailedSearch', true)
                this.$emit('allowedClickOnDevice')
            },
            focus: function () {
                this.$nextTick(() => this.$refs.inputMultiChoice.focus());
            },
        }
    }
</script>

<style lang="scss">
@import './mdm-input.scss';

.input-chips-class{
    background: #3643BA;
    color: var(--vtmn-color_white);
    transform: scale(0.8);
}

.icon-search{
    position:absolute;
    font-size: 2em;
    text-align: center;
    color: var(--vtmn-color_white);
    background-color: #3643BA;
    right:0;
    width: 50px;
    height: 42px;
    bottom: 0;
    vertical-align: baseline;
    border-radius: 5px;
}

.icon-search-bar-icon{
    background: white;
    color: #3643BA;
    border-radius: 10px;
    width:10px;
    margin-left:10px;
}

.search-bar{
    min-height: 33px !important;
    padding: 5px !important;
    > .actual-selected-item{
        padding: 2px 1px 2px 10px !important;
        margin: 1px 0px 1px 0px !important;
    }
}

.div-icon-search{
    position: relative;
}

.ri-search-line:before {
    vertical-align: middle;
}

</style>
