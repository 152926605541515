<template>
    <v-layout wrap align-center>
        <v-flex xs12 text-center >
            <span class="title-step">Starting and stopping the kiosk</span>
        </v-flex>
        <v-layout row wrap align-center>
            <v-flex xs12  sm6 lg6 text-center text-sm-right>
                <el-time-select
                    :id="'mdm-starting-time-choice'"
                    v-model="rebootTime"
                    :picker-options="{
                        start: '07:00',
                        step: '00:5',
                        end: '22:00'
                    }"
                    :placeholder="'Start time (default ' + DEFAULT_REBOOT_TIME + ')'"/>
            </v-flex>
            <v-flex xs12  sm6 lg6 text-center text-sm-left>
                <el-time-select
                    :id="'mdm-end-time-choice'"
                    v-model="lockTime"
                    :picker-options="{ 
                        start: '07:00',
                        step: '00:5',
                        end: '22:00'
                    }"
                    :placeholder="'End time (default ' + DEFAULT_LOCK_TIME + ')'"/>
            </v-flex>
        </v-layout>
        <v-flex xs12 text-center >
            <md-button id="devices-new-device-btn-idle-time-save" class='md-raised md-primary' @click="choose()">
                Save <md-icon>keyboard_arrow_right</md-icon> 
            </md-button>
        </v-flex>
        

    </v-layout>
</template>


<script>
    import FieldConstant from '../../../../utils/field-constant';

    export default {
        name: "new-device-idle-choice",
        data() {
            return {
                rebootTime: '',
                lockTime: '',
                DEFAULT_REBOOT_TIME: FieldConstant.DEFAULT_REBOOT_TIME,
                DEFAULT_LOCK_TIME  : FieldConstant.DEFAULT_LOCK_TIME
            };
        },
        methods: {
            choose() {
                this.$emit('selected', {
                    rebootTime: this.splitHourMinute(this.rebootTime || this.DEFAULT_REBOOT_TIME), 
                    lockTime: this.splitHourMinute(this.lockTime   || this.DEFAULT_LOCK_TIME)
                });
            },
            splitHourMinute(hour) {
                const splitedHour = hour.split(':');

                return {
                    hour   : parseInt(splitedHour[0]),
                    minute : parseInt(splitedHour[1])
                }
            }
        }   
    }
</script>

<style lang="scss">
    .title-step {
        font-size: '2em' !important;
        color: #0082c3 !important;
    }   
</style>
