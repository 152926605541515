export default {
    DEVICE: {
        POLICY: {
            FORBIDDEN_FETCH: "You may not have right to fetch device policy, please contact your administrator."
        },
        SEARCH: {
            NO_SEARCH_RESULTS: "No results matching your criterias",
            ERROR_SEARCHING_DEVICES: "An error occurred when trying to get devices, contact your administrator or try to change your filter.",
            DUPLICATED_SEARCH_INPUT: "You can't select the same criterias two times",
            INVALID_SEARCH_INPUT: "You entered an invalid criteria",
            ONLY_ALLOWED_NUMBER: "Only the numbers IDs are accepted"
        }
    }
}
