<template>
    <div class="mdm-badge-panel" >
        <div class="mdm-badge-content" :class="hide && 'hide'">
            <p>{{content}}</p>
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'mdm-vitamine-badge',
    props: {
        content: {
            type: String,
            default: "Badge content"
        },
        hide: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>

.mdm-badge-panel {
    position: relative;

    .hide {
        display: none;
    }

    .mdm-badge-content {
        position: absolute;
        display: flex;
        width: 90%;
        justify-content: flex-end;
        top: -10px;
        p {
            position: relative;
            margin: 0;
            padding: 0px 15px;
            border-radius: 8px;
            right: 0;
            z-index: 999;

            background: linear-gradient(270deg, rgba(0,130,195,1) 0%, rgba(2,176,152,1) 70%, rgba(2,190,138,1) 100%);
            color: var(--vtmn-color_white);
        }
    }
}



</style>
