<template>
    <mdm-vtmn-popup ref="popupColumns" id="devices-button-columns" :class-button-open="classButtonOpen"  >
        <template v-slot:button-open-content >
            <md-icon>filter_alt</md-icon><span class="text-button-columns">Select data to display</span>
        </template>
        <device-select-data-to-display @getHeaders="addSelectedHeaders" :active-application-filter="activeApplicationFilter" :saved-headers="savedFiltersInSession" />
    </mdm-vtmn-popup>
</template>

<script>
import MdmVtmnPopup from "../../components/mdm/vitamine/popup/mdm-vtmn-popup";
import DeviceSelectDataToDisplay from "../../../src/pages/devices/search/device-select-data-to-display";
import DeviceSearchConstants from "../../utils/device-search-constant";
import LocalStorageService from "../../service/local-storage-service";
import LocalStorageConstant from "../../../src/constant/local-storage-constant";

export default {
    name: "device-filter-columns",
    components: {
        MdmVtmnPopup,
        DeviceSelectDataToDisplay
    },
    props: {
        activeApplicationFilter: {
            type: Boolean,
            default: false
        },
    },
    data: function() {
        return {
            classButtonOpen: "class-button-open",
            savedFiltersInSession : this.getUserLastHeadersSelected(),
            headers: []
        }
    },
    methods: {
        addSelectedHeaders(selectedHeaders, listOfHeaders) {
            let headers = [...DeviceSearchConstants.HEADERS.filter(header => header.default === true)];

            selectedHeaders.forEach(selectedHeader => {
                headers.push(listOfHeaders.find(header => {
                    return header.value === selectedHeader;
                }))
            })

            LocalStorageService.setItem(LocalStorageConstant.FILTERS_COLUMN, headers);

            this.$refs.popupColumns.close()

            this.headers = headers

            this.$emit('getDisplayedHeaders', this.headers)
        },
        getUserLastHeadersSelected() {
            let savedHeaders = JSON.parse(LocalStorageService.getItem(LocalStorageConstant.FILTERS_COLUMN))?.map(item => item.value);
            let defaultHeaderValue = (DeviceSearchConstants.HEADERS.filter(header => header.default === true)).map(header => header.value)
            let savedHeadersWithoutDefault = [];

            savedHeaders?.forEach(header => {
                if (!defaultHeaderValue.includes(header)) savedHeadersWithoutDefault.push(header)
            })
            return savedHeadersWithoutDefault

        },
    }
};
</script>

<style>
.text-button-columns {
    color: #3643BA;
    font-weight: 700;
}

</style>
