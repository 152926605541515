<template>
    <div>
        <div class="text-h6">CLEARED CACHE HISTORY</div>

        <md-progress-spinner v-if="dataLoading" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>

        <div v-else-if="dataError">

            <md-content class="md-accent">An error occurred while searching the Cache events. See the console for details.</md-content>

        </div>

        <div v-else-if="lastEvents && lastEvents.length > 0">
            <ul>
                <li class="mt-3 mb-3"v-for="item in lastEvents" :key="item.key">
                    <em class="ri-stop-mini-fill"></em> <span class="pr-5"><span class="font-weight-bold">User ID : </span>{{item.uid}}</span> {{item.date}}
                </li>
            </ul>
        </div>
        <div v-else>
            <p>No cache cleared for the moment</p>
        </div>

    </div>

</template>

<script>

import traceApiService from "./../../../service/user/trace-api-service";
import Moment from "moment";

export default {
    name: 'cache-list-component',
    props:{
      reload: {
          type: Boolean,
          default: false,
      }
    },
    data: function () {
        return {
            dataLoading: true,
            dataError: false,
            lastEvents: [],
            NB_ROWS: 20
        }
    },
    watch: {
        reload: function(val){
            if ( val === true ){
                this.getLastEventsWithDelay();
                this.$emit("reloadCacheInfoOK");
            }
        }
    },
    mounted(){
        this.getLastEventsWithDelay();
    },
    methods: {
        getLastEventsWithDelay(){
            setTimeout(() => {
                this.getLastEvents()
            }, 2000)
        },
        async getLastEvents(){

            this.dataLoading = true;
            this.dataError = false;
            this.lastEvents = [];
            let response = [];

            try{
                response = await traceApiService.getLast();
            }catch(e){
                this.dataError = true;
                this.dataLoading = false;
                return;
            }

            this.lastEvents = response.body?.data?.filter(el => el.typeTrace === "CACHE" && el.typeOperation === "DELETED")
                .map(el => {
                    const dateFormat = Moment(el.timestamp).format("DD/MM/YYYY HH:mm")
                    return {
                        type: el.typeTrace,
                        information: el.message,
                        uid: el.uid,
                        date: dateFormat,
                        key: el.uid + el.timestamp
                    }
                }).slice(0,this.NB_ROWS);

            this.dataLoading = false;
        }
    }
}
</script>
