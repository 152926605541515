
import DistributionChartUtils from '../../../utils/store/distribution-chart-utils';
import applicationService from "../../../service/application/application-service";
import router from "../../../router/router";
import appTypeEnum from "../../../service/application/app-type-enum";

const state = () => ({
    application: {
    },
    saveApp: {
        loading: false,
        error: null
    },
    steppers: {
        activeStep: null,
    },
    blacklistUrl: {
        loading: false,
        regex: null
    },
    upload: {
        progress: 0,
    },
    upToDateDevices: 0,
    totalTargetedDevices: 0,
    upToDateDevicesByVersionName: [],
    productionVersionCode: '',
    productionVersionName: ''
});


const getters = {
    getApplication(state) {
        return state.application;
    },
    getSaveApp(state) {
        return state.saveApp;
    },
    getActiveStep(state) {
        return state.steppers.activeStep;
    },
    getBlacklistUrl(state) {
        return state.blacklistUrl;
    },
    getUploadProgress(state){
        return state.upload.progress;
    }
};


const actions = {
    setApplication({commit}, application) {
        commit('setApplication', application);
    },

    setSaveApp({commit}, loading, error) {
        commit('setSaveApp', loading, error);
    },

    setActiveStep({commit}, step) {
        commit('setActiveStep', step);
    },

    setApplicationAttribute({commit}, attribute, value) {
        commit('setApplicationAttribute', attribute, value);
    },

    setBlacklistUrl({commit}, value) {
        commit('setBlacklistUrl', value);
    },

    setUploadProgress({commit}, value){
        commit('setUploadProgress', value);
    },

    async save({commit, getters}, route) {
        await saveApplication(commit, getters.getApplication, route);
    },

    async publicSave({commit, getters}, route) {
        await saveApplication(commit, getters.getApplication, route, appTypeEnum.PUBLIC_APP);
    },
};

const mutations = {
    resetUpToDateDevicesByVersionName(state){
      state.upToDateDevicesByVersionName = [];
    },
    setUpToDateDevicesByVersionName(state, pObj){
        DistributionChartUtils.updateStoreData(state, pObj);
    },
    addDevicesUpToDate(state, countDevices){
        state.upToDateDevices += countDevices;
    },
    addTotalTargetedDevices(state, countDevices){
        state.totalTargetedDevices += countDevices;
    },
    resetDevicesWithInstalledApp(state) {
        state.upToDateDevices = 0;
    },
    resetTotalTargetedDevices(state) {
        state.totalTargetedDevices = 0;
    },
    setApplication(state, application) {
        state.application = {...state.application, ...application};
        state.saveApp = {...state.saveApp, error: null};
    },
    setUploadProgress(state, value){
        state.upload.progress = value;
    },

    setSaveApp(state, status) {
        state.saveApp = {...state.saveApp, ...status};
    },

    setActiveStep(state, step) {
        state.steppers.activeStep = step;
    },

    setApplicationAttribute(state, attribute, value) {
        state.application[attribute] = value;
    },

    setBlacklistUrl(state, value) {
        state.blacklistUrl = {loading: value.loading || false, regex: value.regex};
    },
    setProductionVersionCode(state, value) {
        state.productionVersionCode = value;
    },
    setProductionVersionName(state, value) {
        state.productionVersionName = value;
    }
};

const saveApplication = async (commit, applicationToSave, route, appType = appTypeEnum.PRIVATE_APP) => {
    try {
        commit("setSaveApp", {loading: true, error: null});

        const application = await applicationService.saveApplication(applicationToSave, appType);

        document.getElementById('feedback')?.click();

        commit("setSaveApp", {loading: false});

        router.push({
            'path': `/applications/${application.id}${route}`
        });
    } catch(e) {
        let message = "An error occurred. Please, retry in a few minutes or contact the MDM administrator.";
        if((e.code === 409 || e.status === 409) && e.data?.errors) {
            let errorState = e.data.errors.filter((error) => error.code == 409);
            if(errorState.length > 0) message = errorState[0].errorMessage;
        }

        console.error("An error occurred when saving app:", e);
        commit("setSaveApp", {loading: false, error: message});
    }
}



export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
