<template>
    <div class="page-content full-size">
        <mdm-vitamine-back-link link="/applications/new" id="application-add-button-back">
            ADD AN APP
        </mdm-vitamine-back-link>

        <p class="vtmn-typo_title-5 app-title">Add a public's app</p>

        <md-content v-if="iframeLoadingError" class="md-accent">
            <ul>
                <li>We are encountering a problem to load the google iframe, please contact your administrator</li>
            </ul>
        </md-content>
        <div v-else-if="!iframe" class="text-center" ><md-progress-spinner :md-diameter=50 :md-stroke=5 md-mode="indeterminate"/></div>
        <div id="playstore-iframe"/>
        <div v-if="staging === 'preprod'" class="hide" id="application-public-skip-iframe">
            <button id="application-public-skip-iframe-btn-slack" @click="skipIframe(uidApps.SLACK.UID)" >Skip the iframe - Slack Public App</button>
            <button id="application-public-skip-iframe-btn-linkedin" @click="skipIframe(uidApps.LINKEDIN.UID)" >Skip the iframe - Linkedin Public App</button>
            <button id="application-public-skip-iframe-btn-chrome" @click="skipIframe(uidApps.CHROME.UID)" >Skip the iframe - Chrome Public App</button>
        </div>
        <mdm-vtmn-popup ref="popupPublicAppSelected" >
            <mdm-vitamine-save-application-card dispatch-route="application/publicSave"
                                                :logoIsBase64="false" :app-type="appTypeEnum.PUBLIC_APP"
                                                :security-validation-status="$store.state.application.playstoreReady"
            />
        </mdm-vtmn-popup >
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue';
    import MdmVitamineCard from '../../../../components/mdm/vitamine/card/mdm-vtmn-card';
    import MdmVitamineSaveApplicationCard from '../../../../components/mdm/vitamine/card/mdm-vtmn-save-application-card';
    import MdmVitamineBackLink from '../../../../components/mdm/vitamine/link/mdm-vtmn-back-link';
    import PublicApplicationService from '../../../../service/application/public-application-services';
    import constants from '../../../../../tests/constants';
    import AppTypeEnum from '../../../../service/application/app-type-enum';
    import stagingUtils from "../../../../utils/staging-utils";

    export default {
        components: {
            MdmVitamineBackLink,
            MdmVitamineCard,
            MdmVitamineSaveApplicationCard
        },
        data: function() {
            return {
                iframe: null,
                iframeLoadingError: false,
                uidApps: constants.APPLICATIONS,
                appTypeEnum: AppTypeEnum,
                staging: stagingUtils.getStaging()
            }
        },
        methods: {
            getApplicationInformation(packageName) {
                PublicApplicationService.getApplicationInformation(packageName).then((application) => {
                    this.$store.dispatch('application/setApplication', {
                        ...application,
                        name: application.title,
                        logo: application.icon,
                        packageName,
                        descriptions: [{code: "EN", content: application.description}]
                    });
                    this.$refs.popupPublicAppSelected.open();
                })
            },
            skipIframe(uid) {
                this.getApplicationInformation(uid);
            }
        },
        mounted: function() {
            Vue.nextTick(() => {
                this.iframeLoadingError = false;
                PublicApplicationService.loadPlaystoreIframe(
                    'playstore-iframe',
                    (event) => this.getApplicationInformation(event.packageName),
                    (iframe) => this.iframe = iframe,
                    error => {this.iframeLoadingError = true;}
                )
            })
        }
    }

</script>

<style lang="scss" >
@import '../scss/new-app.scss';

#playstore-iframe {
    flex: 1;
}
</style>
