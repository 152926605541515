<template>
    <div class="parent">
        <v-skeleton-loader v-if="isLoadingIconsList && !logo" type="image" class="application-icon" />

        <span v-else-if="!logo && !isLoadingIconsList">
            <img class="img-icon" :src=appDefaultIcon />
            <i v-if="application.appType === 'WEBVIEW'" class="img-small-icon-inside ri-link msg-vertical-align"></i>
            <i v-if="application.appType === 'PUBLIC_APP'" class="img-small-icon-inside ri-earth-fill msg-vertical-align"></i>
        </span>
        <span v-else>
            <img class="img-icon application-icon" :class="application.playstoreReady === 'TODO' ? 'opacity-05' : ''" :src="logo" />
            <i v-if="application.appType === 'WEBVIEW'" class="img-small-icon-inside ri-link msg-vertical-align"></i>
            <i v-if="application.appType === 'PUBLIC_APP'" class="img-small-icon-inside ri-earth-fill msg-vertical-align"></i>
        </span>

        <span class="appName">
            <router-link class='a-decoration-none' :class="application.playstoreReady === 'TODO' ? 'grey-row-color' : 'default-row-color'"
                         :to="{ name: 'application-show-one', params: {id: application.id}}">
                {{application.name}}
            </router-link>
        </span>
    </div>
</template>

<script>
    import FieldConstant from "../../../utils/field-constant";

    export default {
        name: "application-card",
        props: {
            application: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                appDefaultIcon: FieldConstant.APPLICATION.ICON_BASE64_PREFIX + FieldConstant.APPLICATION.WEBAPP.DEFAULT_ICON,
            }
        },
        computed: {
            logo() {
                return this.$store.getters['icons/getIconByPackageName'](this.application?.uid);
            },
            isLoadingIconsList() {
               return this.$store.state.icons.isLoadingIconsList;
            }
        }
    }
</script>

<style lang="scss" scoped>
.application-icon{
    width:100px;
    height: 100px;
}
.appName{
	font-size :15px;
	display: inline-block;
    position: relative;
    color: #404040;
    font-weight: bold;
	text-transform: uppercase;
	word-break: break-word;
}

.parent:hover .img-small-icon-inside {
    opacity: 0;
    transition: opacity 0.2s;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
}

.img-icon {
    position: relative;
    top: 0;
    left: 0;
}
.img-small-icon-inside {
    font-size: small;
    position: absolute;
    top: -17px;
    left: -17px;
    color: var(--vtmn-color_brand);
    opacity: 0.66;
}

</style>
