<template>
    <div class="group-choose-id">
        <input v-if="handle"
            class="vtmn-checkbox"
            type="checkbox"
            :id="'group-choose-id-btn'"
            :checked="isCheck"
            @change="handle"
        />
        <label :for="'group-choose-id-btn'"><slot /></label>
        <div v-if="isCheck" class="mdm-vtmn-padding">
            <mdm-multi-choice
                :id='id' :value="value" :placeholder="placeholder" :label="hasToShowlabel ? placeholder : ''"
                :isNumber="true"
                :has-to-check-numeric-type="hasToCheckNumericType"
                :find-item-in-api="findItemInApi ? findItemInApi : checkIfDeviceExist"
                @notFoundItem="handleNotFoundItem" @selected="handleSelectNewDevice"
                @updateAlreadySelected="updateAlreadySelected"
                :alreadySelectedError="alreadySelectedError">
                <template slot="mdm-autocomplete-item" slot-scope="{ item }">{{item}}</template>
                <template slot="error-text-not-found" slot-scope="{ removeAllNotFoundItem }">
                    Some ids were not found in the mdm, please remove them from the list.
                    <a @click="removeAllNotFoundItem">Click here to remove all unused item.</a>
                </template>
                <template slot="error-text-already-selected" >You can't select the same id two times</template>
            </mdm-multi-choice>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import DeviceService from '../../../../../service/devices/device-service';

    export default {
        name: 'group-choose-ids',
        data: function() {
            return {
                alreadySelectedError: false
            }
        },
        props: {
            id: {
                type: String,
                required: true
            },
            isCheck: {
                type: Boolean,
                required: true
            },
            handle: {
                type: Function,
            },
            placeholder: {
                type: String
            },
            hasToShowlabel: {
              type: Boolean,
            },
            value: {
                type: Array
            },
            hasToCheckNumericType: {
                type: Boolean,
                default: true
            },
            findItemInApi: {
                type: Function,
            }
        },
        computed: {
            group() {
                return this.$store.state.group.group;
            }
        },
        methods: {
            checkIfDeviceExist(idDevice) {
                return DeviceService.isDeviceExist(idDevice);
            },
            handleNotFoundItem(event) {
                this.$store.dispatch('group/setGroup', {notFoundDevice: event})
            },
            handleSelectNewDevice(listDevices) {
                this.$store.dispatch('group/setGroup', {
                    requirement: {
                        ...this.group.requirement,
                        deviceId: listDevices
                    }
                })
            },
            updateAlreadySelected(alreadySelect) {
                this.alreadySelectedError = alreadySelect;
            }
        }
    }
</script>

<style lang="scss">
@import './perimeter.scss';
</style>
