import Vue from 'vue';
import {store} from "../../store/store";

export default {
    async getToken() {
        const response = await Vue.http.get(store.state.urlIframeGetToken);
        return response.data.token;
    },

    async loadPlaystoreIframe(idForIframe, onSelect, whenLoaded, errorHandler) {
        if(!Vue.google.isInit) {
            console.info("Iframe not load yet.. Retrying in 1 seconds..")
            setTimeout(() => this.loadPlaystoreIframe(idForIframe, onSelect, whenLoaded, errorHandler), 1000);
            return;
        }

        try {
            const token = await this.getToken();
            Vue.google.api.load('gapi.iframes', () => {
                var options = {
                    url: `https://play.google.com/work/embedded/search?token=${token}&mode=SELECT`,
                    where: document.getElementById(idForIframe),
                    attributes: { style: 'height: 100%; width: 100%', scrolling: 'yes' }
                }

                let iframe = gapi.iframes.getContext().openChild(options);

                iframe.register('onproductselect', onSelect, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);

                whenLoaded(iframe);
            });
        } catch (e) {
            errorHandler(e);
        }
    },

    async getApplicationInformation(packageName) {
        const urlGetPublicApplicationInfo = store.state.getUrlWithParams(store.state.urlGetPublicApplicationInfo, {packageName});
        const response = await Vue.http.get(urlGetPublicApplicationInfo);
        return response.body;
    },
}
