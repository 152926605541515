import Vue from 'vue';
import {store} from "../store/store";
import FieldConstant from "../utils/field-constant";

import ApplicationService from './application/application-service';
import {messageConstant} from "./../utils/message-constant";
import httpRequestsUtils from "@/utils/http-requests-utils";
import AppUtils from "@/utils/application-utils";

const CHUNK_SIZE = 10 * 1000 * 1024;

/**
 * Upload the file on application-api
 * @param fileToUpload a File object to upload
 * @returns {Promise<boolean>}
 */
export async function uploadFileByChunk (fileToUpload) {
    let currentChunk = 0;
    let uuid = undefined;
    const chunkTotal = parseInt( fileToUpload.size / CHUNK_SIZE ) + 1;
    store.dispatch('application/setUploadProgress', 0);

    for (let start = 0; start < fileToUpload.size; start += CHUNK_SIZE) {
        currentChunk ++;
        const chunk = fileToUpload.slice(start, start + CHUNK_SIZE );

        const reader = new FileReader();
        reader.readAsDataURL(chunk);

        const uploadDto = await new Promise( (resolve, reject) => {
            reader.onloadend = async function () {
                const jsonData = {
                    name: fileToUpload.name.replace(/\s/g, ""),
                    uuid,
                    chunk: {
                        number: currentChunk,
                        total: chunkTotal,
                        base64: removeBase64Prefix(reader.result),
                        total_size:  fileToUpload.size,
                        byte_start: start,
                        byte_end: start + CHUNK_SIZE
                    }
                };

                let res;
                try {
                    res = await httpRequestsUtils.retryRequestWhenFailed(async () => Vue.http.post(store.state.urlUploadVersionFile, jsonData),
                        JSON.parse(process.env.VUE_APP_HTTP_REQUEST_APP_UPLOAD_RETRY_CONFIG || "{\"demander\":\"uploadFileByChunk\",\"count\":5,\"delay\":5000}"));
                } catch (e) {
                    reject (e.body);
                    return;
                }

                resolve(res.body);
            };
        });
        uuid = uploadDto.uuid;
        const uploadProgress = Math.round(parseInt((currentChunk / chunkTotal) * 100) / 10) * 10;
        store.dispatch('application/setUploadProgress', uploadProgress);
        if( uploadDto.upload_is_finished ) return uploadDto;
    }

    throw new Error("An unexpected error happened while uploading")

}

const removeBase64Prefix = (base64Payload) =>{
    const prefix = 'base64,';
    const indexOfBase64 = base64Payload.indexOf(prefix);

    if ( indexOfBase64 < 0){
        return base64Payload;
    }
    return base64Payload.substring(indexOfBase64 + prefix.length);
}
export async function uploadFileIfIsOk(fileToUpload) {
    const file = checkIfApplicationFileIsOk(fileToUpload);
    const appInfo = await uploadFileByChunk(file.file);
    if (!appInfo) throw new Error("A problem occurred during upload. Please retry, or contact an administrator");
    const isApplicationExist = await ApplicationService.isApplicationExist(appInfo.package_name);
    if(isApplicationExist) throw new Error(`${appInfo.application_name} is already on the MDM . You can't add it again.`);

    return {
        applicationResponsible: store.state.user.displayName,
        name: appInfo.application_name,
        packageName: appInfo.package_name,
        version: appInfo.version_name,
        versionCode: appInfo.version_code,
        filePath: appInfo.file_path,
        fileSize: appInfo.file_size,
        sdkVersion: appInfo.sdk_version,
        platform: appInfo.platform,
        logo: appInfo.icon,
    };

}
const checkIfApplicationFileIsOk = (applicationFile) => {
    let file = applicationFile?.target?.files[0];

    AppUtils.checkAppSize({typeSource: file.name, size: file.size});

    if (!file) file = applicationFile[0];

    const name = file.name;
    const extension = name.substring(name.lastIndexOf('.') + 1);

    if(name === "")
        throw new Error("The file cannot have an empty name")
    else if (!FieldConstant.APPLICATION.ALLOWED_EXTENSIONS_WITHOUT_APK.includes(extension))
        throw new Error(messageConstant.APPLICATION_ADD_BAD_EXTENSION_WITHOUT_APK)
    else if (!/^[A-Za-z][A-Za-z0-9-_.]*$/.test(name.replace('.'+extension, '')))
        throw new Error(messageConstant.APPLICATION_FILE_NAME_RESTRICTION);
    else if (file.size > 500000000)
        throw new Error(messageConstant.APPLICATION_ADD_BIG_SIZE);

    return {
        file,
        osName: (extension === 'aab') ? 'ANDROID' : 'IOS',
    }
}

