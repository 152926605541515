import Moment from "moment";
export default {
    formatHoursDisplayed(hour) {
        return hour.length === 5 ? hour + ':00' : hour
    },
    getLatestParam(params){
        if(params){
            const dates = params.map(date => new Date(date.date_update));
            const maxDate = new Date(Math.max(...dates));
            return params.find(param => new Date(param.date_update).getTime() === maxDate.getTime());
        }
    },
    formatTimestampTo(timestamp, specificFormat = "DD/MM/YYYY HH:MM"){
        return Moment(new Date(timestamp)).format(specificFormat);
    }
}
