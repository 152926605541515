import Vue from 'vue';
import {store} from "../../store/store";

export default {
    async updateKioskInfo(kioskInfo) {
        return Vue.http.post(store.state.urlAWSUpdateInfosKiosk, JSON.stringify(kioskInfo));
    },
    splitHourMinute(hour) {
        const splitedHour = hour.split(':');
        return {
            hour   : splitedHour[0],
            minute : splitedHour[1]
        }
    }
}
