import MessageConstant from "./message-constant";

export default {
    chooseNewPasswordPopinMessage(isChangeLockCodeByUser) {
            return isChangeLockCodeByUser ? MessageConstant.NEW_CHOSEN_PASSWORD : MessageConstant.NEW_RANDOM_PASSWORD;
 },
    choosePopinAfterNewPasswordValidation(lock1, lock2, lock3) {
        const lock1And2 = `${lock1}${lock2}`;
        return isNotMakingNumericComplex(lock1And2, lock3) ? MessageConstant.PASSWORD_RULES : 'confirmChangeLock';
 }
}

function isNotMakingNumericComplex(lock1And2, lock3) {
    return Boolean(isMakingDescendingOrAscendingSequence(lock1And2, lock3) || allSameDigit(lock1And2, lock3));
}
function isMakingDescendingOrAscendingSequence(lock1And2, lock3) {
    return Boolean(+lock1And2.charAt(1) - +lock1And2.charAt(0) === lock3 - +lock1And2.charAt(1));
}
function allSameDigit(currentPassword, nb) {
    return Boolean(+currentPassword.charAt(0) === +currentPassword.charAt(1) && +currentPassword.charAt(1) === nb);
}

