<template>
    <!-- This component call API when it's mounted to get country and store if it used to change custom mode in device details -->
    <v-row no-gutters align="center" class="overridden-enrollment-choice">
        <v-col cols="12" align="center">
            <md-progress-spinner v-if="Object.keys(countryAndStore).length === 0 && data === undefined" :md-diameter=50 :md-stroke=5 md-mode="indeterminate" />

            <el-carousel v-if="Object.keys(countryAndStore).length > 0 || data !== undefined" trigger="click" :initial-index="0" :autoplay=false :interval="4000" type="card" height="260px" @change="defaultAppChanged">

                <el-carousel-item v-for="type in allTypes" :key="type">
                    <mdm-button :id='"devices-new-device-btn-is-not-" + type' :text="type" css="md-raised button-choice random-color-evenly" is-no-need-inline-block />
                </el-carousel-item>
            </el-carousel>
        </v-col>
        <v-col cols="12" align="center" class="mt-8 mb-8">
            <v-alert
                icon="mdi-emoticon-happy-outline"
                text prominent >
                <p class="mb-0">
                    By choosing <b>{{chosenApp}}</b>,
                    <span v-if="chosenApp === NORMAL">you can use all corporate applications.</span>
                    <span v-else>
                        you will be able to use only : <b>{{ getApplicationsNames || 'loading applications names...'}}</b> on your device
                    </span>
                </p>
            </v-alert>
        </v-col>
        <v-col cols="12" align="center" class="mb-5" d-flex>
            <slot name="cancel-button"></slot>
            <mdm-vtmn-button id="devices-details-btn-edit-type-enrolment" not-block variant="primary" @click="choose(chosenApp); $emit('click')">
                CHOOSE THIS TYPE OF ENROLMENT
            </mdm-vtmn-button>
        </v-col>
    </v-row>
</template>


<script>
    import FieldConstant from "../../../../utils/field-constant";
    import applicationService from '../../../../service/application/application-service';

    export default {
        name: "choose-custom-mode",
        data: function() {
            return {
                NORMAL: FieldConstant.ENROLLMENT_TYPE.NORMAL,
                chosenApp: FieldConstant.ENROLLMENT_TYPE.NORMAL,
                applicationNames: {},
                awaitCountryAndStore : {}
            }
        },
        props: {
            data: {
                type: Object,
                required: false
            },
            functionCountryAndStore : {
                type: Function,
                required: false
            }
        },
        asyncComputed: {
            async getApplicationsNames() {
                if(this.chosenApp != this.NORMAL && !this.applicationNames[this.chosenApp]) {
                    this.applicationNames[this.chosenApp] = { loading: true };
                    let packageNamesToSearch = []

                    if(this.data !== undefined) packageNamesToSearch = this.data.types.find(({type}) => type === this.chosenApp).packageNames;
                    if(Object.keys(this.countryAndStore).length > 0) packageNamesToSearch = this.awaitCountryAndStore.types.find(({type}) => type === this.chosenApp).packageNames;

                    const applications = await applicationService.getAllApplicationsByPackageName(packageNamesToSearch);
                    this.applicationNames[this.chosenApp] = {
                        loading : false,
                        applications: applications.map(application => application.name)
                    }
                }
                return this.applicationNames[this.chosenApp] && !this.applicationNames[this.chosenApp].loading && this.applicationNames[this.chosenApp].applications.join(', ');
            }
        },
        computed: {
            allTypes() {
                const dataOrCountry = this.data !== undefined ? this.data : this.awaitCountryAndStore;
                return [this.NORMAL, ...dataOrCountry?.types?.map(specificApplication => specificApplication.type)];
            },
            countryAndStore() {
                return this.awaitCountryAndStore
            }
        },
        methods: {
            defaultAppChanged(idx) {
                this.chosenApp = this.allTypes[idx];
            },
            choose(type) {
                this.$emit('selected', {type});
            },
        },
        async mounted() {
            if(this.functionCountryAndStore) {
                this.awaitCountryAndStore = await this.functionCountryAndStore();
            }
        }
    }
</script>

<style lang='scss' >

.overridden-enrollment-choice .el-carousel__item {
    &> div > button {
        color: white !important;
    }

    &:nth-child(1n) > div > button {
        background-color: var(--vtmn-color_brand-digital-light-1) !important;
    }

    &:nth-child(2n) > div > button {
        background-color: var(--vtmn-color_brand-digital) !important;
    }

    &:nth-child(3n) > div > button {
        background-color: var(--vtmn-color_brand-digital-dark-1) !important;
    }
}



</style>
