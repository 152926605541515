<template>
  <div class="vtmn-toggle vtmn-toggle_size--medium">
    <div class="vtmn-toggle_switch">
      <input v-model="modelValue" type="checkbox" :id="id" :active="'' + modelValue" />
      <span aria-hidden="true"></span>
    </div>
    <label :for="id"><slot /></label>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'mdm-vtmn-switch',
  props: {
    id: {
      type: String
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      }
    }
  }
}
</script>
