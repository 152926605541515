<template>
    <span class="main-eye">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="eye">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M1.66268 9.33284C3.19584 6.26604 6.3599 4.08411 10.0002 4.08411C13.6306 4.08411 16.8037 6.17409 18.3415 9.34057L18.3629 9.38463L18.3783 9.43109C18.513 9.83511 18.513 10.2516 18.3783 10.6556L18.3615 10.7062L18.3376 10.7539C16.8064 13.8168 13.7278 16.0026 10.0002 16.0026C6.27248 16.0026 3.19387 13.8168 1.66268 10.7539L1.63884 10.7062L1.62198 10.6556C1.48733 10.2516 1.48733 9.83511 1.62198 9.43109L1.63884 9.38052L1.66268 9.33284ZM3.03302 9.94691C3.01698 10.0127 3.01699 10.0741 3.03303 10.1399C4.34426 12.714 6.91718 14.5024 10.0002 14.5024C13.0831 14.5024 15.6561 12.714 16.9673 10.1399C16.9836 10.0729 16.9833 10.0106 16.9665 9.94353C15.6616 7.30542 13.0165 5.58434 10.0002 5.58434C6.99585 5.58434 4.3435 7.37655 3.03302 9.94691ZM10.0002 8.25142C8.99771 8.25142 8.25016 8.99908 8.25016 10.0017C8.25016 11.0043 8.99771 11.752 10.0002 11.752C11.0026 11.752 11.7502 11.0043 11.7502 10.0017C11.7502 8.99908 11.0026 8.25142 10.0002 8.25142ZM6.75016 10.0017C6.75016 8.17052 8.16928 6.75118 10.0002 6.75118C11.831 6.75118 13.2502 8.17052 13.2502 10.0017C13.2502 11.8328 11.831 13.2522 10.0002 13.2522C8.16928 13.2522 6.75016 11.8328 6.75016 10.0017Z" :fill="fillColor"/>
            </g>
        </svg>
    </span>
</template>

<script>
export default {
    name: "eye",
    props: {
        fillColor: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.main-eye {
    margin-right: 12px;
}
</style>
