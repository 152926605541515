import Vue from 'vue';
import {store} from '../../store/store';
import FieldConstant from "../../utils/field-constant";


export default {
    async getModels() {
        if(store.state.model.models) {
            return store.state.model.models.models;
        }

        const response =  await getModelsViaApi();
        const models = response.body.map(model => model.model)
            .sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
        store.dispatch('model/setModels', {models});
        return models;
    },

    async getModelsByCategory(obj) {
        const response = await getModelsViaApi();
        return removeDuplicateModels(filterDevicesByColumn(response.body, FieldConstant.CHOSEN_FILTER.CATEGORY_DB, obj[FieldConstant.CHOSEN_FILTER.CATEGORY]));
    },

    async getModelsByManufacturer(obj) {
        const response = await getModelsViaApi();
        return removeDuplicateModels(filterDevicesByColumn(response.body, FieldConstant.CHOSEN_FILTER.MANUFACTURER,  obj[FieldConstant.CHOSEN_FILTER.MANUFACTURER]));
    },
    async getModelsByCategoryAndManufacturer(obj) {
        const response = await getModelsViaApi();
        return removeDuplicateModels(
            filterDevicesByColumn(response.body, FieldConstant.CHOSEN_FILTER.CATEGORY_DB,  obj[FieldConstant.CHOSEN_FILTER.CATEGORY], FieldConstant.CHOSEN_FILTER.MANUFACTURER, obj[FieldConstant.CHOSEN_FILTER.MANUFACTURER])
        );
    },

}

function filterDevicesByColumn(devices, column, columnToCheck, column2, column2ToCheck){
    return devices.filter(device => {
        return device[column].toLowerCase() === columnToCheck.toLowerCase()
            && ( column2 && column2ToCheck ? device[column2].toLowerCase() === column2ToCheck.toLowerCase() : true )
    });
}

function removeDuplicateModels(devices){
    let modelsByManufacturer = [];
    devices.forEach(device => {
        if (!modelsByManufacturer.includes(device.model))
            modelsByManufacturer.push(device.model)
    });
    return modelsByManufacturer;
}

async function getModelsViaApi(){
    return Vue.http.get(store.state.urlDevicesModels);
}
