export default {
    clone(object){
        return JSON.parse(JSON.stringify(object));
    },
    convertKeysToCamelCase(obj) {
        const newObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
                newObj[newKey] = obj[key];
            }
        }
        return newObj;
    }
}
