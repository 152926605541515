<template>
  <div :style="getBtnStyle()">
    <md-button :id="id" :class="css" :style="customStyle" :disabled="disable" @click="onClick" :to="to" @mouseover="fireMouseOver" @mouseleave="fireMouseLeave">
      <md-icon v-if="icon && !waitingTreatment">{{ icon }}</md-icon>
      <md-progress-spinner v-if="waitingTreatment" md-mode="indeterminate" :md-diameter="20"   :md-stroke="3" class="md-icon"></md-progress-spinner>
      {{text}}
    </md-button>
    <md-tooltip v-show="tooltipText != undefined && disable || alwaysDisplayTooltip" md-direction="top"  >{{ tooltipText }}</md-tooltip>
  </div>
</template>

<script>
// This is a component to display a button on mdm.
// It contains a spinner to show an action is working
export default {
  name: 'MdmButton',
  data: function () {
    return {
      waitingTreatment: false,
    }
  },
  props: {
    text: {
      type: String
    },
    css:{
      type: String,
      default: 'md-raised'
    },
    customStyle:{
      type: String
    },
    tooltipText: {
      type: String
    },
    alwaysDisplayTooltip: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String
    },
    useProgressSpinner: {
      type: Boolean,
      default: false,
    },
    to:{
      type: Object,
      default: null
    },
    id:{
      type: String,
      required: false
    },
    isNoNeedInlineBlock: {
      type: Boolean,
      default: false
    }
  },
	methods: {
    // @vuese
    // Used when you click on the button
    onClick: function () {
      let _this = this;
      this.$ga.event({eventCategory: 'button', eventAction: 'click', eventLabel: JSON.stringify(this.$props)});
      if (this.useProgressSpinner) {
        this.waitingTreatment = true;
      }
      // Fire when the form is clicked
      // @arg The argument is a calback to call when you finish your action on the click. It allow to stop the progress-spinner and display the icon
      this.$emit('click',
        function () {
          _this.waitingTreatment = false;
        })
    },
    fireMouseOver() {
      this.$emit('mouseover');
    },
    fireMouseLeave() {
      this.$emit('mouseleave');
    },
    getBtnStyle() {
      if(this.isNoNeedInlineBlock) return null;
      return {display: "inline-block"};
    }
  }
}
</script>
