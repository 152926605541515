<template>
    <div v-if='app'>
		<div class='page-title' @click='cancel'>
			<span> < </span>
			<span>{{app.name}}</span>
		</div>
		</router-link>
		<div class="content">
			<h1 class='page-title2'>
				<span>Edit application</span>
			</h1>
			<h2 class='page-subtitle'>
				{{app.name}}
			</h2>
			<div>
				<div class='form'>
					<div class="form-item">
						<md-field>
				     		<label id="applications-edit-label-responsible" >Application responsible : *</label>
                            <md-input id="applications-edit-input-responsible" v-focus v-model="app.responsible"></md-input>
						</md-field>
				    </div>
					<div class="form-item">
						<md-checkbox id="applications-edit-btn-block-uninstall" v-model="app.blockUninstall" class="md-primary">Don't propose uninstall for this application .</md-checkbox>
						<br/>
						<md-checkbox id="applications-edit-checkbox-essential" v-model="app.essential" class="md-primary">Essential</md-checkbox>
					</div>
				</div>
				<div>
					<h2 class="page-subtitle">Application description</h2>
					<div>
						<div class="container" id="todo">
	    					<md-tabs md-elevation=10 md-alignment="fixed" @md-changed="tabActive">
	      						<md-tab id="tab-english" md-label="English" v-if="editTranslation">
									<md-field>
											<md-textarea id="applications-edit-textarea-en" v-model="app.applicationDescriptionGB[0]" v-on:keyup="logEnglish" required></md-textarea>
											<span class="md-helper-text">(Only English)</span>
									</md-field>
	     						</md-tab>
								<md-tab v-else md-disabled id="tab-english" md-label="English"></md-tab>
	      						<md-tab v-if="translation"  id="tab-french" md-label="French">
									<md-field>
											<md-textarea id="applications-edit-textarea-fr" v-model="app.applicationDescriptionFR[0]" v-on:keyup="logEditTranslation"></md-textarea>
											<span class="md-helper-text">(Only French)</span>
									</md-field>
	      						</md-tab>
								<md-tab v-else md-disabled id="tab-french" md-label="French"></md-tab>
	      						<md-tab v-if="translation" id="tab-deutch" md-label="deutsch">
									<md-field>
											<md-textarea id="applications-edit-textarea-de" v-model="app.applicationDescriptionDE[0]" v-on:keyup="logEditTranslation"></md-textarea>
											<span class="md-helper-text">(Only Deutsch)</span>
									</md-field>
	      						</md-tab>
								<md-tab v-else md-disabled id="tab-deutch" md-label="deutsch"></md-tab>
	      						<md-tab v-if="translation"  id="tab-spain" md-label="Spanish">
									<md-field>
											<md-textarea v-model="app.applicationDescriptionES[0]" v-on:keyup="logEditTranslation"></md-textarea>
											<span class="md-helper-text">(Only Spanish)</span>
									</md-field>
	      						</md-tab>
								<md-tab v-else md-disabled id="tab-spain" md-label="Spanish"></md-tab>
	      						<md-tab v-if="translation" id="tab-portugal" md-label="portuguese">
									<md-field>
											<md-textarea v-model="app.applicationDescriptionPT[0]" v-on:keyup="logEditTranslation"></md-textarea>
											<span class="md-helper-text">(Only Portuguese)</span>
									</md-field>
	      						</md-tab>
								<md-tab v-else md-disabled id="tab-portugal" md-label="portuguese"></md-tab>
	      						<md-tab v-if="translation" id="tab-china" md-label="chinese" >
									<md-field>
											<md-textarea v-model="app.applicationDescriptionCN[0]" v-on:keyup="logEditTranslation"></md-textarea>
											<span class="md-helper-text">(Only Chinese)</span>
									</md-field>
	      						</md-tab>
								<md-tab v-else md-disabled id="tab-china" md-label="chinese"></md-tab>
	    					</md-tabs>
						</div>
					</div>
				</div>
				<div class="formActions">
					<md-button id="applications-edit-btn-cancel" class="md-raised" @click="cancel">Cancel</md-button>
					<md-button id="applications-edit-btn-save" class="md-raised md-primary" :class="enableSave" @click='sendData'>Save</md-button>
				</div>
				<p class='mandatory-legend'>* This field is mandatory</p>
			</div>
		</div>
	</div>
	<md-progress-spinner v-else-if="!app || !this.$store.state.user || this.$store.state.user.uid === 'fake'" class='loading' :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
	<Error v-else/>
</template>

<script type="text/javascript">
import Vue from 'vue'
import 'vue-material/dist/theme/default.css'
import Error from '../error/error';

export default{
	components: {
		Error
    },
	data : function(){
		return {
			newDescription: "",
			activeTab : "tab-english",
			translation : false,
			editTranslation : true,
			app: null
		}
	},
	mounted: function() {
		Vue.http.get(
        	this.$store.state.getUrlWithParams(
          this.$store.state.urlApplicationIdOrPackageName,
          {
            idOrPackageName : this.$route.params.id
          })).then((response) => {
				var app = response.body;
				var applicationDescriptionGB =[];
				var applicationDescriptionFR =[];
				var applicationDescriptionES =[];
				var applicationDescriptionPT =[];
				var applicationDescriptionDE =[];
				var applicationDescriptionCN =[];
				if(app.applicationDescription != []){
					app.applicationDescription.forEach(function(description,i){
						switch(description.language){
							case("fr-FR"): applicationDescriptionFR = description.description; break;
							case("en-GB"): applicationDescriptionGB = description.description; break;
							case("de-DE"): applicationDescriptionDE = description.description; break;
							case("es-ES"): applicationDescriptionES = description.description; break;
							case("pt-PT"): applicationDescriptionPT = description.description; break;
							case("zh-CN"): applicationDescriptionCN = description.description; break;
						}
					})
					this.translation = true;
				}
        		const applicationToUpdate =  {
					...app,
						applicationDescriptionGB : applicationDescriptionGB,
						applicationDescriptionFR : applicationDescriptionFR,
						applicationDescriptionDE : applicationDescriptionDE,
						applicationDescriptionES : applicationDescriptionES,
						applicationDescriptionPT : applicationDescriptionPT,
						applicationDescriptionCN : applicationDescriptionCN,
						manageBy : app.manager,
						responsible : app.applicationResponsible,
					};
				this.app = applicationToUpdate;
			}, (error) => {
				console.log(error);
			});
	},
	computed : {
		enableSave : function(){
			var enableSaveOrNot = '';
			if(this.app.responsible == '' || this.app.applicationDescriptionGB == null || this.app.applicationDescriptionGB == '' ){
				enableSaveOrNot = 'button-disable';
			}
			return enableSaveOrNot;
		},
		toSendApplication : function(){
			var descriptionToSend = [];
			if(this.translation == true){
				//envoyer toutes les langues sauf l'anglais
				descriptionToSend.push({
					"language" : "fr-FR",
					"description" : this.app.applicationDescriptionFR,
				},
				{
					"language" : "de-DE",
					"description" : this.app.applicationDescriptionDE,
				},
				{
					"language" : "es-ES",
					"description" : this.app.applicationDescriptionES,
				},
				{
					"language" : "pt-PT",
					"description" : this.app.applicationDescriptionPT,
				},
				{
					"language" : "zh-CN",
					"description" : this.app.applicationDescriptionCN,
				})
			}
			else{
				//envoyer que l'Anglais
				descriptionToSend.push({
					"language" : "en-GB",
					"description" : this.app.applicationDescriptionGB,
				})
			}

			var applicationToSave ={
                "appType" : this.app.appType,
				"id" : this.app.id,
				"applicationDescription" : descriptionToSend,
				"name" : this.app.name,
				"platform" : this.app.platform.toUpperCase(),
				"uid" : this.app.uid,
                "applicationResponsible" : this.app.responsible,
                "blockUninstall" : this.app.blockUninstall,
                "essential" : this.app.essential,
                "managerUser" : this.app.managerUser,
                "userCountries": this.app.userCountries,
                "manager": this.app.manageBy,
                "playstoreReady": this.app.playstoreReady
			}
			return applicationToSave
		}
	},
	methods : {
        sendData : async function(){
            try {
                await Vue.http.post(`${this.$store.state.urlApplicationUpdate}`, this.toSendApplication)
                this.$ga.event({eventCategory: 'application', eventAction: 'update', eventLabel: JSON.stringify(this.toSendApplication)});
                this.$router.push({'path' : `/applications/${this.$route.params.id}`});
            } catch(e) {
                console.error(e);
            }
        },
		cancel : function(){
			document.location.href = document.location.href.replace('/edit', '');
		},
		tabActive : function(activetab){
			this.activeTab = activetab;
		},
		logEditTranslation :function () {
			this.editTranslation = false;
		},
		logEnglish : function(){
			this.translation = false;
		}
	}
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/color';
@import '~@/assets/style/formBySteps';
</style>
