<template>
		<div class="filter-selectOptions" ref="optionelmt" v-show=display>
			<div class='filter-selectOption filter-aFilter' v-for="(elem, i) in elems" @click="$emit('selectData', elem)">
				<span v-if="typeof elem == 'object' && elem != null"> {{elem.name}} </span>
				<span v-else> {{elem}}</span>
			</div>
		</div>
</template>

<script>
export default {
	props : ['elems', 'display']
}
</script>
