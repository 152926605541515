<template>

    <div class="chart-half-home">

        <div style="height: 510px;" class="md-content md-theme-default" v-if="searchLoading">
            <h1 id="home-label-chart-last-activities-loading" class="md-title"> Last events</h1>
            <md-progress-spinner class="mt-5 ml-5" :md-diameter="30" :md-stroke="3"
                                 md-mode="indeterminate"></md-progress-spinner>
        </div>

        <div v-else-if="searched">

            <md-table style="height: 510px;" v-model="searched" md-fixed-header>
                <md-table-toolbar>
                    <h1 id="home-label-chart-last-activities" class="md-title"> Last events</h1>
                    <md-field md-clearable class="md-toolbar-section-end">
                        <md-input placeholder="Search an information..." v-model="search" @input="searchOnTable"/>
                    </md-field>
                </md-table-toolbar>

                <md-table-empty-state md-label="No activities found"
                                      :md-description="`No activities found${search ? ' for the query \'' + search + '\'' : ''}. Try another search term.`">
                </md-table-empty-state>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell style="max-width: 50px" md-label="Type">
                        <p v-if="item.type == 'APPLICATION'">
                            <md-icon style="color: #0082c3; padding-top: 15px;">apps</md-icon>
                        </p>
                        <p v-if="item.type == 'CACHE'">
                            <md-icon style="color: #0082c3; padding-top: 15px;">cleaning_services</md-icon>
                        </p>
                        <p v-if="item.type == 'DEVICES'">
                            <md-icon style="color: #0082c3; padding-top: 15px;">phone_android</md-icon>
                        </p>
                        <p v-if="item.type == 'DEPLOY'">
                            <md-icon style="color: #0082c3; padding-top: 15px;">system_update</md-icon>
                        </p>
                    </md-table-cell>
                    <md-table-cell style='font-size: 11px;' md-label="Information">{{ item.information }}
                        <p>
                            <md-icon class="mdm-size-0-8">access_time</md-icon>
                            <span class="text-caption">{{item.date}}</span></p>
                    </md-table-cell>
                </md-table-row>

            </md-table>
        </div>
    </div>
</template>

<script>

    import Moment from "moment";
    import traceApiService from "./../../service/user/trace-api-service";

    export default {
        name: "LastActivities",
        data: function () {
            return {
                searchLoading: true,
                search: null,
                searched: [],
                lastActivities: [],
            };
        },
        mounted: async function () {
            this.load();
        },
        methods: {
            async load() {
                await this.getLastActivitiesOnS3();
            },
            searchOnTable() {
                this.searched = searchByName(this.lastActivities, this.search);
            },
            async getLastActivitiesOnS3() {
                this.searchLoading = true;
                const response = await traceApiService.getLast();
                let lastActivities = [];
                response?.body?.forEach(function (element, i) {
                    if (element.typeTrace && element.message) {
                        lastActivities.push({
                            type: element.typeTrace,
                            information: element.message,
                            date: Moment(element.timestamp).format("DD/MM/YYYY HH:mm")
                        });
                    }
                });
                this.searched = lastActivities;
                this.lastActivities = lastActivities;
                this.searchLoading = false;
            },

        }
    }

    const searchByName = (items, term) => {
        if (term && items) {
            return items.filter(item => toLower(item.information).includes(toLower(term)));
        }
        return items;
    };


    const toLower = text => {
        return text.toString().toLowerCase();
    };


</script>

<style lang="scss">

    .mdm-size-0-8 {
        transform: scale(0.7);
    }

</style>
