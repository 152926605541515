<template>

      <v-layout row wrap>
        <v-flex xs12 md3 class='description-info information-phoneImportant area'>
            <div v-if="application.platform != null">
                <div class='information-item'>
                    <span id="applications-details-value-uid" class='value font-italic font-weight-bold medium'>
                        <i v-if="application.appType === 'WEBVIEW'" class="img-small-icon-inside ri-link msg-vertical-align"></i>
                        <i v-if="application.appType === 'PUBLIC_APP'" class="img-small-icon-inside ri-earth-fill msg-vertical-align"></i>
                        {{application.uid}}
                    </span>
                </div>
                <div class='information-item'>
                    <span id="applications-details-label-managed-by" class='label'>Managed by : </span>
                    <span id="applications-details-value-managed-by" class='value'>{{application.manageBy}}</span>
                </div>
                <div class='information-item'>
                    <span id="applications-details-label-responsible" class='label'>Responsible : </span>
                    <span id="applications-details-value-responsible" class='value'>{{(application.appType === appTypeEnum.PUBLIC_APP) ? "Playstore" : application.responsible}}</span>
                </div>
            </div>
            <v-skeleton-loader v-else type="text@3" :boilerplate="true" calss="tall-app-icon" style="width:50%;"  />
        </v-flex>
        <v-flex xs12 md1/>
        <v-flex xs12 md6 v-if="!application.appType">
            <v-skeleton-loader type="text@3" :boilerplate="true" />
        </v-flex>
        <v-flex xs12 md6 v-else>
            <v-flex xs12>
                <strong><i class="ri-file-text-line msg-vertical-align"></i> DESCRIPTION : </strong>
                <span v-if="applicationDescription != null">{{ applicationDescription }}</span>
            </v-flex>
            <v-flex xs12>
                <div v-if="application.appType === appTypeEnum.WEBVIEW">
                    <strong><i class="ri-link msg-vertical-align"></i> WEBVIEW URL : </strong>
                    <span>{{application.url}}</span>
                </div>
            </v-flex>
            <v-flex xs12 justify-center v-if="application.platform === 'ANDROID'">
                <strong><i class="ri-android-line msg-vertical-align"></i> USE PLAYSTORE : </strong>

                <span v-if="playstoreReady==='TODO' && application.appType === appTypeEnum.PRIVATE_APP">Need to be configured by an MDM administrator<br/>
                    <span v-if="isErrorSettingPlaystore" class="errorSetPlaystore">An error occurred, please retry later or contact your administrator</span>

                    <span v-if="isAdmin && !isErrorSettingPlaystore && !isForbiddenToSetPlaystoreReady" >
                        <mdm-button id="applications-details-btn-playstore-ready"
                                  :text="'I configured this application on the playstore'"
                                  :icon="'settings_input_hdmi'" :useProgressSpinner=true
                                  :disable="isForbiddenToSetPlaystoreReady"
                                  @click="setPlaystoreReady()" />
                    </span>
                    <div v-if="displayWaitingMessage && !isErrorSettingPlaystore">Please wait, this operation may take several minutes... </div>


                    <div v-if="isForbiddenToSetPlaystoreReady" :class="'popin-error-text div-with-margin-top-10'">
                        <p>Could not create the app on the Playstore, reason : {{application.playstoreEventStatus.event.error.message}}</p>
                        <span>Please, contact the MDM team.</span>
                    </div>

                </span>

                <span id="applications-details-use-playstore-yes" v-else-if="playstoreReady==='DONE'">Yes</span>

                <span id="applications-details-use-playstore-waiting-for-validation" v-else>Waiting validation of the security team</span>

            </v-flex>
        </v-flex>

    </v-layout>
</template>

<script>
    import ApplicationService from '../../../service/application/application-service'
    import appTypeEnum from '../../../service/application/app-type-enum';
    import MessageConstant from '../../../utils/message-constant'

    export default {
        name: "applicationDescription",
        data: () => {
            return { 
                appTypeEnum,
                playstore: {
                    error: false,
                    loading: false
                },
            }
        },
        props: {
            application: {
                type: Object,
                required: true
            }
        },
        computed: {
            isAdmin() {
                return this.$store.state.user.admin
            },
            isForbiddenToSetPlaystoreReady() {
                return this.application.playstoreEventStatus?.state === "ERROR"
                    || this.application.playstoreEventStatus?.state === "IN_PROGRESS";
            },
            applicationDescription() {
                let descriptions = this.application.description;

                if(descriptions == null) return null;

                let userCountry = this.$store.state.user.country ? this.$store.state.user.country : 'GB';

                let countryDescription = descriptions.filter((countryDescription) => this.countryDescriptionIsUsable(userCountry, countryDescription));
                if (countryDescription?.length <= 0) countryDescription = descriptions?.filter((countryDescription) => this.countryDescriptionIsUsable('GB', countryDescription));
                if (countryDescription?.length <= 0) countryDescription = descriptions?.filter((countryDescription) => countryDescription.description.length > 0);

                if (!countryDescription[0]?.description[0]) return MessageConstant.APPLICATION_DESCRIPTION_UNAVAILABLE

                return countryDescription[0]?.description[0];
            },
            isErrorSettingPlaystore() {
                return this.playstore.error;
            },
            playstoreReady() {
                return this.application.playstoreReady;
            },
            displayWaitingMessage() {
                return this.playstore.loading;
            }
        },
        methods: {

            countryDescriptionIsUsable(country, countryDescription) {
                return countryDescription.language.indexOf(country) >= 0 && countryDescription.description.length > 0;
            },
            setPlaystoreReady() {
                this.playstore.loading = true;
                ApplicationService.setPlaystoreReady(this.application.uid, this.application.versions[0].versionCode, this.application.versions[0].filePath)
                    .then((data) => {
                    this.application.playstoreReady = data.playstoreReady;
                        this.playstore.loading = false;
                })
                .catch(e => {
                    this.playstore.error = true;
                });

            }
        }
    }
</script>

<style>
    .div-with-margin-top-10{
        margin-top: 10px;
    }
    .errorSetPlaystore {
        color: var(--vtmn-color_danger);
        font-weight: bold;
    }
</style>
