<template>

    <div id = "application-add-panel-steppers">
        <md-steppers class="no-background-color" :md-active-step.sync="active" md-vertical md-linear >

            <md-step id="application-add-step-terms-of-use" md-label="TERMS OF USE" :md-editable="false"
                     :md-done.sync="step[0].value">
                <good-practices @updateUnderstood="updateUnderstood"></good-practices>
            </md-step>

            <md-step id="application-add-step-information" md-label="INFORMATION" :md-editable="true"
                     :md-done.sync="step[1].value"
                     @click="changeActiveStep('information','none')">
                <private-app-information-form @onSubmit="changeActiveStep('description','information')"/>
            </md-step>

            <md-step id="application-add-step-description" md-label="DESCRIPTION" :md-editable="false"
                     :md-done.sync="step[2].value">

                <private-app-description-form :type="'application'" @onSubmit="changeActiveStep('authorization','description')"/>

           </md-step>

            <md-step id="application-add-step-authorization" md-label="DEPLOYMENT AUTHORIZATION" :md-editable="false"
                     :md-done.sync="step[3].value">
                <private-app-deployment-auth-form :type="'application'"/>
            </md-step>

        </md-steppers>
    </div>

</template>

<script type="text/javascript">
    import PrivateAppInformationForm from './private-app-information-form';
    import PrivateAppDescriptionForm from "./app-description-form";
    import PrivateAppDeploymentAuthForm from "./app-deployment-auth-form";
    import GoodPractices from "@/components/Alerts/good-practices";
    const vueStepperUtils = require('../../../utils/vue-stepper-utils');
    export default {
        components: {
            GoodPractices,
            PrivateAppDescriptionForm,
            PrivateAppInformationForm,
            PrivateAppDeploymentAuthForm
        },
        data: function () {
            return {
                active: 'application-add-step-terms-of-use',
                step: [
                    {type: 'terms of use', value: false},
                    {type: 'information', value: false},
                    {type: 'description', value: false},
                    {type: 'authorization', value: false} ]
            }
        },
        methods: {
            changeActiveStep(stepNameToActivate, stepNameToSetDone) {
                this.active = vueStepperUtils.getActiveStep('application-add-step-', stepNameToActivate);
                this.step = vueStepperUtils.getSteps(this.step, stepNameToSetDone);
                this.$store.dispatch('application/setActiveStep', stepNameToActivate);

            },
            updateUnderstood: function(){
                this.changeActiveStep('information', 'terms-of-use');
            }
        }
    }
</script>

<style lang="scss">
    #application-add-panel-steppers{
        button {
            &:not([disabled]) .md-stepper-number{
                background-color: var(--vtmn-color_brand-digital);
            }
            &.md-done .md-stepper-number{
                background-color: var(--vtmn-color_green);
            }
        }
    }
</style>
