<template>
<div class="mdm-select">
    <v-row>
        <v-col>
            <v-select :items="filters" item-text="name" item-value="name" dense solo v-model="chosenFilter"
                      @change="getFilterEvent(chosenFilter)" ></v-select>
        </v-col>
    </v-row>
</div>
</template>

<script>

export default {
    name: "mdm-select",
    props: {
        filters : {
            type: Array,
            required: true
        },
        defaultValue: {
            type: Object | String,
            required: false,
        }
    },
    data:function() {
        return {
            chosenFilter: this.getDefaultValue() || this.filters[0],
        }
    },
    watch: {
        filters:function() {
            this.chosenFilter = this.getDefaultValue();
        },
        defaultValue() {
            this.chosenFilter = this.getDefaultValue();
        }
    },
    methods: {
        getDefaultValue() {
            return this.defaultValue ? this.filters.find(filter => filter === this.defaultValue) : this.chosenFilter = this.filters[0];
        },
        getFilterEvent: function(chosenFilter) {
            this.$emit("getFilterEvent", chosenFilter);
            this.$emit("getFilterEventWithoutType", chosenFilter);
        }
    }

};
</script>

<style lang="scss">

.mdm-select {

    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: none;
        border: 1px solid var(--vtmn-color_grey-light-1);

    }

    .v-text-field__details { /** don't delete, it's to align v-select**/
        display: none;
    }

    .v-input--dense > .v-input__control > .v-input__slot {
        margin-bottom: 0;
        width: 100%;
    }

    .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
        min-height: 43px;
        margin-top: 5px;
    }

    .v-input {
        width: 100%;
    }

    .v-input__control {
        height: 43px;
        margin-bottom: 4px;
    }

    z-index: 100;
}


</style>
