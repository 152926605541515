<template>

    <v-row no-gutters class="form-flex popup-deployment-content" align="center" justify="center" >
        <v-col cols="12" v-if="appType === appTypeEnum.PRIVATE_APP || (appType !== appTypeEnum.PRIVATE_APP && securityValidationStatus === 'DONE')">
            <p class="vtmn-text-info vtmn-typo_title-3" align="center">Your application is ready !</p>
            <p v-if="!isEdit" class="vtmn-typo_title-5" align="center">Do you want create a group to deploy it ?</p>
        </v-col>

        <v-col cols="12" v-else>
            <div class="div-validation-container">
                <div class="div-validation-content">
                    <img src="./stop_panel.png" width="64" />
                    <p id="p-title-validation" class="vtmn-text-info vtmn-typo_title-3" align="center">Your app needs to be validated !</p>
                    <p id="p-subtitle-validation" v-if="!isEdit" class="vtmn-typo_title-6" align="center">To deploy your app via the MDM, the security team will check your application before.<br/>We will inform you by email during the process.</p>
                </div>
            </div>
        </v-col>

        <v-col cols="8" >
            <info-card :isBase64="logoIsBase64" :type="type" />
        </v-col>

        <v-col v-if="!applicationsaveAppStatus.loading && !isEdit && appType === appTypeEnum.PRIVATE_APP" cols="12" md="6" class="mt-5" align="center">
            <button class="vtmn-btn vtmn-btn_variant--secondary width-90" id="application-add-button-deploy-later"
                    @click="saveApplication('')">Deploy later
            </button>
        </v-col>
        <v-col v-if="!applicationsaveAppStatus.loading && !isEdit && appType === appTypeEnum.PRIVATE_APP" cols="12" md="6" class="mt-5" align="center">
            <button class="vtmn-btn width-90" id="application-add-button-deploy-now"
                    @click="saveApplication('/group')">Deploy now
            </button>
        </v-col>
        <v-col v-if="!applicationsaveAppStatus.loading && !isEdit && appType !== appTypeEnum.PRIVATE_APP" cols="12" md="6" class="mt-5" align="center">
            <button class="vtmn-btn vtmn-btn_variant--secondary width-90" id="application-add-button-wait-validation"
                    @click="saveApplication('')">Wait for validation
            </button>
        </v-col>
        <v-col v-if="!applicationsaveAppStatus.loading && isEdit" cols="12" md="12" class="mt-5" align="center">
            <button class="vtmn-btn width-90" id="application-edit-button"
                    @click="saveApplication('')">Edit the webview
            </button>
        </v-col>
        <v-col v-if="applicationsaveAppStatus.error" cols="12" class="mt-5" align="center">
            <md-content class="md-accent">{{applicationsaveAppStatus.error}}</md-content>
        </v-col>
        <v-col v-else-if="applicationsaveAppStatus.loading" cols="12" class="mt-5" align="center">
            <md-progress-spinner :md-diameter=50 :md-stroke=5 md-mode="indeterminate" />
        </v-col>
    </v-row>
</template>

<script type="text/javascript">
import InfoCard from "../../../../pages/applications/new-app/app-info-card";
import AppTypeEnum from "../../../../service/application/app-type-enum";

export default {
    name: 'mdm-vitamine-save-application-card',
    components: {
        InfoCard
    },
    props: {
        type: {
            type: String,
            default: 'application'
        },
        dispatchRoute: {
            type: String
        },
        logoIsBase64: {
            type: Boolean,
            default: true
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        securityValidationStatus: {
            type: String,
            default: "TODO"
        },
        appType: {
            type: String
        }
    },
    data() {
        return {
            appTypeEnum: AppTypeEnum
        }
    },
    computed: {
        applicationsaveAppStatus() {
            return this.$store.state[this.type].saveApp;
        }
    },
    methods: {
        saveApplication(route) {
            this.$store.dispatch(this.dispatchRoute, route);
        }
    }
}
</script>

<style lang="scss" scoped>
.popup-deployment-content {
    padding-left: 15%;
    padding-right: 15%;
}

#p-title-validation{
    color: var(--vtmn-color_red);
}
#p-subtitle-validation{
    color: var(--vtmn-color_warning);
}

.div-validation-container{
    display: flex;
    align-items: center;
}

.div-validation-content {
    width: 100%;
}

.div-validation-content img {
    float: left;
    margin-right: 10px;
}

</style>
