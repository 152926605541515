import {
    ANDROID_ENROLLMENT,
    APP_DEFAULT_CHOICE,
    DEVICE_OWNER_CHOICE,
    SERVICE_SELECT_USER,
    GOOGLE_ENDPOINT_MANAGEMENT,
    WSO_ENROLLMENT,
    IDLE_CHOICE,
    SITE_CHOICE,
    STORE_CHOICE,
    NORMAL_OR_GENERIC_POLICY_CHOICE,
    UNKNOWN_END,
    NO_BRANCH_USER,
    SERVICE_OR_RETAIL_CHOICE, MODEL_CHOICE, DS2_ENROLLMENT
} from "./step-constant";

const COMMON_PARCOURS = {
    SMARTPHONE_OR_TABLET_BEGINING_AND_SERVICE_OR_RETAIL_CHOICE: {nextSteppers: [DEVICE_OWNER_CHOICE, STORE_CHOICE, UNKNOWN_END], currentStepToBuild: DEVICE_OWNER_CHOICE.id}
}

export default  {
    AAM_PARCOURS: {nextSteppers: [ANDROID_ENROLLMENT], currentStepToBuild: ANDROID_ENROLLMENT.id},
    GOOGLE_ENDPOINT_MANAGEMENT_PARCOURS: {nextSteppers: [GOOGLE_ENDPOINT_MANAGEMENT], currentStepToBuild: GOOGLE_ENDPOINT_MANAGEMENT.id},
    SMARTPHONE_OR_TABLET_BEGINING: COMMON_PARCOURS.SMARTPHONE_OR_TABLET_BEGINING_AND_SERVICE_OR_RETAIL_CHOICE,
    SMARTPHONE_OR_TABLET_SERVICE_BEGINING: {nextSteppers: [SERVICE_SELECT_USER, UNKNOWN_END], currentStepToBuild: SERVICE_SELECT_USER.id},
    SERVICE_OR_RETAIL_RETAIL_CHOICE: COMMON_PARCOURS.SMARTPHONE_OR_TABLET_BEGINING_AND_SERVICE_OR_RETAIL_CHOICE,
    NORMAL_OR_GENERIC_POLICY_CHOICE: {nextSteppers: [NORMAL_OR_GENERIC_POLICY_CHOICE, UNKNOWN_END], currentStepToBuild: NORMAL_OR_GENERIC_POLICY_CHOICE.id},
    KIOSK_FIRST_PARCOURS: {
        nextSteppers: [MODEL_CHOICE, UNKNOWN_END],
        currentStepToBuild: MODEL_CHOICE.id
    },
    KIOSK_NEXT_PARCOURS: {
        nextSteppers: [SITE_CHOICE, STORE_CHOICE, UNKNOWN_END],
        currentStepToBuild: SITE_CHOICE.id
    },
    KIOSK_AAM_AFTER_STORE_CHOICE_PARCOURS: {
        nextSteppers: [IDLE_CHOICE, APP_DEFAULT_CHOICE, UNKNOWN_END],
        currentStepToBuild: IDLE_CHOICE.id
    },

    NO_BRANCH_USER: { nextSteppers: [NO_BRANCH_USER], currentStepToBuild: NO_BRANCH_USER.id },
    USER_API_DOWN_PARCOURS: { nextSteppers: [SERVICE_OR_RETAIL_CHOICE, UNKNOWN_END], currentStepToBuild: SERVICE_OR_RETAIL_CHOICE.id },
    DS2_ENROLLMENT_PARCOURS: {nextSteppers: [DS2_ENROLLMENT], currentStepToBuild: DS2_ENROLLMENT.id},
    WSO_ENROLLMENT_PARCOURS: {nextSteppers: [WSO_ENROLLMENT], currentStepToBuild: WSO_ENROLLMENT.id},

}
