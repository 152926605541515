import VueRouter from 'vue-router';
import Vue from 'vue';

import homepage from '../pages/homepage/homepage';
import rgpd from '../pages/rgpd/dcp-description';
import wso from '../pages/wso/wso-redirection.vue';
import adminMainComponent from '../pages/admin/admin-main';
import devices from '../pages/devices/all';
import device from '../pages/devices/details-device';
import newDevice from '../pages/devices/new/new';
import applications from '../pages/applications/all';
import applicationsEdit from '../pages/applications/edit';
import applicationsDetails from '../pages/applications/details/details';
import applicationsAddVersion from '../pages/applications/addVersion';
import applicationsGroupGesture from '../pages/applications/group/new/group-gesture';
import EndOfKdmRedirect from '../pages/error/end-of-kdm-redirect';
import groupIsDeployed from '../pages/applications/group/new/group-is-deployed';
import applicationChooseType from '../pages/applications/new-app/choose-app-type';
import applicationsPublicChoose from '../pages/applications/new-app/public-app/public-app-choose';
import applicationsPwaChoose from '../pages/applications/new-app/web-app/web-app-information.vue';
import privateApplicationInformation from '../pages/applications/new-app/private-app-information';
import groupPriority from '../pages/applications/groupPriority';
import retryCompletePriority from '../pages/applications/retryCompletePriority';
import bo from '../pages/homepage/bo';
import error from '../pages/error/error';
import userNotRetrievedOrUnauthorized from '../pages/error/user-not-retrieved-or-unauthorized.vue';
import migration from '../pages/migration/main';
import ApplicationService from "@/service/application/application-service";
import appTypeEnum from "@/service/application/app-type-enum";
import {store} from "@/store/store";
import UrlConstant from "../constant/url-constant";
import UserConstant from "../constant/user-constant";

Vue.use(VueRouter);

const routes = [
    {path: '', component: homepage},
    {path: '/devices', component: devices},
    {path: '/rgpd', component: rgpd},
    {path: '/wso', component: wso},
    {path: '/admin', component: adminMainComponent, name: "admin"},
    {path: '/devices/:id', component: device, name: "device-show-one"},
    {path: '/newdevice', component: newDevice},
    {path: '/applications', component: applications},
    {path: '/applications/new', component: applicationChooseType},
    {path: '/applications/private/information', name: "application-private-information", component: privateApplicationInformation},
    {path: '/applications/public/information', name: "application-public-information", component: applicationsPublicChoose},
    {path: '/applications/pwa/information/:idApplication?', name: "application-pwa-information", component: applicationsPwaChoose},
    {path: '/applications/:id/addVersion*', name: "application-add-version", component: applicationsAddVersion},
    {path: '/applications/:id/group/:idGroup?', name: "application-group-gesture", component: applicationsGroupGesture},
    {path: '/applications/:id/group/:idGroup/deployed', name: "application-group-is-deployed", component: groupIsDeployed},
    {path: '/applications/:id/edit', component: applicationsEdit},
    {path: '/applications/:id', component: applicationsDetails, name: "application-show-one"},
    {path: '/applications/:id/groupPriority', component: groupPriority},
    {path: '/applications/:id/retryCompletePriority', component: retryCompletePriority},
    {path: '/bo', component: bo},
    {path: '/error', component: error},
    {path: '/error/userNotRetrievedOrUnauthorized', component: userNotRetrievedOrUnauthorized},
    {path: '/kdm/end-of-life*', component: EndOfKdmRedirect},
    {path: '/migration', component: migration}
];

const router = new VueRouter({routes});

router.beforeEach(async (to, from, next) => {
    // Case 1: "/entities//98" -> check whether string has consecutive charactor '/' or not
    // Case 2: "/entities/123abc" -> check if id exists and id is not a number
    if(/(\/)\1/.test(to.path) || (to.params.id && isNaN(to.params.id))) {
        // "/entities//98", "/entities/123abc" -> "/entities"
        const redirectPath = to.path.substring(0, to.path.split('/', 2).join('/').length);
        return next(redirectPath);
    }
    store.dispatch('headerConfiguration/tooglePreVisualize', false);

    if(to.name === 'application-add-version') {
        const idApp = to.params.id;
        const application = await ApplicationService.getApplication(idApp);
        if (application && application.appType === appTypeEnum.PUBLIC_APP)
            return next(`/applications/${idApp}`);
    }

    next();

    const user = store.state.user;

    // 1) the user was found by the API - 2) he doesn't have any rights on MDM
    if(user.uid !== UserConstant.INITIAL_USER_UID_IN_VUEX
        && !user.admin && !user.appManager && !user.cp && !user.cc) {
        // 3) he ask forbidden urls for him
        if(to.path !== UrlConstant.newDeviceSuffix && to.path !== UrlConstant.rgpdSuffix && to.path !== UrlConstant.homePageSuffix) {
            return next(UrlConstant.userNotRetrievedOrUnauthorizedSuffix);
          // 4) he ask home page url
        } else if(to.path === UrlConstant.homePageSuffix) {
            return next(UrlConstant.rgpdSuffix);
        }
    }

    next();
});



export default router;
