<template>
    <div class="group-choose-model">
        <input
            class="vtmn-checkbox"
            type="checkbox"
            :id="'group-choose-model-' + id + '-btn'"
            :checked="isCheck"
            @change="handle"
            :disabled="disabled"
        />
        <md-tooltip v-show="tooltips != undefined && disabled" md-direction="top">{{ tooltips }}</md-tooltip>
        <label :for="'group-choose-model-' + id + '-btn'"><slot /></label>
        <div v-if="isCheck" class="mdm-vtmn-padding">
            <mdm-autocomplete-multi  :value="value" :options="allModels" :placeholder="placeholder" 
                :label="placeholder" @selected="handleSelect">
                <template slot="mdm-autocomplete-item" slot-scope="{ item }">{{item}}</template>
                <template slot="error-text">All models need to be select in the list</template>
            </mdm-autocomplete-multi>
        </div>
    </div>
</template>

<script>    
    import Vue from 'vue';

    export default {
        name: 'group-choose-model',
        props: {
            id: {
                type: String, 
                required: true
            },
            allModels: {
                type: Array,
                required: true
            },
            isCheck: {
                type: Boolean,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }, 
            tooltips: {
                type: String
            },
            handle: {
                type: Function,
                required: true
            },
            handleSelect: {
                type: Function,
                default: () => {}
            },
            placeholder: {
                type: String
            },
            value: {
                type: Array
            }
        }
    }
</script>

<style lang="scss">
@import './perimeter.scss';
</style>
