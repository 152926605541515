import { render, staticRenderFns } from "./terms-of-use-link.vue?vue&type=template&id=65e846e0&scoped=true"
import script from "./terms-of-use-link.vue?vue&type=script&lang=js"
export * from "./terms-of-use-link.vue?vue&type=script&lang=js"
import style0 from "./terms-of-use-link.vue?vue&type=style&index=0&id=65e846e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e846e0",
  null
  
)

export default component.exports