<template>
    <div>
        <v-alert class="mt-1"
            outlined
            shaped
            icon="info"
            type="info" >
            <p>As you are in BU service, you need to choose if the phone you enroll is for you or for another user !</p>
        </v-alert>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="6" class="max-button-size">
                <mdm-button id="mdm-button-for-me-choice" text="For me !" icon="person"
                            css="md-raised button-choice md-primary" @click="choose(FOR_ME)" is-no-need-inline-block/>
            </v-col>
            <v-col cols="12" sm="6" class="max-button-size full-size enter-user-choice">
                <div class="button-choice">
                    <div class="header">
                        <span aria-hidden="true" aria-label="User share icon" class="ri-user-shared-fill" />
                        <h2>FOR ANOTHER USER</h2>
                    </div>
                    <mdm-input
                            class="px-3 user-choice-input"
                            :val.sync="userSearchCriteria"
                            @touched="$v.userSearchCriteria.$touch"
                            :isError="Boolean(userSearch.error) || $v.userSearchCriteria.$error"
                            :id="'service-select-user-user-choice'"
                            @keyupEnter="searchUserOrganisation(userSearchCriteria)"
                            @keyup="resetError()"
                            name=""
                            placeholder="Ex: Z00XXYYZ or nickname.name@decathlon.com "
                            :error-message="userSearch.error ? userSearch.error : 'You put a wrong User ID or mail !'"/>

                    <mdm-vtmn-button
                        id="select-user-validate"
                        variant="secondary"
                        icon="vtmx-check-fill"
                        @click="searchUserOrganisation(userSearchCriteria)"
                        size="small"
                        :disabled="userSearch.loading"
                    >
                        Validate
                    </mdm-vtmn-button>
                </div>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import FieldConstant from "../../../../utils/field-constant";
import { matchDecathlonUid } from '../../../../utils/form-validator-utils';
import {required, email, or} from 'vuelidate/lib/validators'
import UserApiService from '../../../../service/user/user-api-service';
import NotFoundException from '../../../../error/not-found-exception';
import NoBranchException from '../../../../error/no-branch-exception';
import deviceService from '../../../../service/devices/device-service';

export default {
    name: "new-device-device-owner-choice",
    validations: {
        userSearchCriteria: {
            required,
            valid: or(email, matchDecathlonUid)
        }
    },
    data: function() {
        return {
            rotate: false,
            FOR_ME: "FOR_ME",
            userSearchCriteria: "",
            userSearch: {
                loading: false,
                error: null
            }
        }
    },

    methods: {

        async getRetailOrOtherStepperNameFrom(emailOrUid){
            const userIsRetail = await UserApiService.userIsRetail(emailOrUid);
            return userIsRetail ? FieldConstant.ENTITY.RETAIL : FieldConstant.ENTITY.OTHER_THAN_RETAIL
        },

        resetError() {
            this.userSearch.error = null;
        },
        async searchUserOrganisation(emailOrUid) {
            if(!this.$v.userSearchCriteria.$invalid) {
                try {
                    this.userSearch.loading = true;
                    const nextStep = await this.getRetailOrOtherStepperNameFrom(emailOrUid);
                    return this.choose(nextStep, emailOrUid)
                } catch(e) {
                    if(e instanceof NotFoundException) {
                        this.userSearch.error = "This user is not found";
                        return;
                    } else if(e instanceof NoBranchException) {
                        return this.choose("", emailOrUid);
                    }
                    return this.choose(null, emailOrUid);
                } finally {
                    this.userSearch.loading = false;
                }
            }
        },
        async choose(organisationUnit, userChoose) {
            if(this.FOR_ME === organisationUnit) {
                return this.$emit("selected", {organisationUnit: FieldConstant.ENTITY.OTHER_THAN_RETAIL,
                    isForMe: true,
                    tabToDisplay: await deviceService.hasToDisplayWsoEnrollmentTab() ? "WSO_ENROLLMENT_PARCOURS"
                        : "GOOGLE_ENDPOINT_MANAGEMENT_PARCOURS"});
            }
            if(!this.$v.userSearchCriteria.$invalid) {
                return this.$emit("selected", {organisationUnit, userChoose});
            }
        }
    }
}

</script>

<style lang="scss">
.max-button-size {
    max-width: 300px;
}
.enter-user-choice {
    position: relative;
    background-color: var(--vtmn-color_brand-digital);
    border-radius: 2px;

    > div {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);


        > div.header {
            color: white;

            span, h2 {
                display: inline-block;
                margin-left: 10px;
                font-size: x-large;
                vertical-align: bottom;
                font-weight: bold;
            }
        }

        #select-user-validate {
            margin: auto;
        }

        .user-choice-input > div {
            font-size: 0.5em;
            color: white;
        }
    }
}

#mdm-button-for-me-choice {
    background-color: var(--vtmn-color_brand-digital-light-1)
}
</style>
