<template>
	<div  >
		<span v-html="CallWebAPI" id="CallWebAPI"></span>
	</div>
</template>

<script type="text/javascript">
import Vue from 'vue';
import Moment from 'moment';
import VueRouter from 'vue-router';

Vue.use(require('vue-resource'));
export default{
	asyncComputed : {
 		CallWebAPI : function() {
 			return Vue.http.post(`http://mdm.preprod.org/decathlon-mdm-server-mvc/fwkbo/index.html`,{headers: {'content-type': "application/json"}}).then((response) => {
 				return response.bodyText;
			})
		},
		CallWebAPIcss : function() {
			return Vue.http.get('http://mdm.preprod.org/decathlon-mdm-server-mvc/css/fwkbo/fwkbo.css').then((response) =>{
				return response
			})
		}
	},

}
</script>

<style lang="css" scoped>
	
</style>