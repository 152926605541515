<template>
    <button
        @click="e => $emit('click', e)"
        @mouseover="e => $emit('mouseover', e)"
        @mouseout="e => $emit('mouseout', e)"
        :class="' vtmn-btn vtmn-btn_variant--' + variant +
                ' vtmn-btn_size--' + size +
                ' vtmn-btn--icon-' + iconDirection"
        :disabled="disabled"
        :id="id"
    >
        <span v-if="icon" aria-hidden="true" :class="icon" />
        <slot />
    </button>
</template>

<script type="text/javascript">
export default {
    name: 'mdm-vtmn-basic-button',
    props: {
        id: {
            type: String,
            required: false
        },
        icon: {
            type: String,
            required: false
        },
        variant: {
            type: String,
            default: "primary",
            validator: (value) => ['primary', 'secondary', 'primary-reversed', 'secondary-reversed'].includes(value.toLowerCase())
        },
        iconDirection: {
            type: String,
            default: "left",
            validator: (value) => ['alone', 'left', 'right'].includes(value.toLowerCase())
        },
        size: {
            type: String,
            default: "medium",
            validator: (value) => ['small', 'medium', 'large'].includes(value.toLowerCase())
        },
        disabled : {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

button {
    margin : 5px
}

</style>
