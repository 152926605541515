<template>
  <div v-if='versionData' class="full-size">
    <div class='page-title' @click='cancel'>
      <span> < </span>
      <span>{{this.$route.query.appName}}</span>
    </div>
    <div class="content">
      <div v-if='loading' class='overlay'></div>
      <md-progress-spinner v-if='loading' class='loading' :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
      <h1 class='page-title2'>
        <span>Add version</span>
      </h1>
      <md-steppers class="steppers":md-active-step.sync="active" md-linear md-dynamic-height>
          <!-- STEP 0 -->
          <md-step id="zero" md-label="Terms of use" :md-done.sync="zero">
              <div style="padding-inline: 28%">
                  <h2 class='page-subtitle'>Terms of use</h2>
                  <good-practices @updateUnderstood="updateUnderstood"></good-practices>
              </div>
          </md-step>
        <!-- STEP 1 -->
        <md-step id="first" md-label="Version summary" :md-done.sync="first">
          <div>
            <h2 class='page-subtitle'>Version summary</h2>
            <md-content id="applications-details-add-version-upload-error-msg" v-if="uploadError" class="md-accent">
              <ul v-for="msg of uploadError.split('\n')">
                <li>{{msg}}</li>
              </ul>
            </md-content>

            <div class='form' v-if='versionData.versionCode != "" '>
              <v-alert v-if="appSizeCheckResult.isGreater" dense type="warning" prominent outlined text>
                  {{ WARNING_MSG.APPLICATION_SIZE_NEAR_TO_LIMIT_WARNING.replace("{allowedAppSize}", appSizeCheckResult.allowedAppSize) }}
              </v-alert>

              <md-field>
                <label>Package Name :</label>
                <md-input id="applications-details-add-version-input-package-name" v-model='versionData.packageName' readonly></md-input>

                  <mdm-button :id="'mdm-button-download-version'" :css="'md-icon-button md-dense'"
                              :tooltip-text="tooltipDownloadVersion" :icon="'download'"
                              @click="download(versionData)"
                  />

              </md-field>
              <md-field>
                <label>Version :</label>
                <md-input id="applications-details-add-version-input-version" v-model='versionData.version' readonly></md-input>
              </md-field>
              <md-field>
                <label>Version Code :</label>
                <md-input id="applications-details-add-version-input-version-code" v-model='versionData.versionCode' readonly></md-input>
              </md-field>
            </div>
            <div v-else class="form uploadFile">
              <div class="form-item">
                <table class="import">
                  <tr>
                    <th>
                      <md-button class="md-icon-button md-raised button-import"@click='openFileUpload'> Add</md-button>
                      <input type="file" id="fileApp" :model='file' @change='checkApp'>
                    </th>
                    <th>
                      <md-button class="md-icon-button md-raised button-import"@click='getVersion'>Find</md-button>
                    </th>
                  </tr>
                  <br/>
                  <tr>
                    <th>
                      <span class=' import-cellSorter'>Add your file to our repository</span>
                    </th>
                    <th>
                      <span class='label'>Already on our repository ?</span>
                      <br>
                      <span class='label'>Select your file</span>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div class="formActions">
              <md-button id="versions-add-download-btn-cancel" class="md-raised" @click='cancel'>Cancel</md-button>
              <md-button id="versions-add-download-btn-next" class="md-raised md-primary" @click="setDone('first', 'second')" :class="{'button-disable' : !versionData.filePath || versionData.filePath.length <= 0 }">Next</md-button>
            </div>
          </div>
        </md-step>
        <!-- STEP2 -->
        <md-step id="second" md-label="WHAT'S NEW ?" :md-done.sync="second">
          <div>
            <h2 class="page-subtitle">What's new on {{this.$route.query.appName}} {{versionData.version}} ?</h2>
            <div>
              <md-content v-if="uploadError" class="md-accent">
                <ul v-for="msg of uploadError.split('\n')">
                  <li>{{msg}}</li>
                </ul>
              </md-content>
              <md-field v-if="this.activeTab == 'tab-english'">
                <label>What's new ? (English only)</label>
                <md-input v-model='newDescription' @keyup.enter="addDescription" v-on:keyup.enter="logEnglish"></md-input>
                <span class="md-helper-text">Use the input field to add an item (press Enter)</span>
              </md-field>
              <md-field v-else>
                <label>You can only add a description on the English tab</label>
              </md-field>
              <div class="container" id="todo">
                <md-tabs md-elevation=10 md-alignment="fixed" @md-changed="tabActive">
                  <md-tab id="tab-english" md-label="English" v-if="editTranslation">
                    <section class="list">
                      <ul class="list-item">
                        <li class = 'li-description'v-for="(description,index) in versionData.versionDescriptionGB">
                          <input class ="description-edit"  @keyup.enter="endEditing(description)" @blur="endEditing(description)" v-model="versionData.versionDescriptionGB[index]" v-on:keyup="logEnglish" >
                          <md-button class="md-icon-button md-raised" @click="removeDescription(description)"v-on:click="logEnglish">X</md-button>
                        </li>
                      </ul>
                    </section>
                  </md-tab>
                  <md-tab v-else md-disabled id="tab-english" md-label="English">
                  </md-tab>
                  <md-tab  v-if="translation"  id="tab-french" md-label="French">
                    <section class="list">
                      <ul class="list-item">
                        <li class = 'li-description'v-for="(description,index) in versionData.versionDescriptionFR">
                          <input class ="description-edit"  @keyup.enter="endEditing(description)" @blur="endEditing(description)" v-model="versionData.versionDescriptionFR[index]" v-on:keyup="logEditTranslation">
                        </li>
                      </ul>
                    </section>
                  </md-tab>
                  <md-tab v-else md-disabled id="tab-french" md-label="French">
                  </md-tab>
                  <md-tab v-if="translation" id="tab-deutch" md-label="deutsch">
                    <section class="list">
                      <ul class="list-item">
                        <li class = 'li-description'v-for="(description,index) in versionData.versionDescriptionDE">
                          <input class ="description-edit"  @keyup.enter="endEditing(description)" @blur="endEditing(description)" v-model="versionData.versionDescriptionDE[index]" v-on:keyup="logEditTranslation">
                        </li>
                      </ul>
                    </section>
                  </md-tab>
                  <md-tab v-else md-disabled id="tab-deutch" md-label="deutsch">
                  </md-tab>
                  <md-tab v-if="translation"  id="tab-spain" md-label="Spanish">
                    <section class="list">
                      <ul class="list-item">
                        <li class = 'li-description'v-for="(description,index) in versionData.versionDescriptionES">
                          <input class ="description-edit"  @keyup.enter="endEditing(description)" @blur="endEditing(description)" v-model="versionData.versionDescriptionES[index]" v-on:keyup="logEditTranslation">
                        </li>
                      </ul>
                    </section>
                  </md-tab>
                  <md-tab v-else md-disabled id="tab-spain" md-label="Spanish">
                  </md-tab>
                  <md-tab v-if="translation" id="tab-portugal" md-label="portuguese">
                    <section class="list">
                      <ul class="list-item">
                        <li class = 'li-description'v-for="(description,index) in versionData.versionDescriptionPT">
                          <input class ="description-edit"  @keyup.enter="endEditing(description)" @blur="endEditing(description)" v-model="versionData.versionDescriptionPT[index]" v-on:keyup="logEditTranslation">
                        </li>
                      </ul>
                    </section>
                  </md-tab>
                  <md-tab v-else md-disabled id="tab-portugal" md-label="portuguese">
                  </md-tab>
                  <md-tab v-if="translation" id="tab-china" md-label="chinese" >
                    <section class="list">
                      <ul class="list-item">
                        <li class = 'li-description'v-for="(description,index) in versionData.versionDescriptionCN">
                          <input class ="description-edit"  @keyup.enter="endEditing(description)" @blur="endEditing(description)" v-model="versionData.versionDescriptionCN[index]" v-on:keyup="logEditTranslation">
                        </li>
                      </ul>
                    </section>
                  </md-tab>
                  <md-tab v-else md-disabled id="tab-china" md-label="chinese">
                  </md-tab>
                </md-tabs>
              </div>
            </div>
            <div class="formActions">
              <md-button id="versions-add-whats-new-btn-cancel" class="md-raised" @click='cancel'>Cancel</md-button>
              <md-button id="versions-add-whats-new-btn-previous" class="md-raised md-primary" :class="(uploadError) ? 'button-disable' : ''" @click="setDone('second', 'first')">Previous</md-button>
              <md-button id="versions-add-whats-new-btn-next" class="md-raised md-primary" :class="(uploadError || this.versionData.versionDescriptionGB.length == 0) ? 'button-disable' : ''" @click="setDone('second', 'third')">Next</md-button>
            </div>
          </div>
        </md-step>
        <!-- STEP3 -->
        <md-step id="third" md-label="Rights" :md-done.sync="third">
          <div>
            <h2 class="page-subtitle">Rights</h2>
            <md-content v-if="uploadError" class="md-accent">
              <ul v-for="msg of uploadError.split('\n')">
                <li>{{msg}}</li>
              </ul>
            </md-content>
            <div class="form">
              <p> Give rights to others IT country. They will be allow to deploy this version</p>
              <p>By default, only your country’s IT team mates can .</p>
              <p>&nbsp;</p>
              <arbo type='lite' export='array' @changeArbo='changeArbo' :countryAlreadyChecked="versionData.sitesOpen"></arbo>
            </div>
            <div class="formActions">
              <md-button id="versions-add-rigths-btn-cancel" class="md-raised" @click='cancel'>Cancel</md-button>
              <md-button id="versions-add-rigths-btn-previous" class="md-raised md-primary" :class="(uploadError) ? 'button-disable' : ''" @click="setDone('third', 'second')">Previous</md-button>
              <md-button id="versions-add-rigths-btn-save" class="md-raised md-primary" :class="(uploadError) ? 'button-disable' : ''" @click='sendData'>Save</md-button>
            </div>
          </div>
        </md-step>
      </md-steppers>
    </div>
    <div v-if="popin != null" class="popins">
      <div class='popin-overlay' @click='popin = null'></div>
      <div v-if="popin =='download'" class='popin '>
        <h2 class='page-subTitle2'>
          <span> Select your file </span>
        </h2>
        <filter-select :elems="appVersion" :placeholder='appVersion[0]' idFilter='date' @dataSelected="versionSelected"></filter-select>
        <div class='popin-title'> {{getVersionValue[versionFilterValue].application}} {{getVersionValue[versionFilterValue].versionName}}</div>
        <div class='popin-histo-content' v-if='versionFilterValue'>
          <div class='information-item sort-icons-wrapper'>
            <span id="versions-add-popin-label-package-name" class='label'>Package Name : </span>
            <span id="versions-add-popin-value-package-name" class='value'>{{getVersionValue[versionFilterValue].packageName}}
            </span>

          </div>
          <div class='information-item'>
            <span id="versions-add-popin-label-branch" class='label'>Branch : </span>
            <span id="versions-add-popin-value-branch" class='value'>{{getVersionValue[versionFilterValue].branch}}</span>
          </div>
          <div class='information-item'>
            <span id="versions-add-popin-label-flavor" class='label'>Flavor : </span>
            <span id="versions-add-popin-value-flavor" class='value'>{{getVersionValue[versionFilterValue].flavor}}</span>
          </div>
          <div class='information-item'>
            <span id="versions-add-popin-label-version-name" class='label'>Version Name : </span>
            <span id="versions-add-popin-value-version-name" class='value'>{{getVersionValue[versionFilterValue].versionName}}</span>
          </div>
          <div class='information-item'>
            <span id="versions-add-popin-label-version-code" class='label'>Version Code : </span>
            <span id="versions-add-popin-value-version-code" class='value'>{{getVersionValue[versionFilterValue].versionCode}}</span>
          </div>
          <div class='information-item'>
            <span id="versions-add-popin-label-data-change" class='label'>Date change : </span>
            <span id="versions-add-popin-value-data-change" class='value'>{{getVersionValue[versionFilterValue].dateChange}}</span>
          </div>
          <br>
          <div class="formActions">
            <md-button id="versions-add-popin-btn-return" class="md-raised" @click="popin = null">Return</md-button>
            <md-button id="versions-add-popin-btn-select-this-version" class="md-raised md-primary" @click='validversion'>Select this version</md-button>
          </div>
        </div>
      </div>
    </div>
    <!-- No version to load -->
    <div v-if="popin=='noversion'" class="popin popin-noversion">
      <span>There is no version of {{this.$route.query.appName}} available on our repository . </span>
      <br>
      <md-button id="versions-add-no-version-to-load-btn-return" class="md-raised" @click="popin = null" >Return</md-button>
    </div>

  </div>
</template>

<script type="text/javascript">
  import Vue from 'vue';
  import VueRouter from 'vue-router';
  import Moment from 'moment';
  import 'vue-material/dist/theme/default.css'
  const utils = require('../../utils/utils.js');
  const uploadService = require('../../service/upload-service');
  import VersionService from '../../service/version-service';
  import FieldConstant from './../../utils/field-constant';
  import GoodPractices from "@/components/Alerts/good-practices";
  const {messageConstant} = require('@/utils/message-constant');
  import AppUtils from "@/utils/application-utils";

  export default{
      components: {
          GoodPractices
      },
    data : function(){

      return {
        active: 'zero',
        zero: false,
        first: false,
        second: false,
        third: false,
        newDescription: "",
        editingDescription: {},
        fileName : '.apk or .aab',
        popin : null,
        listOfSteps : ['File', 'Version summary','What news ?', 'Rights'],
        file : null,
        loading : false,
        versionFilterValue : '0',
        urlValidation : '',
        appVersion :[],
        getVersionValue : [],
        activeTab : "tab-english",
        translation : false,
        editTranslation : true,
        uploadError: null,
        tooltipDownloadVersion : this.$store.state.labels.VERSION_DOWNLOAD_IMPOSSIBLE,
        ALLOWED_EXTENSIONS_WITH_APK: FieldConstant.APPLICATION.ALLOWED_EXTENSIONS_WITH_APK,
        ALLOWED_EXTENSIONS_WITHOUT_APK: FieldConstant.APPLICATION.ALLOWED_EXTENSIONS_WITHOUT_APK,
        ERROR_MSG: {
            APPLICATION_ADD_BAD_EXTENSION_WITH_APK: messageConstant.APPLICATION_ADD_BAD_EXTENSION_WITH_APK,
            APPLICATION_ADD_BAD_EXTENSION_WITHOUT_APK: messageConstant.APPLICATION_ADD_BAD_EXTENSION_WITHOUT_APK,
            APPLICATION_ADD_BIG_SIZE: messageConstant.APPLICATION_ADD_BIG_SIZE,
            APPLICATION_FILE_NAME_RESTRICTION: messageConstant.APPLICATION_FILE_NAME_RESTRICTION
        },
        WARNING_MSG: {
            APPLICATION_SIZE_NEAR_TO_LIMIT_WARNING: messageConstant.APPLICATION_SIZE_NEAR_TO_LIMIT_WARNING
        }
      }
    },
    asyncComputed : {
      versionData: function(){

        var id = this.$route.query.id;
        if(id > -1){
          return Vue.http.get(
            this.$store.state.getUrlWithParams(
              this.$store.state.urlVersionId,
              {
                idVersion : id
              })).then((response) => {
            var version = response.body.data;
            var versionDescriptionGB =[];
            var versionDescriptionFR =[];
            var versionDescriptionDE =[];
            var versionDescriptionES =[];
            var versionDescriptionPT =[];
            var versionDescriptionCN =[];

            if(version.versionDescription !== []){
              version.versionDescription.forEach(function(description,i){
                switch(description.language){
                  case("fr-FR"): versionDescriptionFR = description.description; break;
                  case("en-GB"): versionDescriptionGB = description.description; break;
                  case("de-DE"): versionDescriptionDE = description.description; break;
                  case("es-ES"): versionDescriptionES = description.description; break;
                  case("pt-PT"): versionDescriptionPT = description.description; break;
                  case("zh-CN"): versionDescriptionCN = description.description; break;
                }
              })
              this.translation = true;
            }
            const toReturn = {
              id : version.id,
              versionDescriptionGB : versionDescriptionGB,
              versionDescriptionFR : versionDescriptionFR,
              versionDescriptionDE : versionDescriptionDE,
              versionDescriptionES : versionDescriptionES,
              versionDescriptionPT : versionDescriptionPT,
              versionDescriptionCN : versionDescriptionCN,
              packageName : version.uid,
              version : version.versionName,
              versionCode : version.versionCode,
              fileSize : version.fileSize,
              sitesOpen : version.eligibility.replace('[', '').replace(']', '').split(','),
              url : version.file,
              file : version.file,
              filePath : version.filePath,
            }
            return toReturn;
          });
        }
        else{ // Si on add
          return new Promise(
            function(resolve, reject){
              resolve({
                id : '',
                packageName : '',
                versionDescriptionGB : [],
                version : '',
                versionCode : '',
                sitesOpen : '',
                url : '',
                fileSize :''
              })
            }
          );
        }
      }
    },

    computed : {
      toSend : function(){

        var descriptionToSend =[];
        if(this.translation == true){
          //envoyer toutes les langues sauf l'anglais
          descriptionToSend.push({
              "language" : "fr-FR",
              "description" : this.versionData.versionDescriptionFR,
            },
            {
              "language" : "de-DE",
              "description" : this.versionData.versionDescriptionDE,
            },
            {
              "language" : "es-ES",
              "description" : this.versionData.versionDescriptionES,
            },
            {
              "language" : "pt-PT",
              "description" : this.versionData.versionDescriptionPT,
            },
            {
              "language" : "zh-CN",
              "description" : this.versionData.versionDescriptionCN,
            })
        }
        else{
          //envoyer que l'Anglais
          descriptionToSend.push({
            "language" : "en-GB",
            "description" : this.versionData.versionDescriptionGB,
          })
        }

        return {
          "versionId" : this.versionData.id ,
          "appId" : this.$route.params.id ,
          "versionDescription" : descriptionToSend,
          "versionName" : this.versionData.version,
          "versionCode" : this.versionData.versionCode,
          "eligibility" : this.versionData.sitesOpen.toString(),
          "filePath" : this.versionData.filePath,
          "file": this.versionData.file,
          "uid" : this.versionData.packageName,
          "fileSize" : this.versionData.fileSize,
          "sdkVersion" : this.versionData.sdkVersion,
          "platform" : this.versionData.filePath.endsWith(".apk") || this.versionData.filePath.endsWith(".aab") ? "ANDROID" : "IOS",
        };

      },
      appSizeCheckResult() {
          return AppUtils.checkAppSize({typeSource: this.versionData.filePath, size: this.versionData.fileSize},
              0.9, data => data);
      }
    },
    methods : {
      updateUnderstood(){
        this.setDone('zero', 'first');
      },
      async download(version){
        await VersionService.download(version.filePath);
      },
      setDone (id, index) {
        this[id] = true
        if (index) {
          this.active = index
        }
      },
      logEditTranslation :function () {
        this.editTranslation = false;
      },
      logEnglish : function(){
        this.translation = false;
      },

      tabActive : function(activetab){
        this.activeTab = activetab;
      },

      addDescription: function () {
        if (this.newDescription && this.newDescription.length > 0) {
          this.versionData.versionDescriptionGB.push(this.newDescription);
            this.newDescription = "";
        }
      },

      removeDescription: function (description) {
        var index = this.versionData.versionDescriptionGB.indexOf(description);
        var id = this.$route.query.id;
        this.versionData.versionDescriptionGB.splice(index, 1);
        if(id > -1){
          this.versionData.versionDescriptionFR.splice(index, 1);
          this.versionData.versionDescriptionDE.splice(index, 1);
          this.versionData.versionDescriptionES.splice(index, 1);
          this.versionData.versionDescriptionPT.splice(index, 1);
          this.versionData.versionDescriptionCN.splice(index, 1);
        }
      },

      editDescription: function (description) {
        this.editingDescription = description;
      },

      endEditing: function (description) {
        this.editingDescription = {};
        if (description === ""){
          this.removeDescription(description);
        }
      },

      getVersion : function(){
        this.loading = true;
        if(this.$route.query.appUid != null){
          return Vue.http.get(
            this.$store.state.getUrlWithParams(
              this.$store.state.urlAWSAppRepo, {uid : this.$route.query.appUid})).then((response) => {
              if(response.body != null){
                var apklist = response.body.ListRelease;
                var infos = [];
                var versions = [];
                var self = this;
                apklist.forEach(function(el, i){

                  var versionCode = ''
                  if (el.versionCode.match(/[^0-9\.]+/g)){
                    versionCode =el.versionName.replace(/[^0-9]+/g, '');
                  }
                  else{
                    versionCode = el.versionCode;
                  }
                  versions.push(el.versionName + ' - '+Moment.unix(el.dateChange/1000).format('DD/MM/YYYY HH:mm:ss') );
                  infos.push({
                    application : el.application,
                    dateChange : Moment.unix(el.dateChange/1000).format('DD/MM/YYYY HH:mm:ss'),
                    packageName : el.packageName,
                    versionName : el.versionName,
                    versionCode,
                    branch : el.branch,
                    flavor : el.flavor,
                    filePath: el.FileName,
                    file : self.$store.state.getUrlWithParams(self.$store.state.urlAWSS3MobileRepo, {fileName : el.FileName}),
                    fileSize : el.fileSize,
                  });
                });
                this.appVersion = versions;
                this.getVersionValue = infos;
                this.loading =false;
                this.popin='download';
                return infos;
              }
              else{
                //Pas de version
                this.loading =false;
                this.popin='noversion';
              }
            }
            , (error) => {
              console.log(error);
            });
        }
      },

      openFileUpload : function(){
        document.getElementById('fileApp').click();
      },
      displayUploadError: function(message) {
        this.uploadError = message;
        this.loading = false;
      },
      checkApp : function(value) {
        this.loading = true;
        var path = value.target.value;

        if(path == '') {
          this.loading = false;
          return;
        }

        var file = {
          name: path.substring(path.lastIndexOf('\\') + 1),
          extension: path.substring(path.lastIndexOf('.') + 1),
          size : value.target.files[0].size
        }

        try {
            AppUtils.checkAppSize({typeSource: file.name, size: file.size});
        } catch (e) {
            this.displayUploadError(e.message);
            return;
        }

        if ( file.size > 500000000) {
          this.displayUploadError(this.ERROR_MSG.APPLICATION_ADD_BIG_SIZE);
          return;
        }

        if (!this.ALLOWED_EXTENSIONS_WITH_APK.includes(file.extension)) {
            this.displayUploadError(this.ERROR_MSG.APPLICATION_ADD_BAD_EXTENSION_WITH_APK);
            return;
        } else if (file.extension === "apk" && this.$route.query.hasAab === 'true') {
            this.displayUploadError(this.ERROR_MSG.APPLICATION_ADD_BAD_EXTENSION_WITHOUT_APK);
            return;
        } else if (!/^[A-Za-z][A-Za-z0-9-_.]*$/.test(file.name.replace('.'+file.extension, ''))) {
            this.displayUploadError(this.ERROR_MSG.APPLICATION_FILE_NAME_RESTRICTION);
            return;
        }

        this.fileName = file.name;

        uploadService.uploadFileByChunk(value.target.files[0]).then((appInfo) => {
          if (!appInfo) {
            this.displayUploadError("A problem occurred during upload. Please retry, or contact an administrator");
            return;
          }

          if (appInfo.package_name !== this.$route.query.appUid) {
            this.displayUploadError('Error : The file you are trying to add is not a version of ' + this.$route.query.appName);
            return;
          }
          this.uploadError = null;
          this.loading = false;
          this.versionData.url = appInfo.url;
          this.versionData.packageName = appInfo.package_name;
          this.versionData.filePath = appInfo.file_path;

          this.versionData.version = appInfo.version_name;
          this.versionData.fileSize = appInfo.file_size;
          this.versionData.versionCode = appInfo.version_code;
          this.versionData.sdkVersion = appInfo.sdk_version;
          this.versionData.platform = appInfo.platform;

          this.$ga.event({eventCategory: 'version', eventAction: 'upload', eventLabel: JSON.stringify(this.versionData)});

        }).catch (e => {
          this.manageError(e);
        })
      },
      validversion : function(value){
        this.popin= null;
        this.active = 'second';
        this.versionData.versionCode = this.getVersionValue[this.versionFilterValue].versionCode;
        this.versionData.fileSize = this.getVersionValue[this.versionFilterValue].fileSize;
        this.versionData.filePath = this.getVersionValue[this.versionFilterValue].filePath;
        this.versionData.file = this.getVersionValue[this.versionFilterValue].file;
        this.versionData.url = this.getVersionValue[this.versionFilterValue].url;
        this.versionData.packageName = this.getVersionValue[this.versionFilterValue].packageName;
        this.versionData.version = this.getVersionValue[this.versionFilterValue].versionName;
      },

      sendData : function(){
        this.loading = true;

        return Vue.http.post(`${this.$store.state.urlVersionSaved}`, this.toSend).then((response) => {

            this.$ga.event({eventCategory: 'version', eventAction: 'update', eventLabel: JSON.stringify(this.toSend)});

            this.$router.push({
            'path' : `/applications/${this.$route.params.id}`,
            'query' : {
              'confirm' : 'confirmUpdateVersion'
            }
          });
          this.loading = false;
        }, (error) => {
          console.log(error);
          this.loading = false;
          this.manageError(error.body);
        });
      },
      manageError: function(e) {
          const errorMessageRaw = e.message || e.error || "A problem occurred during upload. Please retry, or contact an administrator";

          if(!e || !e.errors || (Array.isArray(e.errors) && !e.errors[0].errorMessage)) {
          this.displayUploadError(errorMessageRaw);
          return;
        }

          this.displayUploadError(
            e.errors.map( err => {
                let msgError;
                try {
                    msgError = JSON.parse(err.errorMessage).error
                } catch (e) {
                    msgError = err.errorMessage
                }
                return msgError;
            }).join("<br />")
        );

      },
      cancel : function(){
        document.location.href = document.location.href.substring(0, document.location.href.lastIndexOf('/'));
      },
      changeArbo : function(value){
        this.versionData.sitesOpen = value.country;
      },
      versionSelected : function(value){
        var index = this.appVersion.indexOf(value.value)+'';
        this.versionFilterValue = index;

      }
    },
    watch : {
      popin : function(){
        if(this.popin != null){
        } else {
          document.querySelector('body').style.overflow = 'scroll';
        }
      }
    },
    mounted : function(){

    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/style/color';
  @import '~@/assets/style/formBySteps';
</style>

<style lang="scss">
  @import './addVersion';
  @import '~@/assets/style/button';
</style>
