import UserUtils from "./user-utils";
import AuthenticationService from "../service/auth/authentication-service";

const API_PORTAL_PREFIX_EMM = "EMM";
const API_PORTAL_PREFIX_EMM_UNSECURED = API_PORTAL_PREFIX_EMM + "_UNSECURED";

export default {

    getUrlWithParams(storeUrl, params)  {
        let url = storeUrl;
        for (let [key, value] of Object.entries(params)) {
            url = url.replace("${"+key+"}", value);
        }
        return url;
    },

    updateUrlAndApiKeyIfNeeded(request) {
        const bearerToken = AuthenticationService.getSingleInstance().getAccessToken();

        if (bearerToken) {
            request.headers.set("Authorization", "Bearer " + bearerToken);
            if (request.url.includes(process.env.VUE_APP_BASE_EU_PATH_URL_API_PORTAL)) {
                const EMM_USED = request.url.includes(process.env.VUE_APP_API_PORTAL_URL_EMM) ? API_PORTAL_PREFIX_EMM
                    : request.url.includes(process.env.VUE_APP_API_PORTAL_URL_EMM_UNSECURED) ? API_PORTAL_PREFIX_EMM_UNSECURED : "";
                if (EMM_USED !== "") {
                    request.headers.set("x-api-key", process.env["VUE_APP_API_PORTAL_KEY_" + EMM_USED]);
                }
                if (UserUtils.isChinese()) {
                    request.url = replaceApiPortalUrlByChina(request.url);
                }
            }

            if(request.url.includes(process.env.VUE_APP_URL_USERINFO_API)) {
                request.headers.set("x-api-key-md", process.env["VUE_APP_API_MASTERDATA_KEY"]);
            }
        }
    },

    /**
     * retryRequestWhenFailed
     * @param {Function} request - A function that returns a Promise representing the request.
     * @param {Object} retryConfig - An object containing properties 'count' (number) and 'delay' (number).
     */
    async retryRequestWhenFailed(request, retryConfig) {
        try {
            return await request();
        } catch (e) {
            if ((retryConfig.codePrefix || [1,2,3,4]).includes(Number(String(e.status)[0]))) throw e;
            if (retryConfig.count <= 0) {
                throw new Error(`Request failed after the retries, error: ${e.message}`);
            }
            console.warn(`Retrying ${retryConfig.demander ? "for "+retryConfig.demander : ""}: ${JSON.stringify(retryConfig)}`);
            await new Promise(resolve => setTimeout(resolve, retryConfig.delay));
            return await this.retryRequestWhenFailed(request,
                {...retryConfig, count: retryConfig.count - 1, delay: retryConfig.delay * 2});
        }
    }
};

const replaceApiPortalUrlByChina = (url) => {
    return url.replace(process.env.VUE_APP_BASE_EU_PATH_URL_API_PORTAL, process.env.VUE_APP_BASE_CN_PATH_URL_API_PORTAL);
};
