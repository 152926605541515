<template>
    <div>
        <v-app-bar >
            <v-app-bar-nav-icon id="burger-menu-btn-navigation" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title v-if="user" class="xs-app-title"> {{user.displayName + " [" + user.uid + "]"}}</v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
            <v-list>
                <v-list-item >
                    <v-list-item-content>
                        <v-list-item-title class="title">
                            Menu
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list >
                <v-list-item-group active-class="deep-purple--text text--accent-4" class="text-align-start">
                    <generic-nav />
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import Vue from 'vue';
    import GenericNav from './generic-nav';
    import 'vue-material/dist/theme/default.css';

    export default {
        components: {
            'generic-nav': GenericNav,
        },
        data: function () {
            return {
                drawer: false
            }
        },
        props: {
            user: {
                type: Object
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../scss/header';

    .text-align-start {
        text-align: start;
    }

    .xs-app-title {
        font-size: initial;
    }
</style>
