<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.04167 3.37506H8.79167V4.54173H17.5V5.79173H8.79167V7.12506H5.04167V3.37506ZM6.29167 4.62506V5.87506H7.54167V4.62506H6.29167ZM5 5.79173H2.5V4.54173H5V5.79173ZM11.2083 8.12506H14.9583V11.8751H11.2083V10.6251H2.5V9.37506H11.2083V8.12506ZM12.4583 9.37506V10.6251H13.7083V9.37506H12.4583ZM15 9.37506H17.5V10.6251H15V9.37506ZM5.04167 12.8751H8.79167V14.2084H17.5V15.4584H8.79167V16.6251H5.04167V12.8751ZM6.29167 14.1251V15.3751H7.54167V14.1251H6.29167ZM5 15.4584H2.5V14.2084H5V15.4584Z" fill="#3643BA"/>
    </svg>
</template>

<script>
export default {
    name: "filter-by-site"
};
</script>

<style scoped lang="scss">

</style>
