import Vue from "vue";
import {store} from "../../store/store";
import FieldConstant from '../../utils/field-constant';
import NoBranchException from '../../error/no-branch-exception';
import NotFoundException from '../../error/not-found-exception';
import { matchDecathlonUid } from '../../utils/form-validator-utils';

export default {

    async isPartOfMdmTeam() {
        try {
            const response = (await Vue.http.get(store.state.urlUserAdmin + '/is-mdm-team')).body;
            return response.is_mdm_team;
        } catch(e) {
            console.warn("Can't get mdm team part", e);
            return false;
        }
    },
    async getByUid(uid) {
        return getUserFromLdap(uid).catch(errorManagement);
    },
    async getCacheKeysByUid(uid) {
        const args = "/cache/keys/_search?term=" + uid;
        return Vue.http.get(process.env.VUE_APP_URL_ADMIN + args).then((response) => {
            return response.status === 204 ? [] : response?.body;
        }, errorManagement);
    },
    async deleteCacheKeysByUid(uid) {
        const args = "/cache/key/" + uid + "*";
        return Vue.http.delete(process.env.VUE_APP_URL_ADMIN + args).then((response) => {
            return response?.body;
        }, errorManagement);
    },

    async checkIfOrganisationUnitIsRetail(userEmail) {
        // http code 204 -> Branch, but NOT RETAIL
        // http code 404 -> no branch at all
        // http code 200 -> RETAIL is YES
        let isRetail = false;
        try {
            const response = await Vue.http.get(store.state.getUrlWithParams(
                store.state.urlApiPortalUserFromGoogleApis
                    + FieldConstant.ENTITY.RETAIL_ID, {userEmail}));
            response?.status === 200 && (isRetail = true);
        }catch(e) {
            if ( e?.code === 404 ){
                console.warn("Can't get the organisation unit of this user", e);
                throw new NoBranchException();
            }
            return manageNotFoundException(e);
        }
        return isRetail;
    },
    async userIsRetail(uidOrEmail) {
        const userEmail = await transformUidToEmail(uidOrEmail);
        return await this.checkIfOrganisationUnitIsRetail(userEmail);
    }
}

const errorManagement = (error) => {
    const msg = "An error occurred on User API";
    console.error(msg, error);
    return {error: error.bodyText};
};

const EMAIL_REGEX = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const transformUidToEmail = async (uid) => {
    if(matchDecathlonUid(uid)) {
        const user = await getUserFromLdap(uid);
        return user?.mail;
    } else if(!EMAIL_REGEX.test(uid)) {
        return null;
    }
    return uid; // This is already an email
}

const getUserFromLdap = async (uid) => {
    const args = "?uid=" + uid;

    try {
        const response = await Vue.http.get(store.state.urlApiPortalDirectoryUsers + args);
        return response?.body;
    } catch(e) {
        console.warn("Can't get the user with this uid", e);
        return manageNotFoundException(e);
    }
}

const manageNotFoundException = (error) => {
    if((error.code || error.status) && (error.code === 404 || error.status === 404)) {
        throw new NotFoundException();
    }
    throw error;
}
