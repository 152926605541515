<template>
    <v-row justify="center">
        <div v-if="!isDisplayButtonEditMode" class="edit-kiosk-confirm-btn">
            <span aria-label="Button to close edit hours mode"
                  class="vtmx-close-circle-line"
                  @click="changeEditMode(false)"
            />
            <span aria-label="Button to valid hours"
                  class="vtmx-checkbox-circle-line"
                  @click="updateKiosk(); changeEditMode(false)"
            />
        </div>

        <mdm-vtmn-button
            v-else
            id="devices-details-btn-edit-kiosk-hour"
            variant="secondary"
            icon="vtmx-edit-fill"
            @click="changeEditMode(true)"
            class="edit-kiosk-btn"
            :size="'small'"
        >
            Edit
        </mdm-vtmn-button>
    </v-row>
</template>

<script>
import KioskInfosServices from '../../../service/devices/kiosk-info-service'

export default {
    name: "buttonsChangeKioskHours",
    props : {
        isDisplayEditMode: {
            type: Boolean,
            required: true
        },
        title : {
            type: String,
        },
        lockTime : {
            type: String
        },
        rebootTime : {
            type: String
        }
    },
    data() {
        return {
            isDisplayEditModeData : this.isDisplayEditMode
        }
    },
    computed: {
        isDisplayButtonEditMode() {
            return !this.isDisplayEditModeData
        }
    },
    methods : {
        updateKiosk() {
            this.$emit('updateKiosk', {
                rebootTime: KioskInfosServices.splitHourMinute(this.rebootTime),
                lockTime: KioskInfosServices.splitHourMinute(this.lockTime)
            })
            this.$emit('refreshHours', this.rebootTime, this.lockTime)
        },
        changeEditMode(isDisplayEditMode) {
            this.isDisplayEditModeData = isDisplayEditMode
            this.$emit('changeEditMode', this.isDisplayEditModeData)
        }
    }
};
</script>

<style scoped>
.vtmx-close-circle-line {
    color: var(--vtmn-color_danger);
    font-size: 25px;
    display: inline-block;
    cursor: pointer;
}

.vtmx-checkbox-circle-line {
    color: var(--vtmn-color_success);
    font-size: 25px;
    display: inline-block;
    cursor: pointer;
}

</style>
