export function getSteps(steps, stepNameToSetDone) {
    steps.forEach(s => {
        stepNameToSetDone === "none" ?
            s.value = false :
            s.type === stepNameToSetDone ?
                s.value = true :
                ''
    });
    return steps;
}

export function getActiveStep(prefixId, stepNameToActivate) {
    return prefixId + stepNameToActivate;
}
