<template>
  <div >
    <div class='page-title' @click='cancel'>
      <span> < </span>
      <span>{{appName}}</span>
    </div>
    <div class="priority">
      <h1 class='page-title2'>
        <span>Retry</span>
      </h1>
      <div class="page-subtitle">Retry to calculate the following groups :</div>

      <div>
        <ul class="ul-retry" v-for='group in groups' :key="group.id">
          <li class="li-retry">#{{group.priority}} {{group.name}}</li>
        </ul>
        <br>
      </div>
      <div class="formActions">
        <md-button class="md-raised" @click='cancel'>Cancel</md-button>
        <md-button class="md-raised md-primary" @click='retryPriority($route.params.id)'>Retry</md-button>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import Vue from 'vue';
  import 'vue-material/dist/theme/default.css'


  export default{
    data(){
      return {
        appName : localStorage.getItem('current_app_name'),
        groups : JSON.parse(localStorage.getItem('groups_failed_or_todo')),
      }
    },
    methods : {
      cancel : function(){
        this.$router.push(`/applications/${this.$route.params.id}`)
      },
      retryPriority : function(id){
          return Vue.http.post(this.$store.state.getUrlWithParams(
              this.$store.state.urlRetryCompletePrio,
              {
                  idApplication : id,
              })).then(() => {
          this.$router.push({
            'path' : `/applications/${this.$route.params.id}`
          });
        }, (error) => {
          console.log(error);
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/style/color';
  @import '~@/assets/style/formBySteps';
  @import './retryCompletePriority';

  .filter-toggle, .filter-toggle-container{
    display : inline-block;
  }
  .filter-toggle {
    vertical-align : top;
  }
</style>
