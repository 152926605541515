<template>
    <div class="page-content full-size">
        <mdm-vitamine-back-link link="/applications/new" id="application-add-button-back">
            ADD AN APP
        </mdm-vitamine-back-link>

        <p class="vtmn-typo_title-5 app-title">Add a Decathlon's app</p>

        <v-row no-gutters class="form-flex">
            <v-col cols="12" md="6">
                <private-app-information-form-stepper />
            </v-col>

            <v-col cols="12" md="6" align-self="center" v-if="!$vuetify.breakpoint.smAndDown" align="center">
                <div id="info-panel">
                    <info-card :type="'application'" />
                    <mdm-vtmn-popup ref="popupDeployment"
                                    id-button-open="application-add-button-valid-and-deploy"
                                    class-button-open="vtmn-btn add-app-valid-button"
                                    :disabled-button="!canDeploy">
                        <template v-slot:button-open-content>Valid and deploy my application</template>

                        <mdm-vitamine-save-application-card dispatch-route="application/save"
                                                            :app-type="appTypeEnum.PRIVATE_APP"
                                                            :security-validation-status="application.playstoreReady"/>
                    </mdm-vtmn-popup>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script type="text/javascript">
    import MdmVitamineCard from '../../../components/mdm/vitamine/card/mdm-vtmn-card';
    import MdmVitamineSaveApplicationCard from '../../../components/mdm/vitamine/card/mdm-vtmn-save-application-card';
    import MdmVitamineBadge from '../../../components/mdm/vitamine/badge/mdm-vtmn-badge';
    import MdmVitamineBackLink from '../../../components/mdm/vitamine/link/mdm-vtmn-back-link';
    import InfoCard from './app-info-card';
    import PrivateAppInformationFormStepper from './private-app-information-form-stepper';
    import AppTypeEnum from '../../../service/application/app-type-enum';

    export default {
        components: {
            PrivateAppInformationFormStepper, MdmVitamineSaveApplicationCard,
            MdmVitamineCard, MdmVitamineBadge, MdmVitamineBackLink,
            InfoCard
        },
        data() {
            return {
                appTypeEnum: AppTypeEnum
            }
        },
        mounted() {
            if(this.application.name == undefined) {
                this.$router.push("/applications/new");
            }
        },
        computed: {
            application() {
                return this.$store.state.application.application;
            },
            canDeploy() {
                return !this.understood && this.application.descriptions?.length > 0 &&
                    this.$store.state.application.steppers.activeStep === 'authorization';
            }
        }
    }

</script>

<style lang="scss" >
    @import './scss/new-app.scss';

    .add-app-valid-button {
        width:100%;
        margin-top: 20px;
    }

    #info-panel {
        width: 60%;
    }


</style>
