<template>
    <v-col cols="12" md="3" sm="6" class="info-component">
        <h2 class="md-title"><md-icon>info</md-icon> Enrollment</h2>
        <div class='information-phone area'>
            <div class='information-item'>
                <span class='label'>Business unit :</span>
                <span class='value'>{{device.businessUnit}}</span>
            </div>

            <div class='information-item'>
                    <span class='label'>Site : </span>
                    <span :class="{'labeledit' : canEditSite()}"
                          id="labeledit-modifysite" @click="openChooseSite">
                        {{isKiosk && kiosk && kiosk.kioskSite ? kiosk.kioskSite : device.site}}
                    </span>
                    <device-site-choose :is-loading="isSiteChanging" :editable="canEditSite()" v-if="hasEditRights()" @storeSelected="registerDevice" />
            </div>

            <div v-if="isKiosk && kiosk">
                <div v-if="userUtils.canUpdateKioskInfo()">
                    <div class='information-item'>
                        <span class='label'>Name :</span>
                        <span v-if="!editModifyName" id="labeledit-modifyname" class="labeledit" @click="editModifyName=true,editModifyPlace=false">{{kiosk.kioskName}}</span>
                        <input v-else id="inputedit-modifyname" class="inputedit" v-on:keyup.enter="textUpdated('inputedit-modifyname', 'name')" v-on:blur="textUpdated('inputedit-modifyname', 'name')" v-bind:value="kiosk.kioskName" />
                        <span class='editButton' @click="editModifyName=true,editModifyPlace=false"><md-icon>edit</md-icon></span>
                    </div>
                    <div id='placeDiv' class='information-item'>
                        <span class='label'>Place :</span>
                        <span v-if="!editModifyPlace" id="labeledit-modifyplace" class="labeledit" @click="editModifyName=false,editModifyPlace=true">{{kiosk.kioskPlace }}</span>
                        <input v-else id="inputedit-modifyplace" class="inputedit" v-on:keyup.enter="textUpdated('inputedit-modifyplace', 'place')" v-on:blur="textUpdated('inputedit-modifyplace', 'place')" v-bind:value="kiosk.kioskPlace" />
                        <span class='editButton' @click="editModifyName=false,editModifyPlace=true"><md-icon>edit</md-icon></span>
                    </div>
                </div>
                <div v-else>
                    <div class='information-item'>
                        <span class='label'>Name :</span>
                        <span id="label-kiosk-name">{{kiosk.kioskName}}</span>
                    </div>
                    <div id='placeDiv' class='information-item'>
                        <span class='label'>Place :</span>
                        <span id="label-kiosk-place">{{kiosk.kioskPlace }}</span>
                    </div>
                </div>
            </div>

            <div class='information-item'>
                <span class='label'>Country :</span>
                <span class='value'>{{device.country}}</span>
            </div>
            <div class='information-item'>
                <span class='label'>Enrollment date :</span>
                <span class='value'>{{device.enrollmentDate}}</span>
            </div>
            <div class='information-item'>
                <span class='label'>Agent :</span>
                <span class='value'>{{device.agent}}</span>
            </div>
            <div class='information-item'>
                <span class='label'>Ownership :</span>
                <span class='value'>{{device.ownership}}</span>
            </div>
            <div v-if="isKiosk && kiosk && device.androidSilentUpdate" class='information-item'>
                <span class='label'>Localisation :</span>
                <span class='value'>{{device.siteType}}</span>
            </div>
        </div>
    </v-col>
</template>


<script>
    import kioskInfoService from '../../../service/devices/kiosk-info-service';
    import deviceService from '../../../service/devices/device-service';
    import DeviceSiteChoose from './device-site-choose';
    import userUtils from '../../../utils/user-utils';
    import FieldConstant from "../../../utils/field-constant";
    const utils = require('../../../utils/utils');

    const updateInputValue = (idElem, value) => {
        if(document.getElementById(idElem) != undefined){
            document.getElementById(idElem).value = value;
        }
    }

    const getValue = (site, idElem, type) => {
        if(type != 'site'){
            return document.getElementById(idElem).value;
        }
        return site;
    }

    export default {
        name: "enrollment-info",
        components: {DeviceSiteChoose},
        data: function() {
            return {
                canUpdateKiosk: false,
                editModifyName : false,
                editModifyPlace: false,
                kioskSites: JSON.parse(sessionStorage.getItem('kioskSites')),
                isSiteChanging: false,
                userUtils
            }
        },
        props: {
            device: {
                type: Object
            },
            kiosk: {
                type: Object
            },
            isKiosk: {
                type: Boolean
            }
        },
        methods: {
            hasEditRights(){
                return (this.$store.state.user.admin
                    || this.$store.state.user.cp
                    || this.$store.state.user.uid === this.device.lastUsedLogged) && this.isKiosk && this.kiosk;
            },
            canEditSite(){
                return this.hasEditRights() && (this.device.androidSilentUpdate || this.device.agent.toLowerCase().includes(FieldConstant.FILTER.DS2));
            },
            openChooseSite(){
                document.getElementById('device-details-button-choose-site').click();
            },
            registerDevice(store) {
                this.isSiteChanging = true;
                deviceService.registerDevice(this.device, this.kiosk, store)
                    .then(response => {
                        this.isSiteChanging = false;
                        if (Number(this.device.id) !== Number(response.id)) {
                            this.$router.push({
                                'path': `/devices/${response.id}`
                            });
                        } else {
                            this.$router.go();
                        }
                    }).catch((e) => {
                        this.isSiteChanging = false;
                        const msg = "An error occurred when trying to change the site. Contact your administrator.";
                        utils.displayAlertDialog(msg);
                        console.log(msg, e);
                    });
            },
            textUpdated: function(idElem, type){
                const site = this.kiosk.kioskSite;
                const value = getValue(site, idElem, type);
                let name = this.kiosk.kioskName;
                let place = this.kiosk.kioskPlace;

                if(value == ''){
                    updateInputValue("inputedit-modifyname", name);
                    updateInputValue("inputedit-modifyplace", place);
                    utils.displayAlertDialog("The value of this input can't be empty");
                    return;
                }

                switch(type) {
                    case 'name':
                        name = value;
                        this.kiosk.kioskName = value;
                        break;
                    case 'place':
                        place = value;
                        this.kiosk.kioskPlace = value;
                        break;
                }

                let data = {
                    "device_id" : this.device.id.toString(),
                    "device_site" : site ? site : FieldConstant.UNKNOWN,
                    "device_name" : name ? name : FieldConstant.UNKNOWN,
                    "device_place" : place ? place : FieldConstant.UNKNOWN,
                }

                return kioskInfoService.updateKioskInfo(data).then((response) => {
                    this.editModifyPlace = false;
                    this.editModifyName = false;
                }, (error) => {
                    console.error(error);
                });
            }
        }
    }

</script>

<style>
</style>
