<template>
    <div :id="id" class="mdm-text-area">
        <label class="vtmn-text-input_label" :for="id + '-text-area-ref'"> {{ label }} </label>
        <textarea
            :ref="id + '-text-area-ref'"
            :readonly="readOnly"
            :value="content"
            @input="$emit('update:val', $event.target.value); $emit('touched', 'touched');"
            :id="id + '-text-area'"
            class="vtmn-text-input input-fill-all-width "
            :class="{'vtmn-text-input vtmn-text-input--error': isError}"
            :placeholder="placeholder"
            @keyup="$emit('keyup')"
            @blur= "(e) => $emit('onBlur', e)"
        />
        <p
            v-if="isError"
            :id="id + '-error-message'"
            class="vtmn-text-input_helper-text vtmn-text-input_helper-text--error"
        >
            {{ errorMessage }}
        </p>
    </div>
</template>


<script type="text/javascript">

    export default {
        name: 'mdm-text-area',
        props: {
            content: {
                type: String
            },
            id: {
                type: String,
                required: true
            },
            label: {
                type: String
            },
            placeholder: {
                type: String,
            },
            errorMessage: {
                type: String,
            },
            isError: {
                type: Boolean,
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            isFocus : {
                Boolean,
                default: false
            }
        },
        mounted: function() {
            if(this.isFocus) this.focus();
        },
        updated: function () {
            if(this.isFocus) this.focus();
        },
        methods: {
            focus: function () {
                this.$nextTick(() => this.$refs[this.id + '-text-area-ref'].focus());
            },
        }
    }

</script>

<style lang="scss" scoped>
    .mdm-text-area {
        textarea {
            min-height: 30vh;
        }
    }
</style>
