<template>
        <v-row align="center">
            <v-col cols="12" sm="2" >
                <img :src="'data:image/png;base64,'+webview.logo" class="app-icon"/>
            </v-col>
            <v-col cols="12" sm="4" >
                <label for="new-devices-btn-defaultapp-name">Name of your webview</label>
                <input v-model.trim="webview.name" type="text"
                       v-on:input="$v.webview.name.$touch"
                       class="vtmn-text-input input-fill-all-width"
                       :class="{'vtmn-text-input vtmn-text-input--error': $v.webview.name.$error}"
                       id="new-devices-btn-defaultapp-name" placeholder="Name of your webview"/>
                    <p :class="{ 'mdm-visibility-hidden' : !$v.webview.name.$error, 'danger' : $v.webview.name.$error }"><i class="ri-error-warning-line" ></i>{{nameErrorMessage}}</p>
            </v-col>
            <v-col cols="12" sm="4">
                <label for="new-devices-btn-defaultapp-url">Url</label>
                <input v-model.trim="webview.url" type="text"
                       v-on:input="$v.webview.url.$touch"
                       :class="{'vtmn-text-input vtmn-text-input--error': $v.webview.url.$error}"
                       class="vtmn-text-input input-fill-all-width"
                       id="new-devices-btn-defaultapp-url" placeholder="Url"/>
                    <p :class="{ 'mdm-visibility-hidden' : !$v.webview.url.$error, 'danger' : $v.webview.url.$error }">
                        <em class="ri-error-warning-line" /> {{ urlErrorMessage }}
                    </p>
            </v-col>

            <v-col cols="12" class="text-center">
                <md-button id="new-devices-btn-defaultapp-create-webview" class='md-raised md-primary'
                           @click="sendData" :disabled="$v.$invalid">Create my app<md-icon>keyboard_arrow_right</md-icon>
                </md-button>
                <md-tooltip v-if="$v.$invalid" md-direction="top">{{ urlErrorMessage }}</md-tooltip>
            </v-col>

        </v-row>

</template>


<script>

    import Vue from 'vue';
    import Vuelidate from 'vuelidate';
    import {maxLength, minLength, required, url} from "vuelidate/lib/validators";
    import { mustNotContainsBlacklistedUrl, matchUrl } from '../../../../utils/form-validator-utils';
    import {getWebviewNameErrorMessage, getWebviewUrlErrorMessage} from "../../../../utils/error-message-utils";
    import FieldConstant from "../../../../utils/field-constant";
    import WebviewConstant from "../../../../constant/webview-constant";

    export default {
        name: "default-app-webview-info",
        data: () => ({
            webview: {
                name: 'My private webview',
                url: FieldConstant.APPLICATION.WEBAPP.DEFAULT_URL,
                logo: FieldConstant.APPLICATION.WEBAPP.DEFAULT_ICON,
            }
        }),
        validations: {
            webview: {
                name: {
                    required,
                    minLengthValue: minLength(WebviewConstant.NAME.MIN_LENGTH),
                    maxLengthValue: maxLength(WebviewConstant.NAME.MAX_LENGTH)
                },
                url: {
                    required,
                    url, matchUrl,
                    mustNotContainsBlacklistedUrl
                },
                logo: {
                    required,
                }
            }
        },
        computed: {
            urlErrorMessage() {
                return getWebviewUrlErrorMessage(this.webview.url);
            },
            nameErrorMessage() {
                return getWebviewNameErrorMessage(this.webview.name);
            }
        },
        methods: {
            sendData() {
                if (!this.$v.$invalid) {
                    this.$emit('selected',
                        {
                            webview: {
                                name: this.webview.name,
                                url: this.webview.url,
                            }
                        });
                }
            },
        }
    }
</script>
