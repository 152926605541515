export default {
    SET_RAW_VALUE: true,
    FILTER_VERSION: 1,
    ACTIVE_FILTERS: "activeFilters",
    FILTERS_COLUMN: "filtersColumn",
    OAUTH: {
        ACCESS_TOKEN_KEY: "access_token",
        CODE_VERIFIER_KEY: "code_verifier",
        FROM_ENDPOINT: "from_endpoint",
        FED_CALL_TTL: 180000,
    },
    USER: {
        COUNTRY: "user_country"
    },
    GROUPS_FAILED_OR_TODO: "groups_failed_or_todo",
    BU: "BU",
    MIGRATION: {
        ACTIVE_FILTERS: "migrationActiveFilters",
        ACTIVE_ARBO: "migrationActiveArbo",
        ACTIVE_DATE_FILTERS: "activeDateFilters"
    }
}
