<template>
    <div class="mdm-autocomplete mb-5">
        <label class="vtmn-text-input_label" :for="id">
            {{ label }}
        </label>
        <div class="vtmn-text-input_container" >
            <div :id="id" class="vtmn-text-input full-size mb-0 multi-select-input-area">
                <div v-for="item of actualSelectedItems" :key="item" class="actual-selected-item" @click="removeItem($event, item)">
                    {{item}}
                    <md-icon class="actual-selected-item-btn-remove"
                             :id="'actual-selected-item-btn-remove-' + item" >
                        close
                    </md-icon>
                </div>
                <input
                    v-model="searchTerms"
                    type="text"
                    :placeholder="placeholder"
                    @focus="printOption = true"
                    @blur="onBlur"
                    :class="{'vtmn-text-input vtmn-text-input--error': isError}"
                />

                <div v-if="isError">
                    <p><i class="ri-error-warning-line vtmn-text-danger msg-vertical-align"></i> {{errorMessage}}
                    </p>
                </div>
            </div>
            <div class="option-dropdown" v-if="printOption">
                <md-list>
                    <md-list-item v-for="item of actualSearchList" :key="item.versionName" @click="select(item)" :disabled="isActualSelectedItem(transformItem(item))">
                        <slot name="mdm-autocomplete-item" v-bind:item="item"/>
                    </md-list-item>
                </md-list>
            </div>
        </div>
        <p v-if="actualSelectedItems && !actualSelectedIsInAOptions" class="vtmn-text-input_helper-text error-text">
            <md-icon>error</md-icon><slot name="error-text" />
        </p>
    </div>
</template>

<script>
    import Vue from 'vue';
    import ArrayUtils from '../../../../utils/array-utils';

    export default {
        name: 'mdm-autocomplete-multi',
        props: {
            id: {
                type: String
            },
            label: {
                type: String
            },
            placeholder: {
                type: String
            },
            options: {
                type: Array,
                required: true
            },
            value: {
                type: Array
            },
            keyToPrint: {
                type: String,
                required: false
            },
            isError: {
                type: String
            }
        },
        data: function() {
            return {
                printOption: false,
                actualSelectedItems: this.value || new Array(),
                searchTerms: '',
                MAX_ITEMS_TO_SHOW: 100
            }
        },
        watch: {
            value(value) {
                this.actualSelectedItems = value || new Array();
            }
        },
        computed: {
            actualSelectedIsInAOptions() {
                return ArrayUtils.containsAll(this.actualSelectedItems, this.options.map(item => this.transformItem(item)));
            },
            actualSearchList() {
                if(!this.searchTerms || this.searchTerms == "") {
                    return this.options.slice(0, this.MAX_ITEMS_TO_SHOW);
                }

                return this.search()
                    .sort(this.compareSearchItem)
                    .slice(0, this.MAX_ITEMS_TO_SHOW);
            }
        },
        methods: {
            select(item) {
                if(!this.isActualSelectedItem(this.transformItem(item))) {
                    this.actualSelectedItems.push(this.transformItem(item));
                    this.$emit("selected", this.nonTransformList());
                    this.searchTerms = "";
                    this.printOption = false;
                }
            },
            transformItem(item) {
                if(!item) return "";
                return this.keyToPrint ? item[this.keyToPrint] : item
            },
            nonTransformList() {
                return this.actualSelectedItems.map(item =>
                    this.options.find(itemOption => this.transformItem(itemOption) === item)
                );
            },
            search() {
                return this.options.filter(item => {
                    let itemText = item;
                    if(this.keyToPrint) {
                        itemText = item[this.keyToPrint];
                    }
                    return itemText.trim().toLowerCase().includes(this.searchTerms.trim().toLowerCase());
                });
            },
            compareSearchItem(item1, item2) {
                const itemToCompare = this.transformItem(item1);
                const targetItem    = this.transformItem(item2);
                if(this.isActualSelectedItem(itemToCompare)) return 1;
                if(this.isActualSelectedItem(targetItem)) return -1;
                return 0;
            },
            isActualSelectedItem(itemToCheck) {
                return this.actualSelectedItems?.some(item => item === itemToCheck);
            },
            onBlur(event) {
                setTimeout(() => this.printOption = false, 200);
            },
            removeItem(event, itemToRemove) {
                if(event.target.id.startsWith('actual-selected-item-btn-remove-')) {
                    this.actualSelectedItems.splice(this.actualSelectedItems.indexOf(itemToRemove), 1);
                    this.$emit("selected", this.nonTransformList());
                }
            }
        }
    }
</script>

<style lang="scss">
@import './mdm-input.scss';
</style>
