export default {
    ERROR_ENUM: ["DEFAULT", "WEBVIEW", "KIOSK_CONFIG", "NO_AUTHORIZATION"],
    DEFAULT: {
        title: "An error occurred",
        subTitle: "Please contact the mdm team"
    },
    WEBVIEW: {
        title: "An error occurred during the creation of your webview !",
        subTitle: "Please, look at your kiosk in the mdm website"
    },
    KIOSK_CONFIG: {
        title: "An error occurred during the configuration of your kiosk !",
        subTitle: "Please contact the mdm team for help : <FeatureKDMMDM@decathlon.net>"
    },
    NO_AUTHORIZATION: {
        title: "No authorization",
            subTitle: "Please contact the mdm team"
    }
}
