<template>
    <div class="page-content full-size group-is-update">
        <md-icon class="md-size-4x">thumb_up_off_alt</md-icon>
        <p class="vtmn-typo_title-3">Well done ! Your group is deployed.</p>

        <v-row no-gutters class="button-layer">
            <v-col cols="12" md="6">
                <button class="vtmn-btn" id="group-is-update-btn-return-to-app" @click="goToTheApplication">Go back to the application</button>
            </v-col>
            <v-col cols="12" md="6">
                <button class="vtmn-btn" id="group-is-update-btn-go-to-priority" @click="goToThePriorityGroup">Manage the priority of groups</button>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "group-is-deployed",
        methods: {
            goToTheApplication() {
                this.$router.push({
                    path : `/applications/${this.$route.params.id}`
                });
            },
            goToThePriorityGroup() {
                this.$router.push({
                    path : `/applications/${this.$route.params.id}/groupPriority`
                });
            }
        }
    }
</script>

<style lang="scss">
.group-is-update {
    display: flex;
    background: linear-gradient(255.64deg, #02BE8A 4.37%, #007AB8 98.06%);
    box-shadow: -1px 1px 28px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    justify-content: space-evenly;
    align-items: center;

    &, i, p {
        margin: 0;
        color: white !important;
    }

    .button-layer {
        text-align: center;
        flex: none;
        width: 60%;

        @media (max-width: 960px) {
            button {
                width: 100%;

                &:first-child {
                    margin-bottom: 10px;
                }
            }

        }

        @media (max-width: 600px) {
            width: 95%;

        }
    }
}
</style>
