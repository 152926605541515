import { findStepFromStepId } from './generic-stepper';
import DeviceChoice from '../../../../pages/devices/new/step/device-choice';
import DeviceOwnerChoice from '../../../../pages/devices/new/step/device-owner-choice';
import serviceSelectUser from '../../../../pages/devices/new/step/service-select-user';
import ServiceOrRetailChoice from '../../../../pages/devices/new/step/service-or-retail-choice';
import NoBranchUser from '../../../../pages/devices/new/step/no-branch-user';
import SiteChoice from '../../../../pages/devices/new/step/site-choice';
import ModelChoice from '../../../../pages/devices/new/step/model-choice';
import GenericPolicyEnrollmentChoice from '../../../../pages/devices/new/step/generic-policy-enrollment-choice';
import NormalOrGenericPolicyChoice from '../../../../pages/devices/new/step/normal-or-generic-policy-choice';
import StoreChoice from '../../../../pages/devices/new/step/store-choice';
import DefaultAppChoice from '../../../../pages/devices/new/step/default-app-choice';
import DefaultAppWebviewInfo from '../../../../pages/devices/new/step/default-app-webview-info';
import IdleStep from '../../../../pages/devices/new/step/idle-step';
import AAMEnrollment from '../../../../pages/devices/new/step/enrollment/android-enrollment';
import GoogleEndpointManagement from '../../../../pages/devices/new/step/enrollment/google-endpoint-management';
import DS2Enrollment from '../../../../pages/devices/new/step/enrollment/ds2-enrollment';
import WsoEnrollment from '../../../../pages/devices/new/step/enrollment/wso-enrollment';


const buildDescription = (steppers, stepId, descriptionCb) => {
    const data = findStepFromStepId(steppers, stepId).data;
    if(data) return descriptionCb(data);
    return "";
}

export const DEVICE_CHOICE = {
    id: "DEVICE_CHOICE",
    label:"Choose your device type",
    description: (steppers) => buildDescription(steppers, DEVICE_CHOICE.id, (data) => `Use ${data.deviceType}`),
    onClick: true,
    mdDone: true,
    render: DeviceChoice
}

export const DEVICE_OWNER_CHOICE = {
    id: "DEVICE_OWNER_CHOICE",
    label:"Choose if the device is yours or enterprise",
    description: (steppers) => buildDescription(steppers, DEVICE_OWNER_CHOICE.id, (data) => `Use ${data.deviceOwner}`),
    onClick: true,
    mdDone: true,
    render: DeviceOwnerChoice
}

export const SERVICE_SELECT_USER = {
    id: "SERVICE_SELECT_USER",
    label:"Choose if the enrollment is for you or for another user",
    description: (steppers) => buildDescription(steppers, SERVICE_SELECT_USER.id, (data) => !data.isForMe ? `Enrollment for user ${data.userChoose}` : 'Enrollment is for you' ),
    onClick: true,
    mdDone: true,
    render: serviceSelectUser
}

export const NO_BRANCH_USER = {
    id: "NO_BRANCH_USER",
    label:"User has no branch",
    description: (steppers) => buildDescription(steppers, NO_BRANCH_USER.id, () => 'User has no branch'),
    onClick: true,
    mdDone: true,
    render: NoBranchUser
}

export const SERVICE_OR_RETAIL_CHOICE = {
    id: "SERVICE_OR_RETAIL_CHOICE",
    label:"Choose the organisation unit of enrolled user",
    description: (steppers) => buildDescription(steppers, SERVICE_OR_RETAIL_CHOICE.id, (data) => 'User is ' + data.organisationUnit),
    onClick: true,
    mdDone: true,
    render: ServiceOrRetailChoice
}

export const SITE_CHOICE = {
    id: "SITE_CHOICE",
    label:"Choose your site",
    description: (steppers) => buildDescription(steppers, SITE_CHOICE.id, (data) => `Use site ${data.siteType}`),
    onClick: true,
    mdDone: true,
    render: SiteChoice
}

export const NORMAL_OR_GENERIC_POLICY_CHOICE = {
    id: "NORMAL_OR_GENERIC_POLICY_CHOICE",
    label:"Choose if your device is normal or need to be enrolled as custom one",
    description: (steppers) => buildDescription(steppers, NORMAL_OR_GENERIC_POLICY_CHOICE.id, (data) => data.isGenericPolicy ? 'Using custom mode' : 'Using normal enrollment'),
    onClick: true,
    mdDone: true,
    render: NormalOrGenericPolicyChoice
}


export const GENERIC_POLICY_TYPE_CHOICE = {
    id: "GENERIC_POLICY_TYPE_CHOICE",
    label:"Choose the custom mode",
    description: (steppers) => buildDescription(steppers, GENERIC_POLICY_TYPE_CHOICE.id, (data) => data.type ? 'Using ' + data.type : 'Normal enrollment'),
    onClick: true,
    mdDone: true,
    render: GenericPolicyEnrollmentChoice
}

export const STORE_CHOICE = {
    id: "STORE_CHOICE",
    label:"Choose your store",
    description: (steppers) => buildDescription(steppers, STORE_CHOICE.id, (data) => `Use store ${data.id}-${data.name}`),
    onClick: true,
    mdDone: true,
    render: StoreChoice
}

export const APP_DEFAULT_CHOICE = {
    id: "APP_DEFAULT_CHOICE",
    label:"Choose your default app",
    description: (steppers) => buildDescription(steppers, APP_DEFAULT_CHOICE.id, (data) => `Use default app ${data.packageName}`),
    onClick: true,
    mdDone: true,
    render: DefaultAppChoice
}

export const APP_WEBVIEW_INFO = {
    id: "APP_WEBVIEW_INFO",
    label:"Information for the webview",
    description: (steppers) => buildDescription(steppers, APP_WEBVIEW_INFO.id, (data) => `Use webapp name ${data.webview.name} and url ${data.webview.url}`),
    onClick: true,
    mdDone: true,
    render: DefaultAppWebviewInfo
}

export const IDLE_CHOICE = {
    id: "IDLE_CHOICE",
    label:"Turn ON / Turn Off",
    description: (steppers) => buildDescription(steppers, IDLE_CHOICE.id,
        (data) => `${data.rebootTime.hour}h${data.rebootTime.minute} to ${data.lockTime.hour}h${data.lockTime.minute}`
    ),
    onClick: true,
    mdDone: true,
    render: IdleStep
}

export const ANDROID_ENROLLMENT = {
    id: "ANDROID_ENROLLMENT",
    label:"AAM Enrollment",
    onClick: true,
    mdDone: true,
    render: AAMEnrollment
}

export const GOOGLE_ENDPOINT_MANAGEMENT = {
    id: "GOOGLE_ENDPOINT_MANAGEMENT",
    label:"Google process Enrollment",
    onClick: true,
    mdDone: true,
    render: GoogleEndpointManagement
}

export const DS2_ENROLLMENT = {
    id: "DS2_ENROLLMENT",
    label:"DS2 Enrollment",
    onClick: true,    mdDone: true,
    render: DS2Enrollment
}

export const WSO_ENROLLMENT = {
    id: "WSO_ENROLLMENT",
    label:"WSO Enrollment",
    onClick: true,    mdDone: true,
    render: WsoEnrollment
}

export const MODEL_CHOICE = {
    id: "KIOSK_MODEL_CHOICE",
    label:"Choose your kiosk model",
    description: (steppers) => buildDescription(steppers, MODEL_CHOICE.id,
        (data) => `Use ${data.kioskModel}`),
    onClick: true,
    mdDone: true,
    render: ModelChoice
}

export const UNKNOWN_END = {}
