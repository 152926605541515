<template>
  <mdm-button :css="'md-icon-button md-dense'"
              :icon="generateAvailableIcon(version)"
              :tooltip-text="generateAvailableTooltip(version.available)"
              :alwaysDisplayTooltip="! ( version.available && version.available.available ) "
              @mouseover= "displayRetry = true"
              @mouseleave="displayRetry = false"
              @click="sendAvailableRetry(version)"
  />


</template>

<script>
import Vue from 'vue';
import VersionService from "../../../service/version-service";

export default {
  name: 'GroupAppsRetryButton',
  data: function () {
    return {
      displayRetry: false,
      retryAlreadyClick: false,
    }
  },
  props: {
    version: {
      type: Object
    },
  },
	methods: {
    generateAvailableTooltip(availableObject) {
      if( ! availableObject) return "Loading data, please wait ...";

      let appIsUploadedOnEuropeNotOnChina = availableObject.detail.europe && !availableObject.detail.china

        const uploadChinaMessage = " Users will be able to download the app from EU servers. WARN : Can cause errors or longer download time.";

      return "europe : " +
        ( availableObject.detail.europe ? "yes" : "no" )  + ", china : " +
        ( availableObject.detail.china ? "yes" : "no" ) + "." +
        ( appIsUploadedOnEuropeNotOnChina && !this.retryAlreadyClick ? " Click to retry upload on china" : "" ) +
        ( appIsUploadedOnEuropeNotOnChina &&  this.retryAlreadyClick ? uploadChinaMessage : "" ) ;
    },
    generateAvailableIcon(version) {
      let available = version.available;

      if( ! available ) return "loop"; // waiting for response of asynchrone api

      let appIsUploaded = available && available.available
      let appIsUploadedOnEuropeNotOnChina = available.detail.europe && !available.detail.china

      if( this.retryAlreadyClick && appIsUploadedOnEuropeNotOnChina ) return "loop";
      if ( this.displayRetry && ! appIsUploaded && appIsUploadedOnEuropeNotOnChina ) return "replay";
      if( appIsUploaded ) return "done";
      return  "close";
    },
    sendAvailableRetry(version) {
      if(! version.available) return

      let appIsUploadedOnEuropeNotOnChina = version.available.detail.europe && !version.available.detail.china;

      if(! this.retryAlreadyClick && appIsUploadedOnEuropeNotOnChina)
        VersionService.retryUploadApplicationToCN(version.filePath);

      this.retryAlreadyClick = true;
    },
  }
}
</script>
