<template>
    <span class="main-chart">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bar-chart">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M7.45833 2.37537H13.5417V6.54268H18.125V17.6277H2.875V10.71H7.45833V2.37537ZM8.95833 16.1275H12.0417V3.8756H8.95833V16.1275ZM13.5417 16.1275H16.625V8.04291H13.5417V16.1275ZM7.45833 16.1275V12.2102H4.375V16.1275H7.45833Z" :fill="fillColor"/>
            </g>
        </svg>
    </span>
</template>

<script>
export default {
    name: "chart",
    props: {
        fillColor: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.main-chart {
    margin-right: 12px;
}
</style>
