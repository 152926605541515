<template>
    <mdm-vtmn-popup  ref="popupColumns" id="devices-details-button-edit-custom-mode"   >
        <v-snackbar v-if="displayErrorMessage"
            :timeout="-1" :value="true" top color="red accent-2">
            {{ errorMessage || defaultErrorUpdateCustomMode}}
        </v-snackbar>
        <template v-slot:button-open-content >
            <mdm-vtmn-button
                id="devices-details-btn-edit-type-enrolment"
                variant="secondary" icon="vtmx-edit-fill"
                :disabled="!(device.androidSilentUpdate && canChangeOverridenPolicyType())"
                @click="displayErrorMessage = false"
                :text-tooltip="chooseTooltipText"
                :is-tooltip-show="(props) => (props.disabled)"
            >
                EDIT CUSTOM MODE
            </mdm-vtmn-button>
        </template>
        <v-row>
            <v-col align="center" class="mb-6" >
                <div class="title-popup-type-enrolment-bold">{{device.name}} <span aria-hidden="true" class="vtmx-user-line"/></div>
                <div><span class="title-popup-type-enrolment-regular">Currently type of enrolment : </span>
                    <span v-if="device.overriddenPolicyType !== null" class="title-popup-type-enrolment-bold">{{device.overriddenPolicyType}}</span>
                    <span v-else class="title-popup-type-enrolment-bold">NORMAL</span>
                </div>
            </v-col>

          <md-progress-spinner v-if='isLoading' class='loading' :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            <choose-custom-mode v-else :function-country-and-store="getCountryAndStore" @selected="changeOverriddenPolicyType" >
              <template v-slot:cancel-button>
                    <mdm-vtmn-button id="devices-details-btn-edit-type-enrolment" not-block variant="secondary" @click="$refs.popupColumns.close()">
                        CANCEL
                    </mdm-vtmn-button>
                </template>
            </choose-custom-mode>
        </v-row>
    </mdm-vtmn-popup>
</template>

<script>
import ChooseCustomMode from "../../../pages/devices/new/step/generic-policy-enrollment-choice";
import Vue from "vue";
import ApplicationService from "../../../service/application/application-service";
import DeviceService from "../../../service/devices/device-service";
import MessageConstant from "../../../utils/message-constant";


export default {
    name: "editEnrolmentTypeButton",
    components : {
        ChooseCustomMode
    },
    data() {
        return {
            countryAndStore : {},
            defaultErrorUpdateCustomMode: MessageConstant.ERROR_UPDATE_CUSTOM_MODE,
            errorMessage: "",
            displayErrorMessage : false,
            tooltipText: MessageConstant.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_NOT_AAM,
            isLoading : false
        }
    },
    props : {
        device : {
            type : Object,
            required : true
        },
        isTooltipShow: {
          type: Function,
          default: () => false
        }
    },
    methods : {
        canChangeOverridenPolicyType: function() {
            return this.$store.state.user?.cc === true || this.$store.state.user?.cp === true || this.$store.state.user?.appManager === true || this.$store.state.user?.admin === true;
        },
        changeOverriddenPolicyType : async function(newOverriddenPolicyType) {
          this.isLoading = true
            this.device.overriddenPolicyType = newOverriddenPolicyType.type
            try {
                await DeviceService.updateOverriddenPolicyType(this.$route.params.id, newOverriddenPolicyType.type, this.device.type)
                this.$refs.popupColumns.close()
            } catch(e) {
                this.errorMessage = e.body?.error?.msg || e.message;
                this.displayErrorMessage = true
            }
          this.isLoading = false
        },
        async getCountryAndStore() {
            return {types : await ApplicationService.getSpecificApplicationByCountryAndStore(this.device.countryCode, this.device.siteNumber)}
        }
    },
    computed: {
        chooseTooltipText() {
            return this.device.androidSilentUpdate ? MessageConstant.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE : MessageConstant.DEFAULT_NOTIF_BUTTON_ERROR_MESSAGE_NOT_AAM
        }
    }
};
</script>

<style scoped>
.title-popup-type-enrolment-bold {
    font-size: 20px;
    font-weight: bold;
}
.title-popup-type-enrolment-regular {
    font-size: 20px;
}

.vtmx-user-line {
    display: inline-block;
}

</style>
