<template>
    <div
        class="vtmn-progressbar_container vtmn-progressbar_variant--circular mdm-vtmn-loader"
        role="progressbar"
        aria-label="progress bar"
        aria-valuemin="0"
        aria-valuemax="100"
        :aria-valuenow="progress"
    >
        <span class="vtmn-progressbar_label" :data-value="progress"></span>
        <svg>
            <circle
                class="vtmn-progressbar_indicator"
                :stroke-dashoffset="'calc(400 - (400 * '+progress+') / 100)'"
                cx="50%"
                cy="50%"
                r="64"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'mdm-vitamine-loader',
    props: {
        progress: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss" scoped>

.mdm-vtmn-loader{
    margin: 0 auto;
}


</style>
