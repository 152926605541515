<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45411 2.12048C5.73293 1.84166 6.10819 1.70825 6.50022 1.70825H13.5002C13.8922 1.70825 14.2675 1.84166 14.5463 2.12048C14.8251 2.3993 14.9586 2.77456 14.9586 3.16659V3.99992H13.7086V3.16659C13.7086 3.05862 13.6753 3.01721 13.6624 3.00436C13.6496 2.99151 13.6082 2.95825 13.5002 2.95825H6.50022C6.39225 2.95825 6.35084 2.99151 6.33799 3.00436C6.32515 3.01721 6.29188 3.05862 6.29188 3.16659V3.99992H5.04188V3.16659C5.04188 2.77456 5.17529 2.3993 5.45411 2.12048ZM3.03906 4.79159H16.9614L15.3673 17.1015L15.3631 17.1225C15.2348 17.7637 14.6954 18.3749 13.9169 18.3749H6.08355C5.30504 18.3749 4.7656 17.7637 4.63735 17.1225L4.63315 17.1015L3.03906 4.79159ZM4.46137 6.04159L5.86666 16.8936C5.88548 16.9713 5.92459 17.0336 5.96717 17.0732C6.00987 17.1128 6.04921 17.1249 6.08355 17.1249H13.9169C13.9512 17.1249 13.9906 17.1128 14.0333 17.0732C14.0758 17.0336 14.115 16.9713 14.1338 16.8936L15.5391 6.04159H4.46137ZM7.54188 15.3333V7.66659H8.79188V15.3333H7.54188ZM11.2086 15.3333V7.66659H12.4586V15.3333H11.2086Z" fill="#3643BA"/>
    </svg>
</template>

<script>
export default {
    name: "bin"
};
</script>

<style scoped lang="scss">

</style>
