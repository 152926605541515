import UrlLogoUtils from "../../utils/url-logo-utils";
import {store} from "./../../store/store";

const ICON_SET_STORE = 'icons/setIcon';

export default {
    async getAndStoreIconsList(applications){
        store.commit('icons/setIsLoadingIconListToTrue');
        const packageNamesToCheck =  applications.map(app => app.uid).filter(uid => uid != null);
        if (!hasToGetAndStoreIconsList(packageNamesToCheck)) {
            return;
        }

        const baseAppObj = (application) => ({ appId: application.id, appName: application.name, packageName: application.uid, icon: undefined });
        applications.forEach(application => {
            store.dispatch(ICON_SET_STORE, {...baseAppObj(application), isLoading: true})
        });
        const logos = await UrlLogoUtils.getApplicationLogosByPackageNames({packageNames: packageNamesToCheck});
        applications.forEach(application => store.dispatch(ICON_SET_STORE,
            { ...baseAppObj(application), isLoading: false, icon: logos.find(l => l.package_name === application.uid) }));
        store.commit('icons/setIsLoadingIconListToFalse');
    },

}

const hasToGetAndStoreIconsList = (packageNamesFromDb) => {
    const packageNamesFromStore = store.state.icons.iconsList?.map(appWithIcon => appWithIcon.packageName);
    return !packageNamesFromDb.every(packageName => packageNamesFromStore.includes(packageName));
}
