<template>
    <div>
        <mdm-input v-if="filterType.toLowerCase() === this.TEXT" ref="secondSearchInput" id="device-filter-second-mdm-input"
                   :name="name" :errorMessage="errorMessageEmpty" :is-error="isErrorEmpty" :is-focus="isFocus"
                   :val.sync="textSearchCriteria" @keyupEnter="doNewSearch" @update:val="textSearchCriteriaEvent"
                    />

        <mdm-input v-if="filterType.toLowerCase() === this.TEXT" ref="secondSearchInput" id="device-filter-second-mdm-input"

        />

        <mdm-select v-else-if="filterType.toLowerCase() === this.SELECT" :filters="secondFilters"
                    @getFilterEventWithoutType="getFilterEventWithoutType"
                    @getFilterEvent="getOsVersionFilterEvent" id="device-filter-second-mdm-select"/>

        <mdm-date-picker v-else-if="filterType.toLowerCase() === this.DATE_FROM_TO" @getDateEvent="getDateEvent" id="device-filter-second-mdm-date"/>

        <mdm-autocomplete v-else-if="filterType.toLowerCase() === this.AUTOCOMPLETE"
                          :options="secondFilters" :error-message="errorMessageEmpty" :is-error="isErrorEmpty"
                          :is-focus="isFocus"
                          @selected="getFilterEventWithoutType" ref="secondSearchInput"
                          @keyupEnter="doNewSearch"
                          :refreshed="refreshed"
                          :nb-items="nbItems"
                          keyToPrint="key"
                          @checkToRefreshList="$emit('checkToRefreshList')"
                          id="device-filter-second-mdm-autocomplete" >
            <template slot="mdm-autocomplete-item" slot-scope="{ item }">{{ item.key }}</template>
            <template slot="error-text">Please select a version from the list</template>
            <template slot="loading">Loading...</template>
            <template slot="no-item">No items</template>
            <div v-if="isErrorEmpty">
                <p><em class="ri-error-warning-line vtmn-text-danger msg-vertical-align"></em> {{errorMessageEmpty}}</p>
            </div>
        </mdm-autocomplete>


        <mdm-autocomplete v-if="isOsSelected" :options="osVersionFilters" :error-message="errorMessageEmpty" :is-error="isErrorEmpty"
                          @selected="addOsVersionInSearchCriteria" ref="osVersionSearchInput"
                          @keyupEnter="doNewSearch"
                          id="device-filter-os-version-input"
                          :nb-items="nbItems"
                          :placeholder="osVersionPlaceholder"
                          :is-focus="isFocus" >
            <template slot="mdm-autocomplete-item" slot-scope="{ item }">{{ item }}</template>
            <template slot="error-text">Please select a version from the list</template>
            <template slot="no-item">Loading...</template>
            <template slot="no-item">No items</template>
        </mdm-autocomplete>


    </div>
</template>


<script>
import MdmSelect from "../../components/mdm/vitamine/input/mdm-select";
import MdmDatePicker from "../mdm/vitamine/input/mdm-date-picker";
import FieldConstant from "../../utils/field-constant";
let messageConstant = require("../../../src/utils/message-constant")

export default {
    components : {
        MdmSelect,
        MdmDatePicker
    },
    props: {
        nbItems: {
            type: Number
        },
        val: {
            type: String
        },
        refreshed: {
            type: Boolean
        },
        filterType: {
            type: String
        },
        errorMessageEmpty: {
            type: String
        },
        isErrorEmpty: {
            type: Boolean
        },
        errorMessageChoice: {
            type: Boolean
        },
        isErrorChoice: {
            type: Boolean
        },
        name: {
            type: String
        },
        secondFilters : {
            type : Array
        },
        osVersionFilters : {
            type : Array
        },
        osVersionPlaceholder : {
            type : String
        },
        isOsSelected : {
            type : Boolean
        },
        isFocus : {
            type : Boolean
        }

    },
    data:function() {
        return {
            textSearchCriteria : "",
            TEXT: FieldConstant.DEVICE_FILTER_TYPE.TEXT,
            SELECT: FieldConstant.DEVICE_FILTER_TYPE.SELECT,
            AUTOCOMPLETE: FieldConstant.DEVICE_FILTER_TYPE.AUTOCOMPLETE,
            DATE_FROM_TO: FieldConstant.DEVICE_FILTER_TYPE.DATE_FROM_TO,
            PLACEHOLDER: messageConstant.messageConstant.OS_VERSION_PLACEHOLDER
        }
    },
    methods: {
        doNewSearch: function() {
            this.$emit("doNewSearch")
        },
        getFilterEventWithoutType: function(criteria) {
            this.$emit("getFilterEventWithoutType", criteria)
        },
        getOsVersionFilterEvent: function(criteria) {
            this.$emit("getOsVersionFilterEvent", criteria)
        },
        getDateEvent: function(pickerValue) {
            this.$emit("getDateEvent", pickerValue)
        },
        addOsVersionInSearchCriteria: function(criteria) {
            this.$emit("addOsVersionInSearchCriteria", criteria)
        },
        textSearchCriteriaEvent: function() {
            this.$emit("textSearchCriteriaEvent", this.textSearchCriteria)
        }

    }


};


</script>
