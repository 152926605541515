<template>

    <div v-if="data">

        <v-data-table id="cache-search-result-table"
                      :headers="headers"
                      :items="data"
                      :items-per-page="pageSize"
                      hide-default-footer
                      hide-default-header>

            <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :id="'col'+head.text">
                        <span class="text-caption"> {{ head.text }} </span>
                    <hr class="color-grey mt-3 mb-3"/>
                </th>
            </template>

        </v-data-table>

    </div>

</template>

<script>

export default {
    name: 'cache-search-result-table-component',
    props: {

        data: {
            type: Array
        },
        headers: {
            type: Array
        },
        pageSize: {
            type: Number,
            default: 1,
        }
    }

}
</script>

<style lang="scss">
    .color-grey{
        color: var(--vtmn-color_grey-light-1);
    }

    #cache-search-result-table{
        tbody {
            background-color: #eeeeee;
        }
        td{
            padding-top: 15px;
        }
    }

</style>
