<template>
  <div>
    <md-dialog :md-active="showDialog">
      <div>
        <div class="onRight">

          <mdm-button :id="'mdm-button-refresh-dialog'" :text="''" :icon="'replay'" @click="refreshAction" />
          <mdm-button :id="'mdm-button-close-dialog'"   :text="''" :icon="'close'" @click="closeAction" />
        </div>
      </div>

      <div class="dialog-content" md-label="General">
        <img v-bind:src="'data:image/png;base64,'+screenshot" />
      </div>

    </md-dialog>
  </div>
</template>

<script>
// This is a component to display a screenshot on a popup
export default {
  data: function () {
    return {

    }
  },
  props: {
    showDialog: {
      type : Boolean
    },
    screenshot: {
      type: String
    }
  },
  methods: {
    refreshAction : function (traitmentFinish) {
      // Fire when you click on refresh button
      // @arg The argument is a calback to call when you finish your action on the click. It allow to stop the progress-spinner and display the icon
      this.$emit('refresh', traitmentFinish);
    },
    closeAction : function () {
      //  Fire when you click on close button
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
  .md-dialog {
    max-width: 850px;
    min-height: 550px;
  }
  .onRight {
    right: 0px;
    position: absolute;
  }
  .dialog-content {
    margin-top: 50px;
    padding: 20px;
  }
</style>
