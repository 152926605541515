<template>
    <a class="terms-of-use-link" target="_blank" :href="link">{{ label }}</a>
</template>

<script>
export default {
    name: "terms-of-use-link",
    props: {
        link: String,
        label: String
    }
};
</script>

<style scoped>
    .terms-of-use-link{
        color: var(--vtmn-color_brand);
        text-decoration: underline
    }
</style>
