<template>
    <div>

        <v-row >
            <v-col cols="12" class="pt-3 pb-0" style="margin-top: -10px;" align="center" justify="center">

                <mdm-vtmn-popup v-if="editable" ref="popupSiteChoose"
                                id-button-open="application-add-button-open-popup-auth"
                                class-button-open="border-none editButton">
                    <template v-slot:button-open-content>

                        <span class='editButton'>
                            <md-icon v-if="!isChangingSite" id="device-details-button-choose-site" class="padding-neg-1">edit</md-icon>
                            <md-progress-spinner class="padding-neg-1" v-else :md-diameter="25"
                                                 md-mode="indeterminate"></md-progress-spinner>

                        </span>

                    </template>

                    <mdm-vtmn-adjust-card>
                        <template v-slot:header>
                            <p class="vtmn-typo_title-5" align="center">Choose the store for your phone</p>
                        </template>

                        <new-device-store-choice :list-class="'flex-store-list'" class="px-10" :data="{}"
                                                 :extra-data="this.user || {}"
                                                 @selected="selected"
                                                 @storeChoiceLoaded="getActiveUser"/>

                        <template v-slot:footer>

                            <div class="px-10 py-2 font-italic text-left" v-if="storeSelected != null">
                                Store selected : {{storeSelected.idTxt + " - " + storeSelected.name}}
                            </div>

                            <mdm-vtmn-button class="ma" id="device-details-button-close-popup-auth"
                                    @click="validChoice"
                                    :disabled="storeSelected == null"
                            >Validate
                            </mdm-vtmn-button>
                        </template>
                    </mdm-vtmn-adjust-card>
                </mdm-vtmn-popup>

                <div class='infoButton mr-n1' v-else>

                    <mdm-button id="device-details-kiosk-store-edit-info-button"
                                :css="'md-icon-button md-dense padding-neg-info'"
                                :tooltip-text="CANT_EDIT_STORE_KIOSK"
                                :always-display-tooltip="true"
                                :icon="'info'"
                                :is-no-need-inline-block="true"
                                :disable="true" />

                </div>


            </v-col>
        </v-row>
    </div>
</template>

<script type="text/javascript">
    import NewDeviceStoreChoice from "../../../pages/devices/new/step/store-choice";
    import androidApi from '../../../service/devices/android-api-service';
    import FieldConstant from './../../../utils/field-constant';

    export default {
    components: {NewDeviceStoreChoice},
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data: function(){
        return {
            user: null,
            storeSelected: null,
            isChangingSite: this.isLoading,
            CANT_EDIT_STORE_KIOSK: FieldConstant.DEVICE.TOOLTIPS.CANT_EDIT_STORE_KIOSK,
        }
    },
    methods: {
        getActiveUser(){
            androidApi.getActiveUser().then((user) => {
                this.user = user;
            });
        },
        selected(store){
            this.storeSelected = store;
        },
        validChoice(){
            if ( this.storeSelected != null ){
                this.$emit("storeSelected", this.storeSelected);
                this.$refs.popupSiteChoose.close();
            }
        }
    }
}

</script>

<style lang="scss">
    .popin {
        background: var(--vtmn-color_white);
    }
    .border-none{
        border: none;
    }
    .flex-store-list{
        background-color : lightgrey !important;
        height: 100%;
        overflow: auto;
    }
    .padding-neg-1{
        margin-top: -8px;
        margin-bottom: 6px;
    }
    .padding-neg-info{
        margin-top: -16px;
        margin-bottom: 6px;
        margin-right: auto;
    }
</style>
