import RealtimeService from './realtime-service';
import KioskInfoService from './kiosk-info-service';
import kioskInfosConstant from "@/constant/kiosk-infos-constant";

export default {
    async changeDefaultApp(device, packageName, socket, isConnectedToRealtime) {
        await KioskInfoService.updateKioskInfo({device_id: device.id, device_app_default: packageName});
        if(!device.androidSilentUpdate) RealtimeService.changeDefaultApp(socket, device.id, packageName);
        return Promise.resolve();
    }
}
