<template>
    <mdm-vitamine-card
        :id="id"
        title="ADD A WEB APP"
        :minimized="minimized"
        @click="cardClicked">
            <p>
                A progressive web app is a web application that displays pages or websites.
                They are displayed the same way as native mobile applications.
            </p>
    </mdm-vitamine-card>
</template>

<script>
import MdmVitamineCard from '../../../../components/mdm/vitamine/card/mdm-vtmn-card';
import MdmVitamineBadge from '../../../../components/mdm/vitamine/badge/mdm-vtmn-badge';

export default {
    name: 'web-app-card',
    components: {
        MdmVitamineCard, MdmVitamineBadge
    },
    props: {
        id: {
            type: String,
            required: true
        },
        minimized: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        cardClicked() {
            this.$router.push({name: "application-pwa-information"});
        }
    }
}
</script>
