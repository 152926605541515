<template>
    <div class="group-choose-type">
        <v-row no-gutters align="center" justify="space-around" class="display-group-choose-type">
            <v-col cols="4" sm="3" class="text-center">
                <button id="applications-details-group-type-choice-mobile"
                        class="group-choose-type-btn" :class="isActive(type.SMARTPHONE) && 'is-active'"
                        @click="chooseTypeDevice(type.SMARTPHONE)">
                    <device-svg />
                    <p>Mobile</p>
                </button>
            </v-col>
            <v-col cols="4" sm="3" class="text-center">
                <button id="devices-new-device-btn-device-choice-tablet"
                        class="group-choose-type-btn" :class="isActive(type.TABLET) && 'is-active'"
                        @click="chooseTypeDevice(type.TABLET)">
                    <tablet-svg />
                    <p>Tablet</p>
                </button>
            </v-col>
            <v-col cols="4" sm="3" class="text-center">
                <button id="devices-new-device-btn-device-choice-kiosk"
                        class="group-choose-type-btn" :class="isActive(type.KIOSK) && 'is-active'"
                        @click="chooseTypeDevice(type.KIOSK)">
                    <kiosk-svg />
                    <p>Kiosk</p>
                </button>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Constant from '../../../../../utils/field-constant';

    export default {
        name: "group-choose-type",
        data: function() {
            return { type: Constant.DEVICE.TYPE }
        },

        computed: {
            group() {
                return this.$store.state.group.group;
            },
        },
        methods: {
            chooseTypeDevice(type) {
                let actualDeviceType = this.group.requirement?.deviceType || [];
                let filteredList = actualDeviceType.filter(actualType => actualType !== type);

                if(actualDeviceType.length === filteredList.length) {
                    filteredList.push(type);
                }

                this.$store.dispatch('group/setGroup', {
                    requirement : {
                        ...this.group.requirement,
                        deviceType: filteredList
                    }
                });
            },
            isActive(type) {
                return this.group.requirement?.deviceType?.some(actualType => actualType === type);
            }
        }
    }
</script>

<style lang="scss">
.group-choose-type-btn {
    width: -webkit-fill-available;
    height: 125px;
    max-width: 300px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;

    &.is-active {
        border-color: #007dbc;
        box-shadow: 0 0 2px 1px #007dbc;
    }

    &:focus {
        outline: none;
    }

    > img, > svg  {
        margin: auto;
        min-height: 80%;
    }

    p {
        margin: 0;
        font-size : 150%;
    }
}

</style>
