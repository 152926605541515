import application from './modules/applications/application';
import webview from './modules/applications/webview';
import group from './modules/group/group';
import model from './modules/models/model';
import icons from "./modules/applications/icons";
import headerConfiguration from "./modules/header/header-configuration";
import devices from "./modules/devices/devices"
import migration from "./modules/migration/migration"
import noSecuredVueRoutes from "@/store/modules/auth/no-secured-vue-routes";

export default {icons, application, webview, group, model, headerConfiguration, devices, noSecuredVueRoutes, migration};


