<template>
    <v-row no-gutters align="center" class="model-choice-panel">
        <v-col cols="12" sm="6" class="text-md-right" >
            <mdm-button id="devices-new-device-btn-model-choice-elo-touch" :text="ELO_TOUCH_MIN_3"
                        :icon="'dvr'" css="md-raised button-choice md-primary"
                        @click="choose(ELO_TOUCH_MIN_3)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" sm="6" class="text-md-left">
            <mdm-button id="devices-new-device-btn-model-choice-other" :text="OTHER"
                        :icon="'dvr'" css="md-raised button-choice md-info md-accent"
                        @click="choose(OTHER)" is-no-need-inline-block/>
        </v-col>
    </v-row>
</template>

<script>
import FieldConstant from "@/utils/field-constant";

export default {
    name: "model-choice",
    data : function() {
        return {
            ELO_TOUCH_MIN_3: FieldConstant.KIOSK_MODEL.ELO_TOUCH_MIN_3,
            OTHER: FieldConstant.KIOSK_MODEL.OTHER,
        }
    },
    methods: {
        choose(kioskModel) {
            this.$emit("selected", {kioskModel});
        }
    }
};
</script>

<style lang="scss">
    .model-choice-panel {
        #devices-new-device-btn-model-choice-elo-touch {
            background-color: var(--vtmn-color_brand-digital-light-1)
        }

        #devices-new-device-btn-model-choice-other{
            background-color: var(--vtmn-color_brand-digital)
        }
    }
</style>
