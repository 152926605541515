<template>
    <div>
        <div v-if="state.error">
            <span style='color :#FF0000;'>{{ state.error }}</span>
        </div>
        <div v-else >
            <mdm-vtmn-popover class="max-content" position="top-right" :id="'up-to-date-popover-' + groupId" :maxWidth=400>
                <div>
                    <span v-if="state.deviceOk" :style="metricColor">{{state.deviceOk}}%</span>
                    <span v-else>No metric available</span>
                    <v-icon v-if="!advancedStats.error" small id="group-eye-icon" class="ml-2">
                        mdi-eye-outline
                    </v-icon>
                    <v-icon v-else small id="group-eye-icon" class="ml-2 text-error">
                        mdi-eye-off
                    </v-icon>
                </div>
                <div class="ds-aam-stats">
                    <div v-if="advancedStats.error">
                        <div>DS: <span class="text-error">#N/A</span></div>
                        <div>AAM: <span class="text-error">#N/A</span></div>
                    </div>
                    <v-skeleton-loader v-else-if="!advancedStats.data || !advancedStats.data.updateSince" type="text@2" />
                    <div v-else>
                        <div v-if="advancedStats.data.updateSince.threeMonths.total.ds > 0">DS: {{ advancedStats.data.updateSince.threeMonths.upToDate.pourcentDs }}%</div>
                        <div v-if="advancedStats.data.updateSince.threeMonths.total.aam > 0">AAM: {{ advancedStats.data.updateSince.threeMonths.upToDate.pourcentAam }}%</div>
                    </div>
                </div>
                <template v-if="!advancedStats.error" v-slot:title>Details</template>
                <template v-slot:content>
                    <v-alert color="var(--vtmn-color_danger)" text icon="mdi-alert-circle" dense v-if="advancedStats.error" class="popover-error">
                        No details are available for this deployment group
                    </v-alert>
                    <div v-else-if="!advancedStats.data || !advancedStats.data.updateSince">
                         <md-progress-spinner :md-diameter="25" :md-stroke="1" md-mode="indeterminate" />
                    </div>
                    <div v-else class="up-to-date-content">
                        <div class="pb-2">
                            <div class="popover-content">
                                <span>Total targeted devices: {{ advancedStats.data.updateSince.threeMonths.total.all }} </span>
                                <span>(100%)</span>
                            </div>
                            <div class="popover-content">
                                <span>DS devices: {{ advancedStats.data.updateSince.threeMonths.total.ds }} </span>
                                <span class="up-to-date-buble">update to date : {{ advancedStats.data.updateSince.threeMonths.upToDate.pourcentDs }}%</span>
                                <span>({{ advancedStats.data.updateSince.threeMonths.total.pourcentDs }}%)</span>
                            </div>
                            <div class="popover-content">
                                <span>AAM devices: {{ advancedStats.data.updateSince.threeMonths.total.aam }} </span>
                                <span class="up-to-date-buble">update to date : {{ advancedStats.data.updateSince.threeMonths.upToDate.pourcentAam }}%</span>
                                <span>({{ advancedStats.data.updateSince.threeMonths.total.pourcentAam }}%)</span>
                            </div>
                        </div>
                        <div class="pt-2">
                            <div class="popover-content">
                                <span>Devices turned on since the updates: {{ advancedStats.data.updateSince.groupUpdate.total.all }} </span>
                                <span>(100%)</span>
                            </div>
                            <div class="popover-content">
                                <span>DS devices: {{  advancedStats.data.updateSince.groupUpdate.total.ds }} </span>
                                <span class="up-to-date-buble">update to date : {{ advancedStats.data.updateSince.groupUpdate.upToDate.pourcentDs }}%</span>
                                <span>({{ advancedStats.data.updateSince.groupUpdate.total.pourcentDs}}%)</span>
                            </div>
                            <div class="popover-content">
                                <span>AAM devices: {{  advancedStats.data.updateSince.groupUpdate.total.aam }} </span>
                                <span class="up-to-date-buble">update to date : {{ advancedStats.data.updateSince.groupUpdate.upToDate.pourcentAam }}%</span>
                                <span>({{ advancedStats.data.updateSince.groupUpdate.total.pourcentAam}}%)</span>
                            </div>
                        </div>
                    </div>
                </template>
            </mdm-vtmn-popover >
        </div>
    </div>
</template>

<script>

    export default {
        name: "up-to-date-column",
        props: {
            state: {
                type: Object,
                required: true
            },
            groupId: {
                type: Number,
                required: true
            },
            advancedStats : {
                type: Object
            }
        },

        computed: {
            metricColor() {
                let color = '#7acf1f'
                if(this.state.deviceOk <= 40) color = '#687787';
                else if(this.state.deviceOk < 80)  color = '#FFA500';
                return { color };
            }
        },
    }
</script>

<style lang="scss" scoped>
.up-to-date-buble {
    border: 1px solid white;
    border-radius: 15px;
    padding: 0px 10px;
}
.max-content {
    width: max-content;
    padding-left: 25px;
}
.up-to-date-content {
    > div {
        &:not(:last-child) {
            border-bottom: 2px dotted white;
        }

        > div:not(:first-child) {
            font-size: 0.8em;
        }
    }
    .popover-content {
        display: flex;
        justify-content: space-between;

        span:last-child {
            margin-left: 10px;
        }
    }
    span {
        margin-bottom: 5px;
    }
}
.ds-aam-stats {
    border-left: 3px solid #d9d6d6;
    padding-left: 5px;
    text-align: left;
}
</style>
