import DeviceService from './device-service';
const CSV_MODE = true;
const DEFAULT_CSV_START_PAGE = 1;
const MAX_FILENAME_LENGTH = 75;

import Moment from 'moment'
import {store} from "../../store/store";

export default {
    async buildFrom(activeFilter, orderBy, arbo) {
        let totalPageCount = 1;
        let currentPage = 1;
        let rawResponseCount = 0;
        let rawResponse = await DeviceService.callSearchDevicesDbApi(activeFilter, currentPage, CSV_MODE, orderBy, arbo);
        rawResponseCount ++;
        let blobParts = Buffer.from(rawResponse.body.dataCsv).toString("utf-8");

        totalPageCount = rawResponse.body.totalPageCount;

        store.commit("devices/setExportSpinnerProgressStatus", Math.round(rawResponseCount/totalPageCount*100));

        if(totalPageCount > 1) {
            for (let start = 1; start < totalPageCount; start++) {
                currentPage++;
                rawResponse = await DeviceService.callSearchDevicesDbApi(activeFilter, currentPage, CSV_MODE, orderBy, arbo);
                rawResponseCount ++;
                store.commit("devices/setExportSpinnerProgressStatus", Math.round(rawResponseCount/totalPageCount*100));
                blobParts += Buffer.from(rawResponse.body.dataCsv).toString("utf-8");
            }
        }
        store.commit("migration/setIsMigrationExport", false);
        const element = document.createElement('a');
        const csvData = new Blob([blobParts], {type: 'text/csv'});
        const csvUrl = URL.createObjectURL(csvData);
        element.setAttribute('href', csvUrl);
        element.setAttribute('download', buildFileNameFrom(activeFilter));
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}

const buildFileNameFrom = (activeFilter) => {
    const filters = activeFilter?.map(filter => filter.key)?.join("_")?.replace(/:/g, '_');
    return 'MDM_DEVICES_' + filters.substring(0, MAX_FILENAME_LENGTH) + "_" + Moment(new Date()).format('YYYYMMDDHHMMSS')+ '.csv';
}
