<template>
    <v-row no-gutters align="center" class="overridden-choice-panel">
        <v-col cols="12" sm="6" class="text-md-right" >
            <mdm-button id="devices-new-device-btn-overridden-choice-normal" :text="NORMAL"
                        icon="check" css="md-raised button-choice md-primary"
                        @mouseover="triggerHover(NORMAL)"
                        @click="choose(false)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" sm="6" class="text-md-left">
            <mdm-button id="devices-new-device-btn-overridden-choice-overridden" :text="CUSTOM + '...'"
                        icon="settings" css="md-raised button-choice md-info md-accent"
                        @mouseover="triggerHover(CUSTOM)"
                        @click="choose(true)" is-no-need-inline-block/>
        </v-col>
        <v-col cols="12" align="center">
            <v-alert 
                icon="mdi-emoticon-happy-outline"
                text prominent >
                <p class="mb-0">
                    By choosing <b>{{hoverType}}</b>,
                    <span v-if="hoverType === NORMAL">you can use all corporate applications.</span>
                    <span v-else>
                        you will get only a limited number of applications.
                    </span>
                </p>
            </v-alert>
        </v-col>
    </v-row>
</template>


<script>
import FieldConstant from "../../../../utils/field-constant";

export default {
    name: "new-device-device-choice",
    data: function(){
        return {
            NORMAL: FieldConstant.ENROLLMENT_TYPE.NORMAL,
            CUSTOM: FieldConstant.ENROLLMENT_TYPE.CUSTOM,
            hoverType: FieldConstant.ENROLLMENT_TYPE.CUSTOM
        }
    },
    methods: {
        choose(isGenericPolicy) {
            this.$emit("selected", {isGenericPolicy});
        },
        triggerHover(hoverType) {
            this.hoverType = hoverType;
        }
    }
};

</script>

<style lang="scss">

.overridden-choice-panel {
    #devices-new-device-btn-overridden-choice-normal {
        background-color: var(--vtmn-color_brand-digital-light-1)
    }

    #devices-new-device-btn-overridden-choice-overridden {
        background-color: var(--vtmn-color_brand-digital)
    }
}
</style>
