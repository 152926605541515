<template>
    <div class="header-configuration">
        <v-row align="end">
            <v-col cols=8>
                <mdm-input 
                    id="header-configuration-title-input" name="Title" 
                    :val.sync="configuration.title" @update:val="updateHeaderConfigurationStore('title', $event)"
                />
            </v-col>
            <v-col cols=2>
                <mdm-select 
                    id="header-configuration-select-criticity" 
                    :filters="criticityNames"
                    :defaultValue="configuration.criticity"
                    @getFilterEvent="updateHeaderConfigurationStore('criticity', $event)"
                />
            </v-col>
            <v-col cols=2 class="mb-2">
                <mdm-vtmn-switch id="header-configuration-activated-switch" :value="configuration.active" @update:value="updateHeaderConfigurationStore('active', $event)">
                    Active
                </mdm-vtmn-switch>
            </v-col>
        </v-row>
        <mdm-text-area 
            label="Content" 
            id="header-configuration-content" 
            placeholder="Content that will be show to the user"
            :content.sync="configuration.content" @update:val="updateHeaderConfigurationStore('content', $event)"
        />
        <v-alert v-if="configuration.actualState.error || errorHtmlTag" dense type="warning" id="header-configuration-alert" >
            {{ errorHtmlTag ? "Content can't contain 'script' or 'img' HTML tag" : configuration.actualState.error }}
        </v-alert>
        <mdm-vtmn-button 
            id="header-configuration-deploy-button"  
            :class="'header-configuration-deploy-content ' + 
                (configuration.actualState.error || errorHtmlTag ? 'red-bg' : ' ')" 
            @click="deploy"
            :disabled="disableDeployment()"
            textTooltip="Make some changes in order to save it"
            :isTooltipShow="disableDeployment"
            :data-saved=true
        >
            Validate
        </mdm-vtmn-button>
        <p class="text-center mt-5" v-if="!configuration.actualState.first && configuration.actualState.saved">This header banner is well saved</p>
    </div>
</template>

<script>
    import AdminTabConstant from '../../../constant/admin-tabs-constant'
    import StringUtils from '../../../utils/string-utils';

    export default {
        name: "header-configuration",
        data: function () {
            return {
                HEADER_CONSTANT: AdminTabConstant.HEADER,
                errorHtmlTag: false
            }
        },
        computed: {
            criticityNames() {
                return this.HEADER_CONSTANT.CRITICITY_LEVEL.map(({NAME}) => NAME);
            },
            configuration() {
                return this.$store.getters['headerConfiguration/getHeaderConfiguration'];
            }
        },
        methods: {
            updateHeaderConfigurationStore(field, newValue) {
                this.errorHtmlTag = !StringUtils.isNoBadHTMLTag(newValue);
                this.$store.dispatch('headerConfiguration/setConfiguration', {field, newValue});
            },
            deploy() {
                this.$store.dispatch('headerConfiguration/saveConfiguration');
            },
            disableDeployment() {
                return Boolean(this.errorHtmlTag || this.configuration.actualState.error || this.configuration.actualState.loading || this.configuration.actualState.saved || this.configuration.actualState.first);
            }
        }

    };
</script>


<style lang="scss">
.header-configuration {
    padding: 10px;
    .green-bg button {     
        background-color: green;
    }
    .red-bg button {     
        background-color: red;
    }
    .header-configuration-deploy-content {
        width: 100%;
        
        #header-configuration-deploy-button {
            margin: auto !important;
        }
    }

    #header-configuration-well-saved i {
        align-self: auto;
    }
}
</style>
