<template>

  <div v-if="url == undefined"><md-progress-spinner md-mode="indeterminate"></md-progress-spinner></div>
  <qrcode-vue id="qr-code-enrollment" v-else ref="qrcode" class="margin-top-start auto-size" :value="url" :size="getQrCodeSize" level="H" ></qrcode-vue>

</template>

<script>

    import QrcodeVue from 'qrcode.vue'

    export default {
      name: "qrCode",
      components: {
        QrcodeVue,
      },
      props: {
        url: {
          type: String
        }
      },
      data() {
        return {
          size: 300
        }
      },
      computed: {
        getQrCodeSize() {
          if(this.$vuetify.breakpoint.smAndDown) {
            return 200;
          }
          return this.size;
        }
      },
      mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        document.getElementById('feedback').click();
      },
      destroyed() {
        window.removeEventListener('resize', this.handleResize);
      },
      methods: {
        handleResize() {
          this.size = this.$refs.qrcode.$parent.size;
        }
      }
    }

</script>

<style scoped>

  .margin-top-start{
    margin-top: 30px;
    text-align: center;
  }

  .auto-size + canvas {
    height: auto !important;
    margin-top: 1000px;
  }

</style>
