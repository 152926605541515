import Vue from "vue";
import {store} from "../../store/store";
const utils = require('../../utils/utils.js');

export default {

  /**
   * Active filter is store on a session.
   * It use to store current filters user preferences on browser
   */
  getActiveFilters ( userPreferencesInJson ) {
    userPreferencesInJson = userPreferencesInJson ? userPreferencesInJson : "";
    let activeFiltersOnSession = sessionStorage.getItem("userfilter");


    if ( activeFiltersOnSession ) {
      return JSON.parse(activeFiltersOnSession)
    }
    else {
      return ( {
        "pagination": {
          "count": 50,
          "currentPage": 1
        },
        "orderBy": "country",
        "reverse": false,
        "model": userPreferencesInJson !== "" && typeof userPreferencesInJson["deviceFilter"]["model"][0] !== "undefined" ? userPreferencesInJson["deviceFilter"]["model"] : [],
        "user": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["user"] : "",
        "name": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["name"] : "",
        "os": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["os"] : "",
        "osVersion": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["osVersion"] : "",
        "agent": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["agent"] : "",
        "category": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["category"] : "",
        "serialNumber": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["serialNumber"] : "",
        "imeiValue": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["imeiValue"] : "",
        "email": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["email"] : "",
        "application": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["application"] : "",
        "dateLastCheck": {
          "startDateLastCheck": {
            "time": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["startDateLastCheck"] : "",
          },
          "dateLastCheck": {
            "time": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["dateLastCheck"] : ""
          }
        },
        "manufacturer": userPreferencesInJson !== "" && typeof userPreferencesInJson["deviceFilter"]["manufacturer"][0] !== "undefined" ? userPreferencesInJson["deviceFilter"]["manufacturer"] : [],
        "displayPerso": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["displayPerso"] : "",
        "version": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["version"] : "",
        "androidSilentUpdate": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["androidSilentUpdate"] : "",
        "androidManagementMode": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["androidManagementMode"] : "",
        "siteType": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["siteType"] : "",
        "kioskPackageName": userPreferencesInJson !== "" ? userPreferencesInJson["deviceFilter"]["kioskPackageName"] : ""
      })

    }
  },

  async reloadActiveFilter() {
    return this.getActiveFilters (await this.getFilterUserPreferences() )
  },

  updateActiveFilter ( activeFilters ) {
    sessionStorage.setItem("userfilter", JSON.stringify(activeFilters));
  },

  updateUserPreferences ( userPreferences ) {
    localStorage.setItem("userPreferences", JSON.stringify(userPreferences));
  },

  /**
   * Contains the device filter value (ex : application : 'Decathlon Service')
   * @returns {undefined|*}
   */
  getDeviceFilter ( ) {
    let userFilter = sessionStorage.getItem("userfilter");

    if(userFilter != null ){
      return  JSON.parse( userFilter );
    }
    else {
      let userPreferencesInJson = localStorage.getItem("userPreferences");

      if(!userPreferencesInJson || userPreferencesInJson === "undefined")
        return undefined;

      userPreferencesInJson = JSON.parse(userPreferencesInJson);
      return userPreferencesInJson["deviceFilter"];
    }
  },

  async getFilterUserPreferences () {
    let userPreferencesInJson = localStorage.getItem("userPreferences");

    if(userPreferencesInJson == "undefined" || userPreferencesInJson == null){
      userPreferencesInJson = await this.loadFilterUserPreferencesFromDatabase();
    }
    else{
      userPreferencesInJson = JSON.parse(userPreferencesInJson);
      if(! userPreferencesInJson.deviceFilter.hasOwnProperty("application") ){
        Object.assign(userPreferencesInJson.deviceFilter, {
          application : ""
        });
      }

      return Promise.resolve(userPreferencesInJson);
    }

    return userPreferencesInJson;
  },

  async loadFilterUserPreferencesFromDatabase () {
    return Vue.http.get(`${store.state.urlUser}`).then((response) => {
      let userInfo = response.body.user;
      store.commit('setUser', userInfo);
      this.updateUserPreferences( userInfo.userPreferences );

      return userInfo.userPreferences;
    }, (error) => {
      console.log(error);
      utils.displayAlertDialog("An error occurred while your preferences loading")

      return null;
    });
  },

  realignSessionWithObject(activeFilters) {
    if (sessionStorage.getItem("userfilter") && sessionStorage.getItem("userfilterGestion")) {
      let userfilterGestionInJson = sessionStorage.getItem("userfilterGestion");
      userfilterGestionInJson = JSON.parse(userfilterGestionInJson);



      if ( ! userfilterGestionInJson.hasOwnProperty("application")) {
        Object.assign(userfilterGestionInJson, {
          application: {
            "id": "application",
            "active": false,
            "name": "Application"
          }
        });
        Object.assign(activeFilters, {
          "application": ""
        });
      }


      let startDateLastCheck = activeFilters.dateLastCheck.startDateLastCheck;
      let dateLastCheck = activeFilters.dateLastCheck.dateLastCheck;

      //si les filtres de date de l"utilisateurs ne sont pas vide
      if (startDateLastCheck.time != "") {
        //controle si les filtres de date de la session sont vide
        if (activeFilters.dateLastCheck.startDateLastCheck.time == "" && startDateLastCheck.time == "") {
          activeFilters.dateLastCheck.startDateLastCheck.time = ""
        } else {
          userfilterGestionInJson["lastcheck"].active = true;
          activeFilters.dateLastCheck.startDateLastCheck = startDateLastCheck;
        }
      }
      if (dateLastCheck.time != "") {
        if (activeFilters.dateLastCheck.dateLastCheck.time == "" && dateLastCheck.time == "") {
          activeFilters.dateLastCheck.dateLastCheck.time = ""
        } else {
          userfilterGestionInJson["lastcheck"].active = true;
          activeFilters.dateLastCheck.dateLastCheck = dateLastCheck;
        }
      }

      if (userfilterGestionInJson) {
        sessionStorage.setItem("userfilterGestion", JSON.stringify(userfilterGestionInJson));
      }
      if (activeFilters) {
        sessionStorage.setItem("userfilter", JSON.stringify(activeFilters));
      }

    }
  }

}
