const state = () => ({
    isLoadingArboAfterReset: false,
    isMigrationExport: false
});

const mutations = {
    setIsLoadingArboAfterReset(state, isLoadingArboAfterReset) {
        state.isLoadingArboAfterReset = isLoadingArboAfterReset;
    },
    setIsMigrationExport(state, isMigrationExport) {
        state.isMigrationExport = isMigrationExport;
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
