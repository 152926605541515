<template>
	<div class='filter-arbo' v-if='arbo' :class="type">
		<span id="arbo-filter-btn-hide" @click="clickOnBack" v-if="canHide">
			<v-icon color="white" dense >
				mdi-chevron-left
			</v-icon>
			Hide
		</span>
        <input type="text" @keyup="searchEvent"  class="vtmn-text-input input-fill-all-width" id="arbo-filter-input-search" placeholder="Search"/>

        <div class="filter-arbo-content">
            <ul class="filter-list-arbo" v-if="type === 'light'">
                <li class='filter-main'>
                    <span v-if="userAdminOrhasWholeArboRight" class="check" :class="{ checkActive: arbo.checked, checkPartial: isCheckPartialAll()}" @click="check('*ALL*', '*ALL*', '*ALL*')"></span>
                    <span v-else class="unCheckable" :class="{ checkActive: arbo.checked}"></span>
                    <span>Decathlon</span>
                </li>
                <li>
                    <ul class='filter-buItems'>
                        <li v-for='(arboElement, index) in arboElements' class='filter-buItem'>
                            <span v-if="userAdminOrhasWholeArboRight" class="check" :class="{ checkActive: partialArbo[arboElement.name].checked, checkPartial : isCheckPartialEntity(arboElement.name)}" @click="check(arboElement.name, '*ALL*', '*ALL*')"></span>
                            <span v-else class="unCheckable" :class="{ checkActive: partialArbo[arboElement.name].checked, checkPartial : isCheckPartialEntity(arboElement.name)}"></span>
                            <span  class='label' @click='openSite(index)' :class="{ 'label-open' : arboElement.open}">{{arboElement.name}}</span>
                            <ul class='filter-countryItems' :class="{ 'filter-countryItems-open' : arboElement.open}">
                                <li v-for="(country, indexCountry) in partialArbo[arboElement.name].site" class='filter-countryItem' :class="{hide : hideCountry(country.ref)}">
                                    <span class="check" :class="{ checkActive: country.checked, checkPartial : isCheckPartialCountry(arboElement.name, indexCountry)}" @click="check(arboElement.name, indexCountry, '*ALL*')"></span>
                                    <span class='label' @click='openCountry(arboElement.name, indexCountry)' :class="{'label-open' : openCountries[arboElement.name].indexOf(indexCountry) > -1}" >{{country.code}}
                                    </span>
                                    <ul class='filter-siteItems' :class=" {'filter-siteItems-open' : openCountries[arboElement.name].indexOf(indexCountry) > -1}">
                                        <li class='filter-siteItem' v-for="(site, indexSite) in partialArbo[arboElement.name].site[indexCountry].sites">
                                            <span class="check" :class="{ checkActive: site.checked}" @click="check(arboElement.name, indexCountry, indexSite)">
                                            </span>
                                            <span>{{site.name}}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

            <ul class="filter-list-arbo" v-if="type == 'filterFull'">
                <li class='filter-main'>
                    <span class="check" :class="{ checkActive: arbo.checked}" @click="check('*ALL*', '*ALL*', '*ALL*')"></span>
                    <span> Decathlon </span>
                </li>
                <li>
                    <ul class='filter-buItems'>
                        <li v-for='(arboElement, index) in arboElements' class='filter-buItem'>
                            <span class="check" :class="{ checkActive: partialArbo[arboElement.name].checked, checkPartial : isCheckPartialEntity(arboElement.name)}" @click="check(arboElement.name, '*ALL*', '*ALL*')"></span>
                            <span  class='label' @click='openSite(index)' :class="{ 'label-open' : arboElement.open}">{{arboElement.name}}</span>
                            <ul class='filter-countryItems' :class="{ 'filter-countryItems-open' : arboElement.open}">
                                <li v-for="(country, indexCountry) in partialArbo[arboElement.name].site" class='filter-countryItem' :class="{hide : hideCountry(country.ref)}">
                                    <span class="check" :class="{ checkActive: country.checked, checkPartial : isCheckPartialCountry(arboElement.name, indexCountry)}" @click="check(arboElement.name, indexCountry, '*ALL*')"></span>
                                    <span class='label' @click='openCountry(arboElement.name, indexCountry)' :class="{'label-open' : openCountries[arboElement.name].indexOf(indexCountry) > -1}" >{{country.code}}
                                    </span>
                                    <ul class='filter-siteItems' :class=" {'filter-siteItems-open' : openCountries[arboElement.name].indexOf(indexCountry) > -1}">
                                        <li class='filter-siteItem' v-for="(site, indexSite) in partialArbo[arboElement.name].site[indexCountry].sites">
                                            <span class="check":class="{ checkActive: site.checked}" @click="check(arboElement.name, indexCountry, indexSite)">
                                            </span>
                                            <span>{{site.name}}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

            <ul class='filter-list-arbo filter-countryItems' v-if="type === 'lite'">
                <li class='filter-main'>
                    <span class="check" :class="{ checkActive: arbo['retail'].checked, checkPartial : isCheckPartialEntity('retail')}"
						@click="check('retail', '*ALL*', '*ALL*')" />
                    <strong><span style="font-size : 14px;">  ALL </span></strong>
                </li>
                <li v-for="(country, index) in partialArbo.retail.site" class='filter-countryItem'>
                    <span class="check" :class="{ checkActive: country.checked}" @click="check('retail', index, '*ALL*');" />
                    <span  class='label' >{{country.code}}</span>
                </li>
		    </ul>
        </div>
    <div v-if="popin != null" class="pops">
      <div class='pop-overlay' @click='popin = null'></div>
    <div  v-if="popin =='confirmDeployAll'" class="pop">
      <div class='popin-text'>Are you sure you want to deploy this version of your application on {{this.countAll}} devices ?</div>
      <div class='popin-wipe-actions popin-actions'>
        <md-button class="md-raised" @click="popin = null">No, cancel</md-button>
        <md-button  class="md-raised md-primary" @click="popin = null">Yes</md-button>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';

export default {
	data : function(){
		return {
      		popin : null,
		  	countAll : 0,
			countRetail : 0,
			countLogistics : 0,
            userAdminOrhasWholeArboRight: this.$store.state.user.admin || this.$store.state.user.hasWholeArbo,
			arboElements : [
				{
					'name' : 'retail',
					'open' : false
				},
				{
					'name' : 'logistic',
					'open' : false
				}
			],
            partialArbo: [],
			openCountries : {
				'retail' : [],
				'logistic' : []
			},
			countriesOpen : [],
			actualSearchValue: ""
		}
	},
	props: {
        type: {
            type: String
        },
        export: {
            type: String
        },
        countryAlreadyChecked: {
            type: String
        },
        source: {
            type: Object
        },
        canHide: {
            type: Boolean,
            default: false
		},
		countryCountNeeded: {
			type: Boolean,
			default: false
		}
    },
	asyncComputed : {
		arbo : function(){
			return Vue.http.get(`${this.$store.state.urlArbo}`).then((response) => {

				var arbo = response.body;

				if(arbo == null){
				  return arbo;
        		}
				var self = this;

				//building stores list for kiosk detail sites openOption
				let retail = response.body.retail.site;
				let logistic = response.body.logistic.site;

				let kioskSites = [];

				kioskSites.push.apply(kioskSites, this.addSites(retail));
				kioskSites.push.apply(kioskSites, this.addSites(logistic));

				sessionStorage.setItem('kioskSites', JSON.stringify(kioskSites));

				if(this.type === 'lite'){
					arbo['retail'].site.forEach(function(arboCountry, indexCountry){
						// RAJOUTER UNE PROP AVEC UN TABLEAU DE PAYS POUR countryAlreadyChecked
						if(self.countryAlreadyChecked){ // Verify that the prop is present
							if(self.countryAlreadyChecked.length > 0){ // Verify that it's not empty
								if(self.countryAlreadyChecked.indexOf(arboCountry.ref) > -1){
									arbo['retail'].site[indexCountry].checked = true;
								}
							}
							else if(self.$store.state.user.countries.indexOf(arboCountry.ref) > -1){
								arbo['retail'].site[indexCountry].checked = true;
							}
						}
						else if(self.$store.state.user.countries.indexOf(arboCountry.ref) > -1){
							arbo['retail'].site[indexCountry].checked = true;
						}
					});
					this.generateArray('retail', arbo);

				} else if(this.type === 'light'){
					if(this.source){
						var nbOfProperty = 0;
						for(var property in this.source){
							nbOfProperty++;
						}
						if(nbOfProperty > 0){
							arbo = this.source;
						}
					}
				} else if(this.type == 'filterFull'){
					if(this.source){
						var nbOfProperty = 0;
						for(var property in this.source){
							nbOfProperty++;
						}
						if(nbOfProperty > 0){
							arbo = this.source;
						}
					}
					this.generateArray('retail', arbo);
				}
                this.partialArbo=arbo;
				return arbo;
			}, (error) => {
				console.log(error);
			});
		},
	},
	methods : {
        searchEvent(event) {
			this.actualSearchValue = event.target.value;
            this.search(event.target.value);
        },
        search(search) {
            let searchSites = (sites) => sites.filter(site => site?.name.toLowerCase().includes(search.toLowerCase()))

            let searchCountries = (countries) => {
                let searchCountries = countries
                    .filter(country => country?.code.toLowerCase().includes(search.toLowerCase()))
                    .map( country => country.code);

                let countriesWithSitesFiltered = countries.map(country => {
                    let sites = []

					if(this.type !== 'lite')
                        sites = searchSites(country.sites);

                    return {
                        checked: country.checked,
                        sites: sites.length > 0 ? sites : country.sites,
                        filteredCountry : sites.length > 0,
                        code:country.code,
                        ref:country.ref
                    }
                }).filter (country => country.filteredCountry || searchCountries.includes(country.code));

                return countriesWithSitesFiltered;
            };

            let searchIntoLogisticOrRetail = (logOrRetail) => {
                return {
                    checked: logOrRetail.checked,
                    site: searchCountries(logOrRetail.site)
                }
            };

            this.partialArbo = {
                checked: this.arbo.checked,
                logistic: searchIntoLogisticOrRetail(this.arbo.logistic),
                retail: searchIntoLogisticOrRetail(this.arbo.retail),
            }
        },
        addSites : function(type) {
            let kioskSites = []
            type.forEach(function (country) {
                country.sites.forEach(function (sites) {
                    kioskSites.push(sites.name)
                });
            });

            return kioskSites;
		},
		openSite: function(index){
			this.arboElements[index].open = (this.arboElements[index].open) ? false : true;
		},
		openCountry : function(idSite, idCountry){
			var index = this.openCountries[idSite].indexOf(idCountry);
			if (index > -1){
				this.openCountries[idSite].splice(index, 1);
			} else{
				this.openCountries[idSite].push(idCountry);
			}
		},
		generateArray : function(idType,arbo){
			var arraysExport = {
				'siteId' : [],
				'site' : null,
				'country' : []
			}
			var arboTmp = {};
			if(arbo){
				arboTmp = arbo;
			} else {
				arboTmp = this.arbo;
			}
			if(idType == 'retail' && !arboTmp.logistic.checked && arboTmp.retail.checked){
				arraysExport.site = 'RETAIL';
			} else if(idType == 'logistic' && !arboTmp.retail.checked && arboTmp.logistic.checked){
				arraysExport.site = 'LOGISTICS';
			} else if(idType == 'retail' && !arboTmp.retail.checked && arboTmp.logistic.checked){
				arraysExport.site = 'LOGISTICS'
			} else if(idType == 'logistic' && !arboTmp.logistic.checked && arboTmp.retail.checked){
				arraysExport.site = 'RETAIL';
			} else if(idType == 'retail' && !arboTmp.logistic.checked && !arboTmp.retail.checked){
				arraysExport.site = '';
			} else if(idType == 'logistic' && !arboTmp.logistic.checked && !arboTmp.retail.checked){
				arraysExport.site = '';
			} else {
				arraysExport.site = '';
			}
			var self = this;
			var noCountryChecked = true;
			var noSiteChecked = true;

			this.arboElements.forEach(function(el, idTypeVal){
				arboTmp[el.name].site.forEach(function(el, idCountry){
					if(el.checked){
						noCountryChecked = false;
						arraysExport.country.push(el.ref);
						if(idTypeVal == 0){
							arraysExport.site = 'RETAIL';
						} else {
							arraysExport.site = 'LOGISTICS'
						}
					}
					el.sites.forEach(function(el, i){
						if(el.checked){
							noSiteChecked = false;
							arraysExport.siteId.push(el.id);
							if(idTypeVal == 0){
								arraysExport.site = 'RETAIL';
							} else {
								arraysExport.site = 'LOGISTICS'
							}
						}
					});
				});
			});
			if(noSiteChecked){
				arraysExport.siteId = '';
			}
			if(noCountryChecked){
				arraysExport.country = '';
			}

			if (this.type === 'lite'){
				this.$emit('changeArbo', arraysExport);
			} else if (this.countryCountNeeded){
				this.$emit('countryCount', arraysExport);
			}
			if(this.type == 'filterFull'){
				sessionStorage.setItem('arboCheck', JSON.stringify(arraysExport));
			}
		},
		clickOnBack: function() {
			this.$emit('hideArbo', true);
		},
		needAlertOnActualPath() {
			const actualPath = window.location.href.split('/').pop();
			return actualPath !== "devices" && actualPath !== "information";
		},
		check : function(idType, idCountry, idSite){
			if(idType == '*ALL*' && idCountry == '*ALL*' && idSite == '*ALL*'){ // click on Decathlon
				var toCheck = this.arbo.checked ? false : true;
				this.arbo.checked = toCheck;
                if(toCheck && this.needAlertOnActualPath()){
                  if (!confirm('Are you sure you want to deploy this version of your application on '+this.countAll+ ' devices ?')) {
                    this.arbo.checked = !toCheck;
                    return 0;
                  }
				}

				var self = this;
				this.arboElements.forEach(function(el, idTypeVal){
					self.arbo[el.name].checked = toCheck; // check type
					self.arbo[el.name].site.forEach(function(el, idCountry){
						el.checked = toCheck;
						el.sites.forEach(function(el, i){
							var idType = 'logistic';
							if(idTypeVal == 0){
								idType = 'retail';
							}

							el.checked = toCheck;
						});
					});
				});
			} else if(idType != '*ALL*' && idCountry == '*ALL*' && idSite == '*ALL*'){ // click on type
				let toCheck = !this.arbo[idType].checked;
				this.arbo[idType].checked = toCheck;

				if(toCheck && this.needAlertOnActualPath()) {
					const countDevice = idType === "retail" ? this.countRetail : this.countLogistics;
					if (!confirm('Are you sure you want to deploy this version of your application on '+ countDevice + ' devices ?')) {
						this.arbo[idType].checked = !toCheck;
						return 0;
					}
				}

				var self = this;
				this.arbo[idType].site.forEach(function(el, idCountry){
					el.checked = toCheck; //check country
					el.sites.forEach(function(el, i){
						el.checked = toCheck; // check site
					})
				});
			} else if(idType != '*ALL*' && idCountry != '*ALL*' && idSite == '*ALL*'){ // click on country
				idCountry = this.arbo[idType].site.findIndex(({ref}) => ref === this.partialArbo[idType].site[idCountry].ref);
				let toCheck = !this.arbo[idType].site[idCountry].checked;
				this.arbo[idType].site[idCountry].checked = !this.arbo[idType].site[idCountry].checked;

				var self = this
				this.arbo[idType].site[idCountry].sites.forEach(function(el, i){
					el.checked = toCheck;
				});
				if(!toCheck){ // Si on décoche un pays, enlève tous les sites pour le check partial
					if(this.arbo[idType].checked = true){
						this.arbo[idType].checked = false;
					}
				}
				this.arbo[idType].checked = !this.arbo[idType].site.some(site => site.checked === false);
			} else if(idType != '*ALL*' && idCountry != '*ALL*' && idSite != '*ALL*'){ // click on site
				const actualSiteToCheck = this.partialArbo[idType].site[idCountry];
				idCountry = this.arbo[idType].site.findIndex(({ref}) => ref === actualSiteToCheck.ref);
				idSite    = this.arbo[idType].site[idCountry].sites.findIndex(({name}) => name === actualSiteToCheck.sites[idSite].name);

				var toCheck = !this.arbo[idType].site[idCountry].sites[idSite].checked;
				if(!toCheck){
					this.arbo[idType].site[idCountry].checked = false;
				}
				this.arbo[idType].site[idCountry].sites[idSite].checked = toCheck;
				this.arbo[idType].site[idCountry].checked = !this.arbo[idType].site[idCountry].sites.some(site => !site.checked)
			}

			this.arbo.checked = this.arbo['retail'].checked && this.arbo['logistic'].checked;

			if(this.export == 'array'){
				this.generateArray();
			} else {
				this.generateArray();
				this.$emit('changeArbo', this.arbo);
			}
			this.search(this.actualSearchValue);
		},
        isCheckPartialCountry : function(typeName, country){
			const actualSiteToCheck = this.partialArbo[typeName].site[country];
			country = this.arbo[typeName].site.findIndex(({ref}) => ref === actualSiteToCheck.ref);
			const countryToCheck = this.arbo[typeName].site[country];

			const someFalse = countryToCheck.sites.some(item => !item.checked);

			if(someFalse) {
				return countryToCheck.sites.some(item => item.checked);
			}

			return true;
		},
		isCheckPartialAll: function() {
			const isCheckPartialRetail = this.isCheckPartialEntity('retail');
			const isCheckPartialLogistic = this.isCheckPartialEntity('logistic');
			if(isCheckPartialRetail || isCheckPartialLogistic) return true;

			let counter = 0;
			if(this.arbo['retail'].checked) counter++;
			if(this.arbo['logistic'].checked) counter++;

			return counter === 1;
		},
		isCheckPartialEntity : function(typeName){
			if(this.arbo[typeName].checked != true){
				var checkPartial = '';
				var countCheck = 0;
				var arboTmp = this.arbo[typeName].site;
				var countTotal= arboTmp.length;
				arboTmp.forEach(function(el, i){
					if(el.checked == true){
						countCheck ++;
						checkPartial = true;
					}
					else if(el.checked == false){
						var siteTemp = el.sites;
						siteTemp.forEach(function(el, i){
							if(el.checked == true){
								checkPartial = true;
							}
						})
					}
				})
				if(countTotal == countCheck){
					checkPartial = false;
					this.arbo[typeName].checked = true;
				}
				return checkPartial;
			}
		},
		hideCountry : function(countryRef){
			if(this.type === 'light'){
				var hideCountry = true;
				if(!this.userAdminOrhasWholeArboRight){
					this.$store.state.user.countries.forEach(function(countryUser){
						if(countryRef == countryUser){
							hideCountry = false;
						}
					});
				} else {
					hideCountry = false;
				}
				return hideCountry;
			} else {
				return false;
			}

		}
	}
}

</script>

<style lang="scss">
@import './arbo.scss';
</style>
