var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-device-detail"},[_c('v-row',{attrs:{"wrap":""}},[_c('enrollment-info',{attrs:{"device":_vm.device,"kiosk":_vm.kiosk,"is-kiosk":_vm.isKiosk}}),_c('card-info',{attrs:{"icon":"perm_device_info","title":"Android Api Management","contents":[
                { libelle  : 'Activated : ', value: _vm.device.androidSilentUpdate ? 'YES' : 'NO'},
                { libelle  : 'Management mode :', value: _vm.device.androidManagementMode, display:  _vm.device.androidSilentUpdate},
                { libelle  : 'Custom mode :', value: _vm.device.overriddenPolicyType, display:  Boolean(_vm.device.overriddenPolicyType)},
                { libelle  : 'Last Google report time :', value: _vm.device.errorAamDeviceNotFound ? _vm.device.errorAamDeviceNotFound : _vm.device.lastStatusReportTime, display:  _vm.device.androidSilentUpdate},
                { libelle  : 'Show the policy', icon: 'loupe', id:'policy', linkStyle:true, display: (_vm.isAdmin() || _vm.isCP) && _vm.device.androidSilentUpdate},
                { libelle  : 'Dev mode: ', id:'dev-mode', renderer : {component: _vm.renderOnOff, param: {isOn: _vm.devMode || _vm.kiosk.dev_mode, error: _vm.devModeError}}, display: (_vm.isAdmin() || _vm.isCP) && _vm.device.androidSilentUpdate && _vm.isKiosk},
                //{ libelle  : 'Screen Orientation: ', id:'screen-orientation', flexStyle:true, renderer : {component: renderSetDeviceOrientation, param: {screenOrientation, error: screenOrientationError}}, display: (isAdmin() || isCP) && device.androidSilentUpdate && isKiosk}
            ],"id-device":_vm.device.id},on:{"clickOnInfo":_vm.clickOnInfo,"dispatchValue":_vm.specialComponentChange}}),_c('card-info',{attrs:{"icon":"perm_device_info","title":"Device","contents":[
                    { libelle  : 'Type :', value: _vm.device.type },
                    { libelle  : 'Constructor :', value: _vm.device.manufacturer },
                    { libelle  : 'Model :', value:  _vm.device.model },
                    { libelle  : 'Serial number :', value:  _vm.device.serialNumber },
                    { libelle  : 'IMEI :', value:  _vm.device.imei },
            ]}}),_c('card-info',{attrs:{"icon":"settings","title":"OS","contents":[
                { libelle  : 'OS :', value:  _vm.device.os },
                { libelle  : 'Version :', value:  _vm.device.version },
                { libelle  : 'Build version :', value:  _vm.device.buildVersion },
            ]}}),_c('card-info',{attrs:{"icon":"contact_mail","title":"Users","contents":[
                { libelle  : 'Enrolled by :', value: _vm.device.enrolledBy || 'Unknown', display: _vm.device.androidSilentUpdate },
                { libelle  : 'Last user logged :', value: _vm.device.lastUsedLogged },
                { libelle  : 'Email :', value: _vm.device.email },
                { libelle  : 'Show the stores', icon: 'loupe', id:'stores', linkStyle:true, display: _vm.isAdmin() || _vm.isCP},
            ]},on:{"clickOnInfo":_vm.clickOnInfo}}),_c('card-info',{attrs:{"icon":"sim_card","title":"SIM","contents":[
                { libelle  : 'Phone N° :', value:  _vm.device.phoneNumber },
                { libelle  : 'SIM N° :', value:  _vm.device.simNumber },
            ]}}),_c('card-info',{attrs:{"icon":"cell_wifi","title":"Network","contents":[
                { libelle  : 'Wifi :', value: _vm.device.wifi },
                { libelle  : 'Address IP :', value:  _vm.device.adressIp },
                { libelle  : 'Mac address :', value:  _vm.device.macAdress },
            ]}}),(_vm.displayErrorMessage)?_c('error-kiosk-update',{attrs:{"icon":"live_help","title":"Support informations"},on:{"closeErrorMessage":function($event){_vm.isDisplayError=false}}}):_vm._e(),(_vm.device.type==='KIOSK' && !_vm.displayErrorMessage)?_c('card-kiosk-info',{attrs:{"icon":"live_help","title":"Support informations","content":_vm.contentComputed},on:{"updateKiosk":_vm.updateKiosk}}):_vm._e()],1),(_vm.loading)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.loading)?_c('md-progress-spinner',{staticClass:"loading",attrs:{"md-diameter":100,"md-stroke":10,"md-mode":"indeterminate"}}):_vm._e(),_c('mdm-json-dialog',{attrs:{"json":_vm.jsonPolicy,"showDialog":_vm.showPolicy},on:{"close":function($event){_vm.showPolicy=false}}}),_c('store-dialog',{attrs:{"uid":_vm.device.lastUsedLogged,"stores":_vm.stores,"showDialog":_vm.showStores},on:{"close":function($event){_vm.showStores=false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }