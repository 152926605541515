<template>
    <v-container class="mdm-vtmn-padding mdm-vtmn-margin input-icons p-0">
        <v-row no-gutters>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 10">
                <div class='p-0' >
                    <mdm-multi-choice
                        :id='id' :value="value"
                        :label="''"
                        keyToPrint="key"
                        :placeholder="placeholder"
                        :find-item-in-api="deviceExistsByDefault"
                        :valid-criteria-callback="validCriteriaCallback"
                        :has-to-check-numeric-type="true"
                        :variant="VARIANT_SEARCH_DEVICE"
                        :already-selected-error="alreadySelectedError"
                        @startSearch="search"
                        @selected="select"
                        @updateAlreadySelected="updateAlreadySelected"
                        @displayDetailedSearch="display"
                        @reloadSecondFilterList="reloadSecondFilterList"
                        @allowedClickOnDevice="allowedClickOnDevice"
                        :map-search-term="mapSearchTerm"
                    >
                        <template slot="error-text-already-selected">{{Constants.DEVICE.SEARCH.DUPLICATED_SEARCH_INPUT}}</template>
                        <template slot="error-text-invalid-criteria">{{Constants.DEVICE.SEARCH.INVALID_SEARCH_INPUT}}</template>
                    </mdm-multi-choice>
                </div>
            </v-col>

            <v-col v-if="!$vuetify.breakpoint.mobile" cols="2" align-self="center" align="right">
                <router-link id="devices-btn-new-device" :to="newDeviceRedirectPath" class="router-link">
                    <button class="vtmn-btn btn-center" id="devices-icon-search"><i class="ri-add-line"></i> New device</button>
                </router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import FieldConstant from "../../utils/field-constant";
    import stringUtils from '../../utils/string-utils';
    import Constants from "../../constant/constants";
    import NewDeviceConstants from "../../constant/new-device-constant";
    import UrlConstants from "../../constant/url-constant";
    import ParameterApiService from "../../service/admin/parameter/parameter-api-service";

    export default {
        name: 'device-general-search',
        props: {
            id: {
                type: String,
                required: true
            },
            placeholder: {
                type: String
            },
            value: {
                type: Array
            },
            alreadySelectedError: {
                type: Boolean,
                default: false
            },
        },

        data: function() {
            return {
                searchTerms: null,
                searchError : false,
                selectedCriteria: [],
                VARIANT_SEARCH_DEVICE: FieldConstant.COMPONENT.MULTI_CHOICE.VARIANT.SEARCH_DEVICE,
                ALLOWED_CRITERIAS: FieldConstant.DEVICE.SEARCH.ALLOWED_CRITERIAS,
                NewDeviceConstants,
                UrlConstants,
                Constants,
                newDeviceRedirectPath: "",
            }
        },

        async mounted(){
            this.getNewDeviceRedirectPath();
        },

        methods: {

            async getNewDeviceRedirectPath(){
               const enrolmentViaWso = await ParameterApiService.getBooleanBy("front.wso.enrolment.global.activated", NewDeviceConstants.WSO_ENROLMENT_GLOBAL_ACTIVATED);
               this.newDeviceRedirectPath = enrolmentViaWso ? UrlConstants.wsoSuffix : UrlConstants.rgpdSuffix;
            },

            updateAlreadySelected(alreadySelected){
                this.$emit('updateAlreadySelected', alreadySelected)
            },
            mapSearchTerm(searchTerm) {
                return stringUtils.mapSearchTerm(searchTerm);
            },
            validCriteriaCallback(item) {
                if(!item.key || item.key.length === 0) return true;
                return stringUtils.canParseDeviceSearchCriteria(item, this.selectedCriteria);
            },

            async deviceExistsByDefault(idDevice){
                return true;
            },
            search(value){
                this.$emit("startSearch", value);
            },
            select(value) {
                this.selectedCriteria = value;
            },
            display: function() {
                this.$emit('displayDetailedSearch', true)
            },
            reloadSecondFilterList: function() {
                this.$emit("reloadSecondFilterList")
            },
            allowedClickOnDevice: function() {
                this.$emit('allowedClickOnDevice')
            }
        }
    }
</script>

<style lang="scss" scoped>
@import './../applications/group/new/perimeter/perimeter.scss';

.btn-center {
    margin-bottom: 10px;
}
.vtmn-btn {
    background-color: #3643BA;
    &:hover{
        background-color: #3643BA;
        opacity: 0.7;
    }
}

.md-theme-default a:not(.md-button):hover {text-decoration:none;}

</style>
