<template>
    <div>
        <mdm-vtmn-popup ref="popupStores"
                        id-button-open="application-group-button-add-perimeter"
                        :class-button-open="'vtmn-btn vtmn-btn_variant--ghost vtmn-btn_size--stretched border-btn ' + classForPerimeterBouton()">
            <template v-slot:button-open-content>
                Select a perimeter
                <md-icon v-if="arbo && countDevice > 0" id="check-icon">check</md-icon>
            </template>

            <mdm-vtmn-adjust-card>
                <template v-slot:header>
                    <p class="vtmn-typo_title-5" align="center">Deployment perimeter</p>    
                </template>

                <arbo type="light" :source="arbo" @changeArbo='changeArbo' @countryCount='handleCountryCount' :country-count-needed="true" />

                <template v-slot:footer>
                    <mdm-vtmn-button id="application-group-button-add-perimeter-close" @click="refresh() && $refs.popupStores.close()">Validate</mdm-vtmn-button>
                </template>
            </mdm-vtmn-adjust-card>
        </mdm-vtmn-popup>
        <div v-if="countDevice > 0"> 
            {{ countDevice }} devices impacted 
            <span v-if="countryCount"> in {{ countryCount.siteId.length }} {{ countryCount.siteId.length > 1 ? 'stores' : 'store' }}</span>
        </div>
    </div>
</template>

<script>    
    import Vue from 'vue';
    import applicationService from '../../../../../service/application/application-service';
    import FilterDeviceService from '../../../../../service/devices/filter-device-service';
    import GroupService from '../../../../../service/group/group-service';
    
    export default {
        name: "group-choose-store",
        data: () => {
            return {
                countDevice: 0,
                countryCount: null
            }
        },
        computed: {
            group() {
                return this.$store.state.group.group;
            },
            arbo() {
                return this.$store.state.group.group.arbo;
            }
        },
        watch: {
            arbo(newArbo) {
                this.refresh();
            }
        },
        methods: {
            changeArbo(arbo) {
                this.refresh();
                this.$store.dispatch('group/setGroup', {
                    requirement: {
                        ...this.group.requirement,
                        arbo
                    }
                }); 
            },
            classForPerimeterBouton() {
                if(this.arbo && this.countDevice > 0) {
                    return "vtmn-btn--icon-right color-green"; 
                }
                return "";
            },
            handleCountryCount(countryCount) {
                this.countryCount = countryCount;
            },
            refresh : function(){
                return GroupService.countUser(this.group, true).then((response) => { 
                    this.countDevice = response.body.count
                }, (error) => {
                    console.warn("Count user not work.", error);
                    this.$store.dispatch("group/setCountUser", null);
                });
            }
        }
    }
</script>

<style lang="scss">
.border-btn {
    border: 1px solid !important;
}

.color-green {
    color: var(--vtmn-color_green) !important;
}

#check-icon {
    color: var(--vtmn-color_green) !important;
    margin-left: 10px;
}

#application-group-button-add-perimeter-close {
    margin: auto;
}
</style>
