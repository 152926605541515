import grantConstant from "./grant-constant";

const RISK = {
    LOW: "low",
    MEDIUM: "medium",
    HIGH: "hight",
};

const GRANT_TYPE = {
    APPLICATION: "application",
    DEVICE: "device",
    GROUP: "group",
};

function grantAdmin() {
    return grantConstant.ENABLED;
}

function grantCC(user, el) {
    if (el.isWipe) return user.appManager ? grantConstant.ENABLED : grantConstant.DISABLED;

    if (el.category === GRANT_TYPE.DEVICE) {
        if (el.risk !== RISK.HIGH) {
            return grantConstant.ENABLED;
        }
    } else if (el.category === GRANT_TYPE.APPLICATION) {
        if (el.uid.toLowerCase() === user.displayName.toLowerCase()) {
            return grantConstant.ENABLED;
        }
    }
    return grantConstant.DISABLED;
}

function grantCP(user, el) {

    const deviceAndRiskMediumHigh = el.category === GRANT_TYPE.DEVICE && (el.risk === RISK.MEDIUM || el.risk === RISK.HIGH);
    const applicationAndRiskMedium = el.category === GRANT_TYPE.APPLICATION && el.risk === RISK.MEDIUM;
    const deviceApplicationAndRiskLow = (el.category === GRANT_TYPE.APPLICATION || el.category === GRANT_TYPE.DEVICE)
        && el.risk === RISK.LOW;

    if (el.isWipe) return user.appManager ? grantConstant.ENABLED : grantConstant.DISABLED;

    if (deviceApplicationAndRiskLow) {
        return grantConstant.ENABLED;
    }

    if (deviceAndRiskMediumHigh || applicationAndRiskMedium) {
        let cpStatus = false;
        el.countries.forEach(function (elCountry, i) {
            user.countries.forEach(function (userCountry, i) {
                if (elCountry === userCountry) {
                    cpStatus = true;
                }
            });
        });
        if (cpStatus) {
            return grantConstant.ENABLED;
        }
    }

    return grantConstant.DISABLED;
}

function grantAM(user, el) {
    return el.category === GRANT_TYPE.APPLICATION ? grantConstant.ENABLED : grantConstant.DISABLED;
}

export default {
    grant(state, el) {

        /*
          state : information contenue dans le store vuex
          el : {
            category - string: 'device', 'application'
            risk     - string : 'low', 'hight'
                      - hight :
                       - application :
                       - device : wipe, delete
                      - medium : tous les autres sauf que consultation
                      - low : que consultation
            countries - array : tableau des pays d'un device ou d'une app
            uid - string: id de la personne qui a créé l'app
          }
        */

        const user = state.user;
        const canManageApplications = user.appManager
        && el.category === GRANT_TYPE.APPLICATION ? grantConstant.ENABLED : grantConstant.DISABLED;

        switch (true) {
            case user.admin:
                return grantAdmin();
            case user.cp :
                return canManageApplications === grantConstant.ENABLED ? canManageApplications : grantCP(user, el);
            case user.cc:
                return canManageApplications === grantConstant.ENABLED ? canManageApplications : grantCC(user, el);
            case user.appManager:
                return canManageApplications === grantConstant.ENABLED ? canManageApplications : grantAM(user, el);
            default:
                return  grantConstant.DISABLED;
        }
    },
    grantViaRolesOnly({user}, neededRoleLists) {
        if (user.admin) return grantConstant.ENABLED;
        let hasNeededRoles = false;
        neededRoleLists.forEach(roleList => {
            if (roleList.every(role => user[role] === true)) hasNeededRoles = true;
        });
        return hasNeededRoles ? grantConstant.ENABLED : grantConstant.DISABLED;
    }
};
