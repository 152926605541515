<template>
    <redirection-enrollment :message="message" :link="link" :logo="logo"/>
</template>

<script>
import RedirectionEnrollment from "@/pages/devices/new/step/enrollment/redirection-enrollment";
import NewDeviceConstant from "@/constant/new-device-constant";
export default {
    name: "wso-enrollment",
    components: {RedirectionEnrollment},
    data : function() {
        return {
            message: "As a selected WORKSPACE ONE user, You are invited to enroll your device using this solution.",
            link: NewDeviceConstant.WSO_ENROLMENT_PROCEDURE_LINK,
            logo: "wso"
        }
    }
};
</script>
