<template>
    <v-col cols="12" md="3" sm="6" class="info-component">
        <h2 class="md-title"><md-icon>{{ icon }}</md-icon> {{title}} </h2>
        <div class='information-phone area'>
            <v-row>
                <v-col cols="8" align-self="center" class="pr-1" >
                    <div class='information-item align-input-and-text'>
                        <span class='label'>Start at: </span>
                        <span v-if="!isDisplayEditMode" class='value'>{{ rebootTimeDisplayed}}</span>
                        <span v-else class='value'>
                            <el-time-select
                                :id="'kiosk-starting-time-choice'"
                                v-model="rebootTime"
                                :picker-options="pickerOptions"
                            />
                        </span>
                    </div>
                    <div class='information-item align-input-and-text'>
                        <span class='label'>Stop at: </span>
                        <span v-if="!isDisplayEditMode" class='value'>{{ lockTimeDisplayed}}</span>
                        <span v-else class='value align-input-stop-at'>
                            <el-time-select
                                :id="'kiosk-starting-time-choice'"
                                v-model="lockTime"
                                :picker-options="pickerOptions"
                            />
                        </span>
                    </div>
                </v-col>

                <v-col v-if="userUtils.canUpdateKioskInfo()" cols="4" align-self="center" >
                    <buttons-change-kiosk-hours
                        :is-display-edit-mode=isDisplayEditMode
                        :lock-time="lockTime"
                        :reboot-time="rebootTime"
                        @updateKiosk="updateKiosk"
                        @refreshHours="refreshHours"
                        @changeEditMode="changeEditMode"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <div class='information-item' v-if="contentInfos.aam && contentInfos.idleModeInfo">
                        <span class='label'>Idle mode :</span>
                        <span class='value'>{{contentInfos.idleModeInfo.mode}}</span>
                    </div>
                    <div class='information-item' v-if="contentInfos.aam && contentInfos.idleModeInfo && contentInfos.idleModeInfo.message != undefined">
                        <span class='label'>Idle message :</span>
                        <span class='value'>{{contentInfos.idleModeInfo.message}}</span>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-col>
</template>

<script>
import ButtonsChangeKioskHours from './buttonsChangeKioskHours'
import KioskInfosConstant from '../../../constant/kiosk-infos-constant'
import TimeUtils from '../../../utils/time-utils'
import userUtils from '../../../utils/user-utils';

export default {
    name: "cardKioskInfo",
    components : {
        ButtonsChangeKioskHours
    },
    props : {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        content: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isDisplayEditMode : false,
            lockTime : this.content.kioskStopAt,
            rebootTime : this.content.kioskStartAt,
            contentInfos : this.content,
            infoNoAamKiosk : KioskInfosConstant.INFOS_KIOSK_NO_AAM,
            pickerOptions: {
                start: '07:00',
                step: '00:5',
                end: '22:00'
            },
            userUtils
        }
    },
    computed: {
        rebootTimeDisplayed() {
            return this.contentInfos.kioskStartAt
        },
        lockTimeDisplayed() {
            return this.contentInfos.kioskStopAt
        }
    },
    methods: {
        updateKiosk(hours) {
            this.$emit('updateKiosk', hours)
        },
        refreshHours(rebootTime, lockTime) {
            this.contentInfos = {
                kioskStartAt : TimeUtils.formatHoursDisplayed(rebootTime),
                kioskStopAt : TimeUtils.formatHoursDisplayed(lockTime),
                aam: this.content.aam,
                idleModeInfo: this.content.idleModeInfo
            }
        },
        changeEditMode(isDisplayEditMode) {
            this.isDisplayEditMode = isDisplayEditMode
        }
    },
    watch: {
        content() {
            this.contentInfos = this.content
            this.rebootTime = this.content.kioskStartAt
            this.lockTime = this.content.kioskStopAt
        }
    }
};
</script>

<style scoped>
.info-component {
    padding: 0px 10px;
}

@media (max-width: 960px) {
    .info-component {
        padding: 10px 10px;
    }
}

.information-phone {
    height: 90%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 97px;
    margin-left: 1px;
}

.align-input-and-text {
    display: flex;
    align-items: center;
}

.align-input-stop-at {
    margin-left: 7px
}

.value {
    overflow: initial;
}

.vtmx-information-line {
    display: inline-block;
}


</style>
