<template>
    <div class="datepicker-content">
        <v-row>
            <v-col cols="12">
                <v-menu v-model="isDisplay" :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            placeholder="Please select date"
                            :value="date"
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="date = null"
                            class="vtmn-text-input input-fill-all-width"
                        />
                    </template>
                    <v-date-picker v-model="date" @change="isDisplay = false ; getDateEvent()"/>
                </v-menu>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FieldConstant from "../../../../../src/utils/field-constant";
import moment from "moment";
import {format, parseISO} from "date-fns";

export default {
    name: "mdm-date-picker",
    data: function () {
        return {
            date: null,
            isDisplay: false
        };
    },
    methods: {
        getDateEvent: function () {
            this.$emit("getDateEvent", this.date);
        }
    }
};
</script>

<style lang="scss">

.v-menu__content {
    z-index: 999 !important;
}

.datepicker-content {

    .vtmn-text-input {
        height: 44px;
    }

    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
        border: none;
    }

    .v-text-field input {
        padding: 0;
    }
}


</style>
