<template>
  <div>
    <md-dialog :md-active="showDialog" class="jsonDialog">
      <div>
        <div class="onLeft">
          <h1>Device policy</h1>
        </div>
        <div class="onRight">
          <mdm-button :id="'mdm-button-close-dialog'"   :text="''" :icon="'close'" @click="closeAction" />
        </div>
      </div>

      <div class="dialog-content jsonContent" md-label="General">
        <json-viewer :value="json" :expand-depth=4 copyable/>
      </div>

    </md-dialog>
  </div>
</template>

<script>
// This is a component to display a screenshot on a popup
export default {
  props: {
    showDialog: {
      type : Boolean
    },
    json: {
      type: Object
    }
  },
  methods: {
    closeAction : function () {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
  .jsonDialog {
    max-width: 850px;
    max-height: 100%;
  }
  .jsonContent {
    overflow : auto;
  }
  .onRight {
    right: 0px;
    position: absolute;
  }
  .onLeft {
    left: 10px;
    top : 10px;
    position: absolute;
  }
  .onLeft h1 {
    font-size: 23px;
  }
  .dialog-content {
    margin-top: 50px;
    padding: 20px;
  }
</style>
