<template>
    <div @drop.prevent="addFile" @dragover.prevent>
         <mdm-vitamine-card
            :id="id"
            title="ADD A DECATHLON'S APP (PRIVATE)"
            :minimized="minimized"
            @click="cardClicked" @close="cardClose">
                <div v-if="!isActive">
                    <p>A private application is an application designed within the Decathlon ecosystem</p>
                </div>
                <v-col cols="12" md="4" sm="10" v-else>
                    <div v-if="uploadError.length === 0 && !loading">
                        <img src=https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png class="google-logo"/>
                        <div class='information-item' id="applications-private-app-card-file-warning">
                            <div class="warning-title"><span aria-label="Information icon" class="vtmx-information-line"/><span>Attention</span></div>
                            <span class='value'>{{infosOnlyAab}}</span>
                        </div>
                    </div>
                    <div v-else v-for="error in uploadError" :key="error" id="applications-new-panel-error-on-upload">
                        <md-content class="md-accent">{{error}}</md-content>
                    </div>
                </v-col>
                <template v-slot:footer>
                    <div v-if="!isActive" class="under-description-logo">
                        <img :src="logo['CIS']" alt="Cube in store logo" />
                        <img :src="logo['PLUS']" alt="Plus V3 logo" />
                        <img :src="logo['SNB']" alt="Stock'n Business logo" />
                    </div>
                    <div v-else>
                        <div v-if="loading">
                            <mdm-vitamine-loader :progress="uploadProgress" />
                        </div>
                        <div v-else>
                            <mdm-vtmn-button v-if="isActive" id="applications-new-btn-add-private-app-import" @click="searchAppClicked">Import</mdm-vtmn-button>
                            <input ref="searchAppInput" type ="file" id="applications-new-input-add-private-app-import" :model='file' @change='checkApp' />
                        </div>
                    </div>
                </template>

        </mdm-vitamine-card>
    </div>
</template>

<script>
import Vue from 'vue';
import MdmVitamineCard from '../../../../components/mdm/vitamine/card/mdm-vtmn-card';
import FieldConstant from './../../../../utils/field-constant';
import MdmVitamineLoader from '../../../../components/mdm/vitamine/loader/mdm-vtmn-loader';

const UploadService = require('../../../../service/upload-service');
import applicationService from '../../../../service/application/application-service';

export default {
    name: 'private-app-card',
    components: {
        MdmVitamineCard, MdmVitamineLoader
    },
    data: function() {
        return {
            isActive: false,
            file: null,
            loading: false,
            uploadError: [],
            logo: {
                'CIS': '',
                'PLUS': '',
                'SNB': ''
            },
            ALLOWED_EXTENSIONS: FieldConstant.APPLICATION.ALLOWED_EXTENSIONS_WITHOUT_APK,
            infosOnlyAab: FieldConstant.APPLICATION.INFOS_ONLY_AAB
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        minimized: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        uploadProgress(){
            return this.$store.getters['application/getUploadProgress'];
        }
    },
    async created() {
        this.logo['CIS'] = await this.getPrivateAppIcon('com.oxylane.android.cubeinstore');
        this.logo['PLUS'] = await this.getPrivateAppIcon('com.oxylane.plus');
        this.logo['SNB'] = await this.getPrivateAppIcon('com.decathlon.inventory');
    },
    methods: {
        addFile(e) {
            let droppedFiles = e.dataTransfer.files;
            if (droppedFiles.length > 0) {
                this.isActive = true;
                this.checkApp(droppedFiles);
            }
        },
        cardClicked(info) {
            this.isActive = true;
            this.$emit("click", info);
        },
        cardClose(info) {
            this.isActive = false
            this.$emit("close", info);
        },
        searchAppClicked() {
            this.uploadError = [];
            this.$refs.searchAppInput.click();
        },

        setError(message) {
            let messages = message
            if(!Array.isArray(message)) messages = [message];

            this.uploadError = messages;
            this.loading = false;
        },

        manageError(error) {

            const errorMessageRaw = error.message || error.error || "A problem occurred during upload. Please retry, or contact an administrator";

            if(!error?.errors || (Array.isArray(error.errors) && !error.errors[0].errorMessage)) {
                return this.setError(errorMessageRaw);
            }

            return this.setError(error.errors.map(err => err.errorMessage));
        },

        checkApp : function(selectedFiles) {
            this.loading = true;
            UploadService.uploadFileIfIsOk(selectedFiles).then(applicationInformation => {
                this.$ga.event({eventCategory: 'application', eventAction: 'upload', eventLabel: JSON.stringify(applicationInformation)});
                this.loading = false;
                this.$store.dispatch('application/setApplication', applicationInformation);
                this.$router.push({name: "application-private-information"});
            }).catch(e => {
                this.manageError(e);
            }).finally(() => {
                this.loading = false;
            });
        },

        async getPrivateAppIcon(appId) {
            return (await applicationService.getApplicationIcon(appId)).imageBase64;
        }
    }
}
</script>

<style lang="scss" scoped>
#applications-new-input-add-private-app-import {
    display: none;
}
.upload-progress-bar{
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--vtmn-color_brand);
    width: 50%;
    margin: 0 auto;
}

.vtmx-information-line {
    font-size: 25px;
    margin-right: 5px;
}

.warning-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 5px;
}

.google-logo {
    width: 15%;
    display: initial;
    margin-top: 20px;
    margin-bottom: 15px;
}

</style>
