import appTypeEnum from "../../../service/application/app-type-enum";
import applicationService from "../../../service/application/application-service";
import router from "../../../router/router";
const WEBVIEW_CONTENT_RESET = {
    display: 'FULL_SCREEN'
}
const state = () => ({
    webview: WEBVIEW_CONTENT_RESET,
    steppers: {
        activeStep: null,
    },
    saveApp: {
        loading: false,
        error: null
    },
})

const getters = {
    getWebview(state) {
        return state.webview;
    },
    getSaveApp(state) {
        return state.saveApp;
    },
    getActiveStep(state) {
        return state.steppers.activeStep;
    }
}

const actions = {

    setWebview : async (context, data) => {
        context.commit('setWebview', data);
    },
    setSaveApp({commit}, loading, error) {
        commit('setSaveApp', loading, error);
    },
    setActiveStep : async (context, data) => {
        context.commit('setActiveStep', data);
    },
    async webviewSave({commit, getters}, route) {
        await saveApplication(commit, getters.getWebview, route, appTypeEnum.WEBVIEW);
    },

}

const mutations = {

    setWebview : (state, webview) => {
        state.webview = {...state.webview, ...webview};
    },
    setSaveApp(state, status) {
        state.saveApp = {...state.saveApp, ...status};
    },
    setActiveStep : (state,step) => {
        state.steppers.activeStep = step;
    },

}

const saveApplication = async (commit, applicationToSave, route, appType = appTypeEnum.PRIVATE_APP) => {
    try {
        commit("setSaveApp", {loading: true, error: null});

        let application;
        if(applicationToSave.packageName) {
            application = await applicationService.updateApplication(applicationToSave, appType);
        } else {
            application = await applicationService.saveApplication(applicationToSave, appType);
        }

        document.getElementById('feedback')?.click();

        commit("setSaveApp", {loading: false});

        router.push({
            'path': `/applications/${application.id}${route}`
        });
    } catch(e) {
        let message = "An error occurred. Please, retry in a few minutes or contact the MDM administrator.";
        if(e.data.errors) message = e.data.errors[0].errorMessage;
        if((e.code === 409 || e.status === 409) && e.data?.errors) {
            let errorState = e.data.errors.filter((error) => error.code == 409);
            if(errorState.length > 0) message = errorState[0].errorMessage;
        }

        console.error("An error occurred when saving app:", e);
        commit("setSaveApp", {loading: false, error: message});
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
